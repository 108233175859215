<template>
  <UserListComponent></UserListComponent>
</template>

<script>
/**compoenents */
import UserListComponent from "./UserListComponent.vue"

export default {
  name: 'OtherUsers',  
  components: {
    UserListComponent
  }
}
</script>