<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 col-12">
        <changeAsset type="increase" :id="this.$router.history.current.params.id" />
      </div>
      <div class="col-md-6 col-12">
        <changeAsset type="decrease" :id="this.$router.history.current.params.id" />
      </div>
    </div>

    <AssetsComponent :columns="columns" :isAllUsers="false"></AssetsComponent>
  </div>
</template>

<script>

import changeAsset from "./ChangeAsset"
import AssetsComponent from "../components/AssetsComponent";


export default {
  name: 'userAsset',
  data() {
    return {
      columns: [

        {
          label: 'ارز',
          field: 'logoUrl',
          sortable: false
        },
        {
          label: 'حجم کل',
          field: 'amountTotal',
          sortable: false
        },
        // {
        //   label: 'نماد',
        //   field: 'symbol',
        //   sortable: false
        // },
        {
          label: 'Spot',
          field: 'amountMainSpot',
          sortable: false
        },
        {
          label: 'قفل شده در Spot',
          field: 'amountMainSpotLocked',
          sortable: false
        },
        {
          label: 'ارزش تومانی',
          field: 'amountTotalAsTmn',
          sortable: false
        },
        {
          label: 'ارزش دلاری',
          field: 'amountTotalAsUsdt',
          sortable: false
        },
        {
          label: 'ارزش بیت کوینی',
          field: 'amountTotalAsBtc',
          sortable: false
        },
      ],
    }
  },

  components: {
    changeAsset,
    AssetsComponent
  }
}
</script>

<style>
.network {
  text-transform: uppercase;
}
</style>
