<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="card-title">
              <h6>لیست کارت ها</h6>
            </div>
          </div>

          <!-- the page content  -->
          <div class="card-body">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="columns"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <div @click="rowClick(props.row)" class="table-row">
                  <!-- created field  -->
                  <span v-if="props.column.field == 'created'">
                    {{ jdate(props.row.created) }}
                  </span>

                  <!-- IBAN field  -->
                  <span v-else-if="props.column.field == 'IBAN'">
                    {{ props.row.IBAN }}
                    <img
                      :src="require('../../../assets/img/copy.svg')"
                      alt="copy"
                      class="copy"
                      @click="copyCode(props.row.IBAN)"
                    />
                  </span>

                  <!-- card_number field  -->
                  <span v-else-if="props.column.field == 'card_number'">
                    {{ props.row.card_number }}
                    <img
                      :src="require('../../../assets/img/copy.svg')"
                      alt="copy"
                      class="copy"
                      @click="copyCode(props.row.card_number)"
                    />
                  </span>

                  <!-- type field  -->
                  <span
                    v-else-if="props.column.field == 'status'"
                    v-html="getStatusLabel(props.row.status)"
                  >
                  </span>

                  <!-- show in default  -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </div>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from "vue-good-table";

/**import mixin */
import Helper from "../../../settings/Helper";

export default {
  name: "Cards",
  mixins: [Helper],
  data() {
    return {
      columns: [
        {
          label: "کاربر",
          field: "user.username",
          sortable: false,
        },
        {
          label: "IBAN",
          field: "IBAN",
          sortable: false,
        },
        {
          label: "تاریخ",
          field: "created",
          sortable: false,
        },
        {
          label: "بانک",
          field: "bank",
          sortable: false,
        },
        {
          label: "شماره کارت",
          field: "card_number",
          sortable: false,
          html: true,
        },
        {
          label: "وضعیت",
          field: "status",
          sortable: false,
        },
      ],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 10,
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: "بعدی",
        prevLabel: "قبلی",
        firstLabel: "اولین",
        lastLabel: "آخرین",
        rowsPerPageLabel: "تعداد ردیف هر صفحه",
        ofLabel: "از",
        pageLabel: "صفحه", // for 'pages' mode
        allLabel: "همه",
        perPageDropdown: [10, 20, 30, 40, 50],
        dropdownAllowAll: false,
      },
    };
  },
  methods: {
    /**row click
     * on row click go to user detail
     */
    rowClick(row) {
      this.$router.push({
        path: "carts/" + row.id + "/tab1",
        replace: true,
      });
    },

    async copyCode(s) {
      await this.copyToClipboard(s);
      this.$notify({
        text: "کپی انجام شد",
        type: "success",
        group: "copynotif",
      });
    },

    /**handle unssl server */
    async copyToClipboard(textToCopy) {
      // navigator clipboard api needs a secure context (https)
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return await navigator.clipboard.writeText(textToCopy);
      } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
        });
      }
    },

    /**
     * @param status
     * @return string the translated of status
     */
    getStatusLabel(status) {
      if (status) {
        return '<span class="badge badge-success">فعال</span>';
      } else {
        return '<span class="badge badge-danger">غیرفعال</span>';
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage;
        let offset = this.serverParams.perPage * (this.serverParams.page - 1);
        this.isLoading = true;
        let res = await this.$axios({
          method: "get",
          url: "card/?limit=" + perPage + "&offset=" + offset,
        });
        this.totalRecords = res.data.count;
        this.rows = res.data.results;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    this.$rtl.enableRTL();

    /**get orders */
    try {
      this.isLoading = true;
      let res = await this.$axios({
        method: "get",
        url: "card/?limit=10&offset=0",
      });
      this.totalRecords = res.data.count;
      this.rows = res.data.results;
      this.isLoading = false;
    } catch (error) {
      console.error(error);
    }
  },
  components: {
    VueGoodTable,
  },
};
</script>

<style>
/**vue table */
@import "~vue-good-table/dist/vue-good-table.css";

.copy {
  width: 14px !important;
  cursor: pointer;
}
</style>
