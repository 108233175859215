<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <!-- title and filter -->
          <div class="card-header">
            <div class="card-title d-flex justify-content-between">
              <h6>لیست کاربران سطح {{$router.history.current.params.id}}</h6>
              <h6 class="filter-title" @click="filter = !filter">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر کاربران
              </h6>
            </div>
            <!-- filter content -->
            <card v-if="filter" class="filter">
              <form @submit.prevent="loadItems">
                <div class="row">
                  <!-- name farsi -->
                  <base-input dir="ltr" class="col-12 col-lg-4" label="نام کاربر" type="text" placeholder="نام کاربر" v-model="userFilter"> </base-input>

                  <!-- fromFilter -->
                  <div class="col-12 col-md-6 col-xl-4 date-holder">
                    <base-input class="fromDate" label="از تاریخ" type="text" placeholder="از تاریخ" v-model="fromFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDate('from')" type>
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="fromFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".fromDate" view="year" auto-submit></date-picker>
                  </div>

                  <!-- toFilter -->
                  <div class="col-12 col-md-6 col-xl-4 date-holder">
                    <base-input class="toDate" label="تا تاریخ" type="text" placeholder="تا تاریخ" v-model="toFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDate('to')" type>
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="toFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".toDate" view="year" auto-submit></date-picker>
                  </div>
                </div>

                <!-- checkbox's and buttons  -->
                <div class="row">
                  <!-- checkbox 1 -->
                  <base-checkbox v-if="false" v-model="verifyDocumentFilter" class="col-12 col-sm-6 col-md-3 justify-content-start text-center">مدارک تائید شده</base-checkbox>

                  <!-- checkbox 2 -->
                  <base-checkbox v-if="false" v-model="verifyUserFilter" class="col-12 col-sm-6 col-md-3 justify-content-start text-center">کاربران تائید شده</base-checkbox>

                  <!-- btn submit -->
                  <div class="col-12">
                    <base-button class="mt-3 btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                    <base-button class="mt-3 mx-0 mx-sm-3 btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                  </div>
                </div>
              </form>
            </card>
          </div>

          <!-- the page content  -->
          <div class="card-body">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="columns"
              :rtl="true"
              :line-numbers="false"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <div class="table-row">
                  <!-- date_joined field  -->
                  <span v-if="props.column.field == 'date_joined'">
                    {{ jdate(props.row.date_joined) }}
                  </span>

                  <!-- userName -->
                  <a v-else-if="props.column.field == 'userName'" :href="'/#/dashboard/users/' + props.row.userId + '/personal-info'" target="_blank">
                    <span dir="ltr"> {{ props.row.userName }} </span></a
                  >

                  <!-- firstTime field  -->
                  <span v-else-if="props.column.field == 'firstTime'">
                    {{ props.row.firstTime ? jdate(props.row.firstTime) : null }}
                  </span>

                  <!-- firstTime field  -->
                  <span v-else-if="props.column.field == 'descManual'" class="d-flex align-items-center justify-content-center">
                    <input :name="props.row.userId" :id="props.row.firstTime" class="w-100 form-control" @blur="editDescManual" type="text" :placeholder="props.row.descManual ? props.row.descManual : 'توضیحات مدیر'"> </input>
                  </span>

                  <!-- show in default  -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </div>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from 'vue-good-table'

/**convert miladi to jalali */
var moment = require('moment-jalaali')

/**import mixin */
import Helper from '../../../settings/Helper'

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'


// Multiselect
import Multiselect from 'vue-multiselect'

export default {
  name: 'Profiles',
  mixins: [Helper],
  data() {
    return {
      columns: [
        {
          label: 'نام',
          field: 'userName',
          sortable: true
        },
        {
          label: 'توضیحات سیستمی',
          field: 'descSystematic',
          sortable: false
        },

        {
          label: "توضیحات مدیر",
          field: 'descManual',
          sortable: false
        },
        {
          label: 'اولین تاریخ',
          field: 'firstTime',
          sortable: false
        },

      ],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: 30,
        search: null
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: [30, 40, 50, 100],
        dropdownAllowAll: false
      },
      // filter==true => show filter card
      userFilter: null,
      fromFilter: null,
      toFilter: null,
      filter: false
    }
  },
  methods: {
    /**reset search and search filters */
    resetSearch() {
      this.userFilter = this.currencyFilter = this.assetLogFilter = this.fromFilter = this.toFilter = null

      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null
      this.loadItems()

    },
    /**clear from and to date filter */
    eraseDate(type) {
      type == 'from' ? this.fromFilter = null : this.toFilter = null
    },
    /** save change input DescManual when blur  */
    async editDescManual(e) {
      try {
        if (e.target.value) {
          let res = await this.$axios({
            method: 'post',
            url: `/Organizer/TraderLevel/UserDescSet`,
            data: {
              userId: e.target.name,
              firstTime: e.target.id,
              idTraderLevel: this.$router.history.current.params.id,
              descManual: e.target.value
            }
          })
          this.$notify({
            text: 'ویرایش توضیحات مدیر با موفقیت انجام شد',
            type: 'success'
          })
        }
        else
          this.$notify({ text: 'وارد کردن توضیحات مدیر الزامی می باشد', type: 'error' })
      } catch (error) {
        console.error(error)
      }
    },
    
    /**
     * @param status
     * @return string the translated of status
     */
    getStatusLabel(status, title = '') {
      switch (status) {
        case true:
          return '<span class="badge badge-success">تایید شده</span>'
          break;
        case false:
          return '<span class="badge badge-danger">تایید نشده</span>'
          break;
        case 0:
          return `<span class="badge badge-warning">${title}</span>`
          break;
        case 11:
          return `<span class="badge badge-success">${title}</span>`
          break;
        case 81:
          return `<span class="badge badge-danger">${title}</span>`
          break;
        default:
          return '<span>...</span>'
          break;
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      switch (params[0].field) {
        case 'user.username':
          params[0].field = 'user_username'
          break
        case 'user.uid':
          params[0].field = 'user_uid'
          break
        default:
          break
      }
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage
        let offset = this.serverParams.perPage * (this.serverParams.page - 1)
        this.isLoading = true

        /**handle sorts */
        let sort = ''
        if (this.serverParams.sort[0].field) {
          /**check sort asc or desc */
          let sortType = this.serverParams.sort[0].type == 'asc' ? '' : '-'
          sort = this.serverParams.sort[0].field ? '&ordering=' + sortType + this.serverParams.sort[0].field : ''
        }

        let search = ''
        if (this.serverParams.search) {
          search = this.serverParams.search
        }
        let res = await this.$axios({
          method: 'post',
          url: `/Organizer/TraderLevel/UserListGet?PageNumber=${this.serverParams.page}&PageSize=${perPage}`,
          data: {
            searchStatements: [
              {
                key: "srchUser",
                value: this.userFilter ? this.userFilter : ''
              },
              {
                key: "srchDateFrom",
                value: this.fromFilter ? moment(this.fromFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
              },
              {
                key: "srchDateTo",
                value: this.toFilter ? moment(this.toFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
              },

            ],
            idTraderLevel: parseInt(this.$router.history.current.params.id)
          }
        })
        this.totalRecords = res.data.totalRecords
        this.rows = res.data.data
        this.isLoading = false
      } catch (error) {
        console.error(error)
      }
    },

  },
  async mounted() {
    this.$rtl.enableRTL()

    this.loadItems();
  },
  components: {
    VueGoodTable,
    datePicker: VuePersianDatetimePicker,
    Multiselect
  }
}
</script>

<style lang="scss">
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';
.table-row {
  cursor: pointer;
}
.filter-title {
  cursor: pointer;
  color: rgb(133, 131, 131) !important;
}

/**talkhabi customize  */
.vpd-wrapper {
  .vpd-days {
    color: black;
  }
}
</style>
