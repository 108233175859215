<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <!-- title and filter -->
          <div class="card-header">
            <div class="card-title d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <h6>واریز تومانی</h6>
                <base-button v-if="false" type="info" class="mx-3" size="sm" @click="addCoin()">افزودن</base-button>
              </div>
              <h6 class="filter-title" @click="filter = !filter">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر
              </h6>
            </div>
            <!-- filter content -->
            <card v-if="filter" class="filter">
              <form @submit.prevent="loadItems">
                <div class="row">
                  <!-- srchUser  -->
                  <base-input v-if="source != 'user'" dir="ltr" class="col-12 col-md-6 col-xl-3" label=" نام کاربر" type="text" placeholder=" کاربر" v-model="srchUser"> </base-input>

                  <!-- srchFactor  -->
                  <base-input dir="ltr" class="col-12 col-md-6 col-xl-3" label=" شماره فاکتور " type="text" placeholder=" فاکتور" v-model="srchFactor"> </base-input>

                  <!-- fromFilter -->
                  <div class="col-12 col-md-6 col-xl-3 date-holder">
                    <base-input class="fromDate" label="از تاریخ" type="text" placeholder="از تاریخ" v-model="fromFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDate('from')" type>
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="fromFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".fromDate" view="year" auto-submit></date-picker>
                  </div>

                  <!-- toFilter -->
                  <div class="col-12 col-md-6 col-xl-3 date-holder">
                    <base-input class="toDate" label="تا تاریخ" type="text" placeholder="تا تاریخ" v-model="toFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDate('to')" type>
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="toFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".toDate" view="year" auto-submit></date-picker>
                  </div>

                  <!-- srchCSymbol  -->
                  <base-input v-if="false" dir="ltr" class="col-12 col-md-6 col-xl-3" label="نماد بازار" type="text" placeholder="نماد" v-model="srchCSymbol"> </base-input>

                  <!-- srchByAssetLogType -->
                  <div class="col-12 col-md-6 col-xl-4" v-if="false">
                    <span class="cart-lable-color">نوع گزارش</span>
                    <div class="mt-1">
                      <multiselect
                        :showLabels="false"
                        v-model="assetLogFilter"
                        :options="assetLogsFilter"
                        placeholder="نوع تغییر دارایی"
                        track-by="code"
                        label="title"
                        :searchable="false"
                        :allow-empty="false"
                      ></multiselect>
                    </div>
                  </div>

                  <div class="col-12 d-flex justify-content-between flex-wrap">
                    <div class="d-flex flex-wrap">
                      <base-button class="btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                      <base-button class="btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                    </div>
                    <a href="#" target="_blank"><base-button class="btn-filter" type="info" size="sm">دانلود فایل</base-button></a>
                  </div>
                </div>
              </form>
            </card>
          </div>
          <!-- the page content  -->
          <div>
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="source == 'user' ? columnsUser : columns"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- userName -->
                <a v-if="props.column.field == 'userName'" :href="'/#/dashboard/users/' + props.row.userId + '/personal-info'" target="_blank">
                  <span dir="ltr"> {{ props.row.userName }} </span></a
                >

                <!-- stateColumnsDeposit field and copy-->
                <span v-else-if="props.column.field == 'id'" style="font-family: iranyekan !important">
                  {{ props.row.id }}
                </span>

                <!-- type field  -->
                <span v-else-if="props.column.field == 'logoUrl'">
                  <img :src="props.row.logoUrl" alt="copy" class="logo-coin" />
                </span>

                <!--  coin in network -->
                <span class="d-flex flex-column align-items-start" v-else-if="props.column.field == 'coinInNetwork'">
                  <p>
                    <b>{{ props.row.cSymbol }}</b>
                  </p>
                  <span class="coin-symbol">{{ props.row.network.length > 10 ? props.row.network.substring(0, 10) + ' ...' : props.row.network }}</span>
                </span>

                <!-- cptTxHash -->
                <span v-else-if="props.column.field == 'cptTxHash'">
                  <a v-if="props.row.cptTxHash" :href="props.row.cptTxHashExplorerUrl" target="_blank" dir="ltr"> {{ props.row.cptTxHash.substring(0, 5) + ' ...' }} </a>
                  <span v-else>خالی</span>
                </span>

                <!-- limmit field and copy-->
                <span style="cursor: pointer" dir="ltr" @click="copy(props.row.cptAddress)" v-else-if="props.column.field == 'cptAddress'">
                  {{ props.row.cptAddress.substring(0, 5) + ' ...' }}
                </span>

                <!-- reject Reason -->
                <div v-else-if="props.column.field == 'rejectReason'">
                  <span v-if="props.row.rejectReason">
                    {{ props.row.rejectReason }}
                  </span>
                  <span v-if="!props.row.rejectReason"> خالی </span>
                </div>

                <!-- limit text and copy value after click -->
                <span v-else-if="props.column.field == 'bankRefNo'" @click="copy(props.row.bankRefNo)" :title="props.row.bankRefNo" style="cursor: pointer">
                  {{ props.row.bankRefNo ? '...' + props.row.bankRefNo.substring(0, 5) : 'خالی' }}</span
                >

                <span v-else-if="props.column.field == 'bankIban'" @click="copy(props.row.bankIban)" :title="props.row.bankIban" style="cursor: pointer">
                  {{ props.row.bankCard ? '...' + props.row.bankCard.substring(0, 5) : 'خالی' }}</span
                >

                <span v-else-if="props.column.field == 'bankCard'" @click="copy(props.row.bankCard)" :title="props.row.bankCard" style="cursor: pointer">
                  {{ props.row.bankCard ? '...' + props.row.bankCard.substring(0, 5) : 'خالی' }}</span
                >

                <span v-else-if="props.column.field == 'bankTrackId'" @click="copy(props.row.bankTrackId)" :title="props.row.bankTrackId" style="cursor: pointer">
                  {{ props.row.bankTrackId ? '...' + props.row.bankTrackId.substring(0, 5) : 'خالی' }}</span
                >

                <!-- time field -->
                <span v-else-if="props.column.field == 'regTime'" class="d-flex flex-column align-items-center">
                  <b>{{ jdate(props.row.regTime, 'jYYYY/jMM/jDD') }}</b>
                  <b>{{ jdate(props.row.regTime, 'HH:mm:ss') }}</b>
                </span>

                <!-- doneTime -->
                <span v-else-if="props.column.field == 'doneTime'" class="d-flex flex-column align-items-center">
                  <span v-if="props.row.doneTime">
                    <b>{{ jdate(props.row.doneTime, 'jYYYY/jMM/jDD') }}</b>
                    <b>{{ jdate(props.row.doneTime, 'HH:mm:ss') }}</b>
                  </span>
                  <span v-if="!props.row.doneTime"> اجرا نشده </span>
                </span>

                <span v-else-if="props.column.field == 'to'" class="d-flex flex-column align-items-center">
                  <b>{{ jdate(props.row.to, 'jYYYY/jMM/jDD') }}</b>
                  <b>{{ jdate(props.row.to, 'HH:mm:ss') }}</b>
                </span>

                <span v-else-if="props.column.field == 'from'" class="d-flex flex-column align-items-center">
                  <b>{{ jdate(props.row.from, 'jYYYY/jMM/jDD') }}</b>
                  <b>{{ jdate(props.row.from, 'HH:mm:ss') }}</b>
                </span>

                <span v-else-if="props.column.field == 'bankPaidAt'">
                  <div class="d-flex flex-column align-items-center" v-if="props.row.bankPaidAt">
                    <b>{{ jdate(props.row.bankPaidAt, 'jYYYY/jMM/jDD') }}</b>
                    <b>{{ jdate(props.row.bankPaidAt, 'HH:mm:ss') }}</b>
                  </div>
                  <span v-else> پرداخت نشده</span>
                </span>

                <!-- btn field  -->
                <span v-else-if="props.column.field == 'detail'">
                  <base-button class="" type="info" size="sm" @click="detail(props.row)">جزئیات</base-button>
                </span>

                <!-- state  -->
                <span v-else-if="props.column.field == 'state'">
                  <span v-html="getStatusLabel(props.row.idState, props.row.state)"></span>
                </span>

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from 'vue-good-table'

/**convert miladi to jalali */
var moment = require('moment-jalaali')

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

/**import mixin */
import Helper from '../../../settings/Helper'


// Multiselect
import Multiselect from 'vue-multiselect'

export default {
  name: 'DepositTmnFactor',
  mixins: [Helper],
  props: ['source'],
  data() {
    return {
      selectedNetwork: null,
      address: null,
      selectedCoin: null,
      coins: [],
      columns: [
        {
          label: 'نام کاربر',
          field: 'userName',
          sortable: false
        },
        {
          label: 'فاکتور',
          field: 'id',
          sortable: false
        },
        {
          label: 'زمان ثبت',
          field: 'regTime',
          sortable: false
        },
        {
          label: 'مقدار',
          field: 'amount',
          sortable: false
        },
        {
          label: 'وضعیت',
          field: 'state',
          sortable: false
        },
        {
          label: ' نوع بانک',
          field: 'bankOperatorType',
          sortable: false
        },
        {
          label: ' کارت بانکی',
          field: 'bankCard',
          sortable: false
        },
        {
          label: ' کد مرجع',
          field: 'bankRefNo',
          sortable: false
        },
        {
          label: 'کد اولیه',
          field: 'bankTrackId',
          sortable: false
        },
        // {
        //   label: 'زمان پرداخت',
        //   field: 'bankPaidAt',
        //   sortable: false
        // },



      ],
      columnsUser: [
        {
          label: 'فاکتور',
          field: 'id',
          sortable: false
        },
        {
          label: 'زمان ثبت',
          field: 'regTime',
          sortable: false
        },
        {
          label: 'مقدار',
          field: 'amount',
          sortable: false
        },
        {
          label: 'وضعیت',
          field: 'state',
          sortable: false
        },
        {
          label: ' نوع بانک',
          field: 'bankOperatorType',
          sortable: false
        },
        {
          label: ' کارت بانکی',
          field: 'bankCard',
          sortable: false
        },
        {
          label: ' کد مرجع',
          field: 'bankRefNo',
          sortable: false
        },
        {
          label: 'کد اولیه',
          field: 'bankTrackId',
          sortable: false
        },
        // {
        //   label: 'زمان پرداخت',
        //   field: 'bankPaidAt',
        //   sortable: false
        // },



      ],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: window.env.CountItemInPerPage,
        search: null
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: window.env.PerPageDropdown,
        dropdownAllowAll: false
      },
      // filter==true => show filter card
      filter: true,
      srchCSymbol: null,
      srchUser: null,
      srchFactor: null,
      fromFilter: null,
      toFilter: null,
      assetLogsFilter: [{ code: 'SpotPendingOrderList', title: 'سفارشات باز ' }, { code: 'SpotFilledOrderList', title: ' سفارشات پر شده ' }, { code: 'SpotTradeList', title: 'معاملات' }],
      assetLogFilter: { code: 'SpotPendingOrderList', title: 'سفارشات باز ' }

    }
  },
  methods: {
    /** show state with set color 
   * @param int idState->detect state id
   * @param string State->text of state
  */
    /** show state with set color 
   * @param int idState->detect state id
   * @param string State->text of state
  */
    getStatusLabel(idState, state) {
      let setState = '';
      switch (idState) {
        case 11:
          setState = `<span class='text-dark badge badge-warning'>${state}</span>`;
          break;
        case 71:
          setState = `<span class='text-dark badge badge-success'>${state}</span>`;
          break;
        case 41:
          setState = `<span class='text-dark badge badge-danger'>${state}</span>`;
          break;

        default:
          setState = state;
      }

      return setState;
    },
    async copy(value) {
      if (!value) return
      await navigator.clipboard.writeText(value);
      this.$notify({
        text: `${value} کپی شد`,
        type: "success"
      })
    },
    /**reset search and search filters */
    resetSearch() {
      this.srchCSymbol = this.fromFilter = this.toFilter = this.srchUser = this.srchFactor = null

      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null


      this.loadItems()
    },
    /**clear from and to date filter */
    eraseDate(type) {
      type == 'from' ? this.fromFilter = null : this.toFilter = null
    },


    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },


    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage
        let offset = this.serverParams.perPage * (this.serverParams.page - 1)
        this.isLoading = true

        /**handle sorts */
        let sort = ''
        if (this.serverParams.sort[0].field) {
          /**check sort asc or desc */
          let sortType = this.serverParams.sort[0].type == 'asc' ? '' : '-'
          sort = this.serverParams.sort[0].field ? '&ordering=' + sortType + this.serverParams.sort[0].field : ''
        }


        let url = 'Organizer/Report/DepositTmnList'
        let data = [
          {
            key: "srchUser",
            value: this.srchUser ? this.srchUser : ''
          },
          {
            key: "srchFactor",
            value: this.srchFactor ? this.srchFactor : null
          },
          {
            key: "srchCSymbol",
            value: this.srchCSymbol ? this.srchCSymbol : ''
          },
          {
            key: "srchDateFrom",
            value: this.fromFilter ? moment(this.fromFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
          },
          {
            key: "srchDateTo",
            value: this.toFilter ? moment(this.toFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
          },
        ]
        /** detect parant[from report page or user page] */
        if (this.source == 'user') {
          url = 'Organizer/User/DepositTmnList'
          data = {
            userId: this.$router.history.current.params.id,
            searchStatements: [
              {
                key: "srchFactor",
                value: this.srchFactor ? this.srchFactor : null
              },
              {
                key: "srchCSymbol",
                value: this.srchCSymbol ? this.srchCSymbol : ''
              },
              {
                key: "srchDateFrom",
                value: this.fromFilter ? moment(this.fromFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
              },
              {
                key: "srchDateTo",
                value: this.toFilter ? moment(this.toFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
              },
            ]
          }
        }

        let res = await this.$axios({
          method: 'post',
          url: `${url}?PageNumber=${this.serverParams.page}&PageSize=${perPage}`,
          data: data
        })
        this.totalRecords = res.data.totalRecords
        this.rows = res.data.data
        this.isLoading = false

      } catch (error) {
        this.totalRecords = 0
        this.rows = null
        this.isLoading = false

      }
    },
  },
  async mounted() {

    this.$rtl.enableRTL()

    /**get orders */
    this.loadItems()


  },
  components: {
    VueGoodTable,
    datePicker: VuePersianDatetimePicker,
    Multiselect
  }
}
</script>

<style lang="scss" scoped>
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';

.multiselect__content-wrapper {
  .multiselect__content {
    padding: 0 0 70px 0;
  }
}
.logo-coin {
  width: 27px !important;
}
</style>
