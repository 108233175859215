<template>
  <AssetLogComponent :columns="columns" :isAllUsers="false"></AssetLogComponent>
</template>

<script>
/**components */
import AssetLogComponent from "../components/AssetLogComponent";
export default {
  name: 'userAssetLog',
  data() {
    return {
      columns: [
        {
          label: 'لوگو',
          field: 'logoUrlUser',
          sortable: false
        },
        {
          label: ' نوع تغییر',
          field: 'logType',
          sortable: false
        }, {
          label: ' نوع صندوق',
          field: 'fundType',
          sortable: false
        },
        {
          label: ' مقدار تغییر',
          field: 'value',
          sortable: false
        },
        {
          label: ' آخرین موجودی',
          field: 'balanceAfter',
          sortable: false
        },
        {
          label: ' زمان ثبت',
          field: 'logTime',
          sortable: false
        },

      ],
    }
  },
  components: {
    AssetLogComponent
  }
}
</script>
