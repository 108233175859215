<template>
  <UserListComponent :typeFilter=" { code: 'ListWaitingToGetVerified', lable: 'کاربران منتظر تایید مدارک' }"></UserListComponent>
</template>

<script>
/**compoenents */
import UserListComponent from "./UserListComponent.vue"

export default {
  name: 'NotDocumentVerified',  
  components: {
    UserListComponent
  }
}
</script>