<template>
  <UserLogComponent :columns="columns" :isAllUsers="false"></UserLogComponent>
</template>

<script>
/**components */
import UserLogComponent from "../components/UserLogComponent";
export default {
  name: 'userLog',
  data() {
    return {
      columns: [

        {
          label: 'فعالیت',
          field: 'typeName',
          sortable: false
        },
        {
          label: 'iPv4',
          field: 'iPv4',
          sortable: false
        },
        {
          label: 'iPv6',
          field: 'iPv6',
          sortable: false
        },
        {
          label: ' زمان ثبت',
          field: 'logTime',
          sortable: false
        },

      ],
    }
  },
  components: {
    UserLogComponent
  }
}
</script>
