<template>
  <div class="container-fluid my-message">
    <div class="row">
      <!-- send message -->
      <div class="col-12">
        <card class="p-3">
          <div class="card-title">
            <h6 class="mb-4">ارسال پیام مدیریتی</h6>
          </div>
          <div class="row filter pt-3">
            <!-- title -->
            <base-input class="col-12 col-lg-4" label=" عنوان پیام" type="text" placeholder=" عنوان پیام" v-model="titleSms"> </base-input>
            <!-- content -->
            <base-input class="col-12 col-lg-8" label=" محتوای پیام" type="text" placeholder=" محتوای پیام" v-model="contentSms"> </base-input>

            <div class="col-12 d-flex justify-content-between">
              <div class="d-flex">
                <base-button v-if="!loadingSendSms" class="btn-filter" type="primary" size="sm" @click="sendSms">ارسال پیام</base-button>
                <div v-else class="loader-wait-request m-3"></div>
              </div>
            </div>
          </div>
        </card>
      </div>
      <!-- content -->
      <div class="col-12">
        <card>
          <div class="card-header d-flex justify-content-between">
            <div class="card-title">
              <h6>پیام های دریافتی</h6>
              <base-button v-if="false" type="info" class="mx-3" size="sm" @click="addCoin()">افزودن</base-button>
            </div>
            <h6 class="filter-title" @click="filter = !filter">
              <span v-if="!filter"> + </span>
              <span v-if="filter"> - </span> فیلتر
            </h6>
          </div>
          <!-- filter content -->
          <card v-if="filter" class="filter">
            <form @submit.prevent="loadItems">
              <div class="row">
                <!-- fromFilter -->
                <div class="col-12 col-md-6 col-xl-4 date-holder">
                  <base-input class="fromDate" label="از تاریخ" type="text" placeholder="از تاریخ" v-model="fromFilter"> </base-input>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDate('from')" type>
                    <path
                      id="eraser"
                      d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                      transform="translate(-62.575 -109.75)"
                      fill="#fff"
                    />
                  </svg>
                  <date-picker v-model="fromFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".fromDate" view="year" auto-submit></date-picker>
                </div>

                <!-- toFilter -->
                <div class="col-12 col-md-6 col-xl-4 date-holder">
                  <base-input class="toDate" label="تا تاریخ" type="text" placeholder="تا تاریخ" v-model="toFilter"> </base-input>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDate('to')" type>
                    <path
                      id="eraser"
                      d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                      transform="translate(-62.575 -109.75)"
                      fill="#fff"
                    />
                  </svg>
                  <date-picker v-model="toFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".toDate" view="year" auto-submit></date-picker>
                </div>

                <div class="col-12 d-flex justify-content-between">
                  <div class="d-flex">
                    <base-button class="btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                    <base-button class="btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                  </div>
                  <a href="#" target="_blank"><base-button class="btn-filter" type="info" size="sm">دانلود فایل</base-button></a>
                </div>
              </div>
            </form>
          </card>

          <!-- the page content  -->
          <div class="card-body">
            <vue-good-table mode="remote" @on-page-change="onPageChange" @on-per-page-change="onPerPageChange" :pagination-options="paginationOptions" :totalRows="totalRecords" :rows="rows" :columns="columns" :rtl="true" :line-numbers="false">
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- cptTxHash -->
                <span v-if="props.column.field == 'title'">
                  <p class="">
                    <b>{{ props.row.title }}</b>
                  </p>
                  <div class="msg-text">
                    <span class="msg-content" v-html="props.row.content"></span>
                  </div>
                </span>

                <!-- time field -->
                <span v-else-if="props.column.field == 'regTime'" class="d-flex flex-column">
                  <span>
                    {{ jdate(props.row.regTime, 'jYYYY/jMM/jDD ') }}
                  </span>
                  <span>
                    {{ jdate(props.row.regTime, 'HH:mm') }}
                  </span>
                </span>

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from "vue-good-table";

/**convert miladi to jalali */
var moment = require("moment-jalaali");

/**import mixin */
import Helper from "../../../settings/Helper";


/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

export default {
  name: "user-history",
  mixins: [Helper],
  data() {
    return {
      columns: [
        {
          label: 'پیام',
          field: 'title',
          sortable: false
        },
        {
          label: 'زمان',
          field: 'regTime',
          sortable: false
        }
      ],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: window.env.CountItemInPerPage,
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: "بعدی",
        prevLabel: "قبلی",
        firstLabel: "اولین",
        lastLabel: "آخرین",
        rowsPerPageLabel: "تعداد ردیف هر صفحه",
        ofLabel: "از",
        pageLabel: "صفحه", // for 'pages' mode
        allLabel: "همه",
        perPageDropdown: window.env.PerPageDropdown,
        dropdownAllowAll: false,
      },
      filter: true,
      fromFilter: null,
      toFilter: null,

      contentSms: null,
      titleSms: null,
      loadingSendSms: false
    };
  },
  methods: {


    /** return Type Profile History 
     * @param object
     * @returns string
    */
    showTypeProfileHistory(histories) {
      let history = ''
      histories.map(function (item) {
        history += ', ' + item.value
      })
      return history.substring(1)
    },


    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage;
        let offset = this.serverParams.perPage * (this.serverParams.page - 1);
        this.isLoading = true;
        let res = await this.$axios({
          url:
            "Organizer/User/InformedListGet" + "?PageNumber=" + this.serverParams.page + "&PageSize=" + perPage,

          data: {
            userId: this.$router.history.current.params.id,

            searchStatements: [
              {
                key: "srchDateFrom",
                value: this.fromFilter ? moment(this.fromFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
              },
              {
                key: "srchDateTo",
                value: this.toFilter ? moment(this.toFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
              },
            ]
          }
        });

        this.totalRecords = res.data.totalRecords;
        this.rows = res.data.data;
        this.isLoading = false;
      } catch (error) {
        this.rows = null;
        console.error(error);
      }
    },

    // send sms for user
    async sendSms() {
      try {
        if (!this.titleSms || !this.contentSms)
          return this.$notify({
            text: 'عنوان و محتوای پیام الزامی می باشد',
            type: 'warn'
          })

        this.loadingSendSms = true
        await this.$axios({
          url: 'Organizer/User/InformedAdd',
          data: {
            userId: this.$router.history.current.params.id,
            title: this.titleSms,
            content: this.contentSms
          }
        });

        this.$notify({
          text: 'عملیات با موفقیت انجام شد',
          type: 'success'
        })



        this.loadItems()

        this.titleSms = this.contentSms = null
      } catch (error) {
        console.log(error)
      }

      this.loadingSendSms = false
    },

    /**reset search and search filters */
    resetSearch() {
      this.fromFilter = this.toFilter = null

      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null
      this.loadItems()

    },
    /**clear from and to date filter */
    eraseDate(type) {
      type == 'from' ? this.fromFilter = null : this.toFilter = null
    },


  },
  async mounted() {
    this.$rtl.enableRTL();

    /**get orders */
    this.loadItems()
  },
  components: {
    VueGoodTable,
    datePicker: VuePersianDatetimePicker,

  },
}
</script>

<style lang='scss'>
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';
.my-message {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: var(--dark);
  padding: 1%;

  .header {
    svg {
      path,
      circle,
      rect {
        fill: transparent;
      }
    }
    p {
      color: var(--black);
    }
  }

  .vgt-inner-wrap {
    .vgt-responsive {
      table.vgt-table {
        background: unset !important;
      }
      table.vgt-table td:nth-child(2) {
        width: 100px !important;
      }
      table.vgt-table.bordered {
        border-collapse: collapse !important;
        border: none !important;
        th,
        td {
          border: none !important;
          white-space: nowrap;
          color: var(--black);
          text-align: center;
          vertical-align: middle;
          &:nth-child(1) {
            text-align: justify !important;
            max-width: 375px;
            min-width: 375px;
            span {
              white-space: normal;
            }
          }
          padding: 10px 15px;
        }
        thead {
          th {
            background: var(--header);
          }
          border-bottom: 1px solid var(--shadow);
        }
        tbody {
          tr {
            th {
              background: var(--header);
            }
            &:not(:last-child) {
              border-bottom: 1px dashed rgba(136, 149, 166, 0.3);
            }
          }
          background: var(--header);
        }
      }
    }
    .vgt-wrap__footer.vgt-clearfix {
      background: var(--header);
      border: none !important;
    }
  }

  .msg-text {
    * {
      display: inline;
      white-space: nowrap;
      font-size: 14px !important;
      color: var(--black) !important;
    }
  }

  @media (min-width: 576px) {
    .container {
      max-width: 100% !important;
    }
  }

  .desc {
    max-width: 400px;
    white-space: normal;
    text-align: justify;
  }
}
</style>
