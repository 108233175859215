
/**convert miladi to jalali */
var moment = require('moment-jalaali')

/**define some global mixins */
var jalali = {
    methods: {
        /**convert to shamsi date 
     * @param midalidate 2021-12-27T10:52:42.594633Z	
    */
        jdate(date) {
            return moment(date, 'YYYY-M-D HH:mm:ss').format('jYYYY/jM/jD HH:mm:ss')
        },
    }
}

export default jalali