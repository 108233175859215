<template>
  <section class="my-3">
    <!-- header -->
    <div class="title-component d-flex align-items-center mb-3">
      <i :class="data.icon"></i>
      <h3 class="my-2">{{ data.title }}</h3>
    </div>
    <DxChart id="chart" :data-source="data.data" :title="data.options.title" @pointClick="onPointClick" :palette="data.options.color" resolve-label-overlapping="stack">
      <DxCommonSeriesSettings argument-field="lable" type="bar" hover-mode="allArgumentPoints" selection-mode="allArgumentPoints">
        <DxLabel :visible="true">
          <DxFormat v-if="data.options.precision" :precision="data.options.precision" type="fixedPoint" />
          <DxFormat v-else :precision="0" type="fixedPoint" />
        </DxLabel>
      </DxCommonSeriesSettings>
      <DxSeries v-for="(item, index) in calcColumns(data.data[0])" :key="index" :value-field="index" :name="index + ''" />
      <DxLegend vertical-alignment="right" horizontal-alignment="right" />
      <DxExport v-if="data.options.export" :enabled="true" />
    </DxChart>
  </section>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxLabel,
  DxFormat,
  DxLegend,
  DxExport,
} from 'devextreme-vue/chart';

export default {
  name: 'barChart',
  props: ['data'],
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLabel,
    DxFormat,
    DxLegend,
    DxExport,
  },
  data() {
    return {

    };
  },
  methods: {

    onPointClick({ target }) {
      target.select();
    },

    calcColumns(data) {
      const fields = Object.fromEntries(Object.entries(data).filter(([key, value]) => key != 'lable'))
      return fields
    }
  },
};
</script>
<style lang="scss">
#chart {
  height: 350px;
}
.dxc-val-grid {
  path {
    stroke-opacity: 20% !important;
  }
}
</style>