<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <!-- title and filter -->
          <div class="card-header d-flex justify-content-between">
            <div class="card-title d-flex justify-content-around">
              <h6>لیست {{ typeUser.lable }}</h6>
              <h6 class="filter-title" @click="filter = !filter" v-if="false">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر کاربران
              </h6>
            </div>

            <!-- filter content -->
            <card v-if="filter" class="filter">
              <form @submit.prevent="loadItems">
                <div class="row">
                  <!-- uid Filter -->
                  <base-input v-if="false" class="col-12 col-md-6 col-xl-4" label="شناسه" type="text" placeholder="شناسه" v-model="uidFilter"> </base-input>

                  <!-- name -->
                  <base-input v-if="false" class="col-12 col-md-6 col-xl-4" label="نام" type="text" placeholder="نام" v-model="firstNameFilter"> </base-input>

                  <!-- family -->
                  <base-input v-if="false" class="col-12 col-md-6 col-xl-4" label="نام خانوادگی" type="text" placeholder="نام خانوادگی" v-model="lastNameFilter"> </base-input>

                  <!-- code meli -->
                  <base-input v-if="false" class="col-12 col-md-6 col-xl-4" label="کد ملی" type="text" placeholder="کد ملی" v-model="codeMeliFilter"> </base-input>

                  <!-- birthdate -->
                  <div v-if="false" class="col-12 col-md-6 col-xl-4 date-holder">
                    <base-input class="birthday" label="تاریخ تولد" type="text" placeholder="تاریخ تولد" v-model="birthdayFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseBirthday">
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="birthdayFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".birthday" view="year" auto-submit></date-picker>
                  </div>

                  <!-- craeted -->
                  <div v-if="false" class="col-xl-2 col-md-4 date-holder">
                    <base-input class="created" label="تاریخ ثبت" type="text" placeholder="تاریخ ثبت" v-model="createdFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseCreated">
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="createdFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".created" view="year" auto-submit></date-picker>
                  </div>

                  <!-- mobileFilter -->
                  <base-input class="col-12 col-md-6 col-xl-4" label="همراه" type="text" placeholder="همراه" v-model="mobileFilter"> </base-input>

                  <!-- Email fillter -->
                  <base-input class="col-12 col-md-6 col-xl-4" label="ایمیل" type="text" placeholder="ایمیل" v-model="emailFilter"> </base-input>

                  <!-- type -->
                  <div class="col-12 col-md-6 col-xl-4">
                    <span class="cart-lable-color">نوع سفارش</span>
                    <div class="mt-1">
                      <multiselect :showLabels="false" v-model="typeFilter" :options="typeFilters" placeholder="نوع سفارش" track-by="code" label="lable" :searchable="false" :allow-empty="false"></multiselect>
                    </div>
                  </div>
                </div>

                <!-- checkbox's and buttons  -->
                <div class="row">
                  <!-- checkbox 1 -->
                  <base-checkbox v-if="false" v-model="verifyDocumentFilter" class="col-12 col-sm-6 col-md-3 justify-content-start text-center">مدارک تائید شده</base-checkbox>

                  <!-- checkbox 2 -->
                  <base-checkbox v-if="false" v-model="verifyUserFilter" class="col-12 col-sm-6 col-md-3 justify-content-start text-center">کاربران تائید شده</base-checkbox>

                  <!-- btn submit and reset-->
                  <div class="col-12 d-flex justify-content-between">
                    <div class="d-flex">
                      <base-button class="btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                      <base-button class="btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                    </div>
                    <a href="#" target="_blank"><base-button class="btn-filter" type="info" size="sm">دانلود فایل</base-button></a>
                  </div>
                </div>
              </form>
            </card>
          </div>

          <!-- tab -->
          <div class="mt-4">
            <Tabs :theme="theme" v-model="typeFactor" @input="loadItems()">
              <TabItem name="Deposit">
                <template v-slot:name> زیرمجموعه های تائید شده</template>
              </TabItem>
              <TabItem name="DepositTmn">
                <template v-slot:name> زیرمجموعه های منتظر تائید</template>
              </TabItem>
              <TabItem name="Withdraw">
                <template v-slot:name> سایر زیرمجموعه های</template>
              </TabItem>
            </Tabs>
          </div>

          <!-- the page content  -->
          <div class="card-body">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="columns"
              :rtl="true"
              :line-numbers="false"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <div @click="rowClick(props.row)" class="table-row">
                  <!-- date_joined field  -->
                  <span v-if="props.column.field == 'date_joined'">
                    {{ jdate(props.row.date_joined) }}
                  </span>

                  <!-- birth_date field  -->
                  <span v-else-if="props.column.field == 'birth_date'">
                    {{ props.row.profile ? jdate(props.row.profile.birth_date) : null }}
                  </span>

                  <!-- verified field  -->
                  <span v-else-if="props.column.field == 'isVerified'" v-html="getStatusLabel(props.row.isVerified)"> </span>

                  <!-- idUserState field  -->
                  <span v-else-if="props.column.field == 'idUserState'" v-html="getStatusLabel(props.row.idUserState, props.row.userState)"> </span>

                  <!-- uidCode -->
                  <span v-else-if="props.column.field == 'uidCode'">
                    {{ props.row.uidCode.substring(0, 8) + '..' }}
                  </span>

                  <!-- show in default  -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </div>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from 'vue-good-table'

/**navigation tabs */
import { Tabs, TabItem } from "vue-material-tabs"

/**import mixin */
import Helper from '../../../settings/Helper'

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'


// Multiselect
import Multiselect from 'vue-multiselect'

export default {
  name: 'Profiles',
  mixins: [Helper],
  data() {
    return {
      columns: [
        {
          label: 'نام',
          field: 'name',
          sortable: true
        },
        {
          label: 'نام خانوادگی',
          field: 'family',
          sortable: true
        },
        {
          label: 'همراه',
          field: 'mobile',
          sortable: false
        },

        {
          label: 'ایمیل',
          field: 'email',
          sortable: false
        },
        {
          label: 'زیر مجموعه',
          field: 'countOfChildren',
          sortable: true
        },
        {
          label: 'وضعیت کاربر',
          field: 'idUserState',
          sortable: true
        },
        {
          label: 'وضعیت پروفایل',
          field: 'isVerified',
          sortable: true
        },

      ],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: window.env.CountItemInPerPage,
        search: null
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: window.env.PerPageDropdown,
        dropdownAllowAll: false
      },
      // filter==true => show filter card
      filter: false,
      uidFilter: null,
      firstNameFilter: null,
      lastNameFilter: null,
      mobileFilter: null,
      emailFilter: null,
      codeMeliFilter: null,
      verifyUserFilter: true,
      verifyDocumentFilter: true,
      birthdayFilter: null,
      createdFilter: null,

      typeFilter: { code: 'ListWaitingToGetVerified', lable: 'کاربر منتظر تایید' },
      typeFilters: [
        { code: 'ListWaitingToGetVerified', lable: 'کاربر منتظر تایید' },
        { code: 'ListVerified', lable: 'کاربران تایید شده ' },
        { code: 'ListNotEngaged', lable: 'سایر کاربران' }
      ],

      typeUser: { code: 'ListVerified', lable: 'زیرمجموعه های تایید شده ' },
      typeUsers: [
        { code: 'ListVerified', lable: 'زیرمجموعه های تایید شده ' },
        { code: 'ListWaitingToGetVerified', lable: 'زیرمجموعه های منتظر تایید' },
        { code: 'ListNotEngaged', lable: 'سایر زیرمجموعه ها' }
      ],

      typeFactor: 0,

      theme: {
        nav: "#1e1e2b",
        navItem: "#40435d",
        navActiveItem: "#d250da",
        slider: "#CE93D8",
        arrow: "#f3f3f3",
      },
    }
  },
  methods: {
    /**clear birthdate filter */
    eraseBirthday() {
      this.birthdayFilter = null
    },

    /**clear created filter */
    eraseCreated() {
      this.createdFilter = null
    },

    /**reset search and search filters */
    resetSearch() {
      this.uidFilter = null
      this.firstNameFilter = null
      this.lastNameFilter = null
      this.mobileFilter = null
      this.codeMeliFilter = null
      this.verifyUserFilter = true
      this.verifyDocumentFilter = true
      this.birthdayFilter = null
      this.createdFilter = null
      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null

      this.loadItems()
    },

    /**row click
 * on row click go to user detail
 */
    rowClick(row) {
      window.open('/#/dashboard/users/' + row.uidCode + '/personal-info');
    },

    /**
     * @param status
     * @return string the translated of status
     */
    getStatusLabel(status, title = '') {
      switch (status) {
        case true:
          return '<span class="badge badge-success">تایید شده</span>'
          break;
        case false:
          return '<span class="badge badge-danger">تایید نشده</span>'
          break;
        case 0:
          return `<span class="badge badge-warning">${title}</span>`
          break;
        case 11:
          return `<span class="badge badge-success">${title}</span>`
          break;
        case 81:
          return `<span class="badge badge-danger">${title}</span>`
          break;
        default:
          return '<span>...</span>'
          break;
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      switch (params[0].field) {
        case 'user.username':
          params[0].field = 'user_username'
          break
        case 'user.uid':
          params[0].field = 'user_uid'
          break
        default:
          break
      }
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    /** load items is what brings back the rows from server 
     * @param int typeUser enum[0: ListVerified,1: ListWaitingToGetVerified,2: ListNotEngaged] defalut is ListVerified
     */

    async loadItems() {
      try {
        if (this.isLoading) return

        this.typeUser = this.typeUsers[this.typeFactor]

        let perPage = this.serverParams.perPage
        this.isLoading = true

        let url = this.typeUser.code

        let res = await this.$axios({
          method: 'post',
          url: `Organizer/User/${url}?PageNumber=${this.serverParams.page}&PageSize=${perPage}`,
          data: {
            searchStatements: [
              // { Key: "srchEmail", Value: this.emailFilter},
              // { Key: "srchMobile", Value: this.mobileFilter ? this.mobileFilter.substring(1) : null },
              // { Key: "srchGeneral", Value: this.otherFilter},
            ],
            uIdCodeReferee: this.$router.history.current.params.id
          }
        })
        this.totalRecords = res.data.totalRecords
        this.rows = res.data.data
      } catch (error) {
        this.totalRecords = 0
        this.rows = null
        console.error(error)
      }

      this.isLoading = false

    },

  },
  async mounted() {
    this.$rtl.enableRTL()

    this.loadItems();


  },
  beforeDestroy() {
    this.isLoading = true
  },
  components: {
    VueGoodTable,
    datePicker: VuePersianDatetimePicker,
    Multiselect,
    Tabs,
    TabItem
  }
}
</script>

<style lang="scss">
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';
.table-row {
  cursor: pointer;
}
.filter-title {
  cursor: pointer;
  color: rgb(133, 131, 131) !important;
}

/**talkhabi customize  */
.vpd-wrapper {
  .vpd-days {
    color: black;
  }
}
.btns-show-children {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    font-size: 12px;
    min-width: 170px;
    margin-right: 20px;
  }
}
</style>
