<template>
  <section class="personal-info">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <ValidationObserver ref="contentValidation">
            <!-- card 1 content -->
            <div class="col-12">
              <card>
                <div class="card-header">
                  <div class="card-title">
                    <h6 v-if="!id">افزودن کمیسیون</h6>
                    <h6 v-else>ویرایش کمیسیون</h6>
                  </div>
                </div>
                <!-- the page content  -->
                <div class="card-body">
                  <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-3">
                    <!--  title -->
                    <div class="date-created w-100 w-lg-50">
                      <ValidationProvider name="عنوان" rules="required" v-slot="{ errors }">
                        <span>عنوان </span>
                        <input type="text" v-model="title" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <!-- حداقل تعداد زیرمجموعه -->
                    <div class="date-modify w-100 w-lg-50 mx-2">
                      <ValidationProvider name="حداقل تعداد زیرمجموعه" rules="required" v-slot="{ errors }">
                        <span> حداقل تعداد زیرمجموعه</span>
                        <input type="text" v-model="minMember" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <!-- maxMember -->
                    <div class="date-modify w-100 w-lg-50 mx-2">
                      <ValidationProvider name=" حداکثر تعداد زیرمجموعه" rules="required" v-slot="{ errors }">
                        <span> حداکثر تعداد زیرمجموعه</span>
                        <input type="text" v-model="maxMember" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>

                  <!-- row two-->
                  <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-3">
                    <!--  directPercentage -->
                    <div class="date-created w-100 w-lg-50">
                      <ValidationProvider name="درصد درآمد از زیرمجموعه مستقیم" rules="required" v-slot="{ errors }">
                        <span>درصد درآمد از زیرمجموعه مستقیم</span>
                        <input type="text" v-model="directPercentage" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <!-- offspringPercentage-->
                    <div class="date-modify w-100 w-lg-50 mx-2">
                      <ValidationProvider name="درصد درآمد از شاخه های پایین تر" rules="required" v-slot="{ errors }">
                        <span> درصد درآمد از شاخه های پایین تر</span>
                        <input type="text" v-model="offspringPercentage" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <!-- isDisabled -->
                    <div class="date-modify w-100 w-lg-50 mx-2 switchSate">
                      <span>غیر فعال است</span>
                      <div class="switch">
                        <input type="checkbox" class="toggle" v-model="isDisabled" />
                      </div>
                    </div>

                    <!-- precision -->
                  </div>
                </div>
              </card>
            </div>
          </ValidationObserver>

          <div class="col-12">
            <div class="submit">
              <button class="btn-primary" @click="submit" v-if="!isLoading">ذخیره</button>
              <div v-else class="loader-wait-request mx-5"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

/**import mixin */
import Helper from '../../../settings/Helper'

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

/**import moment-jalali */
var moment = require('moment-jalaali')

// Multiselect
import Multiselect from 'vue-multiselect'

import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate'
import { numeric, required } from 'vee-validate/dist/rules'
import fa from 'vee-validate/dist/locale/fa'
extend('required', required)
extend('numeric', numeric)
localize('fa', fa)

export default {
  name: 'UserPersonalInfo',
  // props: ['id'],
  mixins: [Helper],
  data() {
    return {
      id: null,
      title: null,
      minMember: null,
      maxMember: null,
      directPercentage: null,
      offspringPercentage: null,
      isDisabled: false,
      isLoading: false
    }
  },
  methods: {
    // submit save
    async submit() {
      try {
        /**validate the form */
        if (! await this.$refs.contentValidation.validate()) return

        this.isLoading = true

        let url = "Organizer/ReferralCommission/RuleSet"
        /**check if we are in update mode */


        let data = {
          idLang: 1065,
          rcrName: this.title,
          idTraderLevel: 0,
          idUserReferralCommissionRule: this.id,
          rcrMinMember: this.minMember,
          rcrMaxMember: this.maxMember,
          rcrDirectGetPercentage: this.directPercentage,
          rcrOffspringGetPercentage: this.offspringPercentage,
          rcrDisabled: this.isDisabled,

        }
        await this.$axios({
          method: "post",
          url: url,
          data: data
        })

        this.$notify({
          text: 'عملیات با موفقیت انجام شد',
          type: 'success'
        })
        this.$router.push('/dashboard/commission-rules', true)
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    },

    /**
  * add new network
  */
    addNetwork() {
      /**check latest index */
      if (!this.networks[this.networks.length - 1].name || !this.networks[this.networks.length - 1].fee) return this.$notify({ text: 'مقادیر fee و name شبکه الزامی است', type: 'error' })

      /**add new one */
      this.networks.push({ fee: "", name: "", address: "", explorer: "" })
    },

    /**
     * remove network
     */
    removeNetwork(index) {
      this.networks.splice(index, 1)
    }
  },
  components: {
    datePicker: VuePersianDatetimePicker,
    Multiselect,
    ValidationProvider,
    ValidationObserver
  },
  async mounted() {
    this.$rtl.enableRTL()
    try {
      let index = this.$router.history.current.params.id
      let data = await this.$store.state.commissionRules

      this.id = data[index]['id']
      this.title = data[index]['title']
      this.minMember = data[index]['minMember']
      this.maxMember = data[index]['maxMember']
      this.directPercentage = data[index]['directPercentage']
      this.offspringPercentage = data[index]['offspringPercentage']
      this.isDisabled = data[index]['isDisabled']
    } catch (error) {
      this.$router.push('/dashboard/commission-rules', true)
      return
    }
  },
  computed: {
    minAgeDate: () => {
      return moment().subtract(18, 'year').format('jYYYY/jM/jD') // somthing like 1382/10/05
    },
    maxAgeDate: () => {
      return moment().subtract(90, 'year').format('jYYYY/jM/jD') // somthing like  1310/10/05
    }
  }
}
</script>

<style lang="scss" scoped>
.personal-info {
  svg {
    cursor: pointer;
  }
  .multiselect {
    border: none !important;
    border-bottom: 1px solid #ffffff !important;
    border-radius: 0;
    padding-bottom: 10px;
  }
  input[type='text'] {
    background-color: transparent !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    color: #ffffeb;
    &:disabled {
      color: #9b9b83;
    }
  }
  textarea {
    background-color: transparent !important;
    border: 1px solid #ffffeb;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    direction: ltr;
    color: #ffffeb67;
    padding-top: 10px;
    padding-bottom: 4px;
    padding-left: 10px;
    &:focus-visible {
      outline: none !important;
      color: #ffffeb;
    }
  }
  .flexible {
    border-bottom: 2px solid #ffffff !important;
  }
  .change {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #74839b;
    margin: 0 auto 0 0;
    cursor: pointer;
    &:hover {
      background-color: #445877;
      svg {
        fill: #74839b;
      }
    }
  }
  .switch {
    input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      &:focus {
        outline: 0;
      }
    }

    .toggle {
      height: 23px;
      width: 50px;
      border-radius: 30px;
      display: inline-block;
      position: relative;
      margin: 0;
      border: 2px solid #474755;
      background: linear-gradient(180deg, #2d2f39 0%, #1f2027 100%);
      transition: all 0.2s ease;
      &:after {
        content: '';
        position: absolute;
        top: -4px;
        left: -2px;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background: #5c6e8b;
        box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
        transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
      }
      &:checked {
        border-color: #4f8bec;
        &:after {
          transform: translatex(20px);
          left: 2px;
          background: #4982df;
        }
      }
    }
  }
  .submit {
    button {
      padding: 10px 35px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }
  }
}
.logo-coin {
  width: 70px !important;
}
.switchSate {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>
