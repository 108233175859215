<template>
  <section class="personal-info">
    <div class="row">
      <ValidationObserver ref="contentValidation">
        <!-- card 1 content -->
        <div class="col-12">
          <card>
            <div class="card-header">
              <div class="card-title d-flex align-items-center justify-content-between">
                <h6 v-if="type == 'increase'">افزایش دارایی کاربر</h6>
                <h6 v-else>کاهش دارایی کاربر</h6>
              </div>
            </div>
            <!-- the page content  -->
            <div class="card-body">
              <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-3">
                <!-- cSymbol -->
                <div class="date-modify w-25">
                  <ValidationProvider name="نماد" rules="required" v-slot="{ errors }">
                    <span> نماد</span>
                    <input type="text" v-model="cSymbol" />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <!--  addValue -->
                <div class="date-created w-25 mx-2">
                  <ValidationProvider name=" مقدار" rules="required" v-slot="{ errors }">
                    <span> مقدار</span>
                    <input type="text" v-model="addValue" />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <!--  addDescription -->
                <div class="date-created w-50">
                  <ValidationProvider name=" توضیحات" rules="required|min:5" v-slot="{ errors }">
                    <span> توضیحات</span>
                    <input type="text" v-model="addDescription" @keydown.enter="submit" />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="submit">
                <button class="btn-primary" @click="submit">ذخیره</button>
              </div>
            </div>
          </card>
        </div>
      </ValidationObserver>
    </div>
  </section>
</template>

<script>


/**import mixin */
import Helper from '../../../settings/Helper'

/**handle events */
import { EventBus } from "../../../settings/EventBus"

import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate'
import { min, required, double } from 'vee-validate/dist/rules'
import fa from 'vee-validate/dist/locale/fa'
extend('required', required)
extend('min', min)
extend('double', double)

localize('fa', fa)

export default {
  name: 'UserPersonalInfo',
  mixins: [Helper],
  props: ['type', 'id'],
  data() {
    return {
      addValue: null,
      cSymbol: null,
      addDescription: null,
    }
  },
  methods: {

    // submit save
    async submit() {
      try {
        /**validate the form */
        if (! await this.$refs.contentValidation.validate()) return this.$notify({ text: 'لطفا خطاهای رخ داده را برطرف نمایید', type: 'error' })

        this.isLoading = true

        let data = {
          uIdCode: this.id,
          addValue: parseFloat(this.addValue),
          takeValue: parseFloat(this.addValue),
          cSymbol: this.cSymbol,
          addDescription: this.addDescription,
          takeDescription: this.addDescription
        }
        let url = "Organizer/User/AssetTake"
        if (this.type == 'increase')
          url = "/Organizer/User/AssetAdd"

        await this.$axios({
          url: url,
          data: data
        })

        this.$notify({
          text: 'عملیات با موفقیت انجام شد',
          type: 'success'
        })

        this.isLoading = false

        this.addValue = this.cSymbol = this.addDescription = null
        /**add to recentMarkets */
        EventBus.$emit("loadAsset")

      } catch (error) {
        console.error(error)
      }
    },



  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  async mounted() {
    this.$rtl.enableRTL()


    /**get user info */
  },
  computed: {
    minAgeDate: () => {
      return moment().subtract(18, 'year').format('jYYYY/jM/jD') // somthing like 1382/10/05
    },
    maxAgeDate: () => {
      return moment().subtract(90, 'year').format('jYYYY/jM/jD') // somthing like  1310/10/05
    }
  }
}
</script>

<style lang="scss" scoped>
.personal-info {
  svg {
    cursor: pointer;
  }
  .multiselect {
    border: none !important;
    border-bottom: 1px solid #ffffff !important;
    border-radius: 0;
    padding-bottom: 10px;
  }
  input[type='text'] {
    background-color: transparent !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    color: #ffffeb;
    &:disabled {
      color: #9b9b83;
    }
  }
  textarea {
    background-color: transparent !important;
    border: 1px solid #ffffeb;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    direction: ltr;
    color: #ffffeb67;
    padding-top: 10px;
    padding-bottom: 4px;
    padding-left: 10px;
    &:focus-visible {
      outline: none !important;
      color: #ffffeb;
    }
  }
  .flexible {
    border-bottom: 2px solid #ffffff !important;
  }
  .change {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #74839b;
    margin: 0 auto 0 0;
    cursor: pointer;
    &:hover {
      background-color: #445877;
      svg {
        fill: #74839b;
      }
    }
  }
  .switch {
    input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      &:focus {
        outline: 0;
      }
    }

    .toggle {
      height: 23px;
      width: 50px;
      border-radius: 30px;
      display: inline-block;
      position: relative;
      margin: 0;
      border: 2px solid #474755;
      background: linear-gradient(180deg, #2d2f39 0%, #1f2027 100%);
      transition: all 0.2s ease;
      &:after {
        content: '';
        position: absolute;
        top: -4px;
        left: -2px;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background: #5c6e8b;
        box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
        transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
      }
      &:checked {
        border-color: #4f8bec;
        &:after {
          transform: translatex(20px);
          left: 2px;
          background: #4982df;
        }
      }
    }
  }
  .submit {
    button {
      padding: 10px 35px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }
  }
}
.logoUrl-coin {
  width: 70px !important;
}
</style>
