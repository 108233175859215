<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <!-- title and filter -->
          <div class="card-header">
            <div class="card-title d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <h6>گزارش دارایی {{ isAllUsers ? 'کاربران' : 'کاربر' }}</h6>
                <base-button v-if="false" type="info" class="mx-3" size="sm" @click="addCoin()">افزودن</base-button>
              </div>
              <h6 class="filter-title" @click="filter = !filter" v-if="isAllUsers">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر
              </h6>
            </div>
            <!-- filter content -->
            <card v-if="filter && isAllUsers" class="filter">
              <form @submit.prevent="loadItems">
                <div class="row">
                  <!-- name farsi -->
                  <base-input dir="ltr" class="col-12 col-lg-4" label="نام کاربر" type="text" placeholder="نام کاربر" v-model="userFilter"> </base-input>

                  <!-- symbol -->
                  <base-input class="col-12 col-lg-4" label="واحد پول" type="text" placeholder="واحد پول" v-model="currencyFilter"> </base-input>

                  <!-- type -->
                  <div class="col-12 col-lg-4" v-if="false">
                    <span class="cart-lable-color">نوع</span>
                    <div class="mt-1">
                      <multiselect
                        :showLabels="false"
                        v-model="typeFilter"
                        :options="typeFilters"
                        placeholder="نوع کویین"
                        track-by="code"
                        label="lable"
                        :searchable="false"
                        :allow-empty="false"
                      ></multiselect>
                    </div>
                  </div>

                  <div class="col-12 d-flex justify-content-between flex-wrap">
                    <div class="d-flex flex-wrap">
                      <base-button class="btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                      <base-button class="btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                    </div>
                    <a href="#" target="_blank"><base-button class="btn-filter" type="info" size="sm">دانلود فایل</base-button></a>
                  </div>
                </div>
              </form>
            </card>
          </div>
          <!-- the page content  -->
          <div>
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="columns"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- type field  -->
                <!-- coin datail -->
                <div class="coin-detail d-flex align-items-center" v-if="props.column.field == 'logoUrl'">
                  <div class="d-flex align-items-center">
                    <!-- coin icon -->
                    <div class="coin-icon">
                      <img :src="props.row.logoUrl" alt="coin" />
                    </div>

                    <div class="d-flex flex-column align-items-start">
                      <!-- coin FA name -->
                      <div class="coin-name">
                        <span>{{ props.row.title }}</span>
                      </div>

                      <!-- coin symbol -->
                      <div class="coin-symbol d-flex align-items-center mt-1">
                        <span class="font-bold">{{ props.row.symbol }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- type field  -->
                <span v-else-if="props.column.field == 'type'" v-html="getTypeLabel(props.row.type)"> </span>

                <!-- type field  -->
                <span v-else-if="props.column.field == 'edit'">
                  <base-button class="" type="primary" size="sm" @click="edit(props.row.symbol)">ویرایش</base-button>
                </span>

                <!-- status field  -->
                <span v-else-if="props.column.field == 'state'" v-html="getStatusLabel(props.row.state)"> </span>

                <!-- userName -->
                <a v-else-if="props.column.field == 'userName'" :href="'/#/dashboard/users/' + props.row.userId + '/personal-info'" target="_blank">
                  <span dir="ltr"> {{ props.row.userName }} </span></a
                >

                <!-- amountTotal -->
                <span v-else-if="props.column.field == 'amountTotal'"> {{ numberWithCommas(props.row.amountTotal) }}</span>

                <!-- amountMainSpot -->
                <span v-else-if="props.column.field == 'amountMainSpot'"> {{ numberWithCommas(props.row.amountMainSpot) }}</span>

                <!-- amountMainSpotLocked -->
                <span v-else-if="props.column.field == 'amountMainSpotLocked'"> {{ numberWithCommas(props.row.amountMainSpotLocked) }}</span>
                <span v-else-if="props.column.field == 'amountTotalAsUsdt'"> {{ numberWithCommas(props.row.amountTotalAsUsdt) }}</span>
                <span v-else-if="props.column.field == 'amountTotalAsTmn'"> {{ numberWithCommas(props.row.amountTotalAsTmn) }}</span>
                <span v-else-if="props.column.field == 'amountTotalAsBtc'"> {{ numberWithCommas(props.row.amountTotalAsBtc) }}</span>

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from 'vue-good-table'

/**import mixin */
import Helper from '../../../settings/Helper'

/**handle events */
import { EventBus } from "../../../settings/EventBus"

export default {
  name: 'AssetsComponent',
  mixins: [Helper],
  props: {
    columns: {
      type: Array,
      default: () => [
        {
          label: 'نام کاربر',
          field: 'userName',
          sortable: false
        },
        {
          label: 'ارز',
          field: 'logoUrl',
          sortable: false
        },
        {
          label: 'حجم کل',
          field: 'amountTotal',
          sortable: false
        },
        {
          label: 'Spot',
          field: 'amountMainSpot',
          sortable: false
        },
        {
          label: 'قفل شده در Spot',
          field: 'amountMainSpotLocked',
          sortable: false
        },
        {
          label: 'ارزش تومانی',
          field: 'amountTotalAsTmn',
          sortable: false
        },
        {
          label: 'ارزش دلاری',
          field: 'amountTotalAsUsdt',
          sortable: false
        },
        {
          label: 'ارزش بیت کوینی',
          field: 'amountTotalAsBtc',
          sortable: false
        },
      ]
    },
    isAllUsers: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      selectedNetwork: null,
      address: null,
      selectedCoin: null,
      coins: [],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: window.env.CountItemInPerPage,
        search: null
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: window.env.PerPageDropdown,
        dropdownAllowAll: false
      },
      // filter==true => show filter card
      filter: true,
      userFilter: null,
      currencyFilter: null,

    }
  },
  methods: {
    /** get value and show according to tmn and  
  * @param object
 */
    showValue(value, type) {
      let price = value.map(function (item) {
        if (item.symbol == type)
          return item.value

      });

      return this.numberWithCommas(price[0])
    },


    /**reset search and search filters */
    resetSearch() {
      this.userFilter = this.currencyFilter = null

      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null
      this.loadItems()
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage
        let offset = this.serverParams.perPage * (this.serverParams.page - 1)
        this.isLoading = true

        /**handle sorts */
        let sort = ''
        if (this.serverParams.sort[0].field) {
          /**check sort asc or desc */
          let sortType = this.serverParams.sort[0].type == 'asc' ? '' : '-'
          sort = this.serverParams.sort[0].field ? '&ordering=' + sortType + this.serverParams.sort[0].field : ''
        }

        let url = `Organizer/Report/Asset?PageNumber=${this.serverParams.page}&PageSize=${perPage}`
        let data = [
          {
            key: "srchUser",
            value: this.userFilter
          },
          {
            key: "srchCSymbol",
            value: this.currencyFilter
          }
        ]

        let res = await this.$axios({
          method: 'post',
          url: this.isAllUsers ? url : `Organizer/User/GetAsset?PageNumber=${this.serverParams.page}&PageSize=${perPage}`,
          data: this.isAllUsers ? data : {
            userId: this.$router.history.current.params.id,
            searchStatements: [
              {
                key: "srchUser",
                value: this.userFilter
              },
              {
                key: "srchCSymbol",
                value: this.currencyFilter
              }
            ]
          }
        })
        this.totalRecords = res.data.totalRecords
        this.rows = res.data.data
        this.isLoading = false

      } catch (error) {
        this.totalRecords = 0
        this.rows = null
        this.isLoading = false

      }


    }
  },
  async mounted() {
    this.$rtl.enableRTL()
    this.loadItems();

    EventBus.$on("loadAsset", () => {
      this.loadItems()
    })
  },
  components: {
    VueGoodTable,
  }
}
</script>

<style lang="scss" scoped>
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';

.multiselect__content-wrapper {
  .multiselect__content {
    padding: 0 0 70px 0;
  }
}
.logo-coin {
  width: 27px !important;
}
</style>
