<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <!-- title and filter -->
          <div class="card-header">
            <div class="card-title d-flex flex-column flex-sm-row justify-content-between">
              <div class="d-flex align-items-center">
                <h6>قوانین کمیسیون</h6>
                <base-button type="info" class="mx-3" size="sm" @click="addCoin()" v-if="false">افزودن</base-button>
              </div>
              <h6 class="filter-title" @click="filter = !filter" v-if="false">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر قوانین ها
              </h6>
            </div>
            <!-- filter content -->
            <card v-if="filter" class="filter">
              <form @submit.prevent="search">
                <div class="row">
                  <!-- name farsi -->
                  <base-input class="col-12 col-lg-4" label="نام" type="text" placeholder="نام" v-model="nameFilter"> </base-input>

                  <!-- symbol -->
                  <base-input class="col-12 col-lg-4" label="symbol" type="text" placeholder="symbol" v-model="symbolFilter"> </base-input>

                  <!-- type -->
                  <div class="col-12 col-lg-4">
                    <span class="cart-lable-color">نوع</span>
                    <div class="mt-1">
                      <multiselect :showLabels="false" v-model="typeFilter" :options="typeFilters" placeholder="نوع سفارش" track-by="code" label="lable" :searchable="false" :allow-empty="false"></multiselect>
                    </div>
                  </div>
                </div>

                <!-- checkbox's and buttons  -->
                <div class="row">
                  <!-- btn submit -->
                  <div class="col-12">
                    <base-button class="mt-3 btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                    <base-button class="mt-3 mx-0 mx-sm-3 btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                  </div>
                </div>
              </form>
            </card>
          </div>
          <!-- the page content  -->
          <div class="card-body">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="columns"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- type field  -->
                <span v-if="props.column.field == 'logo'">
                  <img :src="props.row.logo" alt="copy" class="logo-coin" />
                </span>

                <!-- count -->
                <a v-else-if="props.column.field == 'count'" :href="'/#/dashboard/commission-rules/' + props.row.id + '/users'" target="_blank">
                  <span dir="ltr"> {{ props.row.countOfUsers }} </span></a
                >

                <!-- state field  -->
                <span v-else-if="props.column.field == 'isDisabled'" v-html="getStatusLabel(!props.row.isDisabled)"> </span>

                <!-- type field  -->
                <span v-else-if="props.column.field == 'type'" v-html="getTypeLabel(props.row.type)"> </span>

                <!-- maxMember -->
                <span v-else-if="props.column.field == 'maxMember'"> {{ newNumberFormat(props.row.maxMember) }}</span>

                <!-- minMember -->
                <span v-else-if="props.column.field == 'minMember'"> {{ newNumberFormat(props.row.minMember) }}</span>

                <!-- type field  -->
                <span v-else-if="props.column.field == 'edit'">
                  <base-button class="" type="primary" size="sm" @click="edit(props.row.id)">ویرایش</base-button>
                </span>
                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from 'vue-good-table'

// Multiselect
import Multiselect from 'vue-multiselect'

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

/**import mixin */
import Helper from '../../../settings/Helper'

export default {
  name: 'Addresses',
  mixins: [Helper],
  data() {
    return {
      selectedNetwork: null,
      address: null,
      selectedCoin: null,
      coins: [],
      columns: [
        {
          label: 'عنوان',
          field: 'title',
          sortable: false
        },
        {
          label: 'حداقل تعداد زیرمجموعه',
          field: 'minMember',
          sortable: false
        },
        {
          label: 'حداکثر تعداد زیمجموعه',
          field: 'maxMember',
          sortable: false
        },
        {
          label: 'درصد درآمد از زیرمجموعه مستقیم',
          field: 'directPercentage',
          sortable: false
        },
        {
          label: ' درصد درآمد از شاخه های پایین تر',
          field: 'offspringPercentage',
          sortable: false
        },
        {
          label: 'تعداد',
          field: 'count',
          sortable: false
        },
        {
          label: 'غیر فعال است',
          field: 'isDisabled',
          sortable: false
        },
        {
          label: 'ویرایش',
          field: 'edit',
          sortable: false
        }
      ],
      // rows: [],
      totalRecords: 0,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: 10,
        search: null
      },
      paginationOptions: {
        enabled: false,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: [10, 20, 30, 40, 50],
        dropdownAllowAll: false
      },

      // add address
      newNetworkAdd: null,
      newNetworkAdds: [
        { code: 'usdt', lable: 'Usd' },
        { code: 'btc', lable: 'Bitcoin' },
        { code: 'ltc', lable: 'Litecoin' },
        { code: 'dash', lable: 'Dash' },
        { code: 'doge', lable: 'DogeCoin' }
      ],
      newAddress: null,

      // filter==true => show filter card
      filter: false,
      nameFilter: null,
      symbolFilter: null,
      typeFilter: null,
      typeFilters: [
        { code: 'c', lable: 'کویین' },
        { code: 't', lable: 'توکن' }
      ]
    }
  },
  methods: {
    /**
    * @param status
    * @return string the translated of status
    */
    getStatusLabel(status) {
      return status ? '<span class="badge badge-success"> فعال</span>' : '<span class="badge badge-danger"> غیر فعال</span>'
    },

    /**handle search filters */
    search() {
      let nameFilter, symbolFilter, typeFilter
      nameFilter = symbolFilter = typeFilter = ''

      /** nameFilter  */
      if (this.nameFilter) {
        nameFilter = '&name_fa=' + this.nameFilter
      }

      /** symbolFilter  */
      if (this.symbolFilter) {
        symbolFilter = '&symbol=' + this.symbolFilter
      }

      /** typeFilter  */
      if (this.typeFilter) {
        typeFilter = '&type=' + this.typeFilter.code
      }

      this.serverParams.search = nameFilter + symbolFilter + typeFilter

      this.loadItems()
    },

    /**reset search and search filters */
    resetSearch() {
      this.networkFilter = this.addressFilter = this.dateFilter = null

      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null
      this.loadItems()
    },

    /**
     * @param status
     * @return string the translated of status
     */
    getTypeLabel(status) {
      switch (status) {
        case 'c':
          return '<span class="badge badge-success">کویین</span>'
        case 't':
          return '<span class="badge badge-info">توکن</span>'
        default:
          return status
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    /** show modal add address */

    /** ----------- Start modal add address ------------- */
    /**show inquiry modal */
    async addCoin() {
      this.$router.push({ path: 'coin/add-coin', replace: true })
    },

    /** save commission selected in store and open edit commission page
     * @param object commission rule selected
     */
    async edit(id) {

      this.$router.push({ path: 'commission/edi-commission/' + id, replace: true })
    },
    /**resetNetwork */
    resetNetwork() {
      this.selectedNetwork = null
    },
    /** ----------- End modal add address ------------- */

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage
        let offset = this.serverParams.perPage * (this.serverParams.page - 1)
        this.isLoading = true

        /**handle sorts */
        let sort = ''
        if (this.serverParams.sort[0].field) {
          /**check sort asc or desc */
          let sortType = this.serverParams.sort[0].type == 'asc' ? '' : '-'
          sort = this.serverParams.sort[0].field ? '&ordering=' + sortType + this.serverParams.sort[0].field : ''
        }

        let search = ''
        if (this.serverParams.search) {
          search = this.serverParams.search
        }

        let res = await this.$axios({
          method: 'get',
          url: 'coin/?limit=' + perPage + '&offset=' + offset + sort + search
        })
        this.totalRecords = res.data.count
        this.rows = res.data.results
      } catch (error) {
        this.totalRecords = 0
        this.rows = null
        console.error(error)
      }
      this.isLoading = false
    }
  },
  async mounted() {
    this.$rtl.enableRTL()

    /**call action get data */
    this.$store.dispatch('getCommissionRules')

  },
  components: {
    VueGoodTable,
    datePicker: VuePersianDatetimePicker,
    Multiselect
  },
  computed: {
    rows: {
      get() {
        return this.$store.state.commissionRules;
      },
      set() {
        return
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';

.multiselect__content-wrapper {
  .multiselect__content {
    padding: 0 0 70px 0;
  }
}
.logo-coin {
  width: 27px !important;
}
</style>
