<template>
  <div class="container-fluid spot-report">
    <div class="row">
      <div class="col-12">
        <card>
          <!-- title and filter -->
          <div class="card-header">
            <div class="card-title d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <h6>درخواست های ارز دیجیتال</h6>
                <base-button v-if="false" type="info" class="mx-3" size="sm" @click="addCoin()">افزودن</base-button>
              </div>
              <h6 class="filter-title" @click="filter = !filter">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر
              </h6>
            </div>

            <!-- filter content -->
            <card v-if="filter" class="filter">
              <form @submit.prevent="loadItems">
                <div class="row">
                  <!-- srchUser  -->
                  <base-input v-if="source != 'user'" dir="ltr" class="col-12 col-md-6 col-xl-3" label=" نام کاربر" type="text" placeholder=" کاربر" v-model="srchUser"> </base-input>
                  <!-- fromFilter -->
                  <div class="col-12 col-md-6 col-xl-3 date-holder">
                    <base-input class="fromDate" label="از تاریخ" type="text" placeholder="از تاریخ" v-model="fromFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDate('from')" type>
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="fromFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".fromDate" view="year" auto-submit></date-picker>
                  </div>

                  <!-- toFilter -->
                  <div class="col-12 col-md-6 col-xl-3 date-holder">
                    <base-input class="toDate" label="تا تاریخ" type="text" placeholder="تا تاریخ" v-model="toFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDate('to')" type>
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="toFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".toDate" view="year" auto-submit></date-picker>
                  </div>

                  <!-- srchCSymbol  -->
                  <base-input dir="ltr" class="col-12 col-md-6 col-xl-3" label="نماد بازار" type="text" placeholder="نماد" v-model="srchCSymbol"> </base-input>

                  <!-- srchByAssetLogType -->
                  <div class="col-12 col-md-6 col-xl-3" v-if="false">
                    <span class="cart-lable-color">نوع گزارش</span>
                    <div class="mt-1">
                      <multiselect
                        :showLabels="false"
                        v-model="assetLogFilter"
                        :options="assetLogsFilter"
                        placeholder="نوع تغییر دارایی"
                        track-by="code"
                        label="title"
                        :searchable="false"
                        :allow-empty="false"
                      ></multiselect>
                    </div>
                  </div>
                  <div class="col-12 d-flex justify-content-between flex-wrap">
                    <div class="d-flex flex-wrap">
                      <base-button class="btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                      <base-button class="btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                    </div>
                    <a href="#" target="_blank"><base-button class="btn-filter" type="info" size="sm">دانلود فایل</base-button></a>
                  </div>
                </div>
              </form>
            </card>
          </div>

          <!-- the page content  -->
          <div>
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="source == 'user' ? columnsUser : columns"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- coin datail -->
                <div class="coin-detail d-flex align-items-center" v-if="props.column.field == 'logoUrl'">
                  <div class="d-flex align-items-center">
                    <!-- coin icon -->
                    <div class="coin-icon">
                      <img :src="props.row.logoUrl" alt="coin" />
                    </div>

                    <div class="d-flex flex-column align-items-start">
                      <!-- coin FA name -->
                      <div class="coin-name">
                        <span>{{ props.row.title }}</span>
                      </div>

                      <!-- coin symbol -->
                      <div class="coin-symbol d-flex align-items-center mt-1">
                        <span class="font-bold">{{ props.row.symbol }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- type field  -->
                <span v-else-if="props.column.field == 'detail'">
                  <base-button class="" type="info" size="sm" @click="showDetail(props.row)">جزئیات</base-button>
                </span>

                <!-- userName -->
                <a v-else-if="props.column.field == 'userName'" :href="'/#/dashboard/users/' + props.row.userId + '/personal-info'" target="_blank">
                  <span dir="ltr"> {{ props.row.userName }} </span></a
                >

                <!-- regTime -->
                <span v-else-if="props.column.field == 'regTime'"> {{ jdate(props.row.regTime) }}</span>

                <!-- newNumberFormat -->
                <span v-else-if="props.column.field == 'amountOfOrder'"> {{ newNumberFormat(props.row.amountOfOrder) }}</span>
                <span v-else-if="props.column.field == 'amountOfStop'"> {{ newNumberFormat(props.row.amountOfStop) }}</span>
                <span v-else-if="props.column.field == 'amountOfLimit'"> {{ newNumberFormat(props.row.amountOfLimit) }}</span>
                <span v-else-if="props.column.field == 'amountOfIxPrice'"> {{ newNumberFormat(props.row.amountOfIxPrice) }}</span>
                <span v-else-if="props.column.field == 'amountOfTotalBc'"> {{ newNumberFormat(props.row.amountOfTotalBc) }}</span>
                <span v-else-if="props.column.field == 'amountOfFilled'"> {{ newNumberFormat(props.row.amountOfFilled) }}</span>

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>

    <!-- modal show all detail order -->
    <modal name="modalDetailOrder" :click-to-close="false">
      <div class="title text-center">
        <h6>جزئیات درخواست</h6>
      </div>
      <!-- content -->
      <div v-if="detail" class="detail">
        <div class="item d-flex align-items-center flex-column justify-content-center">
          <div class="coin-img">
            <img :src="detail.logoUrl" alt="coin logo" />
          </div>
          <div class="coin-symbol my-2">
            <span>
              <b>{{ detail.symbol }}</b>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="item col-6 col-sm-3">
            <span>نام کاربری : </span>
            <span>
              <b dir="ltr">{{ detail.userName }}</b>
            </span>
          </div>
          <div class="item col-6 col-sm-3">
            <span>زمان : </span>
            <span>
              <b>{{ jdate(detail.regTime) }}</b>
            </span>
          </div>
          <div class="item col-6 col-sm-3">
            <span>نوع درخواست : </span>
            <span>
              <b>{{ detail.requestType }}</b>
            </span>
          </div>
          <div class="item col-6 col-sm-3">
            <span> وضعیت درخواست : </span>
            <span>
              <b>{{ detail.requestState }}</b>
            </span>
          </div>

          <div class="item col-12">
            <span>توضیحات : </span>
            <span>
              <p>{{ detail.description }}</p>
            </span>
          </div>
        </div>
      </div>

      <!-- btn action -->
      <div class="d-flex">
        <base-button class="btn-filter" type="primary" size="sm" @click="submitState('Done')">انجام شد</base-button>
        <base-button class="btn-filter" type="success" size="sm" @click="submitState('Holding')">در حال بررسی</base-button>
      </div>

      <!-- close -->
      <div class="close cursor-pointer" @click="hideModal()">
        <svg xmlns="http://www.w3.org/2000/svg" width="22.268" height="22.268" viewBox="0 0 22.268 22.268">
          <g id="Group_4019" data-name="Group 4019" transform="translate(4422.232 1395.174)">
            <path
              id="Path_14730"
              data-name="Path 14730"
              d="M10.384,20.768A10.384,10.384,0,1,0,0,10.384,10.384,10.384,0,0,0,10.384,20.768Z"
              transform="translate(-4421.482 -1394.424)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Path_15133"
              data-name="Path 15133"
              d="M6.936,5.153A1.437,1.437,0,0,0,4.9,7.186Zm4.743,8.81a1.437,1.437,0,1,0,2.033-2.033ZM4.9,7.186l6.776,6.777,2.033-2.033L6.936,5.153Z"
              transform="translate(-4420.406 -1393.598)"
              fill="#fff"
            />
            <path
              id="Path_15134"
              data-name="Path 15134"
              d="M13.712,7.186a1.437,1.437,0,0,0-2.033-2.033ZM4.9,11.93a1.437,1.437,0,0,0,2.033,2.033Zm6.776-6.777L4.9,11.93l2.033,2.033,6.776-6.777Z"
              transform="translate(-4420.406 -1393.598)"
              fill="#fff"
            />
          </g>
        </svg>
      </div>
    </modal>
  </div>
</template>

<script>

/**navigation tabs */
import { Tabs, TabItem } from "vue-material-tabs"


/**grid table */
import { VueGoodTable } from 'vue-good-table'

/**convert miladi to jalali */
var moment = require('moment-jalaali')

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

/**import mixin */
import Helper from '../../../settings/Helper'


// Multiselect
import Multiselect from 'vue-multiselect'

export default {
  name: 'DigiCoin-report',
  mixins: [Helper],
  props: ['source'],
  data() {
    return {
      selectedNetwork: null,
      address: null,
      selectedCoin: null,
      coins: [],
      columns: [
        {
          label: 'نام کاربر',
          field: 'userName',
          sortable: false
        },
        // {
        //   label: 'نماد',
        //   field: 'symbol',
        //   sortable: false
        // },
        {
          label: 'نماد',
          field: 'logoUrl',
          sortable: false
        },

        {
          label: 'نوع درخواست',
          field: 'requestType',
          sortable: false
        },

        {
          label: 'وضعیت درخواست',
          field: 'requestState',
          sortable: false
        },
        {
          label: 'توضیحات',
          field: 'description',
          sortable: false
        },
        {
          label: ' زمان ثبت',
          field: 'regTime',
          sortable: false
        },
        {
          label: 'جزئیات',
          field: 'detail',
          sortable: false
        },

      ],
      columnsUser: [
        // {
        //   label: 'نماد',
        //   field: 'symbol',
        //   sortable: false
        // },
        {
          label: 'نماد',
          field: 'logoUrl',
          sortable: false
        },

        {
          label: 'نوع درخواست',
          field: 'requestType',
          sortable: false
        },

        {
          label: 'وضعیت درخواست',
          field: 'requestState',
          sortable: false
        },
        {
          label: 'توضیحات',
          field: 'description',
          sortable: false
        },
        {
          label: ' زمان ثبت',
          field: 'regTime',
          sortable: false
        },
        {
          label: 'جزئیات',
          field: 'detail',
          sortable: false
        },

      ],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: window.env.CountItemInPerPage,
        search: null
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: window.env.PerPageDropdown,
        dropdownAllowAll: false
      },
      // filter==true => show filter card
      filter: true,
      srchCSymbol: null,
      srchUser: null,
      fromFilter: null,
      toFilter: null,
      assetLogsFilter: [{ code: 'SpotPendingOrderList', title: 'سفارشات باز ' }, { code: 'SpotFilledOrderList', title: ' سفارشات پر شده ' }, { code: 'SpotTradeList', title: 'معاملات' }],
      assetLogFilter: 0,

      theme: {
        nav: "#1e1e2b",
        navItem: "#40435d",
        navActiveItem: "#d250da",
        slider: "#CE93D8",
        arrow: "#f3f3f3",
      },

      detail: null,
      id: null
    }
  },
  methods: {

    /**reset search and search filters */
    resetSearch() {
      this.srchCSymbol = this.fromFilter = this.toFilter = this.srchUser = null

      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null


      this.loadItems()
    },
    /**clear from and to date filter */
    eraseDate(type) {
      type == 'from' ? this.fromFilter = null : this.toFilter = null
    },


    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },


    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage
        this.isLoading = true

        let url = 'Organizer/Report/CurrencyRequestListGet'
        let data = [
          {
            key: "srchUser",
            value: this.srchUser ? this.srchUser : ''
          },
          {
            key: "srchCSymbol",
            value: this.srchCSymbol ? this.srchCSymbol : ''
          },
          {
            key: "srchDateFrom",
            value: this.fromFilter ? moment(this.fromFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
          },
          {
            key: "srchDateTo",
            value: this.toFilter ? moment(this.toFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
          },
        ]
        /** detect parant[from report page or user page] */
        if (this.source == 'user') {
          url = 'Organizer/User/CurrencyRequestListGet'
          data = {
            userId: this.$router.history.current.params.id,
            searchStatements: [
              {
                key: "srchCSymbol",
                value: this.srchCSymbol ? this.srchCSymbol : ''
              },
              {
                key: "srchDateFrom",
                value: this.fromFilter ? moment(this.fromFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
              },
              {
                key: "srchDateTo",
                value: this.toFilter ? moment(this.toFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
              },
            ]
          }
        }

        let res = await this.$axios({
          method: 'post',
          url: `${url}?PageNumber=${this.serverParams.page}&PageSize=${perPage}`,
          data: data
        })
        this.totalRecords = res.data.totalRecords
        this.rows = res.data.data
        this.isLoading = false

      } catch (error) {
        this.totalRecords = 0
        this.rows = null
        this.isLoading = false

      }
    },

    /** show all detail order */
    async showDetail(row) {
      try {
        this.id = row.id

        let url = 'Organizer/Report/CurrencyRequestGet'

        if (this.source == 'user') {
          url = 'Organizer/User/CurrencyRequestGet'
        }

        let req = await this.$axios({
          url: url,
          method: 'get',
          headers: {
            // uIdCode: row.userId,
            // mSymbol: row.mSymbol,
            idCmcCurrencyRequest: this.id
          }
        })

        this.detail = req.data.data
        this.showModal()

      } catch (error) {
        console.log(error)
      }
    },

    /** submit change state request between done and holding- use in modal detail */
    async submitState(type) {

      try {

        this.isLoading = true

        let source = 'Report'
        if (this.source == 'user') {
          source = 'User'
        }


        let url = `Organizer/${source}/CurrencyRequestStateHolding`

        if (type == 'Done') {
          url = `Organizer/${source}/CurrencyRequestStateDone`
        }

        await this.$axios({
          method: 'get',
          url: url,
          headers: {
            idCmcCurrencyRequest: this.id
          }
        })

        this.$notify({
          text: 'عملیات با موفقیت انجام شد',
          type: 'success'
        })


        this.hideModal()
        this.loadItems()
      } catch (error) {
        console.log(error)
      }
      this.isLoading = false

    },
    /**show modal modalDetailOrder  */
    async showModal() {
      this.$modal.show('modalDetailOrder')
    },

    /**hide modal modalDetailOrder*/
    async hideModal() {
      this.$modal.hide('modalDetailOrder')
    },
  },
  async mounted() {
    this.$rtl.enableRTL()

    /**get orders */
    this.loadItems()

  },
  components: {
    VueGoodTable,
    datePicker: VuePersianDatetimePicker,
    Multiselect,
    Tabs, TabItem
  }
}
</script>

<style lang="scss" scoped>
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';
.spot-report {
  .multiselect__content-wrapper {
    .multiselect__content {
      padding: 0 0 70px 0;
    }
  }
  .logo-coin {
    width: 27px !important;
  }

  .close {
    position: absolute;
    left: 33px;
    top: 15px;
  }
}
</style>
