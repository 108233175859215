<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="card-title d-flex flex-column flex-sm-row justify-content-between">
              <div>
                <h6 class="d-inline">لیست پرداخت ها</h6>
                <base-button class="mx-4" type="primary" size="sm" @click="showIncraseModal">افزایش اعتبار</base-button>
              </div>

              <h6 class="filter-title" @click="filter = !filter">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر پرداخت ها
              </h6>
            </div>

            <!-- filter content -->
            <card v-if="filter" class="filter">
              <form @submit.prevent="search">
                <!-- valueFilter -->
                <div class="row">
                  <base-input class="col-12 col-lg-4" label="مقدار" type="text" placeholder="مقدار" v-model="valueFilter"> </base-input>

                  <!-- descFilter -->
                  <base-input label="توضیحات" type="text" placeholder="توضیحات" class="col-12 col-lg-4" v-model="descFilter"> </base-input>

                  <!-- invoiceIdFilter -->
                  <base-input class="col-12 col-lg-4" label="شماره فاکتور" type="text" placeholder="شماره فاکتور" v-model="invoiceIdFilter"> </base-input>

                  <!-- date -->
                  <div v-if="false" class="col-xl-2 col-md-4 date-holder">
                    <base-input class="dateFilter" label="تاریخ" type="text" placeholder="تاریخ" v-model="dateFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDateFilter">
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="dateFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".dateFilter" view="year" auto-submit></date-picker>
                  </div>

                  <!-- checkbox statusFilter -->
                  <div class="col-12 d-flex">
                    <base-checkbox v-model="statusFilter">وضعیت</base-checkbox>
                  </div>
                  <!-- btn submit and reset-->
                  <div class="col-12">
                    <!-- btn  submit-->
                    <base-button class="mt-3 btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                    <!-- btn  reset-->
                    <base-button class="mt-3 mx-0 mx-sm-3 btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                  </div>
                </div>
              </form>
            </card>
          </div>

          <!-- the page content  -->
          <div class="card-body">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="columns"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- amount field  -->
                <span v-if="props.column.field == 'amount'">
                  <span dir="ltr" :class="props.row.amount > 0 ? 'badge badge-success' : 'badge badge-danger'">
                    {{ numberWithCommas(props.row.amount) }}
                  </span>
                </span>

                <!-- status field  -->
                <span v-else-if="props.column.field == 'status'">
                  <span :class="props.row.status ? 'badge badge-success' : 'badge badge-danger'">{{ props.row.status ? 'موفق' : 'ناموفق' }}</span>
                </span>

                <!-- userid field  -->
                <span v-else-if="props.column.field == 'user'">
                  <a href="#" @click="goUser(props.row.user)">{{ props.row.user.username }}</a>
                </span>

                <!-- changeStatus field  -->
                <span v-else-if="props.column.field == 'changeStatus'">
                  <base-button type="primary" v-show="!props.row.status" @click="showModal(props.row)" size="sm">تائید</base-button>
                </span>

                <!-- created field  -->
                <span v-else-if="props.column.field == 'created'">
                  {{ jdate(props.row.created) }}
                </span>

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>

    <!-- modal confirmStatusLable  -->
    <modal name="confirmStatusLable">
      <div class="title text-center">
        <p>تائید وضعیت پرداخت شماره تراکنش {{ confirmStatusLable }}</p>
      </div>

      <div class="d-flex align-items-center justify-content-around mt-4">
        <base-button type="success" @click="submitConfirmStatusLable">تایید پرداخت</base-button>
        <base-button type="danger" @click="hideModal()"> انصراف</base-button>
      </div>
      <!-- confirmation -->
    </modal>

    <!-- modal increase suser balance  -->
    <modal name="increaseModal">
      <div class="title text-center">
        <p>افزایش اعتبار کاربر</p>
      </div>
      <div class="col-12">
        <span>انتخاب کاربر</span>
        <multiselect class="mt-2" id="ajax" :loading="isLoading"  :internal-search="false" :show-no-results="false" :hide-selected="true" :clear-on-select="false" @search-change="searchFly" :limit="3" v-model="selectedUser" :maxHeight="170" :showLabels="true" :options="users" placeholder="شماره یا شناسه کاربر را وارد کنید" track-by="id" label="username" :searchable="true"></multiselect>
      </div>

      <div class="col-12 mt-2">
        <base-input label="مقدار" v-model="amount"></base-input>
      </div>

      <div class="col-12 mt-2">
        <base-input label="توضیحات" v-model="desc"></base-input>
      </div>

      <div class="d-flex align-items-center justify-content-around mt-4">
        <base-button type="success" @click="submitIncrease">تایید</base-button>
        <base-button type="danger" @click="hideIncreaseModal"> انصراف</base-button>
      </div>
      <!-- confirmation -->
    </modal>
  </div>
</template>

<script>
// Multiselect
import Multiselect from 'vue-multiselect'

/**grid table */
import { VueGoodTable } from 'vue-good-table'

/**import mixin */
import Helper from '../../../settings/Helper'

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

var delayTimer

export default {
  name: 'Transactions',
  mixins: [Helper],
  data() {
    return {
      desc: null,
      amount: null,
      description: null,
      users: [],
      selectedUser: null,
      columns: [
        {
          label: 'کاربر',
          field: 'user',
          sortable: false
        },
        {
          label: 'مقدار',
          field: 'amount',
          sortable: true
        },
        {
          label: 'توضیحات',
          field: 'description',
          sortable: true
        },
        {
          label: 'شماره فاکتور',
          field: 'invoice_id',
          sortable: true
        },
        {
          label: 'وضعیت',
          field: 'status',
          sortable: true,
          html: true
        },
        {
          label: 'تاریخ',
          field: 'created',
          sortable: true
        }
      ],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: 10,
        search: null
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: [10, 20, 30, 40, 50],
        dropdownAllowAll: false
      },
      confirmStatusLable: null,
      confirmStatus: null,

      // filter==true => show filter card
      filter: true,
      valueFilter: null,
      descFilter: null,
      invoiceIdFilter: null,
      statusFilter: true,
      dateFilter: null,

      /**timeout for delay on search */
      timeout: null,
      query: null,
      isLoading : false
    }
  },
  methods: {
    /**user search on typing */
    searchFly(query) {
      if(!query) return
      clearTimeout(delayTimer)
      let ts = this
      delayTimer = setTimeout(async () => {
        ts.isLoading = true
        let res = await ts.$axios({ method : "get" , url : (query.indexOf("ID") === 0) ? `users/?uid=${query}` : `users/?username=${query}` })
        ts.users = res.data.results
        ts.isLoading = false
      }, 500 , query)
    },

    goUser(user) {
      this.$router.push({
        path: 'users/' + user.id + '/personal-info',
        replace: true
      })
    },

    /** show modal confirm status */
    async showModal(row) {
      this.confirmStatusLable = row.invoice_id
      this.confirmStatus = row
      this.$modal.show('confirmStatusLable')
    },

    /** show increase modal */
    async showIncraseModal() {
      this.$modal.show('increaseModal')
    },

    /**hide changeStatus modal */
    hideModal() {
      this.confirmStatusLable = this.confirmStatus = null
      this.$modal.hide('confirmStatusLable')
    },

    /**hide changeStatus modal */
    hideIncreaseModal() {
      this.$modal.hide('increaseModal')
    },

    /** change status tarakonesh */
    async submitIncrease() {
      try {
        if (!this.selectedUser) return this.$notify({ text: 'کاربر را انتخاب نمایید', type: 'error' })
        if (!this.amount) return this.$notify({ text: 'مقدار را وارد نمایید', type: 'error' })
        if (!this.desc) return this.$notify({ text: 'توضیحات را وارد نمایید', type: 'error' })

        this.isLoading = true

        let data = {
          value: this.amount,
          comment: this.desc
        }

        await this.$axios({
          method: 'post',
          url: 'accountings/deposits/' + this.selectedUser.id,
          data: data
        })

        this.$notify({
          text: 'افزایش اعتبار با موفقیت انجام شد',
          type: 'success'
        })

        this.isLoading = false

        this.loadItems()
        this.hideModal()
      } catch (error) {
        console.error(error)
      }
    },

    /** change status tarakonesh */
    async submitConfirmStatusLable() {
      try {
        this.isLoading = true
        let data = {
          status: true,
          amount: this.confirmStatus.amount,
          bill_id: this.confirmStatus.bill_id,
          invoice_id: this.confirmStatus.invoice_id,
          user_id: this.confirmStatus.user.id
        }
        let res = await this.$axios({
          method: 'put',
          url: 'payments/' + this.confirmStatus.id + '/',
          data: data
        })

        this.$notify({
          text: ' وضعیت تراکنش ' + this.confirmStatusLable + ' تغییر کرد',
          type: 'success'
        })

        this.isLoading = false

        this.loadItems()
        this.hideModal()
      } catch (error) {
        console.error(error)
      }
    },

    /**reset search and search filters */
    resetSearch() {
      this.valueFilter = null
      this.descFilter = null
      this.invoiceIdFilter = null
      this.statusFilter = true
      this.dateFilter = null
      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null
      this.loadItems()
    },

    /**handle search filters */
    search() {
      let valueFilter, descFilter, invoiceIdFilter, statusFilter, dateFilter
      valueFilter = descFilter = invoiceIdFilter = statusFilter = dateFilter = ''

      /**valueFilter */
      if (this.valueFilter) {
        valueFilter = '&amount=' + this.valueFilter
      }

      /**descFilter */
      if (this.descFilter) {
        descFilter = '&description=' + this.descFilter
      }

      /**invoiceIdFilter */
      if (this.invoiceIdFilter) {
        invoiceIdFilter = '&invoice_id=' + this.invoiceIdFilter
      }

      /**dateFilter */
      if (this.dateFilter) {
        dateFilter = '&created=' + this.dateFilter
      }

      /**statusFilter */
      statusFilter = '&status=' + this.statusFilter

      this.serverParams.search = valueFilter + descFilter + invoiceIdFilter + statusFilter + dateFilter
      this.loadItems()
    },

    // clear DateFilter
    eraseDateFilter() {
      this.dateFilter = null
    },


    /**
     * @param status
     * @return string the translated of status
     */
    getStatusLabel(status) {
      if (status) return '<span class="badge badge-success">موفق</span>'

      return '<span class="badge badge-danger">ناموفق</span>'
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage
        let offset = this.serverParams.perPage * (this.serverParams.page - 1)
        this.isLoading = true

        /**handle sorts */
        let sort = ''
        if (this.serverParams.sort[0].field) {
          /**check sort asc or desc */
          let sortType = this.serverParams.sort[0].type == 'asc' ? '' : '-'
          sort = this.serverParams.sort[0].field ? '&ordering=' + sortType + this.serverParams.sort[0].field : ''
        }

        let search = ''
        if (this.serverParams.search) {
          search = this.serverParams.search
        }

        let res = await this.$axios({
          method: 'get',
          url: 'payments/?limit=' + perPage + '&offset=' + offset + '&ordering=-created' + sort + search
        })
        this.totalRecords = res.data.count
        this.rows = res.data.results
        this.isLoading = false
      } catch (error) {
        console.error(error)
      }
    }
  },
  async mounted() {
    this.$rtl.enableRTL()

    /**get orders */
    try {
      this.isLoading = true
      let res = await this.$axios({
        method: 'get',
        url: 'payments/?limit=10&offset=0&ordering=-created'
      })
      this.totalRecords = res.data.count
      this.rows = res.data.results
      this.isLoading = false
    } catch (error) {
      console.error(error)
    }
  },
  components: {
    VueGoodTable,
    Multiselect,
    datePicker: VuePersianDatetimePicker
  }
}
</script>

<style lang="scss">
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';

.multiselect {
  .multiselect__input,
  .multiselect__single {
    padding: 5px;
    border-radius: 0px !important;
  }
}
</style>
