<template>
  <section class="notification-edit">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <ValidationObserver ref="contentValidation">
            <!-- card 1 content -->
            <div class="col-12">
              <card>
                <div class="card-header">
                  <div class="card-title d-flex align-items-center justify-content-between">
                    <h6 v-if="id">ویرایش اعلانات</h6>
                    <h6 v-else>افزودن اعلانات</h6>
                    <!-- isDisabled Confirmation -->
                    <div class="profile d-flex align-items-center justify-content-start my-3">
                      <p class="m-3">وضعیت</p>
                      <div class="switch">
                        <input type="checkbox" class="toggle" v-model="isDisabled" />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- the page content  -->
                <div class="card-body">
                  <div class="row my-3">
                    <!-- title -->
                    <div class="date-modify col-12 col-lg-3">
                      <ValidationProvider name="عنوان" rules="required" v-slot="{ errors }">
                        <span> عنوان</span>
                        <input type="text" v-model="title" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <!-- button -->
                    <div class="date-modify col-12 col-lg-3">
                      <ValidationProvider name="متن دکمه" rules="required" v-slot="{ errors }">
                        <span> متن دکمه</span>
                        <input type="text" v-model="button" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <!-- content -->
                    <div class="date-modify col-12 col-lg-3">
                      <ValidationProvider name="محتوی متنی" rules="required" v-slot="{ errors }">
                        <span> محتوی متنی</span>
                        <input type="text" v-model="content" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <!-- linkUrl -->
                    <div class="date-modify col-12 col-lg-3">
                      <ValidationProvider name="لینک دکمه" rules="required" v-slot="{ errors }">
                        <span> لینک دکمه</span>
                        <input type="text" v-model="linkUrl" dir="ltr" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="row mt-5">
                    <!-- optionNotificationCloseType -->
                    <div class="col-12 col-lg-3">
                      <ValidationProvider name="نوع بسته شدن" rules="required" v-slot="{ errors }">
                        <span class="cart-lable-color">نوع بسته شدن</span>
                        <div class="mt-1">
                          <multiselect :showLabels="false" v-model="idCloseType" :options="optionNotificationCloseType" placeholder="نوع بسته شدن" track-by="id" label="value" :searchable="false" :allow-empty="false"></multiselect>
                        </div>
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <!-- optionNotificationReasonType -->
                    <div class="col-12 col-lg-3">
                      <ValidationProvider name="دلیل" rules="required" v-slot="{ errors }">
                        <span class="cart-lable-color">دلیل</span>
                        <div class="mt-1">
                          <multiselect :showLabels="false" v-model="idReasonType" :options="optionNotificationReasonType" placeholder="دلیل" track-by="id" label="value" :searchable="false" :allow-empty="false"></multiselect>
                        </div>
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <!-- optionNotificationThemeType -->
                    <div class="col-12 col-lg-3">
                      <ValidationProvider name="نوع تم" rules="required" v-slot="{ errors }">
                        <span class="cart-lable-color">نوع تم</span>
                        <div class="mt-1">
                          <multiselect :showLabels="false" v-model="idThemeType" :options="optionNotificationThemeType" placeholder="نوع تم" track-by="id" label="value" :searchable="false" :allow-empty="false"></multiselect>
                        </div>
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <!--  file pond  -->
                    <div class="col-12 col-lg-3">
                      <div class="dropzone" @dragover.prevent @drop.prevent>
                        <!-- dropzone area  -->
                        <div class="dropzone-area" @drop="dragFile">
                          <div class="dotted-border"></div>
                          <div class="d-flex justify-content-center align-items-center">
                            <span for="file">
                              <small>300 پیکسل * 300 پیکسل</small>

                              <div class="btn-warning w-100 mt-2" @click="$refs.avatar.click()">
                                <span> انتخاب تصویر </span>
                                <input v-show="false" type="file" id="file" ref="avatar" name="avatar" @change="uploadFile" />
                              </div>
                            </span>
                          </div>
                          <viewer :options="options" class="viewer" v-if="fileUploaded">
                            <img class="btn-warning" :src="fileUploaded" alt="" />
                          </viewer>
                          <div class="show-notify d-flex justify-content-center my-3" v-if="file">
                            <span>فایل دریافتی : {{ file.name }} </span>
                          </div>
                          <span class="btn btn-danger btn-sm text-light" @click="showModal()" v-if="fileUploaded || file">حذف تصویر</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </ValidationObserver>

          <div class="col-12">
            <div class="submit d-flex justify-content-start align-items-center pt-3">
              <button class="btn-primary mx-1" @click="submit" v-if="!isLoading">ذخیره</button>
              <div v-else class="loader-wait-request mx-5"></div>

              <button class="btn-light mx-1" @click="$router.go(-1)">انصراف</button>
            </div>
          </div>
        </div>
      </div>

      <!-- modal confirm delete-->
      <modal name="removeItem" :click-to-close="false">
        <div class="title d-flex justify-content-between align-items-center">
          <h6>تصویر اعلان حذف شود؟</h6>
          <div v-if="!isLoading" class="d-flex align-items-center justify-content-end">
            <base-button type="success" @click="removeImg()">تایید </base-button>
            <base-button type="danger" @click="hideModal()"> انصراف</base-button>
          </div>
          <!-- loader -->
          <div v-else class="">
            <div class="loader-wait-request mx-5"></div>
          </div>
        </div>
      </modal>
    </div>
  </section>
</template>

<script>


/**import mixin */
import Helper from '../../../settings/Helper'


// Multiselect
import Multiselect from 'vue-multiselect'

import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate'
import { numeric, required, double } from 'vee-validate/dist/rules'
import fa from 'vee-validate/dist/locale/fa'
extend('required', required)
extend('numeric', numeric)
extend('double', double)
localize('fa', fa)

/**the validations */
import { size } from "vee-validate/dist/rules";

export default {
  name: 'UserPersonalInfo',
  mixins: [Helper],
  data() {
    return {
      id: null,
      title: null,
      isDisabled: null,
      idMedia: this.idMedia,
      file: null,
      fileUploaded: null,
      linkUrl: null,
      button: null,
      content: null,

      isLoading: false,

      optionNotificationCloseType: [],
      optionNotificationReasonType: [],
      optionNotificationThemeType: [],
      idThemeType: null,
      idReasonType: null,
      idCloseType: null,


      // v-viewer
      options: {
        toolbar: {
          zoomIn: 4,
          zoomOut: 4,
          oneToOne: 4,
          reset: 4,
          prev: false,
          play: {
            show: 4,
            size: 'large'
          },
          next: false,
          rotateLeft: 4,
          rotateRight: 4,
          flipHorizontal: 4,
          flipVertical: 4
        }
      },

    }
  },
  methods: {
    /**show modal remove item 
  * @param int and string of item 
  */
    async showModal() {
      try {

        this.$modal.show('removeItem')
      } catch (error) {
        console.error(error)
      }
    },

    /**hide modal remove item and reload data*/
    async hideModal() {
      this.$modal.hide('removeItem')
    },

    // remove image notification
    removeImg() {
      this.hideModal();
      this.file = this.fileUploaded = null;
      this.idMedia = 0;
      if (this.id)
        this.submit();
    },

    // submit save
    async submit() {
      try {


        /**validate the form */
        if (! await this.$refs.contentValidation.validate()) return this.$notify({ text: 'لطفا خطاهای رخ داده را برطرف نمایید', type: 'error' })

        // this.errors = [];
        if (!this.idMedia && this.file) {
          if (!this.file) {
            // this.errors.push("انتخاب فایل الزامی می باشد")
            this.$notify({
              text: "انتخاب فایل الزامی می باشد",
              type: "warn",
            })
            return
          }

          if (await this.submitUploadFile() != 'next') return
        }

        this.isLoading = true

        let data = {
          id: parseInt(this.id ? this.id : 0),
          title: this.title,
          idThemeType: this.idThemeType.id,
          idReasonType: this.idReasonType.id,
          idCloseType: this.idCloseType.id,
          idMedia: this.idMedia,
          linkUrl: this.linkUrl,
          button: this.button,
          content: this.content,
          isDisabled: !this.isDisabled,
        }

        await this.$axios({
          url: '/Organizer/Notification/AddOrUpdate',
          data: data
        })

        this.$notify({
          text: 'عملیات با موفقیت انجام شد',
          type: 'success'
        })

        this.$router.push({ path: '/dashboard/notification', replace: true })
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    },

    // load date for edit
    async loadData() {
      try {
        // this.idCoin = 

        let res = await this.$axios({
          method: 'get',
          url: '/Organizer/Notification/Get',
          headers: {
            idNotification: this.id
          }
        })

        this.sort = res.data.data.sort
        this.isDisabled = !res.data.data.isDisabled
        this.title = res.data.data.title

        this.id = res.data.data.id
        this.title = res.data.data.title
        this.idMedia = res.data.data.idMedia
        this.linkUrl = res.data.data.linkUrl
        this.button = res.data.data.button
        this.content = res.data.data.content
        this.fileUploaded = res.data.data.logoUrl
        this.isDisabled = !res.data.data.isDisabled

        this.idThemeType = this.getSelectedInMultiSelect(this.optionNotificationThemeType, res.data.data.idThemeType)
        this.idReasonType = this.getSelectedInMultiSelect(this.optionNotificationReasonType, res.data.data.idReasonType)
        this.idCloseType = this.getSelectedInMultiSelect(this.optionNotificationCloseType, res.data.data.idCloseType)


      } catch (error) {
        console.error(error)
      }
    },

    /**  map option multiselect for fill v-model when edit */
    getSelectedInMultiSelect(object, key) {
      let value = null
      object.map(function (item) {
        if (key == item.id)
          value = item
      })
      return value
    },

    /**------------------Start get data for 3 multi select */
    async GetNotificationCloseType() {
      try {
        let req = await this.$axios({
          url: '/Organizer/Enums/GetNotificationCloseType',
          method: 'get',
          headers: {
            idLang: 1065
          }
        })

        this.optionNotificationCloseType = req.data.data
      } catch (error) {
        console.log(error)
      }
    },

    async GetNotificationReasonType() {
      try {
        let req = await this.$axios({
          url: '/Organizer/Enums/GetNotificationReasonType',
          method: 'get',
          headers: {
            idLang: 1065
          }
        })

        this.optionNotificationReasonType = req.data.data
      } catch (error) {
        console.log(error)
      }
    },

    async GetNotificationThemeType() {
      try {
        let req = await this.$axios({
          url: '/Organizer/Enums/GetNotificationThemeType',
          method: 'get',
          headers: {
            idLang: 1065
          }
        })

        this.optionNotificationThemeType = req.data.data
      } catch (error) {
        console.log(error)
      }
    },

    /**------------------End get data for 3 multi select */

    /**------------------Start  upload file */

    /**submit form */
    async submitUploadFile() {
      try {
        let data = new FormData()
        data.append('file', this.file)


        let req = await this.$axios({
          url: "Account/UploadMedia", data: data, headers: {
            mf: 50
          }
        })
        this.idMedia = req.data.data

        this.resetFile();

        return 'next'
      } catch (error) {
        console.error(error)
      }
      return 'error'
    },

    /**remove file */
    resetFile() {
      this.file = null
    },

    /**upload file on upload button */
    async uploadFile(e) {
      this.resetFile()
      if (!this.fileValidation(e.target.files[0])) return
      this.file = e.target.files[0]

      // this.url = URL.createObjectURL(this.file)
    },

    /**upload file on drag and drop */
    async dragFile(e) {
      this.resetFile();
      /**validate the file */
      if (!this.fileValidation(e.dataTransfer.files[0])) return;
      /**fire file input , the input and change events! */
      this.file = e.dataTransfer.files[0];
      // this.url = URL.createObjectURL(this.file);
    },

    /** file validation */
    fileValidation(file) {
      this.errors = [];
      /**check file type */
      // if (!image.validate(file)) {
      //   this.errors.push("فایل وارد شده تصویر نیست");
      //   return false;
      // }

      /**check file size */
      if (!size.validate(file, { size: 10240 })) {
        this.errors.push("حجم فایل ارسالی باید کمتر از 10 مگابایت باشد");
        return false;
      }
      return true;
    },
    /**------------------End upload file */

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    Multiselect
  },
  async mounted() {


    // get option multiselecs
    await this.GetNotificationCloseType();
    await this.GetNotificationReasonType();
    await this.GetNotificationThemeType();


    this.$rtl.enableRTL()

    // load date for edit
    this.id = this.$router.history.current.params.id
    if (this.id)
      this.loadData()


  },
  computed: {
    minAgeDate: () => {
      return moment().subtract(18, 'year').format('jYYYY/jM/jD') // somthing like 1382/10/05
    },
    maxAgeDate: () => {
      return moment().subtract(90, 'year').format('jYYYY/jM/jD') // somthing like  1310/10/05
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-edit {
  svg {
    cursor: pointer;
  }
  .multiselect {
    border: none !important;
    border-bottom: 1px solid #ffffff !important;
    border-radius: 0;
    padding-bottom: 10px;
  }
  input[type='text'] {
    background-color: transparent !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    color: #ffffeb;
    &:disabled {
      color: #9b9b83;
    }
  }
  textarea {
    background-color: transparent !important;
    border: 1px solid #ffffeb;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    direction: ltr;
    color: #ffffeb67;
    padding-top: 10px;
    padding-bottom: 4px;
    padding-left: 10px;
    &:focus-visible {
      outline: none !important;
      color: #ffffeb;
    }
  }
  .flexible {
    border-bottom: 2px solid #ffffff !important;
  }
  .change {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #74839b;
    margin: 0 auto 0 0;
    cursor: pointer;
    &:hover {
      background-color: #445877;
      svg {
        fill: #74839b;
      }
    }
  }
  .switch {
    input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      &:focus {
        outline: 0;
      }
    }

    .toggle {
      height: 23px;
      width: 50px;
      border-radius: 30px;
      display: inline-block;
      position: relative;
      margin: 0;
      border: 2px solid #474755;
      background: linear-gradient(180deg, #2d2f39 0%, #1f2027 100%);
      transition: all 0.2s ease;
      &:after {
        content: '';
        position: absolute;
        top: -4px;
        left: -2px;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background: #5c6e8b;
        box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
        transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
      }
      &:checked {
        border-color: #4f8bec;
        &:after {
          transform: translatex(20px);
          left: 2px;
          background: #4982df;
        }
      }
    }
  }
  .submit {
    button {
      padding: 10px 35px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }
  }

  .dropzone {
    position: relative;
    .dropzone-area {
      background: #cecece;
      border-radius: 9px;
      margin: 20px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      position: relative;
      .dotted-border {
        position: absolute;
        width: 94%;
        height: 86%;
        top: 7%;
        left: 2%;
        border: 2px dashed #707070;
        border-radius: 12px;
        z-index: 0;
      }
      span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 2;
        color: #707070;
        min-width: 100px;
      }
    }

    label {
      width: 170px;
      position: absolute;
      top: 75%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    input[type='file'] {
      opacity: 0;
      outline: none;
    }
    .bg-green {
      background: #33c933;
      border: #33c933;
      color: white;
      svg {
        stroke: white;
      }
    }
  }
  .viewer {
    position: relative;
    z-index: 4;
    img {
      max-width: 122px;
      min-width: 100px;
    }
  }
}
.logoUrl-coin {
  width: 70px !important;
}
</style>
