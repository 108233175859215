<template>
  <div class="container-fluid report-static">
    <div class="row">
      <div class="col-12">
        <card>
          <!-- title and filter -->
          <div class="card-header">
            <div class="card-title d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <h6>گزارشات آماری</h6>
                <base-button v-if="false" type="info" class="mx-3" size="sm" @click="addCoin()">افزودن</base-button>
              </div>
              <h6 class="filter-title" @click="filter = !filter">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر
              </h6>
            </div>
            <!-- filter content -->
            <card v-if="filter" class="filter">
              <form @submit.prevent="loadItems">
                <div class="row">
                  <!-- name farsi -->
                  <base-input dir="ltr" v-if="isAllUsers" class="col-12 col-lg-3" label="نام کاربر" type="text" placeholder="نام کاربر" v-model="userFilter"> </base-input>

                  <!-- marketFilter -->
                  <base-input dir="ltr" class="col-12 col-lg-3" label="بازار" type="text" placeholder="بازار" v-model="marketFilter"> </base-input>

                  <!-- srchByAssetLogType -->
                  <div class="col-12 col-lg-4">
                    <span class="cart-lable-color">نوع گزارش</span>
                    <div class="mt-1">
                      <multiselect
                        :showLabels="false"
                        v-model="assetLogFilter"
                        :options="assetLogsFilter"
                        placeholder="نوع تغییر دارایی"
                        track-by="code"
                        label="title"
                        :searchable="false"
                        :allow-empty="false"
                      ></multiselect>
                    </div>
                  </div>
                  <div class="col-12 d-flex justify-content-between flex-wrap">
                    <div class="d-flex flex-wrap">
                      <base-button class="btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                      <base-button class="btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                    </div>
                    <a href="#" target="_blank"><base-button class="btn-filter" type="info" size="sm">دانلود فایل</base-button></a>
                  </div>
                </div>
              </form>
            </card>
          </div>
          <!-- the page content  -->
          <div>
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="columns"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- type field  -->
                <span v-if="props.column.field == 'logoUrl'">
                  <img :src="props.row.logoUrl" alt="copy" class="logo-coin" />
                </span>

                <!-- type field  -->
                <span v-else-if="props.column.field == 'type'" v-html="getTypeLabel(props.row.type)"> </span>

                <!-- type field  -->
                <span v-else-if="props.column.field == 'edit'">
                  <base-button class="" type="primary" size="sm" @click="edit(props.row.symbol)">ویرایش</base-button>
                </span>

                <!-- status field  -->
                <span v-else-if="props.column.field == 'state'" v-html="getStatusLabel(props.row.state)"> </span>

                <!-- userName -->
                <a v-else-if="props.column.field == 'userName'" :href="'/#/dashboard/users/' + props.row.userId + '/personal-info'" target="_blank">
                  <span dir="ltr"> {{ props.row.userName }} </span></a
                >

                <!-- regTime -->
                <span v-else-if="props.column.field == 'regTime'"> {{ jdate(props.row.regTime) }}</span>

                <!-- value -->
                <span v-else-if="props.column.field == 'value'"> {{ newNumberFormat(props.row.value) }}</span>

                <!-- balanceAfter -->
                <span v-else-if="props.column.field == 'balanceAfter'"> {{ newNumberFormat(props.row.balanceAfter) }}</span>

                <!-- tmn field  -->

                <span v-else-if="props.column.field == 'countLast1M'" class="value-and-Count">
                  <span><small>تعداد :</small> {{ newNumberFormat(props.row.countOfLast1M) }} </span> <span> <small>حجم :</small> {{ newNumberFormat(props.row.sumOfLast1M) }} </span></span
                >
                <span v-else-if="props.column.field == 'countLast3M'" class="value-and-Count"
                  ><span><small>تعداد :</small> {{ newNumberFormat(props.row.countOfLast3M) }} </span> <span> <small>حجم :</small> {{ newNumberFormat(props.row.sumOfLast3M) }} </span></span
                >
                <span v-else-if="props.column.field == 'countLast6M'" class="value-and-Count">
                  <span><small>تعداد :</small> {{ newNumberFormat(props.row.countOfLast6M) }} </span> <span> <small>حجم :</small> {{ newNumberFormat(props.row.sumOfLast6M) }} </span></span
                >
                <span v-else-if="props.column.field == 'sumOfTotal'" class="value-and-Count">
                  <span><small>تعداد :</small> {{ newNumberFormat(props.row.countOfTotal) }} </span> <span> <small>حجم :</small> {{ newNumberFormat(props.row.sumOfTotal) }} </span></span
                >

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from 'vue-good-table'

/**import mixin */
import Helper from '../../../settings/Helper'


// Multiselect
import Multiselect from 'vue-multiselect'

export default {
  name: 'StatisticalReportsComponent',
  mixins: [Helper],
  props: {
    columns: {
      type: Array,
      default: () => [
        {
          label: 'نام کاربر',
          field: 'userName',
          sortable: false
        },
        {
          label: 'نماد',
          field: 'symbol',
          sortable: false
        },
        {
          label: ' 1 ماه گذشته',
          field: 'countLast1M',
          sortable: false
        },
        {
          label: ' 3 ماه گذشته',
          field: 'countLast3M',
          sortable: false
        },
        {
          label: ' 6 ماه گذشته',
          field: 'countLast6M',
          sortable: false
        },


        {
          label: ' کل معاملات',
          field: 'sumOfTotal',
          sortable: false
        },
      ]
    },
    isAllUsers: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      selectedNetwork: null,
      address: null,
      selectedCoin: null,
      coins: [],

      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: window.env.CountItemInPerPage,
        search: null
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: window.env.PerPageDropdown,
        dropdownAllowAll: false
      },
      // filter==true => show filter card
      filter: true,
      userFilter: null,
      marketFilter: null,
      assetLogsFilter: [{ code: 'UserStatsReferral', title: 'درآمد از زیرمجموعه' }, { code: 'UserStatsPrize', title: 'پاداش ها' }, { code: 'UserStatsTrade', title: 'معاملات' }],
      assetLogFilter: { code: 'UserStatsTrade', title: 'معاملات' }
    }
  },
  methods: {

    /**reset search and search filters */
    resetSearch() {
      this.userFilter = this.marketFilter = null

      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null

      this.loadItems()
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage
        let offset = this.serverParams.perPage * (this.serverParams.page - 1)
        this.isLoading = true

        /**handle sorts */
        let sort = ''
        if (this.serverParams.sort[0].field) {
          /**check sort asc or desc */
          let sortType = this.serverParams.sort[0].type == 'asc' ? '' : '-'
          sort = this.serverParams.sort[0].field ? '&ordering=' + sortType + this.serverParams.sort[0].field : ''
        }

        let url = `Organizer/Report/${this.assetLogFilter.code}?PageNumber=${this.serverParams.page}&PageSize=${perPage}`
        let data = [
          {
            key: "srchUser",
            value: this.userFilter
          },
          {
            key: "srchMarket",
            value: this.marketFilter
          },
        ]
        let res = await this.$axios({
          method: 'post',
          url: this.isAllUsers ? url : `Organizer/User/${this.assetLogFilter.code}?PageNumber=${this.serverParams.page}&PageSize=${perPage}`,
          data: this.isAllUsers ? data : {
            userId: this.$router.history.current.params.id,
            searchStatements: [
              {
                key: "srchUser",
                value: this.userFilter
              },
              {
                key: "srchMarket",
                value: this.marketFilter
              },
            ]
          }
        })
        this.totalRecords = res.data.totalRecords
        this.rows = res.data.data
        this.isLoading = false

      } catch (error) {
        this.totalRecords = 0
        this.rows = null
        this.isLoading = false
        console.log(error)
      }
    }
  },
  async mounted() {
    this.$rtl.enableRTL()

    /**get orders */
    this.loadItems()
  },
  components: {
    VueGoodTable,
    Multiselect
  }
}
</script>

<style lang="scss" >
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';
.report-static {
  table th {
    text-align: right !important;
  }

  .multiselect__content-wrapper {
    .multiselect__content {
      padding: 0 0 70px 0;
    }
  }
  .logo-coin {
    width: 27px !important;
  }

  .value-and-Count {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
</style>
