<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="card-title d-flex flex-column flex-sm-row justify-content-between">
              <h6>لیست کارت ها</h6>
              <h6 class="filter-title" @click="filter = !filter">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر کارت ها
              </h6>
            </div>
            <!-- filter content -->
            <card v-if="filter" class="filter">
              <form @submit.prevent="search">
                <!-- userFilter -->
                <div class="row pr-4">
                  <base-input class="col-12 col-md-6 col-xl-3" label="کاربر" type="text" placeholder="کاربر" v-model="userFilter"> </base-input>

                  <!-- ibanFilter -->
                  <base-input label="IBAN" type="text" placeholder="IBAN" class="col-12 col-md-6 col-xl-3" v-model="ibanFilter"> </base-input>

                  <!-- dateFilter -->
                  <div v-if="false" class="col-xl-3 col-md-4 date-holder">
                    <base-input class="dateFilter" label="تاریخ" type="text" placeholder="تاریخ" v-model="dateFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraserDateFilter">
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="dateFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".dateFilter" view="year" auto-submit></date-picker>
                  </div>

                  <!-- bankNameFilter -->
                  <base-input class="col-12 col-md-6 col-xl-3" label="بانک" type="text" placeholder="بانک" v-model="bankNameFilter"> </base-input>

                  <!-- cartNumberFilter -->
                  <base-input class="col-12 col-md-6 col-xl-3" label="شماره کارت" type="text" placeholder="شماره کارت" v-model="cartNumberFilter"> </base-input>

                  <!-- checkbox statusFilter -->
                  <div class="col-12 d-flex">
                    <base-checkbox v-model="statusFilter">وضعیت</base-checkbox>
                  </div>

                  <!-- btn submit and reset-->
                  <div class="col-12">
                    <!-- btn  submit-->
                    <base-button class="mt-3 btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                    <!-- btn  reset-->
                    <base-button class="mt-3 mx-0 mx-sm-3 btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                  </div>
                </div>
              </form>
            </card>
          </div>

          <!-- the page content  -->
          <div class="card-body">
            <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :isLoading.sync="isLoading" :pagination-options="paginationOptions" :totalRows="totalRecords" :rows="rows" :columns="columns" :rtl="true" :line-numbers="true" styleClass="table tablesorter">
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <div class="table-row">
                  <!-- created field  -->
                  <span v-if="props.column.field == 'created'">
                    {{ jdate(props.row.created) }}
                  </span>

                  <!-- IBAN field  -->
                  <span v-else-if="props.column.field == 'IBAN'">
                    {{ props.row.IBAN }}
                    <img :src="require('../../../assets/img/copy.svg')" alt="copy" class="copy" @click="copyCode(props.row.IBAN)" />
                  </span>

                  <!-- card_number field  -->
                  <span v-else-if="props.column.field == 'card_number'">
                    {{ props.row.card_number }}
                    <img :src="require('../../../assets/img/copy.svg')" alt="copy" class="copy" @click="copyCode(props.row.card_number)" />
                  </span>

                  <!-- type field  -->
                  <span v-else-if="props.column.field == 'status'" v-html="getStatusLabel(props.row.status)"> </span>

                  <!-- actions field  -->
                  <span v-else-if="props.column.field == 'actions'">
                    <base-button size="sm" type="primary" @click="showModal(props.row.id)">بررسی</base-button>
                  </span>

                  <!-- show in default  -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </div>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>

    <!-- modal -->
    <modal name="changeStatus">
      <div class="title text-center">
        <p>اطلاعات ثبت شده توسط کاربر</p>
      </div>
      <div class="d-flex align-items-center justify-content-between my-3 mx-5">
        <div class="user-name">
          <span>نام</span>
          <p>{{ username }}</p>
        </div>
        <div v-if="false" class="national-number">
          <span>کد ملی</span>
          <p>{{ nationalNumber }}</p>
        </div>
      </div>
      <hr class="mx-5 bg-white my-4" />
      <div class="text-center">
        <p>تاییدیه</p>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-3 mx-5">
        <div class="cart-number">
          <span>شماره کارت</span>
          <p>{{ cartNumber }}</p>
        </div>
        <div class="cart-owner">
          <span>نام</span>
          <p>{{ cartOwner }}</p>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-3 mx-5">
        <div class="account-number">
          <span>شماره حساب</span>
          <p>{{ accountNumber }}</p>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-around">
        <base-button type="success" @click="submitchangeStatus">تایید اطلاعات</base-button>
        <base-button type="danger" @click="hideModal()"> انصراف</base-button>
      </div>
      <!-- confirmation -->
    </modal>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from 'vue-good-table'

/**import mixin */
import Helper from '../../../settings/Helper'

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

export default {
  name: 'Cards',
  mixins: [Helper],
  data() {
    return {
      columns: [
        {
          label: 'کاربر',
          field: 'user.username',
          sortable: false
        },
        {
          label: 'IBAN',
          field: 'IBAN',
          sortable: true
        },
        {
          label: 'تاریخ',
          field: 'created',
          sortable: true
        },
        {
          label: 'بانک',
          field: 'bank',
          sortable: true
        },
        {
          label: 'شماره کارت',
          field: 'card_number',
          sortable: true,
          html: true
        },
        {
          label: 'وضعیت',
          field: 'status',
          sortable: true
        },
        {
          label: 'فعالیت ها',
          field: 'actions',
          sortable: false
        }
      ],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: 10,
        search: null
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: [10, 20, 30, 40, 50],
        dropdownAllowAll: false
      },

      // filter==true => show filter card
      filter: true,
      userFilter: null,
      ibanFilter: null,
      bankNameFilter: null,
      cartNumberFilter: null,
      statusFilter: true,
      dateFilter: null,

      /** modal change status */
      cartId: null,
      username: null,
      accountNumber: null,
      cartNumber: null,
      cartOwner: null
    }
  },
  methods: {
    /**submit change status cart */
    async submitchangeStatus() {
      try {
        let res = await this.$axios({
          method: 'post',
          url: '/card/verify/' + this.cartId
        })

        this.$notify({ text: 'اطلاعات تائید شد', type: 'success' })
        this.hideModal()
        this.loadItems()
      } catch (error) {
        return console.log(error)
      }
    },

    /**show changeStatus modal */
    async showModal(id) {
      try {
        this.isLoading = true
        let res = await this.$axios({
          method: 'get',
          url: '/card/information/' + id
        })
        this.isLoading = false

        this.cartId = id
        this.username = res.data.profile.first_name + ' ' + res.data.profile.last_name
        this.accountNumber = res.data.data.IBAN
        this.cartNumber = res.data.data.card
        this.cartOwner = res.data.data.bankName

        this.$modal.show('changeStatus')
      } catch (error) {
        console.error(error)
      }
    },

    /**show changeStatus modal */
    hideModal() {
      this.cartId = this.username = this.accountNumber = this.cartNumber = this.cartOwner = null
      this.$modal.hide('changeStatus')
    },

    /**handle search filters */
    search() {
      let userFilter, ibanFilter, bankNameFilter, statusFilter, cartNumberFilter, dateFilter
      userFilter = ibanFilter = bankNameFilter = statusFilter = cartNumberFilter = dateFilter = ''

      /**firstname */
      if (this.userFilter) {
        userFilter = '&user__username=' + this.userFilter
      }

      /** ibanFilter */
      if (this.ibanFilter) {
        ibanFilter = '&IBAN=' + this.ibanFilter
      }

      /**bankNameFilter */
      if (this.bankNameFilter) {
        bankNameFilter = '&bank=' + this.bankNameFilter
      }

      /**cartNumberFilter */
      if (this.cartNumberFilter) {
        cartNumberFilter = '&card_number=' + this.cartNumberFilter
      }

      /** dateFilter  */
      if (this.dateFilter) {
        dateFilter = '&created=' + this.dateFilter
      }

      // statusFilter
      statusFilter = '&status=' + this.statusFilter

      this.serverParams.search = userFilter + ibanFilter + bankNameFilter + statusFilter + cartNumberFilter + dateFilter
      this.loadItems()
    },

    /**reset search and search filters */
    resetSearch() {
      this.userFilter = null
      this.ibanFilter = null
      this.bankNameFilter = null
      this.cartNumberFilter = null
      this.statusFilter = true
      this.dateFilter = null
      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null
      this.loadItems()
    },

    /** eraser input Date Filter */
    eraserDateFilter() {
      this.dateFilter = null
    },

    /**row click
     * on row click go to user detail
     */
    rowClick(row) {
      this.$router.push({
        path: 'carts/' + row.id + '/tab1',
        replace: true
      })
    },

    async copyCode(s) {
      await this.copyToClipboard(s)
      this.$notify({
        text: 'کپی انجام شد',
        type: 'success',
        group: 'copynotif'
      })
    },

    /**handle unssl server */
    async copyToClipboard(textToCopy) {
      // navigator clipboard api needs a secure context (https)
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return await navigator.clipboard.writeText(textToCopy)
      } else {
        // text area method
        let textArea = document.createElement('textarea')
        textArea.value = textToCopy
        // make the textarea out of viewport
        textArea.style.position = 'fixed'
        textArea.style.left = '-999999px'
        textArea.style.top = '-999999px'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand('copy') ? res() : rej()
          textArea.remove()
        })
      }
    },


    /**
     * @param status
     * @return string the translated of status
     */
    getStatusLabel(status) {
      if (status) {
        return '<span class="badge badge-success">فعال</span>'
      } else {
        return '<span class="badge badge-danger">غیرفعال</span>'
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage
        let offset = this.serverParams.perPage * (this.serverParams.page - 1)
        this.isLoading = true

        /**handle sorts */
        let sort = ''
        if (this.serverParams.sort[0].field) {
          /**check sort asc or desc */
          let sortType = this.serverParams.sort[0].type == 'asc' ? '' : '-'
          sort = this.serverParams.sort[0].field ? '&ordering=' + sortType + this.serverParams.sort[0].field : ''
        }

        let search = ''
        if (this.serverParams.search) {
          search = this.serverParams.search
        }

        let res = await this.$axios({
          method: 'get',
          url: 'card/?limit=' + perPage + '&offset=' + offset + '&ordering=-created' + sort + search
        })
        this.totalRecords = res.data.count
        this.rows = res.data.results
        this.isLoading = false
      } catch (error) {
        console.error(error)
      }
    }
  },
  async mounted() {
    this.$rtl.enableRTL()

    /**get orders */
    try {
      this.isLoading = true
      let res = await this.$axios({
        method: 'get',
        url: 'card/?limit=10&offset=0&ordering=-created'
      })
      this.totalRecords = res.data.count
      this.rows = res.data.results
      this.isLoading = false
    } catch (error) {
      console.error(error)
    }
  },
  components: {
    VueGoodTable,
    datePicker: VuePersianDatetimePicker
  }
}
</script>

<style>
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';

.copy {
  width: 14px !important;
  cursor: pointer;
}
</style>
