<template>
  <AssetLogComponent></AssetLogComponent>
</template>

<script>
/**components */
import AssetLogComponent from "../components/AssetLogComponent";
export default {
  name: 'AssetsLog',
  components: {
    AssetLogComponent
  }
}
</script>
