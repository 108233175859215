<template>
  <UserLogComponent></UserLogComponent>
</template>

<script>
/**components */
import UserLogComponent from "../components/UserLogComponent";
export default {
  name: 'usersLog',
  components: {
    UserLogComponent
  }
}
</script>
