<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="card-title d-flex flex-column flex-sm-row justify-content-between">
              <h6>لیست فروش</h6>
              <h6 class="filter-title" @click="filter = !filter">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر خرید سفارشات
              </h6>
            </div>
            <!-- filter content -->
            <card v-if="filter" class="filter">
              <form @submit.prevent="search">
                <!-- userFilter -->
                <div class="row">
                  <base-input class="col-12 col-md-6 col-xl-3" label="کاربر" type="text" placeholder="کاربر" v-model="userFilter"> </base-input>

                  <!-- invoiceIdFilter -->
                  <base-input class="col-12 col-md-6 col-xl-3" label="شماره فاکتور" type="text" placeholder="شماره فاکتور" v-model="invoiceIdFilter"> </base-input>

                  <!-- dateFilter -->
                  <div v-if="false" class="col-xl-3 col-md-4 date-holder">
                    <base-input class="dateFilter" label="تاریخ" type="text" placeholder="تاریخ" v-model="dateFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraserDateFilter">
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="dateFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".dateFilter" view="year" auto-submit></date-picker>
                  </div>

                  <!-- networkFilter -->
                  <base-input v-if="false" class="col-xl-3 col-md-4" label="شبکه" type="text" placeholder="شبکه" v-model="networkFilter"> </base-input>

                  <!-- valueFilter -->
                  <base-input class="col-12 col-md-6 col-xl-3" label="مقدار" type="text" placeholder="مقدار" v-model="valueFilter"> </base-input>

                  <!-- status -->
                  <div class="col-12 col-md-6 col-xl-3">
                    <span class="cart-lable-color">وضعیت سفارش</span>
                    <div class="mt-1">
                      <multiselect :showLabels="false" v-model="statusFilter" :options="statusFilters" placeholder="وضعیت سفارش" track-by="code" label="lable" :searchable="false" :allow-empty="false"></multiselect>
                    </div>
                  </div>

                  <!-- btn submit and reset-->
                  <div class="col-12 col-xl-6 d-flex flex-column flex-sm-row align-items-center">
                    <!-- btn  submit-->
                    <base-button class="mt-3 btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                    <!-- btn  reset-->
                    <base-button class="mt-3 mx-0 mx-sm-3 btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                  </div>
                </div>
              </form>
            </card>
          </div>

          <!-- the page content  -->
          <div class="card-body">
            <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :isLoading.sync="isLoading" :pagination-options="paginationOptions" :totalRows="totalRecords" :rows="rows" :columns="columns" :rtl="true" :line-numbers="true" styleClass="table tablesorter">
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <div @click="rowClick(props.row)" class="table-row">
                  <!-- type field  -->
                  <span v-if="props.column.field == 'type'" v-html="getTypeLabel(props.row.type)"></span>

                  <!-- value field  -->
                  <span v-else-if="props.column.field == 'value'"> {{ props.row.value }}</span>

                  <!-- network field  -->
                  <span v-else-if="props.column.field == 'network'">
                    <span class="network">{{ props.row.network }}</span>
                  </span>

                  <!-- created field  -->
                  <span v-else-if="props.column.field == 'created'">
                    {{ jdate(props.row.created) }}
                  </span>

                  <!-- status field  -->
                  <span v-else-if="props.column.field == 'status'" v-html="getStatusLabel(props.row.status)"> </span>

                  <!-- tmn_value field  -->
                  <span v-else-if="props.column.field == 'tmn_value'">
                    {{ newNumberFormat(props.row.tmn_value) }}
                  </span>

                  <!-- the actions  -->
                  <span v-else-if="props.column.field == 'actions'">
                    <base-button size="sm" type="primary" class="mx-1">مشاهده</base-button>
                    <base-button size="sm" type="danger" class="mx-1" v-if="false">حذف</base-button>
                  </span>

                  <!-- show in default  -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </div>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from 'vue-good-table'

/**import mixin */
import Helper from '../../../settings/Helper'

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

// Multiselect
import Multiselect from 'vue-multiselect'

// register globally

export default {
  name: 'buy-orders',
  mixins: [Helper],
  data() {
    return {
      columns: [
        {
          label: 'کاربر',
          field: 'user.username',
          sortable: false
        },
        {
          label: 'نوع',
          field: 'type',
          sortable: true,
          html: true
        },
        {
          label: 'شماره فاکتور',
          field: 'invoice_id',
          sortable: true
        },
        {
          label: 'تاریخ',
          field: 'created',
          sortable: true
        },
        {
          label: 'وضعیت',
          field: 'status',
          sortable: true,
          html: true
        },
        {
          label: 'شبکه',
          field: 'network',
          sortable: true
        },
        {
          label: 'مقدار',
          field: 'value',
          sortable: true
        },
        {
          label: 'کوین',
          field: 'coin.symbol',
          sortable: false
        },
        {
          label: 'ارزش تومان در لحظه سفارش',
          field: 'tmn_value',
          sortable: false
        },
        {
          label: 'فعالیت ها',
          field: 'actions',
          sortable: false
        }
      ],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: 10,
        search: null
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: [10, 20, 30, 40, 50],
        dropdownAllowAll: false
      },

      // filter==true => show filter card
      filter: true,
      userFilter: null,
      typeFilter: null,
      typeFilters: [
        { code: 'BUY', lable: 'خرید' },
        { code: 'SELL', lable: 'فروش' }
      ],
      invoiceIdFilter: null,
      statusFilter: null,
      statusFilters: [
        { code: 'PENDING', lable: 'درحال بررسی' },
        { code: 'REJECTED', lable: 'رد شده' },
        { code: 'APPROVED', lable: 'تایید شده' },
        { code: 'IN_PROCESS', lable: 'درحال انجام' },
        { code: 'COMPLETE', lable: 'کامل شده' }
      ],
      dateFilter: null,
      networkFilter: null,
      valueFilter: null
    }
  },
  methods: {
    /**handle search filters */
    search() {
      let userFilter, typeFilter, invoiceIdFilter, statusFilter, dateFilter, networkFilter, valueFilter
      userFilter = typeFilter = invoiceIdFilter = statusFilter = dateFilter = networkFilter = valueFilter = ''

      /**firstname */
      if (this.userFilter) {
        userFilter = '&user__username=' + this.userFilter
      }

      /** type */
      if (this.typeFilter) {
        typeFilter = '&type=' + this.typeFilter.code
      }

      /**invoiceIdFilter */
      if (this.invoiceIdFilter) {
        invoiceIdFilter = '&invoice_id=' + this.invoiceIdFilter
      }

      /** statusFilter  */
      if (this.statusFilter) {
        statusFilter = '&status=' + this.statusFilter.code
      }

      /** dateFilter  */
      if (this.dateFilter) {
        dateFilter = '&created=' + this.dateFilter
      }

      /** networkFilter  */
      if (this.networkFilter) {
        networkFilter = '&network=' + this.networkFilter
      }

      /** valueFilter  */
      if (this.valueFilter) {
        valueFilter = '&value=' + this.valueFilter
      }

      this.serverParams.search = userFilter + typeFilter + invoiceIdFilter + statusFilter + dateFilter + networkFilter + valueFilter
      this.loadItems()
    },

    /**reset search and search filters */
    resetSearch() {
      this.filter = true
      this.userFilter = null
      this.typeFilter = null
      this.invoiceIdFilter = null
      this.statusFilter = null
      this.dateFilter = null
      this.networkFilter = null
      this.valueFilter = null
      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null
      this.loadItems()
    },

    /** eraser input Date Filter */
    eraserDateFilter() {
      this.dateFilter = null
    },

    /**row click
     * on row click go to user detail
     */
    rowClick(row) {
      this.$router.push({
        path: row.id + '/orders-details',
        replace: true
      })
    },


    /**
     * @param status
     * @return string the translated of status
     */
    getStatusLabel(status) {
      switch (status) {
        case 'REJECTED':
          return '<span class="badge bg-error">رد شده</span>'

        case 'APPROVED':
          return '<span class="badge bg-info">تایید شده</span>'

        case 'DRAFT':
          return '<span class="badge bg-warning">درحال بررسی</span>'

        case 'IN_PROCESS':
          return '<span class="badge bg-warning">درحال انجام</span>'

        case 'COMPLETE':
          return '<span class="badge bg-success">کامل شده</span>'

        case 'PENDING':
          return '<span class="badge bg-warning">در حال بررسی</span>'

        case 'OPEN':
          return '<span class="badge bg-warning">منتظر دریافت شماره تراکنش از کاربر</span>'

        default:
          return status
      }
    },
    /**
     * @param type
     * @return string the translated of type
     */
    getTypeLabel(type) {
      switch (type) {
        case 'BUY':
          return '<span class="badge badge-info">خرید</span>'
        case 'SELL':
          return '<span class="badge badge-primary">فروش</span>'
        default:
          return type
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage
        let offset = this.serverParams.perPage * (this.serverParams.page - 1)
        this.isLoading = true

        /**handle sorts */
        let sort = ''
        if (this.serverParams.sort[0].field) {
          /**check sort asc or desc */
          let sortType = this.serverParams.sort[0].type == 'asc' ? '' : '-'
          sort = this.serverParams.sort[0].field ? '&ordering=' + sortType + this.serverParams.sort[0].field : ''
        }

        /**handle search */
        let search = ''
        if (this.serverParams.search) {
          search = this.serverParams.search
        }

        /**handle type page filter */
        let typePageFilter = '&type=SELL'

        let res = await this.$axios({
          method: 'get',
          url: 'invoice/?limit=' + perPage + '&offset=' + offset + '&ordering=-created' + sort + search + typePageFilter
        })
        this.totalRecords = res.data.count
        this.rows = res.data.results
        this.isLoading = false
      } catch (error) {
        console.error(error)
      }
    }
  },
  async mounted() {
    this.$rtl.enableRTL()

    /**get orders */
    try {
      this.isLoading = true
      let res = await this.$axios({
        method: 'get',
        url: 'invoice/?limit=10&offset=0&type=SELL&ordering=-created'
      })
      this.totalRecords = res.data.count
      this.rows = res.data.results
      this.isLoading = false
    } catch (error) {
      console.error(error)
    }
  },
  components: {
    VueGoodTable,
    datePicker: VuePersianDatetimePicker,
    Multiselect
  }
}
</script>

<style lang='scss'>
@import '~vue-good-table/dist/vue-good-table.css';

.network {
  text-transform: uppercase;
  .date-holder {
    position: relative;
    svg {
      position: absolute;
      top: 50%;
      left: 20%;
      cursor: pointer;
    }
  }
}
</style>
