<template>
  <AssetsComponent></AssetsComponent>
</template>

<script>
/**components */
import AssetsComponent from "../components/AssetsComponent";
export default {
  name: 'assetsReport',
  components: {
    AssetsComponent
  }
}
</script>
