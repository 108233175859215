<template>
  <StatisticalReportsComponent :columns="columns" :isAllUsers="false"></StatisticalReportsComponent>
</template>

<script>
/**components */
import StatisticalReportsComponent from "../components/StatisticalReportsComponent";
export default {
  name: 'StatisticalReportsUser',
  data() {
    return {
      columns: [
        {
          label: 'نماد',
          field: 'symbol',
          sortable: false
        },

        {
          label: ' 1 ماه گذشته',
          field: 'countLast1M',
          sortable: false
        },
        {
          label: ' 3 ماه گذشته',
          field: 'countLast3M',
          sortable: false
        },
        {
          label: ' 6 ماه گذشته',
          field: 'countLast6M',
          sortable: false
        },


        {
          label: ' کل معاملات',
          field: 'sumOfTotal',
          sortable: false
        },
      ]
    }
  },
  components: {
    StatisticalReportsComponent
  }
}
</script>
