<template>
  <div class="container-fluid assetsLog">
    <div class="row">
      <div class="col-12">
        <card>
          <!-- title and filter -->
          <div class="card-header">
            <div class="card-title d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <h6 v-if="isAllUsers">گزارش تغییر در دارایی ها</h6>
                <h6 v-else>لیست سوابق دارایی های کاربر</h6>
              </div>
              <h6 class="filter-title" @click="filter = !filter">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر
              </h6>
            </div>
            <!-- filter content -->
            <card v-if="filter" class="filter">
              <form @submit.prevent="loadItems">
                <div class="row">
                  <!-- name farsi -->
                  <base-input v-if="isAllUsers" dir="ltr" class="col-12 col-lg-4" label="نام کاربر" type="text" placeholder="نام کاربر" v-model="userFilter"> </base-input>

                  <!-- symbol -->
                  <base-input class="col-12 col-lg-4" label="واحد پول" type="text" placeholder="واحد پول" v-model="currencyFilter"> </base-input>

                  <!-- srchByAssetLogType -->
                  <div class="col-12 col-lg-4">
                    <span class="cart-lable-color">نوع تغییر دارایی</span>
                    <div class="mt-1">
                      <multiselect
                        :showLabels="false"
                        v-model="assetLogFilter"
                        :options="assetLogsFilter"
                        placeholder="نوع تغییر دارایی"
                        track-by="id"
                        label="value"
                        :searchable="false"
                        :allow-empty="false"
                      ></multiselect>
                    </div>
                  </div>

                  <!-- fromFilter -->
                  <div class="col-12 col-md-6 col-xl-4 date-holder">
                    <base-input class="fromDate" label="از تاریخ" type="text" placeholder="از تاریخ" v-model="fromFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDate('from')" type>
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="fromFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".fromDate" view="year" auto-submit></date-picker>
                  </div>

                  <!-- toFilter -->
                  <div class="col-12 col-md-6 col-xl-4 date-holder">
                    <base-input class="toDate" label="تا تاریخ" type="text" placeholder="تا تاریخ" v-model="toFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDate('to')" type>
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="toFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".toDate" view="year" auto-submit></date-picker>
                  </div>

                  <div class="col-12 d-flex justify-content-between flex-wrap">
                    <div class="d-flex flex-wrap">
                      <base-button class="btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                      <base-button class="btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                    </div>
                    <a href="#" target="_blank"><base-button class="btn-filter" type="info" size="sm">دانلود فایل</base-button></a>
                  </div>
                </div>
              </form>
            </card>
          </div>
          <!-- the page content  -->
          <div>
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="columns"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- coin datail -->
                <div class="coin-detail d-flex align-items-center" v-if="props.column.field == 'logoUrl'">
                  <div class="d-flex align-items-center">
                    <!-- coin icon -->
                    <div class="coin-icon">
                      <img :src="props.row.logoUrl" alt="coin" />
                    </div>

                    <div class="d-flex flex-column align-items-start">
                      <!-- coin FA name -->
                      <div class="coin-name">
                        <span>{{ props.row.title }}</span>
                      </div>

                      <!-- coin symbol -->
                      <div class="coin-symbol d-flex align-items-center mt-1">
                        <span class="font-bold">{{ props.row.cSymbol }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="coin-detail d-flex align-items-center" v-else-if="props.column.field == 'logoUrlUser'">
                  <div class="d-flex align-items-center">
                    <!-- coin icon -->
                    <div class="coin-icon">
                      <img :src="props.row.logoUrl" alt="coin" />
                    </div>

                    <div class="d-flex flex-column align-items-start">
                      <!-- coin FA name -->
                      <div class="coin-name">
                        <span>{{ props.row.title }}</span>
                      </div>

                      <!-- coin symbol -->
                      <div class="coin-symbol d-flex align-items-center mt-1">
                        <span class="font-bold">{{ props.row.cSymbol }}</span>
                      </div>
                    </div>
                  </div>

                  <small class="text-description" style="top: 47px; right: 50px" v-if="props.row.lstDescription">{{ showLstDescription(props.row.lstDescription) }}</small>
                </div>

                <!-- userName -->
                <span class="d-flex" style="position: relative" v-else-if="props.column.field == 'userName'">
                  <a :href="'/#/dashboard/users/' + props.row.userId + '/personal-info'" target="_blank" dir="ltr"> {{ props.row.userName }} </a>
                  <small class="text-description" v-if="props.row.lstDescription">{{ showLstDescription(props.row.lstDescription) }}</small>
                </span>

                <!-- logTime -->
                <span v-else-if="props.column.field == 'logTime'"> {{ jdate(props.row.logTime) }}</span>

                <!-- value -->
                <span v-else-if="props.column.field == 'value'" v-html="colorValue(numberWithCommas(props.row.value), props.row.idLogType)"></span>

                <!-- balanceAfter -->
                <span v-else-if="props.column.field == 'balanceAfter'"> {{ numberWithCommas(props.row.balanceAfter) }}</span>

                <!-- TMN field  -->
                <span v-else-if="props.column.field == 'TMN'">
                  {{ showValue(props.row.lstEstimatedValues, 'TMN') }}
                </span>

                <!-- USDT field  -->
                <span v-else-if="props.column.field == 'USDT'">
                  {{ showValue(props.row.lstEstimatedValues, 'USDT') }}
                </span>

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>

    <!-- modal show all detail  -->
    <modal name="modalShowDescription" :click-to-close="false">
      <div class="title text-center">
        <h6>جزئیات</h6>
      </div>
      <!-- content -->
      <div class="detail">
        <div class="item">
          <span v-html="detail"></span>
        </div>
      </div>
      <!-- close -->
      <div class="close cursor-pointer" @click="hideModal()">
        <svg xmlns="http://www.w3.org/2000/svg" width="22.268" height="22.268" viewBox="0 0 22.268 22.268">
          <g id="Group_4019" data-name="Group 4019" transform="translate(4422.232 1395.174)">
            <path
              id="Path_14730"
              data-name="Path 14730"
              d="M10.384,20.768A10.384,10.384,0,1,0,0,10.384,10.384,10.384,0,0,0,10.384,20.768Z"
              transform="translate(-4421.482 -1394.424)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Path_15133"
              data-name="Path 15133"
              d="M6.936,5.153A1.437,1.437,0,0,0,4.9,7.186Zm4.743,8.81a1.437,1.437,0,1,0,2.033-2.033ZM4.9,7.186l6.776,6.777,2.033-2.033L6.936,5.153Z"
              transform="translate(-4420.406 -1393.598)"
              fill="#fff"
            />
            <path
              id="Path_15134"
              data-name="Path 15134"
              d="M13.712,7.186a1.437,1.437,0,0,0-2.033-2.033ZM4.9,11.93a1.437,1.437,0,0,0,2.033,2.033Zm6.776-6.777L4.9,11.93l2.033,2.033,6.776-6.777Z"
              transform="translate(-4420.406 -1393.598)"
              fill="#fff"
            />
          </g>
        </svg>
      </div>
    </modal>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from 'vue-good-table'

/**convert miladi to jalali */
var moment = require('moment-jalaali')

// Multiselect
import Multiselect from 'vue-multiselect'

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

/**import mixin */
import Helper from '../../../settings/Helper'

export default {
  name: 'AssetLogComponent',
  mixins: [Helper],
  props: {
    columns: {
      type: Array,
      default: () => [
        {
          label: 'نام کاربر',
          field: 'userName',
          sortable: false
        },
        {
          label: 'لوگو',
          field: 'logoUrl',
          sortable: false
        },
        {
          label: ' نوع تغییر',
          field: 'logType',
          sortable: false
        },
        {
          label: ' نوع صندوق',
          field: 'fundType',
          sortable: false
        },
        {
          label: ' مقدار تغییر',
          field: 'value',
          sortable: false
        },
        {
          label: ' آخرین موجودی',
          field: 'balanceAfter',
          sortable: false
        },
        {
          label: ' زمان ثبت',
          field: 'logTime',
          sortable: false
        },

      ],
    },
    isAllUsers: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      detail: null,

      selectedNetwork: null,
      address: null,
      selectedCoin: null,
      coins: [],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: window.env.CountItemInPerPage,
        search: null
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: window.env.PerPageDropdown,
        dropdownAllowAll: false
      },
      // filter==true => show filter card
      filter: true,
      userFilter: null,
      currencyFilter: null,
      assetLogFilter: null,
      fromFilter: null,
      toFilter: null,


    }
  },
  methods: {
    /** show Description */
    showLstDescription(desc) {
      // let desc = ''
      // object.map(function (item) {
      //   desc += ' , ' + item.title + ' : ' + item.value
      // })

      return desc.length < 200 ? desc.substring(0) : desc.substring(0, 200) + ' . . . '
    },

    /**show inquiry modal */
    async showModal(description) {
      this.detail = description;
      this.$modal.show('modalShowDescription')
    },

    /**show inquiry modal */
    hideModal() {
      this.$modal.hide('modalShowDescription')
    },

    /** set color danger or success for deposit type and withdraw type */
    colorValue(value, code) {
      if (code < 2000 && code > 1000)
        return `<span class='text-success'>${value}</span>`
      return `<span class='text-danger'>${value}</span>`
    },
    /** get value and show according to tmn and  
   * @param object
  */
    showValue(value, type) {
      let price = value.map(function (item) {
        if (item.symbol == type)
          return item.value

      });

      return this.numberWithCommas(price[0])
    },

    /**reset search and search filters */
    resetSearch() {
      this.userFilter = this.currencyFilter = this.assetLogFilter = this.fromFilter = this.toFilter = null

      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null
      this.loadItems()

    },
    /**clear from and to date filter */
    eraseDate(type) {
      type == 'from' ? this.fromFilter = null : this.toFilter = null
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {

        let perPage = this.serverParams.perPage
        let offset = this.serverParams.perPage * (this.serverParams.page - 1)
        this.isLoading = true

        /**handle sorts */
        let sort = ''
        if (this.serverParams.sort[0].field) {
          /**check sort asc or desc */
          let sortType = this.serverParams.sort[0].type == 'asc' ? '' : '-'
          sort = this.serverParams.sort[0].field ? '&ordering=' + sortType + this.serverParams.sort[0].field : ''
        }

        let url = `Organizer/Report/AssetLog?PageNumber=${this.serverParams.page}&PageSize=${perPage}`;
        let data = {
          searchStatements: [
            {
              key: "srchUser",
              value: this.userFilter ? this.userFilter : null
            },
            {
              key: "srchCSymbol",
              value: this.currencyFilter ? this.currencyFilter : null
            },
            {
              key: "srchDateFrom",
              value: this.fromFilter ? moment(this.fromFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
            },
            {
              key: "srchDateTo",
              value: this.toFilter ? moment(this.toFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
            },
          ],
          reportType: this.assetLogFilter ? this.assetLogFilter.id : 0

        }

        let res = await this.$axios({
          method: 'post',
          url: this.isAllUsers ? url : `Organizer/User/GetAssetLog?PageNumber=${this.serverParams.page}&PageSize=${perPage}`,
          data: this.isAllUsers ? data : {
            userId: this.$router.history.current.params.id,
            searchStatements: [
              {
                key: "srchCSymbol",
                value: this.currencyFilter ? this.currencyFilter : ''
              },
              {
                key: "srchDateFrom",
                value: this.fromFilter ? moment(this.fromFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
              },
              {
                key: "srchDateTo",
                value: this.toFilter ? moment(this.toFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
              },
            ],
            reportType: this.assetLogFilter ? this.assetLogFilter.id : 0

          }
        })

        this.totalRecords = res.data.totalRecords
        this.rows = res.data.data
        this.isLoading = false

      } catch (error) {
        this.totalRecords = 0
        this.rows = null
        this.isLoading = false
        console.log(error)

      }
    }
  },
  async mounted() {
    this.$rtl.enableRTL()

    /** get assetLog */
    this.$store.dispatch('getAssetLogType')

    this.loadItems()
  },
  components: {
    VueGoodTable,
    datePicker: VuePersianDatetimePicker,
    Multiselect
  },
  computed: {
    assetLogsFilter: {
      get() {
        return this.$store.state.assetLogType
      },
      set() {
        return
      }
    }
  }
}
</script>

<style lang="scss" >
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';
.assetsLog {
  .multiselect__content-wrapper {
    .multiselect__content {
      padding: 0 0 70px 0;
    }
  }
  .logo-coin {
    width: 27px !important;
  }

  .text-description {
    color: rgba(245, 245, 245, 0.461);
    font-size: 12px !important;
    position: absolute;
    top: 24px;
    right: 0;
    z-index: 15;
    cursor: pointer;
    &:hover {
      color: white !important;
    }
  }

  .close {
    position: absolute;
    left: 33px;
    top: 15px;
  }
  .vgt-right-align {
    padding-bottom: 18px !important;
  }
}
</style>
