<template>
  <section class="personal-info">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <ValidationObserver ref="contentValidation">
            <!-- card 1 content -->
            <card>
              <div class="card-header">
                <div class="card-title d-flex align-items-center justify-content-between">
                  <h6>ویرایش ارز دیجیتال</h6>
                  <!-- status Confirmation -->
                  <div class="profile d-flex align-items-center justify-content-start my-3">
                    <p class="m-3">وضعیت</p>
                    <div class="switch">
                      <input type="checkbox" class="toggle" v-model="status" @change="changeStatus" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- the page content  -->
              <div class="card-body">
                <div class="row">
                  <!-- row 1 -->
                  <div class="col-12">
                    <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-3">
                      <!-- cSymbol -->
                      <div class="date-modify w-100 w-lg-50 mx-2">
                        <ValidationProvider name="نماد" rules="required" v-slot="{ errors }">
                          <span> نماد</span>
                          <input type="text" v-model="cSymbol" disabled />
                          <span class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>

                      <!-- title -->
                      <div class="date-modify w-100 w-lg-50 mx-2">
                        <ValidationProvider name="نام " rules="required" v-slot="{ errors }">
                          <span> نام </span>
                          <input type="text" v-model="title" />
                          <span class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>

                      <!--  priority -->
                      <div class="date-created w-100 w-lg-50">
                        <ValidationProvider name="اولویت نمایش" rules="required" v-slot="{ errors }">
                          <span>اولویت نمایش</span>
                          <input type="text" v-model="priority" />
                          <span class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>

                    <!-- row two-->
                    <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-3">
                      <!-- minWithdraw -->
                      <div class="date-modify w-100 w-lg-50 mx-2">
                        <ValidationProvider name="حداقل میزان برداشت" rules="required|double" v-slot="{ errors }">
                          <span> حداقل میزان برداشت</span>
                          <input type="text" v-model="minWithdraw" />
                          <span class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>

                      <!-- maxWithdrawPer24H -->
                      <div class="date-modify w-100 w-lg-50 mx-2">
                        <ValidationProvider name="حداکثر میزان برداشت در 24 ساعت" rules="required|double" v-slot="{ errors }">
                          <span>حداکثر میزان برداشت در 24 ساعت</span>
                          <input type="text" v-model="maxWithdrawPer24H" />
                          <span class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>

                      <!-- roundNo -->
                      <div class="date-modify w-100 w-lg-50 mx-2">
                        <ValidationProvider name=" رقم روند اعشار" rules="required|numeric" v-slot="{ errors }">
                          <span> رقم روند اعشار</span>
                          <div class="d-flex align-items-center justify-content-between">
                            <input type="text" v-model="roundNo" />
                          </div>
                          <span class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>

                  <!-- row 2 content (logo and sell state)-->
                  <div class="col-12">
                    <!-- the page content  -->
                    <div class="row my-3">
                      <!-- logo -->

                      <div class="col-12 col-md-8">
                        <span>آدرس لوگو</span>
                        <div class="mt-3 d-flex align-items-center justify-content-between">
                          <input type="text" v-model="logoUrl" />
                          <img :src="logoUrl" v-if="logoUrl" class="logo-coin" alt="" />
                        </div>
                      </div>
                      <!-- networks -->
                      <div class="col-12 col-md-4 mt-3">
                        <div class="d-flex justify-content-between">
                          <span>شبکه</span>
                        </div>
                        <div class="mt-3">
                          <span class="text-light">{{ showNetwork(networks) }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="submit d-flex justify-content-start align-items-center pt-3">
                  <button class="btn-primary mx-1" @click="submit" v-if="!isLoading">ذخیره</button>
                  <div v-else class="loader-wait-request mx-5"></div>

                  <button class="btn-light mx-1" @click="$router.go(-1)">انصراف</button>
                </div>
              </div>
            </card>
          </ValidationObserver>
        </div>
      </div>
    </div>

    <!-- question and post -->
    <div class="row">
      <!-- Question -->
      <div class="col-12 my-5">
        <Question></Question>
      </div>
      <div class="col-12">
        <Post></Post>
      </div>
    </div>
  </section>
</template>

<script>
/**import mixin */
import Helper from '../../../settings/Helper'

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

/**import moment-jalali */
var moment = require('moment-jalaali')

// Multiselect
import Multiselect from 'vue-multiselect'

import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate'
import { numeric, required, double } from 'vee-validate/dist/rules'
import fa from 'vee-validate/dist/locale/fa'
extend('required', required)
extend('numeric', numeric)
extend('double', double)

localize('fa', fa)

import Question from "./Question.vue"
import Post from "./Post.vue"

export default {
  name: 'UserPersonalInfo',
  props: ['id'],
  mixins: [Helper],
  data() {
    return {
      idCoin: null,
      priority: null,
      minWithdraw: 0,
      logoUrl: null,
      title: null,
      cSymbol: null,
      maxWithdrawPer24H: null,
      status: null,
      roundNo: null,
      networks: [{ fee: "", name: "", address: "", explorer: "" }],

      isLoading: false
    }
  },
  methods: {
    /**
         * @param object 
         * @return string the name of network
         */
    showNetwork(network) {
      let net = null;
      if (network) {
        network.filter(function (item) {
          net += ', ' + item['name']
        });
      }
      return net ? net.substring(1) : '-'
    },
    /** enable or disable status market */
    async changeStatus() {
      try {

        let url = 'Organizer/Currency/Disable'

        if (this.status)
          url = 'Organizer/Currency/Activate'

        await this.$axios({
          url: url,
          method: 'get',
          headers: { cSymbol: this.$router.history.current.params.id }
        })

        this.$notify({
          text: 'عملیات با موفقیت انجام شد',
          type: 'success'
        })
      } catch (error) {
        console.log(error)
      }
    },

    // submit save
    async submit() {
      try {
        /**validate the form */
        if (! await this.$refs.contentValidation.validate()) return this.$notify({ text: 'لطفا خطاهای رخ داده را برطرف نمایید', type: 'error' })

        this.isLoading = true

        let data = {
          priority: this.priority,
          minWithdraw: parseFloat(this.minWithdraw),
          logoUrl: this.logoUrl,
          title: this.title,
          cSymbol: this.cSymbol,
          maxWithdrawPer24H: parseFloat(this.maxWithdrawPer24H),
          roundNo: this.roundNo
        }

        await this.$axios({
          url: 'Organizer/Currency/Update',
          data: data
        })

        this.$notify({
          text: 'عملیات با موفقیت انجام شد',
          type: 'success'
        })

        this.isLoading = false
        this.$router.push({ path: '/dashboard/coin', replace: true })
      } catch (error) {
        console.error(error)
      }
    },

    // load date for edit
    async loadData() {
      try {
        // this.idCoin = 

        let res = await this.$axios({
          method: 'get',
          url: 'General/CurrencyGet',
          headers: {
            cSymbol: this.$router.history.current.params.id
          }
        })
        this.minWithdraw = res.data.data.minWithdraw
        this.logoUrl = res.data.data.logoUrl
        this.title = res.data.data.title
        this.cSymbol = res.data.data.symbol
        this.priority = res.data.data.priority
        this.maxWithdrawPer24H = res.data.data.maxWithdrawPer24H
        this.status = res.data.data.idState == 0 || res.data.data.idState == 21 ? true : false
        this.networks = res.data.data.listOfSupportedNetworks
        this.roundNo = res.data.data.roundNo

      } catch (error) {
        console.error(error)
        // this.$router.push('/dashboard/coin', true)
      }
    },

    /**
     * add new network
     */
    addNetwork() {
      /**check latest index */
      if (!this.networks[this.networks.length - 1].name || !this.networks[this.networks.length - 1].fee) return this.$notify({ text: 'مقادیر fee و name شبکه الزامی است', type: 'error' })

      /**add new one */
      this.networks.push({ fee: "", name: "", address: "", explorer: "" })
    },

    /**
     * remove network
     */
    removeNetwork(index) {
      this.networks.splice(index, 1)
    }
  },
  components: {
    datePicker: VuePersianDatetimePicker,
    Multiselect,
    ValidationProvider,
    ValidationObserver,
    Question,
    Post
  },
  async mounted() {
    this.$rtl.enableRTL()

    // load date for edit
    this.loadData()

    /**get user info */
  },
  computed: {
    minAgeDate: () => {
      return moment().subtract(18, 'year').format('jYYYY/jM/jD') // somthing like 1382/10/05
    },
    maxAgeDate: () => {
      return moment().subtract(90, 'year').format('jYYYY/jM/jD') // somthing like  1310/10/05
    }
  }
}
</script>

<style lang="scss" scoped>
.personal-info {
  svg {
    cursor: pointer;
  }
  .multiselect {
    border: none !important;
    border-bottom: 1px solid #ffffff !important;
    border-radius: 0;
    padding-bottom: 10px;
  }
  input[type='text'] {
    background-color: transparent !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    color: #ffffeb;
    &:disabled {
      color: #9b9b83;
    }
  }
  textarea {
    background-color: transparent !important;
    border: 1px solid #ffffeb;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    direction: ltr;
    color: #ffffeb67;
    padding-top: 10px;
    padding-bottom: 4px;
    padding-left: 10px;
    &:focus-visible {
      outline: none !important;
      color: #ffffeb;
    }
  }
  .flexible {
    border-bottom: 2px solid #ffffff !important;
  }
  .change {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #74839b;
    margin: 0 auto 0 0;
    cursor: pointer;
    &:hover {
      background-color: #445877;
      svg {
        fill: #74839b;
      }
    }
  }
  .switch {
    input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      &:focus {
        outline: 0;
      }
    }

    .toggle {
      height: 23px;
      width: 50px;
      border-radius: 30px;
      display: inline-block;
      position: relative;
      margin: 0;
      border: 2px solid #474755;
      background: linear-gradient(180deg, #2d2f39 0%, #1f2027 100%);
      transition: all 0.2s ease;
      &:after {
        content: '';
        position: absolute;
        top: -4px;
        left: -2px;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background: #5c6e8b;
        box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
        transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
      }
      &:checked {
        border-color: #4f8bec;
        &:after {
          transform: translatex(20px);
          left: 2px;
          background: #4982df;
        }
      }
    }
  }
  .submit {
    button {
      padding: 10px 35px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }
  }
}
.logo-coin {
  width: 40px !important;
}
</style>
