<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <!-- title and filter -->
          <div class="card-header">
            <div class="card-title d-flex flex-column flex-sm-row justify-content-between">
              <div class="d-flex align-items-center">
                <h6>لیست شبکه های دیجیتال</h6>
                <base-button v-if="false" type="info" class="mx-3" size="sm" @click="addCoin()">افزودن</base-button>
              </div>
              <h6 class="filter-title" @click="filter = !filter" v-if="false">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر شبکه ها
              </h6>
            </div>
            <!-- filter content -->
            <card v-if="filter" class="filter">
              <form @submit.prevent="loadItems">
                <div class="row">
                  <!-- name farsi -->
                  <base-input v-if="false" class="col-12 col-lg-4" label="نام" type="text" placeholder="نام" v-model="nameFilter"> </base-input>

                  <!-- symbol -->
                  <base-input class="col-12 col-lg-4" label="symbol" type="text" placeholder="symbol" v-model="symbolFilter"> </base-input>

                  <!-- type -->
                  <div class="col-12 col-lg-4" v-if="false">
                    <span class="cart-lable-color">نوع</span>
                    <div class="mt-1">
                      <multiselect
                        :showLabels="false"
                        v-model="typeFilter"
                        :options="typeFilters"
                        placeholder="نوع کویین"
                        track-by="code"
                        label="lable"
                        :searchable="false"
                        :allow-empty="false"
                      ></multiselect>
                    </div>
                  </div>

                  <div class="col-12 col-lg-3">
                    <base-button class="btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                    <base-button class="btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                  </div>
                </div>
              </form>
            </card>
          </div>
          <!-- the page content  -->
          <div>
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="columns"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- type field  -->
                <span v-if="props.column.field == 'logoUrl'">
                  <img :src="props.row.logoUrl" alt="copy" class="logo-coin" />
                </span>

                <!-- type field  -->
                <span v-else-if="props.column.field == 'type'" v-html="getTypeLabel(props.row.type)"> </span>

                <!-- type field  -->
                <span v-else-if="props.column.field == 'edit'">
                  <base-button class="" type="primary" size="sm" @click="edit(props.row.idNetworkProtocol)">ویرایش</base-button>
                </span>

                <!-- status field  -->
                <span v-else-if="props.column.field == 'depositWithdrawDisabled'" v-html="getStatusLabel(props.row.depositWithdrawDisabled)"> </span>

                <!-- network field  -->
                <span v-else-if="props.column.field == 'explorer'" dir="ltr" v-html="showExplorer(props.row.explorer)"> </span>

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from 'vue-good-table'

// Multiselect
import Multiselect from 'vue-multiselect'

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

/**import mixin */
import Helper from '../../../settings/Helper'

export default {
  name: 'Addresses',
  mixins: [Helper],
  data() {
    return {
      selectedNetwork: null,
      address: null,
      selectedCoin: null,
      coins: [],
      columns: [
        {
          label: 'بلاکچین',
          field: 'networkBlockChain',
          sortable: false
        },
        {
          label: 'پروتکل',
          field: 'networkProtocol',
          sortable: false
        },
        {
          label: 'حداقل تعداد تایید',
          field: 'minCountOfConfirmation',
          sortable: false
        },
        {
          label: ' حداقل تاخیر برای چک تراکنش بر حسب ثانیه',
          field: 'minDelayToCheckTxBySec',
          sortable: false
        },
        {
          label: 'آی دی زنجیره',
          field: 'chainId',
          sortable: false
        },
        {
          label: 'غیرفعال سازی مجوز ورود/خروج ارز',
          field: 'depositWithdrawDisabled',
          sortable: false
        },
        {
          label: 'ویرایش',
          field: 'edit',
          sortable: false
        }
      ],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: window.env.CountItemInPerPage,
        search: null
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: window.env.PerPageDropdown,
        dropdownAllowAll: false
      },

      // add address
      newNetworkAdd: null,
      newNetworkAdds: [
        { code: 'usdt', lable: 'Usd' },
        { code: 'btc', lable: 'Bitcoin' },
        { code: 'ltc', lable: 'Litecoin' },
        { code: 'dash', lable: 'Dash' },
        { code: 'doge', lable: 'DogeCoin' }
      ],
      newAddress: null,

      // filter==true => show filter card
      filter: false,
      nameFilter: null,
      symbolFilter: null,
      typeFilter: { code: '21', lable: 'Crypto ' },
      typeFilters: [
        { code: '11', lable: 'NonCrypto ' },
        { code: '21', lable: 'Crypto ' }
      ]
    }
  },
  methods: {
    /**
         * @param object 
         */
    showExplorer(explore) {
      let net = null;
      if (explore) {
        net += explore['title'] + ' - ' + explore['addressFormat'] + ' - ' + explore['txFormat']
      }
      return net
    },
    /**
           * @param status
           * @return string the translated of status
           */
    getStatusLabel(status) {
      return status ? '<span class="badge badge-success">فعال</span>' : '<span class="badge badge-danger"> غیر فعال</span>'
    },

    /**reset search and search filters */
    resetSearch() {
      this.symbolFilter = null

      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null
      this.loadItems()
    },

    /**
     * @param status
     * @return string the translated of status
     */
    getTypeLabel(status) {
      switch (status) {
        case 'c':
          return '<span class="badge badge-success">کویین</span>'
        case 't':
          return '<span class="badge badge-info">توکن</span>'
        default:
          return status
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    /** show modal add address */

    /** ----------- Start modal add address ------------- */
    /**show inquiry modal */
    async addCoin() {
      this.$router.push({ path: 'coin/add-coin', replace: true })
    },

    // edit network\
    async edit(id) {
      this.$router.push({ path: `network/${id}/edit`, replace: true })
    },
    /**resetNetwork */
    resetNetwork() {
      this.selectedNetwork = null
    },
    /** ----------- End modal add address ------------- */

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage
        let offset = this.serverParams.perPage * (this.serverParams.page - 1)
        this.isLoading = true

        /**handle sorts */
        let sort = ''
        if (this.serverParams.sort[0].field) {
          /**check sort asc or desc */
          let sortType = this.serverParams.sort[0].type == 'asc' ? '' : '-'
          sort = this.serverParams.sort[0].field ? '&ordering=' + sortType + this.serverParams.sort[0].field : ''
        }

        let res = await this.$axios({
          method: 'get',
          url: `Organizer/Network/ListGet?PageNumber=${this.serverParams.page}&PageSize=${perPage}`,
          headers: {
            // idCurrencyType: this.typeFilter.code,
            searchStatement: this.symbolFilter ? this.symbolFilter : {}
          }
        })
        this.totalRecords = res.data.totalRecords
        this.rows = res.data.data
        this.isLoading = false

      } catch (error) {
        this.totalRecords = 0
        this.rows = null
        this.isLoading = false

      }


    }
  },
  async mounted() {
    this.$rtl.enableRTL()

    /**get orders */
    try {
      this.isLoading = true
      let res = await this.$axios({
        method: 'get',
        url: `Organizer/Network/ListGet?PageNumber=1&PageSize=30`,
        headers: {
          // idCurrencyType: 21,
          searchStatement: {
          }
        }
      })
      this.totalRecords = res.data.totalRecords
      this.rows = res.data.data
      this.isLoading = false
    } catch (error) {
      console.error(error)
    }
  },
  components: {
    VueGoodTable,
    datePicker: VuePersianDatetimePicker,
    Multiselect
  }
}
</script>

<style lang="scss" scoped>
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';

.multiselect__content-wrapper {
  .multiselect__content {
    padding: 0 0 70px 0;
  }
}
.logo-coin {
  width: 27px !important;
}
</style>
