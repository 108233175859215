<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="card-title d-flex flex-column flex-sm-row justify-content-between">
              <div class="d-flex align-items-center">
                <h6>لیست آدرس ها</h6>
                <base-button type="info" class="mx-3" size="sm" @click="showModal()">افزودن</base-button>
              </div>
              <h6 class="filter-title" @click="filter = !filter">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر آدرس ها
              </h6>
            </div>
            <!-- filter content -->
            <card v-if="filter" class="filter">
              <form @submit.prevent="search">
                <!-- networkFilter -->
                <div class="row">
                  <base-input class="col-12 col-md-6" label="شبکه" type="text" placeholder="شبکه" v-model="networkFilter"> </base-input>

                  <!-- addressFilter -->
                  <base-input label="آدرس" type="text" placeholder="آدرس" class="col-12 col-md-6" v-model="addressFilter"> </base-input>

                  <!-- dateFilter -->
                  <div class="col-md-3 date-holder" v-if="false">
                    <base-input class="dateFilter" label="تاریخ آخرین استفاده" type="text" placeholder="تاریخ آخرین استفاده" v-model="dateFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraserDateFilter">
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="dateFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".dateFilter" view="year" auto-submit></date-picker>
                  </div>

                  <!-- btn submit and reset-->
                  <div class="col-12">
                    <!-- btn  submit-->
                    <base-button class="mt-3 btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                    <!-- btn  reset-->
                    <base-button class="mt-3 mx-0 mx-sm-3 btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                  </div>
                </div>
              </form>
            </card>
          </div>
          <!-- the page content  -->
          <div class="card-body">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="columns"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- type field  -->
                <span v-if="props.column.field == 'address'">
                  {{ props.row.address }}
                  <img :src="require('../../../assets/img/copy.svg')" alt="copy" class="copy" @click="copyCode(props.row.address)" />
                </span>

                <!-- type field  -->
                <span v-else-if="props.column.field == 'last_used'">
                  {{ jdate(props.row.last_used) }}
                </span>

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>

    <!-- modal Add Address -->
    <modal name="addAddress">
      <div class="title text-center">
        <p>افزودن آدرس جدید</p>
      </div>
      <div class="user-name">
        <form @submit.prevent="submitAddAddress">
          <span>انتخاب کوین</span>
          <multiselect class="mt-2" @input="resetNetwork" v-model="selectedCoin" :maxHeight="170" :showLabels="false" :options="coins" placeholder="انتخاب ارز" track-by="symbol" label="name_fa" :searchable="false" :allow-empty="false"></multiselect>

          <div class="mt-4" v-if="selectedCoin">
            <span class="mt-4">انتخاب شبکه</span>
            <multiselect v-model="selectedNetwork" class="mt-2" :showLabels="false" :options="selectedCoin.networks" placeholder="شبکه" label="name" :searchable="false" :allow-empty="false"></multiselect>
          </div>
          <base-input v-model="address" class="w-100 my-4" label="آدرس" type="text" placeholder="آدرس"> </base-input>
        </form>
      </div>

      <div class="d-flex align-items-center justify-content-around">
        <base-button type="success" @click="submitAddAddress">تایید اطلاعات</base-button>
        <base-button type="danger" @click="hideModal()"> انصراف</base-button>
      </div>
      <!-- confirmation -->
    </modal>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from 'vue-good-table'

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

/**import mixin */
import Helper from '../../../settings/Helper'

// Multiselect
import Multiselect from 'vue-multiselect'

export default {
  name: 'Addresses',
  mixins: [Helper],
  data() {
    return {
      selectedNetwork: null,
      address: null,
      selectedCoin: null,
      coins: [],
      columns: [
        {
          label: 'شبکه',
          field: 'network',
          sortable: true
        },
        {
          label: 'آدرس',
          field: 'address',
          sortable: true
        }
      ],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: 10,
        search: null
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: [10, 20, 30, 40, 50],
        dropdownAllowAll: false
      },

      // add address
      newNetworkAdd: null,
      newNetworkAdds: [
        { code: 'usdt', lable: 'Usd' },
        { code: 'btc', lable: 'Bitcoin' },
        { code: 'ltc', lable: 'Litecoin' },
        { code: 'dash', lable: 'Dash' },
        { code: 'doge', lable: 'DogeCoin' }
      ],
      newAddress: null,

      // filter==true => show filter card
      filter: true,
      networkFilter: null,
      addressFilter: null,
      dateFilter: null
    }
  },
  methods: {
    /**handle search filters */
    search() {
      let networkFilter, addressFilter, dateFilter
      networkFilter = addressFilter = dateFilter = ''

      /** networkFilter  */
      if (this.networkFilter) {
        networkFilter = '&network=' + this.networkFilter
      }

      /** addressFilter  */
      if (this.addressFilter) {
        addressFilter = '&address=' + this.addressFilter
      }

      /** dateFilter  */
      if (this.dateFilter) {
        dateFilter = '&last_used=' + this.dateFilter
      }

      this.serverParams.search = networkFilter + addressFilter + dateFilter

      this.loadItems()
    },

    /**reset search and search filters */
    resetSearch() {
      this.networkFilter = this.addressFilter = this.dateFilter = null

      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null
      this.loadItems()
    },

    /** eraser input Date Filter */
    eraserDateFilter() {
      this.dateFilter = null
    },

    async copyCode(s) {
      await this.copyToClipboard(s)
      this.$notify({
        text: 'کپی انجام شد',
        type: 'success',
        group: 'copynotif'
      })
    },

    /**handle unssl server */
    async copyToClipboard(textToCopy) {
      // navigator clipboard api needs a secure context (https)
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return await navigator.clipboard.writeText(textToCopy)
      } else {
        // text area method
        let textArea = document.createElement('textarea')
        textArea.value = textToCopy
        // make the textarea out of viewport
        textArea.style.position = 'fixed'
        textArea.style.left = '-999999px'
        textArea.style.top = '-999999px'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand('copy') ? res() : rej()
          textArea.remove()
        })
      }
    },

  
    /**
     * @param status
     * @return string the translated of status
     */
    getStatusLabel(status) {
      switch (status) {
        case 'PENDING':
          return '<span class="badge badge-info">معلق</span>'
        case 'OPEN':
          return '<span class="badge badge-primary">باز</span>'
        case 'SUCCESS':
          return '<span class="badge badge-success">موفق</span>'
        default:
          return status
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    /** show modal add address */

    /** ----------- Start modal add address ------------- */
    /**show inquiry modal */
    async showModal() {
      this.$modal.show('addAddress')
    },

    /**show inquiry modal */
    hideModal() {
      this.newNetworkAdd = this.newAddress = null
      this.$modal.hide('addAddress')
    },

    // submit add
    async submitAddAddress() {
      try {
        if (!this.selectedCoin) return this.$notify({ text: 'انتخاب کوین الزامی می باشد', type: 'error' })
        
        if (!this.selectedNetwork) return this.$notify({ text: 'انتخاب شبکه الزامی می باشد', type: 'error' })

        if (!this.address) return this.$notify({ text: 'فیلد آدرس الزامی می باشد', type: 'error' })

        this.isLoading = true
        let data = {
          coin : this.selectedCoin.id,
          address: this.address,
          network: this.selectedNetwork.name
        }
        await this.$axios({
          method: 'post',
          url: 'address/',
          data: data
        })

        this.$notify({
          text: 'آدرس جدید با موفقیت ثبت شد',
          type: 'success'
        })

        this.isLoading = false

        this.loadItems()
        this.hideModal()
      } catch (error) {
        console.error(error)
      }
    },

    /**resetNetwork */
    resetNetwork() {
      this.selectedNetwork = null
    },
    /** ----------- End modal add address ------------- */

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage
        let offset = this.serverParams.perPage * (this.serverParams.page - 1)
        this.isLoading = true

        /**handle sorts */
        let sort = ''
        if (this.serverParams.sort[0].field) {
          /**check sort asc or desc */
          let sortType = this.serverParams.sort[0].type == 'asc' ? '' : '-'
          sort = this.serverParams.sort[0].field ? '&ordering=' + sortType + this.serverParams.sort[0].field : ''
        }

        let search = ''
        if (this.serverParams.search) {
          search = this.serverParams.search
        }

        let res = await this.$axios({
          method: 'get',
          url: 'address/?limit=' + perPage + '&offset=' + offset + sort + search
        })
        this.totalRecords = res.data.count
        this.rows = res.data.results
        this.isLoading = false
      } catch (error) {
        console.error(error)
      }
    }
  },
  async mounted() {
    this.$rtl.enableRTL()

    /**get orders */
    try {
      this.isLoading = true
      let res = await this.$axios({
        method: 'get',
        url: 'address/?limit=10&offset=0'
      })
      this.totalRecords = res.data.count
      this.rows = res.data.results
      this.isLoading = false
    } catch (error) {
      console.error(error)
    }

    /**get coins */
    try {
      let res = await this.$axios({
        method: 'get',
        url: 'coins'
      })
      this.coins = res.data.results
    } catch (error) {
      console.error(error)
    }
  },
  components: {
    VueGoodTable,
    datePicker: VuePersianDatetimePicker,
    Multiselect
  }
}
</script>

<style lang="scss">
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';
.multiselect__content-wrapper {
  .multiselect__content {
    padding: 0 0 70px 0;
  }
}
</style>
