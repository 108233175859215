<template>
  <StatisticalReportsComponent></StatisticalReportsComponent>
</template>

<script>
/**components */
import StatisticalReportsComponent from "../components/StatisticalReportsComponent";
export default {
  name: 'StatisticalReports',
  components: {
    StatisticalReportsComponent
  }
}
</script>
