<template>
  <UserListComponent :typeFilter=" { code: 'ListVerified', lable: 'کاربران تایید شده ' }"></UserListComponent>
</template>

<script>
/**compoenents */
import UserListComponent from "./UserListComponent.vue"

export default {
  name: 'UsersVerified',  
  components: {
    UserListComponent
  }
}
</script>