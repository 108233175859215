<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="card-title">
              <h6>مدارک کاربر</h6>
            </div>
          </div>

          <!-- the page content  -->
          <div class="card-body">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="3"
              :rows="dataFromStore"
              :columns="columns"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- value field  -->
                <div class="v-viewer" v-if="props.column.field == 'document_file'">
                  <viewer :options="options" v-if="props.row.document_file">
                    <img :key="props.row.document_file" :src="props.row.document_file" />
                  </viewer>
                  <span v-else>هنوز بارگذاری نشده است</span>
                </div>
                <!-- created field  -->
                <span v-else-if="props.column.field == 'created'">
                  {{ jdate(props.row.created) }}
                </span>

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from 'vue-good-table'

/**import mixin */
import Helper from '../../../settings/Helper'

/** v-viewer */
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
Vue.use(VueViewer)

export default {
  name: 'UserDocuments',
  mixins: [Helper],
  data() {
    return {
      columns: [
        {
          label: 'نوع مدرک',
          field: 'type',
          sortable: false
        },
        {
          label: 'مدرک',
          field: 'document_file',
          sortable: false
        }
      ],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: window.env.CountItemInPerPage,
      },
      paginationOptions: {
        enabled: false,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: window.env.PerPageDropdown,
        dropdownAllowAll: false
      },

      // v-viewer
      options: {
        toolbar: {
          zoomIn: 4,
          zoomOut: 4,
          oneToOne: 4,
          reset: 4,
          prev: false,
          play: {
            show: 4,
            size: 'large'
          },
          next: false,
          rotateLeft: 4,
          rotateRight: 4,
          flipHorizontal: 4,
          flipVertical: 4
        }
      }
    }
  },
  methods: {
    /** detetc image and movie file
     * if image -> show image
     * if movie -> show tag a
     */
    detectFile(file) {
      let position = file.search('mp4')
      return position > 0 ? false : true
    },

    /**
     * @param status
     * @return string the translated of status
     */
    getStatusLabel(status) {
      switch (status) {
        case 'PENDING':
          return '<span class="badge badge-info">معلق</span>'
        case 'OPEN':
          return '<span class="badge badge-primary">باز</span>'
        case 'SUCCESS':
          return '<span class="badge badge-success">موفق</span>'
        default:
          return status
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage
        let offset = this.serverParams.perPage * (this.serverParams.page - 1)
        this.isLoading = true
        let res = await this.$axios({
          method: 'get',
          url: 'profile/documents/' + this.$router.history.current.params.id + '?limit=' + perPage + '&offset=' + offset
        })
        this.totalRecords = res.data.result.count
        this.rows = res.data.result
      } catch (error) {
        this.rows = this.totalRecords = null
        console.error(error)
      }
      this.isLoading = false
    }
  },
  computed: {
    /** get avatar and  picCodeMeli and picAcceptance from store*/
    dataFromStore: {
      get() {
        let data = [{
          document_file: this.$store.state.at.picCodeMeli ? this.$store.state.at.picCodeMeli : null,
          type: "کارت ملی",
        },
        {
          document_file: this.$store.state.at.picAcceptance ? this.$store.state.at.picAcceptance : null,
          type: "تعهد نامه",
        },
        {
          document_file: this.$store.state.at.avatar ? this.$store.state.at.avatar : null,
          type: 'تصویر پروفایل',
        }]
        return data
      },
      set() {
        return
      }
    }
  },
  async mounted() {
    this.$rtl.enableRTL()

    /**get orders */
    try {
      this.$rtl.enableRTL()

    } catch (error) {
      console.error(error)
    }
  },
  components: {
    VueGoodTable,
    VueViewer
  }
}
</script>

<style lang="scss">
@import '~vue-good-table/dist/vue-good-table.css';
.v-viewer {
  width: 100px;
  margin: auto;
  img {
    cursor: pointer;
    &:hover {
      padding: 4px;
    }
  }
}
</style>
