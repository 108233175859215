<template>
  <section class="personal-info">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-12 col-xl-6">
          <!-- action -->
          <card>
            <div class="card-header">
              <h6>عملیات قابل اجرا بر روی پروفایل کاربر</h6>
            </div>
            <!-- the page content  -->
            <div class="d-flex align-items-center flex-wrap" v-if="!actionOnProfileLoading">
              <button class="btn-danger" @click="actionOnProfile('logout')">Log Out</button>
              <button class="btn-primary mx-2" @click="actionOnProfile('disable')">غیر فعال کردن</button>
              <button class="btn-success" @click="actionOnProfile('enable')">فعال کردن</button>
            </div>
            <div v-else class="submit d-flex justify-content-center align-items-center pt-3">
              <div class="loader-wait-request"></div>
            </div>
          </card>
          <!-- info -->
          <card>
            <div class="card-header">
              <div class="change" @click="disabled = !disabled">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.983" height="22.143" viewBox="0 0 47.983 49.143">
                  <path
                    id="pen-alt-1"
                    d="M36.631,31.423c-10.3,9.219-23.147,19.309-24.892,17.51s8.094-14.955,17.092-25.5ZM58.525,16.291l-3.651-3.735c1.6-2.423,2.084-4.153,1.922-5.489l2.723-2.791L55.345,0,52.563,2.845c-2.315-.221-5.789,1.582-12.065,8.01-2.315,2.374-5.185,5.553-8.153,9.023l7.824,8.02c3.4-3.042,6.5-5.981,8.812-8.355,1.656-1.7,2.988-3.189,4.064-4.521l2.3,2.349-7.416,7.6a1.555,1.555,0,0,0,0,2.172,1.472,1.472,0,0,0,2.118,0l8.482-8.684a1.564,1.564,0,0,0,0-2.172Z"
                    transform="translate(-11.535)"
                  />
                </svg>
              </div>
            </div>
            <!-- the page content  -->
            <div>
              <!-- row one (user id and birthday) -->
              <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-3">
                <!-- user id -->
                <div class="user-id w-100 w-lg-50 d-flex flex-column justify-content-between">
                  <span>شناسه کاربر</span>
                  <p>{{ userID }}</p>
                </div>
              </div>

              <!-- row three (first last name ) -->
              <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-3">
                <!-- first name -->
                <div class="date-created w-100 w-lg-50">
                  <span>نام </span>
                  <input type="text" v-model="firstName" :disabled="disabled" :class="{ flexible: !disabled }" />
                </div>
                <!-- last name -->
                <div class="date-modify w-100 w-lg-50 mx-2">
                  <span> نام خانوادگی</span>
                  <input type="text" v-model="lastName" :disabled="disabled" :class="{ flexible: !disabled }" />
                </div>
              </div>
              <div dir="ltr" class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-3">
                <!-- mobile -->
                <div class="national-code w-100 w-lg-50 mx-2">
                  <span>شماره همراه </span>
                  <input type="text" v-model="phoneNumber" :disabled="true" />
                </div>
                <!-- email -->
                <div class="date-modify w-100 w-lg-50 mx-2">
                  <span> ایمیل </span>
                  <input type="text" v-model="email" :disabled="true" />
                </div>
              </div>
              <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-3">
                <!-- national code -->
                <div class="national-code w-100 w-lg-50 mx-2">
                  <span>کد ملی</span>
                  <input dir="ltr" type="text" v-model="codeMeli" :disabled="disabled" />
                </div>
                <!-- nationality -->
                <div class="date-modify w-100 w-lg-50 mx-2" v-if="false">
                  <span> ملیت </span>
                  <input type="text" v-model="nationality" :disabled="disabled" />
                </div>
                <!-- birthday -->
                <div class="date-modify w-100 w-lg-50 mx-2">
                  <span> تاریخ تولد </span>
                  <input type="text" class="birthday" v-model="birthday" :disabled="disabled" :class="{ flexible: !disabled }" />
                  <date-picker simple custom-input=".birthday" v-model="birthday" :disabled="disabled" />
                </div>
              </div>
              <button class="btn-primary p-1 w-25 mx-1" v-if="!disabled" :disabled="isLoading" @click="submitUpdateProfile()">ذخیره</button>
            </div>
          </card>
        </div>

        <!-- checkbox status and wallet entity -->
        <div class="col-12 col-xl-6">
          <!-- notification -->
          <card v-if="false">
            <div class="card-header">
              <span>اعمال اعلان برای کاربر</span>
            </div>
            <!-- the page content  -->
            <div class="card-body">
              <div class="row">
                <div class="col-9">
                  <multiselect
                    :showLabels="false"
                    v-model="notification"
                    :options="optionsNotification"
                    placeholder="انتخاب اعلان"
                    track-by="id"
                    label="title"
                    :searchable="false"
                    :allow-empty="false"
                  ></multiselect>
                  <div v-if="notification">
                    <small v-if="notification.isDisabled" class="text-danger">این اعلان غیر فعال می باشد</small>
                  </div>
                </div>
                <div class="col-3 d-flex align-items-center">
                  <button class="btn-success p-1 mx-1" v-if="!submitNotificationLoading" @click="submitNotification()">اعمال</button>
                  <div v-else class="submit d-flex justify-content-center align-items-center">
                    <div class="loader-wait-request"></div>
                  </div>
                </div>
              </div>
            </div>
          </card>

          <!-- checkbox status -->
          <card>
            <div class="card-header">
              <div class="card-title">
                <h6>تایید مدارک</h6>
              </div>
            </div>
            <!-- the page content  -->
            <div>
              <!-- CodeMeli Confirmation -->
              <div>
                <div class="document">
                  <div class="d-flex align-items-center justify-content-between">
                    <p>تصویر کارت ملی</p>
                    <div>
                      <svg v-if="stateCodeMeli == '1'" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 54.785 49.745">
                        <path
                          id="checked"
                          d="M102.717,144.95H70.8a.783.783,0,0,1-.783-.783v-31.92a.783.783,0,0,1,.783-.783h24.57a2.178,2.178,0,0,0,1.334-.551l6.405-6.405c.307-.307.2-.551-.232-.551H63.283a.783.783,0,0,0-.783.783v46.948a.783.783,0,0,0,.783.783H110.23a.783.783,0,0,0,.783-.783v-29.5c0-.432-.25-.532-.551-.232l-6.412,6.412a2.157,2.157,0,0,0-.551,1.334v14.47A.783.783,0,0,1,102.717,144.95ZM77.7,122.121l-4.208,4.208a.782.782,0,0,0,0,1.108l11.646,11.646a.782.782,0,0,0,1.108,0l30.812-30.812a.782.782,0,0,0,0-1.108l-4.208-4.208a.782.782,0,0,0-1.108,0l-25.5,25.5a.782.782,0,0,1-1.108,0l-6.33-6.337A.782.782,0,0,0,77.7,122.121Z"
                          transform="translate(-62.5 -102.725)"
                          fill="#116405"
                        />
                      </svg>
                      <svg v-else-if="stateCodeMeli == '0'" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 49.751 49.745">
                        <path
                          id="close-squared-alt"
                          d="M101.92,77.249a.892.892,0,0,0,0-1.467l-2.866-2.94a1.033,1.033,0,0,0-.773-.307,1.142,1.142,0,0,0-.483.136,1.7,1.7,0,0,0-.449.33l-8.671,8.586a1.85,1.85,0,0,1-2.615,0l-8.824-8.756a1.033,1.033,0,0,0-.773-.307,1.07,1.07,0,0,0-.415.108,1.767,1.767,0,0,0-.534.381l-2.684,2.763a.971.971,0,0,0,0,1.547L81.7,86.062a1.858,1.858,0,0,1,.006,2.644l-8.87,8.8a1.033,1.033,0,0,0-.307.773,1.045,1.045,0,0,0,.307.773l2.94,2.866a1.033,1.033,0,0,0,.773.307.961.961,0,0,0,.694-.307l8.819-8.813a1.853,1.853,0,0,1,2.627,0l8.819,8.819a1.033,1.033,0,0,0,.773.307.951.951,0,0,0,.694-.307l2.94-2.866a1.033,1.033,0,0,0,.307-.773,1.045,1.045,0,0,0-.307-.773l-8.881-8.887a1.857,1.857,0,0,1,.017-2.638l8.876-8.739Zm8.512-12.929a6.014,6.014,0,0,0-4.4-1.819H68.72a6.212,6.212,0,0,0-6.22,6.215v37.31a6.212,6.212,0,0,0,6.22,6.22h37.31a6.212,6.212,0,0,0,6.22-6.22V68.72A6.014,6.014,0,0,0,110.431,64.319ZM109.14,106.03a3.166,3.166,0,0,1-3.11,3.11H68.72a2.968,2.968,0,0,1-2.183-.927,3,3,0,0,1-.927-2.183V68.72a2.968,2.968,0,0,1,.927-2.183,3,3,0,0,1,2.183-.927h37.31a2.968,2.968,0,0,1,2.183.927,3,3,0,0,1,.927,2.183Z"
                          transform="translate(-62.5 -62.5)"
                          fill="#700505"
                        />
                      </svg>
                    </div>
                  </div>

                  <div class="d-flex align-items-end justify-content-around mb-4">
                    <viewer :options="options" class="viewer" v-if="picCodeMeli">
                      <img class="btn-document" :src="picCodeMeli" alt="" />
                    </viewer>
                    <img class="btn-document" title="فایل آپلود نشده است" src="../../../assets/img/database-error.png" alt="" v-else />

                    <div class="w-75">
                      <span class="cart-lable-color">دلیل رد کارت ملی</span>
                      <div class="mt-1">
                        <multiselect
                          :showLabels="false"
                          v-model="rejectReasonCodeMeli"
                          :options="rejectReasons"
                          placeholder="انتخاب دلیل رد کارت ملی"
                          track-by="id"
                          label="title"
                          :allow-empty="false"
                        ></multiselect>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="!loadingCodeMeli">
                  <button
                    class="btn-success w-25 mx-1"
                    @click="
                      stateCodeMeli = '1'
                      submitStateCodeMeli()
                    "
                  >
                    تائید
                  </button>
                  <button
                    class="btn-danger w-25 mx-1"
                    @click="
                      stateCodeMeli = '0'
                      submitStateCodeMeli()
                    "
                  >
                    رد
                  </button>
                </div>
                <!-- loader -->
                <div v-else class="submit d-flex justify-content-center align-items-center">
                  <div class="loader-wait-request"></div>
                </div>
              </div>

              <!-- Acceptance Confirmation -->
              <div class="mt-5">
                <div class="document">
                  <div class="d-flex align-items-center justify-content-between mt-4">
                    <p>تصویر تعهد نامه</p>
                    <svg v-if="stateAcceptance == '1'" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 54.785 49.745">
                      <path
                        id="checked"
                        d="M102.717,144.95H70.8a.783.783,0,0,1-.783-.783v-31.92a.783.783,0,0,1,.783-.783h24.57a2.178,2.178,0,0,0,1.334-.551l6.405-6.405c.307-.307.2-.551-.232-.551H63.283a.783.783,0,0,0-.783.783v46.948a.783.783,0,0,0,.783.783H110.23a.783.783,0,0,0,.783-.783v-29.5c0-.432-.25-.532-.551-.232l-6.412,6.412a2.157,2.157,0,0,0-.551,1.334v14.47A.783.783,0,0,1,102.717,144.95ZM77.7,122.121l-4.208,4.208a.782.782,0,0,0,0,1.108l11.646,11.646a.782.782,0,0,0,1.108,0l30.812-30.812a.782.782,0,0,0,0-1.108l-4.208-4.208a.782.782,0,0,0-1.108,0l-25.5,25.5a.782.782,0,0,1-1.108,0l-6.33-6.337A.782.782,0,0,0,77.7,122.121Z"
                        transform="translate(-62.5 -102.725)"
                        fill="#116405"
                      />
                    </svg>
                    <svg v-else-if="stateAcceptance == '0'" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 49.751 49.745">
                      <path
                        id="close-squared-alt"
                        d="M101.92,77.249a.892.892,0,0,0,0-1.467l-2.866-2.94a1.033,1.033,0,0,0-.773-.307,1.142,1.142,0,0,0-.483.136,1.7,1.7,0,0,0-.449.33l-8.671,8.586a1.85,1.85,0,0,1-2.615,0l-8.824-8.756a1.033,1.033,0,0,0-.773-.307,1.07,1.07,0,0,0-.415.108,1.767,1.767,0,0,0-.534.381l-2.684,2.763a.971.971,0,0,0,0,1.547L81.7,86.062a1.858,1.858,0,0,1,.006,2.644l-8.87,8.8a1.033,1.033,0,0,0-.307.773,1.045,1.045,0,0,0,.307.773l2.94,2.866a1.033,1.033,0,0,0,.773.307.961.961,0,0,0,.694-.307l8.819-8.813a1.853,1.853,0,0,1,2.627,0l8.819,8.819a1.033,1.033,0,0,0,.773.307.951.951,0,0,0,.694-.307l2.94-2.866a1.033,1.033,0,0,0,.307-.773,1.045,1.045,0,0,0-.307-.773l-8.881-8.887a1.857,1.857,0,0,1,.017-2.638l8.876-8.739Zm8.512-12.929a6.014,6.014,0,0,0-4.4-1.819H68.72a6.212,6.212,0,0,0-6.22,6.215v37.31a6.212,6.212,0,0,0,6.22,6.22h37.31a6.212,6.212,0,0,0,6.22-6.22V68.72A6.014,6.014,0,0,0,110.431,64.319ZM109.14,106.03a3.166,3.166,0,0,1-3.11,3.11H68.72a2.968,2.968,0,0,1-2.183-.927,3,3,0,0,1-.927-2.183V68.72a2.968,2.968,0,0,1,.927-2.183,3,3,0,0,1,2.183-.927h37.31a2.968,2.968,0,0,1,2.183.927,3,3,0,0,1,.927,2.183Z"
                        transform="translate(-62.5 -62.5)"
                        fill="#700505"
                      />
                    </svg>
                  </div>
                  <div class="d-flex align-items-end justify-content-around mb-4">
                    <viewer :options="options" class="viewer" v-if="picAcceptance">
                      <img class="btn-document" :src="picAcceptance" alt="" />
                    </viewer>
                    <img class="btn-document" title="فایل آپلود نشده است" src="../../../assets/img/database-error.png" alt="" v-else />
                    <div class="w-75">
                      <span class="cart-lable-color">دلیل رد تعهد نامه</span>
                      <div class="mt-1">
                        <multiselect
                          :showLabels="false"
                          v-model="rejectReasonAcceptance"
                          :options="rejectReasons"
                          placeholder="انتخاب دلیل رد تعهد نامه"
                          track-by="id"
                          label="title"
                          :allow-empty="false"
                        ></multiselect>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="!loadingAcceptance">
                  <button
                    class="btn-success w-25 mx-1"
                    @click="
                      stateAcceptance = '1'
                      submitStateAcceptance()
                    "
                  >
                    تائید
                  </button>
                  <button
                    class="btn-danger w-25 mx-1"
                    @click="
                      stateAcceptance = '0'
                      submitStateAcceptance()
                    "
                  >
                    رد
                  </button>
                </div>
                <!-- loader -->
                <div v-else class="submit d-flex justify-content-center align-items-center">
                  <div class="loader-wait-request"></div>
                </div>
              </div>

              <div class="col-12 mt-4" v-if="false">
                <div class="submit">
                  <button class="btn-primary" @click="submit">ذخیره</button>
                </div>
              </div>
            </div>
          </card>

          <!-- wallet entity -->
          <card v-if="false">
            <div class="card-header">
              <div class="card-title">
                <h6>موجودی کیف پول</h6>
              </div>
            </div>
            <div class="card-body">
              <p>{{ newNumberFormat(holdingWallet, 'toman') }}</p>
            </div>
          </card>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue"

/**import mixin */
import Helper from '../../../settings/Helper'

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

/**import moment-jalali */
var moment = require('moment-jalaali')

// Multiselect
import Multiselect from 'vue-multiselect'

/** v-viewer */
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
Vue.use(VueViewer)

export default {
  name: 'UserPersonalInfo',
  props: ['id'],
  mixins: [Helper],
  data() {
    return {
      isLoading: false,

      actionOnProfileLoading: false,
      submitNotificationLoading: false,
      loadingAcceptance: false,
      loadingCodeMeli: false,


      authorizeConfirm: false,
      profileId: null,
      disabled: true,
      dateCreated: null,
      dateModify: null,


      dataRecive: null,

      // confirnation level one datas
      profileConfirmation: false,
      documentConfirmation: false,

      // holding Wallet
      holdingWallet: 0,

      // v-viewer
      options: {
        toolbar: {
          zoomIn: 4,
          zoomOut: 4,
          oneToOne: 4,
          reset: 4,
          prev: false,
          play: {
            show: 4,
            size: 'large'
          },
          next: false,
          rotateLeft: 4,
          rotateRight: 4,
          flipHorizontal: 4,
          flipVertical: 4
        }
      },

      /** multi select Reason Reject */
      rejectReasonAcceptance: null,
      rejectReasonCodeMeli: null,
      rejectReasons: [],


      /** notification card */
      optionsNotification: [],
      notification: null

    }
  },
  methods: {
    /** logout - disable and enable request - default is logout
     * @param string type action
     */
    async actionOnProfile(type) {
      try {

        this.actionOnProfileLoading = true
        let url = "Organizer/User/Logout"
        if (type == 'disable')
          url = "Organizer/User/Disable"
        if (type == 'enable')
          url = "Organizer/User/Enable"


        await this.$axios({
          method: 'get',
          url: url,
          headers: {
            uIdCode: this.userID,
          }
        })

        this.$notify({ text: 'عملیات با موفقیت انجام شد', type: 'success' })

      } catch (error) {
        console.log(error)
      }

      this.actionOnProfileLoading = false


    },

    // get Reject Reasons
    async getRejectReasons() {
      try {
        let req = await this.$axios({
          url: "Organizer/RejectReason/ListGetToChoose",
          method: 'get'
        })


        this.rejectReasons = req.data.data
      } catch (error) {
        console.log(error)
      }
    },

    /**filter birthdate */
    checkDate(formatted) {
      return formatted > this.minAgeDate || formatted < this.maxAgeDate
    },

    /** change Acceptance status*/
    async submitStateAcceptance() {
      try {


        if (!this.rejectReasonAcceptance && this.stateAcceptance == '0')
          return this.$notify({ text: 'وارد نمودن دلیل رد تعهد نامه الزامی می باشد', type: 'warn' })


        if (this.stateAcceptance == '')
          return this.$notify({ text: 'انتخاب وضعیت تعهد نامه الزامی می باشد', type: 'warn' })


        if (this.stateAcceptance == '1')
          this.rejectReasonAcceptance = null

        let isVerified = false
        if (this.stateAcceptance == '1')
          isVerified = true

        this.loadingAcceptance = true
        await this.$axios({
          method: 'get',
          url: 'Organizer/User/VerifyRulesAcceptance',
          headers: {
            uIdCode: this.userID,
            isVerified: isVerified,
            idDocsRejectReason: this.rejectReasonAcceptance ? this.rejectReasonAcceptance.id : 0
          }
        })
        this.$notify({ text: 'عملیات با موفقیت انجام شد', type: 'success' })
      } catch (error) {
        console.log(error)
      }

      this.loadingAcceptance = false

    },

    /** change state code meli */
    async submitStateCodeMeli() {
      try {
        if (!this.rejectReasonCodeMeli && this.stateCodeMeli == '0')
          return this.$notify({ text: 'وارد نمودن دلیل رد کارت ملی الزامی می باشد', type: 'warn' })


        if (this.stateCodeMeli == '')
          return this.$notify({ text: 'انتخاب وضعیت کارت ملی الزامی می باشد', type: 'warn' })

        if (this.stateCodeMeli == '1')
          this.rejectReasonCodeMeli = null

        let isVerified = false
        if (this.stateCodeMeli == '1')
          isVerified = true

        this.loadingCodeMeli = true
        await this.$axios({
          method: 'get',
          url: 'Organizer/User/VerifyNationality',
          headers: {
            uIdCode: this.userID,
            isVerified: isVerified,
            idDocsRejectReason: this.rejectReasonCodeMeli ? this.rejectReasonCodeMeli.id : 0
          }
        })
        this.$notify({ text: 'عملیات با موفقیت انجام شد', type: 'success' })
      } catch (error) {
        console.log(error)

      }
      this.loadingCodeMeli = false

    },

    /**------------------Start get data multi select notification */
    async GetOptionsNotification() {
      try {
        let req = await this.$axios({
          url: 'Organizer/Notification/ListGet',
          data: [
            {
              "key": "string",
              "value": "string"
            }
          ]
        })

        this.optionsNotification = req.data.data
      } catch (error) {
        console.log(error)
      }
    },

    /** submit notification on user */
    async submitNotification() {
      try {
        if (!this.notification)
          return this.$notify({ text: 'انتخاب کردن یک اعلان الزامی می باشد', type: 'warn' })

        this.submitNotificationLoading = true
        await this.$axios({
          url: '/Organizer/Notification/UserSet',
          data: {
            id: this.notification.id,
            setConditions: [
              {
                key: 'userID',
                value: this.$router.history.current.params.id
              }
            ]
          }
        })

        this.$notify({ text: 'عملیات با موفقیت انجام شد', type: 'success' })

      } catch (error) {
        console.log(error)
      }
      this.submitNotificationLoading = false

    },

    /**save personal info */
    async submitUpdateProfile() {
      try {
        // validate
        if (this.isLoading) return
        if (!this.firstName) return this.$notify({ text: 'نام الزامی است', type: 'warn' })
        if (!this.lastName)
          return this.$notify({
            text: 'نام خانوادگی الزامی است',
            type: 'warn'
          })
        if (!this.birthday) return this.$notify({ text: 'تاریخ تولد الزامی است', type: 'warn' })

        this.isLoading = true
        let data = [
          {
            id: 1000110,
            title: "name",
            value: this.firstName
          },
          {
            id: 1000120,
            title: "family",
            value: this.lastName
          },
          {
            id: 1000160,
            title: "nid",
            value: this.codeMeli
          },
          {
            id: 1000170,
            title: "birthday",
            value: moment(this.birthday, 'jYYYY/jM/jD').format('YYYY/M/D')
          },
          {
            id: 1000150,
            title: "nationality",
            value: "100"
          }
        ]
        await this.$axios({

          url: 'Organizer/User/ProfileUpdate',
          data: data,
          headers: {
            uIdCode: this.userID
          }

        })


        /**proceed step 1 */
        this.$notify({
          text: 'عملیات با موفقیت انجام شد',
          type: 'success'
        })

        this.disabled = true
        this.$store.dispatch('at/initProfile', this.$router.history.current.params.id)
      } catch (error) {
        console.log(error);
      }

      this.isLoading = false
    },
  },
  async mounted() {
    this.$rtl.enableRTL()


    this.getRejectReasons()
    // this.GetOptionsNotification()
  },
  components: {
    datePicker: VuePersianDatetimePicker,
    Multiselect
  },
  computed: {
    minAgeDate: () => {
      return moment().subtract(18, 'year').format('jYYYY/jM/jD') // somthing like 1382/10/05
    },
    maxAgeDate: () => {
      return moment().subtract(90, 'year').format('jYYYY/jM/jD') // somthing like  1310/10/05
    },
    /** ----------------------- start get profile */
    userID: {
      get() {
        return this.$store.state.at.uuid
      },
      set() {

      }
    },
    firstName: {
      get() {
        return this.$store.state.at.firstName
      },
      set(value) {
        this.$store.commit('at/setFirstName', value)
      }
    },
    lastName: {
      get() {
        return this.$store.state.at.lastName
      },
      set(value) {
        this.$store.commit('at/setLastName', value)
      }
    },
    nationality: {
      get() {
        return this.$store.state.at.nid
      },
      set() {

      }
    },
    phoneNumber: {
      get() {
        return this.$store.state.at.mobile
      }
      ,
      set(value) {
        this.$store.commit('at/setLastName', value)
      }
    },
    birthday: {
      get() {
        return this.$store.state.at.birthDate
      },
      set(value) {
        let date = moment(value, 'jYYYY/jM/jD').format('YYYY/MM/DD')
        this.$store.commit('at/setBirthDate', date)
      }
    },
    codeMeli: {
      get() {
        return this.$store.state.at.codeMeli
      }
      ,
      set(value) {
        this.$store.commit('at/setCodeMeli', value)
      }
    },
    email: {
      get() {
        return this.$store.state.at.email
      },
      set() {

      }
    },
    picAcceptance: {
      get() {
        return this.$store.state.at.picAcceptance
      },
      set() {
      }
    },
    picCodeMeli: {
      get() {
        return this.$store.state.at.picCodeMeli
      },
      set() {
      }
    },
    avatar: {
      get() {
        return this.$store.state.at.avatar
      },
      set() {
      }
    },
    stateAcceptance: {
      get() {
        return this.$store.state.at.stateAcceptance
      },
      set(value) {
        this.$store.commit('at/setStateAcceptance', value);
      }
    },
    stateCodeMeli: {
      get() {
        return this.$store.state.at.stateCodeMeli
      },
      set(value) {
        this.$store.commit('at/setStateCodeMeli', value);
      }
    }
    /** ----------------------- end get profile */
  }
}

</script>

<style lang="scss" scoped>
.personal-info {
  input[type='text'] {
    background-color: transparent !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    color: #ffffeb;
    &:disabled {
      color: #9b9b83;
    }
  }
  .flexible {
    border-bottom: 2px solid #ffffff !important;
  }
  .change {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #74839b;
    margin: 0 auto 0 0;
    cursor: pointer;
    &:hover {
      background-color: #445877;
      svg {
        fill: #74839b;
      }
    }
  }
  .switch {
    input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      &:focus {
        outline: 0;
      }
    }

    .toggle {
      height: 23px;
      width: 50px;
      border-radius: 30px;
      display: inline-block;
      position: relative;
      margin: 0;
      border: 2px solid #474755;
      background: linear-gradient(180deg, #2d2f39 0%, #1f2027 100%);
      transition: all 0.2s ease;
      &:after {
        content: '';
        position: absolute;
        top: -4px;
        left: -2px;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background: #5c6e8b;
        box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
        transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
      }
      &:checked {
        border-color: #4f8bec;
        &:after {
          transform: translatex(20px);
          left: 2px;
          background: #4982df;
        }
      }
    }
  }
  .submit {
    button {
      padding: 10px 35px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }
  }
  .btn-document {
    width: 50px;
    border-radius: 50%;
    height: 50px;
    padding: 4px;
    /* border: 1px; */
    background: #4880db;
    border: none;
    cursor: pointer;
    &:hover {
      background: #8dade0;
      padding: 0px;
      outline: 4px solid #4880db;
    }
  }

  .btn-action {
    min-width: 70px;
    border-radius: 4px;
    padding: 2px;
    margin-right: 10px;
    /* border: 1px; */
    border: none;
    background: rgb(225, 223, 223);
    cursor: pointer;
    &:hover {
      padding: 6px;
    }
  }
  .btn-action-active {
    background: #ffb115;
  }
}
</style>
