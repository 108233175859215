var currencyFormatter = require('currency-formatter')

/**momentTimeZonejs, jalaliMoment */
var moment = require('moment-jalaali')
var momentZone = require('moment-timezone')

/**define some global mixins */
var helper = {
    methods: {
        /**new number format 
         * @param int
        */
        newNumberFormat(num, currency = null) {
            switch (currency) {
                case 'toman':
                    return currencyFormatter.format(num, { symbol: 'تومان', decimal: '.', thousand: ',', precision: 0, format: '%v %s' })
                case 'toman-nos':
                    return currencyFormatter.format(num, { symbol: '', decimal: '.', thousand: ',', precision: 0, format: '%v %s' })
                case 'usdt':
                    return currencyFormatter.format(num, { symbol: '', decimal: '.', thousand: ',', precision: 2, format: '%v %s' })
                default:
                    //means rial
                    return currencyFormatter.format(num, { symbol: '', decimal: '.', thousand: ',', precision: 0, format: '%v %s' })
            }
        },

        /**format numbers */
        numberWithCommas(x) {
            if (!x) return 0
            var parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
        },

        /**
         * @return boolean 
         * check user can buy or sell
         * 1- check user authenticated or not if not move user to login page
         * 2- if user authenticated but not verified , say user you are not verified and move it to authorize page
         * if everything is ok return true
         */
        async canBuyOrSell() {
            if (!this.authenticated) {
                /**user not authenticated */
                this.$notify({
                    text: "لطفا ابتدا وارد سایت شوید",
                    type: "warn"
                })
                this.$router.push({ path: '/login', replace: true })
                return false
            }

            /**before everything update profile status */
            await this.updateProfileStatus()

            if (!this.firstName) {
                /**user not authenticated */
                this.$notify({
                    text: "ابتدا پروفایل خود را تکمیل نمایید",
                    type: "warn"
                })
                this.$router.push({ path: '/authorize', replace: true })
                return false
            }

            if (!this.profileStatus.document_verified) {
                /**user not authenticated */
                this.$notify({
                    text: "مدارک شما هنوز تایید نشده است ، درصورتی که قبلا مدارک را ارسال کرده اید تا تایید آن منتظر بمانید ، در غیر این صورت مدارک را ارسال نمایید",
                    type: "warn"
                })
                this.$router.push({ path: '/authorize/step-2', replace: true })
                return false
            }

            /**every thing is ok */
            return true
        },

        /**
         * check user have enough balance to buy or not
         * @return success=> true , faild => show proper notification
         */
        async haveEnoughToBuy() {
            /**before everything update profile balance */
            await this.updateProfile()

            if (this.priceYouHaveToPay > this.userBalance) {
                /**user not authenticated */
                this.$notify({
                    text: "متاسفانه موجودی شما برای این تراکنش کافی نمی باشد",
                    type: "warn"
                })
                this.$notify({
                    text: "موجودی شما : " + this.numberWithCommas(this.userBalance) + " تومان",
                    type: "info"
                })
                return false
            }

            /**user have enough balance to pay */
            return true
        },

        /**update user profileStatus */
        async updateProfileStatus() {
            await this.$store.dispatch('updateProfileStatus')
        },

        /**update user profile  */
        async updateProfile() {
            await this.$store.dispatch('updateProfile')
        },

        /**new jdate 
         * @param miladi UTC date time
        */
        jdate(date, format = null) {
            if (!date)
                return ''

            var zone = localStorage.getItem('zone') ? localStorage.getItem('zone') :  window.env.TimeZone;
            var utcTime = momentZone.utc(date);
            var time = utcTime.tz(zone);
            var local = time.format('YYYY-MM-DD HH:mm:ss')

            // console.log('set time zone step1 (utc)', date);
            // console.log(`set time zone step2: (${zone})`, moment(local, 'YYYY-M-D HH:mm:ss').format('jYYYY/jMM/jDD HH:mm'));

            if (format) return moment(local, 'YYYY-M-D HH:mm:ss').format(format)

            return moment(local, 'YYYY-M-D HH:mm:ss').format('jYYYY/jMM/jDD HH:mm')
        },
    }
}

export default helper