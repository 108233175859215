<template>
  <div>
    <div class="d-flex justify-content-center">
      <input :readonly="!focus" :type="focus ? 'text' : 'password'" dir="ltr" @blur="inBlur" @focus="inFocus" v-model="password" @input="checkPassword" placeholder="کلمه عبور" />
    </div>
    <div class="input_container" v-show="focus">
      <strong class="title">موارد تاثیرگذار بر رمز عبور قوی:</strong>
      <ul>
        <li v-bind:class="{ is_valid: contains_eight_characters }">حداقل 8 کاراکتر</li>
        <li v-bind:class="{ is_valid: contains_number }">شامل اعداد</li>
        <li v-bind:class="{ is_valid: contains_uppercase }">شامل حروف بزرگ</li>
        <li v-bind:class="{ is_valid: contains_special_character }">شامل حداقل یک کاراکتر خاص مانند@#$%</li>
      </ul>

      <div class="checkmark_container" v-bind:class="{ show_checkmark: valid_password }">
        <svg width="50%" height="50%" viewBox="0 0 140 100">
          <path class="checkmark" v-bind:class="{ checked: valid_password }" d="M10,50 l25,40 l95,-70" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      focus: false,
      password: null,
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false
    }
  },
  methods: {
    checkPassword() {
      this.password_length = this.password.length;
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

      if (this.password_length > 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
      this.contains_special_character = format.test(this.password);

      if (this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
      this.$emit('input', this.password)
    },

    /**when blur */
    inBlur(){
      this.focus = false
    },

    /**when input is focused */
    inFocus(){
      this.focus = true
    }
  },
}
</script>

<style lang="scss" scoped>
strong.title {
  font-size: 15px !important;
}
h2 {
  text-align: center;
  color: #fff;
  font-weight: 400;
}

ul {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

li {
  font-size: 11px;
  margin-bottom: 4px;
  color: #525f7f;
  position: relative;
}

li:before {
  content: '';
  width: 0%;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

/* Password Input --------- */

.input_container {
  position: relative;
  padding: 10px 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 6px;
  background: #fff;
  margin: -10px auto 0 auto;
  width : 80%;

  .is_valid {
    color: rgba(136, 152, 170, 0.8);
  }
  .is_valid:before {
    width: 100%;
  }

  .checkmark_container {
    border-radius: 50%;
    position: absolute;
    top: -15px;
    left: -15px;
    background: #2ecc71;
    width: 30px;
    height: 30px;
    visibility: hidden;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.4s ease;
  }

  .show_checkmark {
    visibility: visible;
    opacity: 1;
  }

  .checkmark {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: white;
    stroke-width: 15;
    stroke-linecap: round;
    stroke-dasharray: 180;
    stroke-dashoffset: 180;
  }

  .checked {
    animation: draw 0.5s ease forwards;
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>