<template>
  <section class="personal-info">
    <div class="container-fluid">
      <div class="row">
        <!-- content orders -->
        <div class="col-12 col-lg-12">
          <card>
            <!-- the page content  -->
            <div class="card-body p-0 ">
              <!-- title -->
              <h5>جزئیات سفارش</h5>

              <!-- row one (instant_price invoice_id value and network ) -->
              <div class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-around my-2">
                <div class="my-1 d-flex flex-column flex-lg-row justify-content-around w-100 w-sm-50">
                  <!-- instant_price -->
                  <div class="user-id d-flex flex-column justify-content-between w-100 w-sm-50">
                    <span>ارزش تومان در لحظه سفارش</span>
                    <p>{{ newNumberFormat(instant_price) }}</p>
                  </div>

                  <!-- instant_price -->
                  <div class="user-id d-flex flex-column justify-content-between w-100 w-sm-50">
                    <span>ارزش تومانی</span>
                    <p>{{ newNumberFormat(tmn_value) }}</p>
                  </div>
                </div>

                <div class="my-1 d-flex flex-column flex-lg-row justify-content-around w-100 w-sm-50">
                  <!-- instant_price -->
                  <div class="user-id d-flex flex-column justify-content-between w-100 w-sm-50">
                    <span>قیمت تتر در لحظه سفارش</span>
                    <p>{{ newNumberFormat(usdt_price) }}</p>
                  </div>

                  <!-- instant_price -->
                  <div class="user-id d-flex flex-column justify-content-between w-100 w-sm-50">
                    <span>قیمت دلاری کوین</span>
                    <p>{{ newNumberFormat(usd_value) }}</p>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between my-2">
                <div class="my-1 d-flex flex-column flex-lg-row justify-content-around w-100 w-sm-50">
                  <!-- invoice_id -->
                  <div class="date-modify mx-2 w-100 w-sm-50">
                    <span> شماره فاکتور </span>
                    <p>{{ invoice_id }}</p>
                  </div>

                  <!-- network -->
                  <div class="date-modify mx-2 w-100 w-sm-50">
                    <span> شبکه </span>
                    <p>{{ network }}</p>
                  </div>
                </div>
                <div class="my-1 d-flex flex-column flex-lg-row justify-content-around w-100 w-sm-50">
                  <!-- value -->
                  <div class="date-modify mx-2 w-100 w-sm-50">
                    <span> مقدار </span>
                    <p>{{ value }}</p>
                  </div>

                  <!-- coin -->
                  <div class="date-modify mx-2 w-100 w-sm-50">
                    <span> کوین </span>
                    <p>{{ coin ? coin.name_fa : 'ثبت نشده' }}</p>
                  </div>
                </div>
              </div>

              <!-- row one (type status and date) -->
              <div class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between my-2">
                <div class="my-1 d-flex flex-column flex-lg-row justify-content-around w-100 w-sm-50">
                  <!-- type -->
                  <div class="date-modify mx-2 w-100 w-sm-50">
                    <span> نوع </span>
                    <p v-html="getTypeLabel(type)"></p>
                  </div>

                  <!-- status -->
                  <div class="date-modify mx-2 w-100 w-sm-50">
                    <span> وضعیت </span>
                    <p v-html="getStatusLabel(status)"></p>
                  </div>
                </div>

                <div class="my-1 d-flex flex-column flex-lg-row justify-content-around w-100 w-sm-50">
                  <!-- created -->
                  <div class="date-modify mx-2 w-100 w-sm-50">
                    <span> تاریخ ایجاد </span>
                    <p>{{ created }}</p>
                  </div>

                  <!-- last_updated -->
                  <div class="date-modify mx-2 w-100 w-sm-50">
                    <span> به روز رسانی </span>
                    <p>{{ last_updated }}</p>
                  </div>
                </div>
              </div>

              <!-- row 3 (user id and birthday) -->
              <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between">
                <!-- destination -->
                <div v-if="destination" class="date-modify w-100 w-lg-50 mx-2">
                  <span> destination </span>
                  <p>{{ destination }}</p>
                </div>

                <!-- txID -->
                <div v-if="txID" class="date-modify w-100 w-lg-50 mx-2">
                  <a :href="getTransactionLink(txID)" target="_blank">
                    <span> شناسه تراکنش </span>
                    <p>{{ txID }}</p>
                  </a>
                </div>
              </div>

              <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between">
                <!-- address -->
                <div class="date-modify w-100 w-lg-50 mx-2">
                  <span> آدرس گیرنده </span>
                  <p>{{ address }}</p>
                </div>
              </div>
            </div>
          </card>
        </div>

        <!-- content card -->
        <div class="col-12 col-lg-12" v-if="type == 'SELL'">
          <card>
            <!-- the page content  -->
            <div class="card-body">
              <!-- title -->
              <h5>اطلاعات حساب بانکی</h5>
              <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-3">
                <!-- bank -->
                <div class="user-id w-100 w-lg-50 d-flex flex-column justify-content-between">
                  <span>نام بانک</span>
                  <p>{{ bank }}</p>
                </div>

                <!-- card_number -->
                <div class="date-modify w-100 w-lg-50 mx-2">
                  <span> شماره کارت </span>
                  <p>{{ card_number }}</p>
                </div>

                <!-- status -->
                <div class="date-modify w-100 w-lg-50 mx-2">
                  <span>تایید شده</span>
                  <div class="switch mt-2">
                    <input type="checkbox" disabled class="toggle" v-model="statusCard" />
                  </div>
                </div>

                <!-- IBAN -->
                <div class="user-id w-100 w-lg-50 d-flex flex-column justify-content-between">
                  <span>شماره شبا</span>
                  <p>{{ IBAN }}</p>
                </div>
              </div>
            </div>
          </card>
        </div>

        <!-- content personal info -->
        <div class="col-12">
          <card>
            <!-- the page content  -->
            <div class="card-body" v-if="true">
              <!-- title -->
              <h5>اطلاعات کاربر سفارش دهنده</h5>
              <!-- row one (user id and birthday) -->
              <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-3">
                <!-- user id -->
                <div class="user-id w-100 w-lg-50 d-flex flex-column justify-content-between border-bottom mx-2">
                  <span>شناسه کاربر</span>
                  <input type="text" v-model="userID" disabled />
                </div>

                <!-- first name -->
                <div class="date-created w-100 border-bottom mx-2">
                  <span>نام </span>
                  <input type="text" v-model="firstName" :disabled="disabled" />
                </div>

                <!-- last name -->
                <div class="date-modify w-100 border-bottom mx-2">
                  <span> نام خانوادگی</span>
                  <input type="text" v-model="lastName" :disabled="disabled" />
                </div>
              </div>

              <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-4">
                <!-- is_active -->
                <div class="date-modify w-100 mx-2" v-if="false">
                  <span> وضعیت فعال بودن </span>
                  <div class="switch mt-2">
                    <input type="checkbox" disabled class="toggle" v-model="is_active" />
                  </div>
                </div>

                <!-- national code -->
                <div class="national-code w-100 border-bottom mx-2">
                  <span>کد ملی</span>
                  <input type="text" v-model="nationalCode" disabled />
                </div>

                <!-- birthday -->
                <div class="date-modify w-100 w-lg-50 border-bottom mx-2">
                  <span> تاریخ تولد </span>
                  <input type="text" class="birthday" v-model="birthday" :disabled="disabled" :class="{ flexible: !disabled }" />
                </div>

                <!-- username -->
                <div class="user-id w-100 w-lg-50 d-flex flex-column justify-content-between border-bottom mx-2">
                  <span>شماره تماس</span>
                  <input type="text" v-model="username" disabled />
                </div>
              </div>

              <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-3">
                <!-- date_joined -->
                <div class="date-modify w-100 border-bottom mx-2">
                  <span> تاریخ عضویت </span>
                  <input type="text" v-model="date_joined" disabled />
                </div>

                <!-- date created -->
                <div class="date-created w-100 border-bottom mx-2">
                  <span>تاریخ ثبت نام</span>
                  <input type="text" v-model="dateCreated" disabled />
                </div>
              </div>

              <div class="col-12 col-lg-4">
                <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-3">
                  <!-- profile Confirmation -->
                  <div class="profile d-flex flex-column align-items-center justify-content-between my-3">
                    <p>تایید پروفایل</p>
                    <div class="switch my-1">
                      <input type="checkbox" disabled class="toggle" v-model="profileConfirmation" />
                    </div>
                  </div>

                  <!-- document Confirmation -->
                  <div class="document d-flex flex-column align-items-center justify-content-between my-3">
                    <p>تایید مدارک</p>
                    <div class="switch my-1">
                      <input type="checkbox" disabled class="toggle" v-model="documentConfirmation" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>

      <!-- btn change properties -->
      <div class="row">
        <div class="col-12">
          <base-button type="primary" size="md" @click="showModal">تغییر وضعیت</base-button>
        </div>
      </div>

      <!-- modal change status -->
      <modal name="changeStatusModal">
        <div class="title text-center">
          <p>تغییر وضعیت</p>
        </div>
        <div class="user-name">
          <form @submit.prevent="submitStatus">
            <span>انتخاب وضعیت</span>
            <multiselect
              class="mt-2"
              v-model="selectedStatus"
              :maxHeight="170"
              :showLabels="false"
              :options="statuses"
              placeholder="انتخاب وضعیت"
              track-by="code"
              label="name"
              :searchable="false"
              :allow-empty="false"
            ></multiselect>

            <div class="mt-4" v-if="type == 'BUY'">
              <base-input v-model="transaction" class="w-100 my-4" label="شناسه" type="text" placeholder="شناسه تراکنش"> </base-input>
            </div>

            <div class="mt-4">
              <base-input v-model="description" class="w-100 my-4" label="توضیحات" type="text" placeholder="توضیحات"> </base-input>
            </div>
          </form>
        </div>

        <div class="d-flex align-items-center justify-content-around">
          <base-button type="success" @click="submitStatus">تایید</base-button>
          <base-button type="danger" @click="hideModal()"> انصراف</base-button>
        </div>
        <!-- confirmation -->
      </modal>
    </div>
  </section>
</template>

<script>

/**import mixin */
import Helper from '../../../settings/Helper'

/**import moment-jalali */
var moment = require('moment-jalaali')

// Multiselect
import Multiselect from 'vue-multiselect'

export default {
  name: 'UserPersonalInfo',
  props: ['id'],
  mixins: [Helper],
  data() {
    return {
      usd_value: null,
      usdt_price: null,
      coin: null,
      description: null,
      transaction: null,
      selectedStatus: null,
      statuses: [
        // { name: 'منتظر دریافت شماره تراکنش از کاربر', code: 'OPEN' },
        // { name: 'درحال بررسی', code: 'PENDING' },
        { name: 'کامل شده', code: 'COMPLETE' },
        // { name: 'درحال انجام', code: 'IN_PROCESS' },
        // { name: 'درحال بررسی', code: 'DRAFT' },
        // { name: 'تایید شده', code: 'APPROVED' },
        { name: 'رد شده', code: 'REJECTED' },
      ],
      // personal
      username: null,
      date_joined: null,
      is_superuser: null,
      is_active: null,

      // orders
      type: null,
      txID: null,
      status: null,
      network: null,
      last_updated: null,
      invoice_id: null,
      instant_price: null,
      destination: null,
      created: null,
      value: null,
      address: null,
      tmn_value: null,

      // card
      card: null,
      IBAN: null,
      bank: null,
      card_number: null,
      statusCard: null,


      /**user personal info  */
      disabled: true,
      userID: null,
      nationalCode: null,
      dateCreated: null,
      dateModify: null,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      birthday: null,
      fatherName: null,
      zipCode: null,
      dataRecive: null,
      // confirnation level one datas
      phoneConfirmation: false,
      profileConfirmation: false,
      documentConfirmation: false
    }
  },
  methods: {
    getTransactionLink(txid) {
      try {
        if (!this.coin) return 
        if (!this.coin.networks.length) return 
        let ts = this
        let network = this.coin.networks.filter(function(item){
          return item.name.toLocaleLowerCase() == ts.network.toLocaleLowerCase()
        })
        if(!network[0].explorer) return
        let temp = network[0].explorer
        let link = temp.replace('{txid}', txid)
        return link
      } catch (error) {
        console.error(error)
      }
    },

    async submitStatus() {
      if (this.type == 'BUY') {
        if (!this.transaction) return this.$notify({ text: 'شناسه تراکنش الزامی می باشد', type: 'error' })
      }

      if (!this.selectedStatus) return this.$notify({ text: 'انتخاب وضعیت الزامی می باشد', type: 'error' })

      try {
        await this.$axios({
          method: 'post',
          url: 'order/' + this.$route.params.id,
          data: {
            comment: this.description,
            status: this.selectedStatus.code,
            tx_id: this.transaction
          }
        })
      } catch (error) {
        console.log(error)
        return
      }
      this.getData()
      this.$modal.hide('changeStatusModal')
      this.$notify({ text: 'موفقیت آمیز', type: 'success' })
    },

    /**show inquiry modal */
    async showModal() {
      this.$modal.show('changeStatusModal')
    },

    /**show inquiry modal */
    hideModal() {
      this.$modal.hide('changeStatusModal')
    },

    /**
     * @param type
     * @return string the translated of type
     */
    getTypeLabel(type) {
      switch (type) {
        case 'BUY':
          return '<span class="badge badge-info">خرید</span>'
        case 'SELL':
          return '<span class="badge badge-primary">فروش</span>'
        default:
          return type
      }
    },

    /**
     * @param status
     * @return string the translated of status
     */
    getStatusLabel(status) {
      switch (status) {
        case 'REJECTED':
          return '<span class="badge bg-error">رد شده</span>'

        case 'APPROVED':
          return '<span class="badge bg-info">تایید شده</span>'

        case 'DRAFT':
          return '<span class="badge bg-warning">درحال بررسی</span>'

        case 'IN_PROCESS':
          return '<span class="badge bg-warning">درحال انجام</span>'

        case 'COMPLETE':
          return '<span class="badge bg-success">کامل شده</span>'

        case 'PENDING':
          return '<span class="badge bg-warning">در حال بررسی</span>'

        case 'OPEN':
          return '<span class="badge bg-warning">منتظر دریافت شماره تراکنش از کاربر</span>'

        default:
          return status
      }
    },

    /**filter birthdate */
    checkDate(formatted) {
      return formatted > this.minAgeDate || formatted < this.maxAgeDate
    },

    async getData() {
      /**get order info */
      try {
        let order = await this.$axios({
          method: 'get',
          url: 'invoice/' + this.$router.history.current.params.id
        })
        this.type = order.data.type
        this.txID = order.data.txID
        this.status = order.data.status
        this.network = order.data.network
        this.tmn_value = order.data.tmn_value
        this.invoice_id = order.data.invoice_id
        this.instant_price = order.data.instant_price
        this.destination = order.data.destination
        this.created = this.jdate(order.data.created)
        this.last_updated = this.jdate(order.data.last_updated)
        this.value = order.data.value
        this.coin = order.data.coin
        this.address = order.data.address
        this.usd_value = order.data.usd_value
        this.usdt_price = order.data.usdt_price

        // personal info
        this.username = order.data.user.username
        this.date_joined = this.jdate(order.data.user.date_joined)
        this.is_superuser = order.data.user.is_superuser
        this.is_active = order.data.user.is_active

        // cart info
        this.card = order.data.card
        if (this.card) {
          this.IBAN = this.card.IBAN
          this.bank = this.card.bank
          this.card_number = this.card.card_number
          this.statusCard = this.card.status
        }
      } catch (error) {
        console.error(error)
      }

      /**get user info */
      try {
        // get order
        let order = await this.$axios({
          method: 'get',
          url: 'invoice/' + this.$router.history.current.params.id
        })

        // get user detail
        let res = await this.$axios({
          method: 'get',
          url: 'users/?uid=' + order.data.user.uid
        })

        this.userID = res.data.results[0].uid
        this.nationalCode = res.data.results[0].profile.nid
        this.birthday = res.data.results[0].profile.birth_date
        this.firstName = res.data.results[0].profile.first_name
        this.lastName = res.data.results[0].profile.last_name
        this.phoneNumber = res.data.results[0].username
        this.dateCreated = this.jdate(res.data.results[0].date_joined)

        this.zipCode = res.data.results[0].zip_code
        this.fatherName = res.data.results[0].father_name
        this.dataRecive = res.data.results[0]

        this.phoneConfirmation = res.data.results[0].is_active
        this.documentConfirmation = res.data.results[0].profile.document_verified
        this.profileConfirmation = res.data.results[0].profile.verified
      } catch (error) {
        console.error(error)
      }
    }
  },
  async mounted() {

    this.$rtl.enableRTL()

    this.getData()
  },
  components: {
    Multiselect
  },
  computed: {
    minAgeDate: () => {
      return moment().subtract(18, 'year').format('jYYYY/jM/jD') // somthing like 1382/10/05
    },
    maxAgeDate: () => {
      return moment().subtract(90, 'year').format('jYYYY/jM/jD') // somthing like  1310/10/05
    }
  }
}
</script>

<style lang="scss" scoped>
.personal-info {
  input[type='text'] {
    background-color: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    color: #ffffeb;
    &:disabled {
      color: #9b9b83;
    }
  }
  .change {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #74839b;
    margin: 0 auto 0 0;
    cursor: pointer;
    &:hover {
      background-color: #445877;
      svg {
        fill: #74839b;
      }
    }
  }
  .switch {
    input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-tap-highlight-color: transparent;
      &:focus {
        outline: 0;
      }
    }

    .toggle {
      height: 23px;
      width: 50px;
      border-radius: 30px;
      display: inline-block;
      position: relative;
      margin: 0;
      border: 2px solid #474755;
      background: linear-gradient(180deg, #2d2f39 0%, #1f2027 100%);
      transition: all 0.2s ease;
      &:after {
        content: '';
        position: absolute;
        top: -4px;
        left: -2px;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background: #5c6e8b;
        box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
        transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
      }
      &:checked {
        border-color: #4f8bec;
        &:after {
          transform: translatex(20px);
          left: 2px;
          background: #4982df;
        }
      }
    }
  }
  .submit {
    button {
      padding: 10px 35px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }
  }
}
</style>
