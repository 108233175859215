<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <!-- title and filter -->
          <div class="card-header">
            <div class="card-title d-flex flex-column flex-sm-row justify-content-between">
              <div class="d-flex align-items-center">
                <h6>لیست جوایز</h6>
                <base-button type="info" class="mx-3" size="sm" @click="addPrize()">افزودن</base-button>
              </div>
              <h6 class="filter-title" @click="filter = !filter" v-if="false">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر ارزها
              </h6>
            </div>
            <!-- filter content -->
            <card v-if="filter" class="filter">
              <form @submit.prevent="loadItems">
                <div class="row">
                  <!-- name farsi -->
                  <base-input v-if="false" class="col-12 col-lg-4" label="نام" type="text" placeholder="نام" v-model="nameFilter"> </base-input>

                  <!-- symbol -->
                  <base-input class="col-12 col-lg-4" label="symbol" type="text" placeholder="symbol" v-model="symbolFilter"> </base-input>

                  <!-- type -->
                  <div class="col-12 col-lg-4" v-if="false">
                    <span class="cart-lable-color">نوع</span>
                    <div class="mt-1">
                      <multiselect
                        :showLabels="false"
                        v-model="typeFilter"
                        :options="typeFilters"
                        placeholder="نوع کویین"
                        track-by="code"
                        label="lable"
                        :searchable="false"
                        :allow-empty="false"
                      ></multiselect>
                    </div>
                  </div>

                  <div class="col-12 col-lg-3">
                    <base-button class="btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                    <base-button class="btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                  </div>
                </div>
              </form>
            </card>
          </div>
          <!-- the page content  -->
          <div>
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="columns"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- coin datail -->
                <div class="coin-detail d-flex align-items-center" v-if="props.column.field == 'logoUrl'">
                  <div class="d-flex align-items-center">
                    <!-- coin icon -->
                    <div class="coin-icon">
                      <img :src="props.row.logoUrl" alt="coin" />
                    </div>

                    <div class="d-flex flex-column align-items-start">
                      <!-- coin FA name -->
                      <div class="coin-name">
                        <span>{{ props.row.title }}</span>
                      </div>

                      <!-- coin symbol -->
                      <div class="coin-symbol d-flex align-items-center mt-1">
                        <span class="font-bold">{{ props.row.symbol }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <span v-else-if="props.column.field == 'prizeTitle'">{{ props.row.prizeTitle.length > 20 ? props.row.prizeTitle.substring(0, 20) + ' . . . ' : props.row.prizeTitle }} </span>
                <span v-else-if="props.column.field == 'header'"> {{ props.row.header.length > 40 ? props.row.header.substring(0, 40) + ' . . . ' : props.row.header }}</span>

                <!-- type field  -->
                <span v-else-if="props.column.field == 'type'" v-html="getTypeLabel(props.row.type)"> </span>

                <!-- type field  -->
                <span v-else-if="props.column.field == 'edit'">
                  <base-button
                    class=""
                    type="danger"
                    size="sm"
                    @click="
                      id = props.row.id
                      changeStatePost(id, 'disable')
                    "
                    >غیر فعال</base-button
                  >
                  <base-button
                    class="mx-2"
                    type="info"
                    size="sm"
                    @click="
                      id = props.row.id
                      changeStatePost(id, 'active')
                    "
                    >فعال</base-button
                  >

                  <base-button class="" type="primary" size="sm" @click="edit(props.row.id)">ویرایش</base-button>
                </span>

                <!-- countOfAllocation -->
                <base-button v-else-if="props.column.field == 'countOfAllocation'" class="" type="" size="sm" @click="allocated(props.row.id, props.row.countOfAllocation)">{{
                  props.row.countOfAllocation
                }}</base-button>

                <!-- status field  -->
                <span v-else-if="props.column.field == 'state'" v-html="getStatusLabel(props.row.idState)"> </span>

                <!-- network field  -->
                <span v-else-if="props.column.field == 'network'" dir="ltr" v-html="showNetwork(props.row.listOfSupportedNetworks)"> </span>

                <!-- logTime -->
                <span v-else-if="props.column.field == 'validUntil'"> {{ jdate(props.row.validUntil) }}</span>

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from 'vue-good-table'

// Multiselect
import Multiselect from 'vue-multiselect'

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

/**import mixin */
import Helper from '../../../settings/Helper'

export default {
  name: 'prize',
  mixins: [Helper],
  data() {
    return {
      selectedNetwork: null,
      address: null,
      selectedCoin: null,
      coins: [],
      columns: [
        {
          label: 'عنوان محتوا',
          field: 'header',
          sortable: false
        },
        {
          label: 'نوع هدیه',
          field: 'prizeType',
          sortable: false
        },

        {
          label: 'نماد',
          field: 'cSymbol',
          sortable: false
        },
        {
          label: 'مقدار',
          field: 'amount',
          sortable: false
        },
        {
          label: ' اولویت نمایش',
          field: 'priority',
          sortable: false
        },
        {
          label: 'تعداد اختصاص',
          field: 'countOfAllocation',
          sortable: false
        },
        {
          label: 'وضعیت',
          field: 'state',
          sortable: false
        },
        {
          label: 'امکانات',
          field: 'edit',
          sortable: false
        }
      ],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: window.env.CountItemInPerPage,
        search: null
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: window.env.PerPageDropdown,
        dropdownAllowAll: false
      },

      // add address
      newNetworkAdd: null,
      newNetworkAdds: [
        { code: 'usdt', lable: 'Usd' },
        { code: 'btc', lable: 'Bitcoin' },
        { code: 'ltc', lable: 'Litecoin' },
        { code: 'dash', lable: 'Dash' },
        { code: 'doge', lable: 'DogeCoin' }
      ],
      newAddress: null,

      // filter==true => show filter card
      filter: false,
      nameFilter: null,
      symbolFilter: null,
      typeFilter: { code: '21', lable: 'Crypto ' },
      typeFilters: [
        { code: '11', lable: 'NonCrypto ' },
        { code: '21', lable: 'Crypto ' }
      ]
    }
  },
  methods: {
    /** change state post between active and disable
    * default disable
    */
    async changeStatePost(id, type = 'disable') {
      try {

        this.isLoading = true

        let url = 'Organizer/AdminPrize/MakeDisabled'
        if (type == 'active')
          url = 'Organizer/AdminPrize/MakeAvailable'

        await this.$axios({
          url: url,
          method: type == 'active' ? 'get' : 'delete',
          headers: {
            idAdminPrize: id
          }
        })

        this.$notify({
          text: 'عملیات با موفقیت انجام شد',
          type: 'success'
        })
        this.loadItems()
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    },


    /**
         * @param object 
         * @return string the name of network
         */
    showNetwork(network) {
      let net = null;
      if (network) {
        network.filter(function (item) {
          net += ',' + item['name']
        });
      }
      return net.length > 25 ? net.substring(1, 25) + " ..." : net.substring(1);
    },
    /**
           * @param status
           * @return string the translated of status
           */
    getStatusLabel(status) {
      return status != 0 ? '<span class="badge badge-success">فعال</span>' : '<span class="badge badge-danger"> غیر فعال</span>'
    },

    /**reset search and search filters */
    resetSearch() {
      this.symbolFilter = null

      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null
      this.loadItems()
    },


    /**
     * @param status
     * @return string the translated of status
     */
    getTypeLabel(status) {
      switch (status) {
        case 'c':
          return '<span class="badge badge-success">کویین</span>'
        case 't':
          return '<span class="badge badge-info">توکن</span>'
        default:
          return status
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    /** show modal add address */

    /** ----------- Start modal add address ------------- */
    /**show inquiry modal */
    async addPrize() {
      this.$router.push({ path: 'prize/add-prize', replace: true })
    },

    // edit Coin\
    async edit(id) {
      this.$router.push({ path: 'prize/' + id + '/edit', replace: true })
    },
    // show Allocated . users that getted prize\
    async allocated(id, count) {
      if (count)
        this.$router.push({ path: 'prize/' + id + '/Allocated', replace: true })
    },
    /**resetNetwork */
    resetNetwork() {
      this.selectedNetwork = null
    },
    /** ----------- End modal add address ------------- */

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage
        this.isLoading = true

        let res = await this.$axios({
          method: 'get',
          url: `Organizer/AdminPrize/ListGet?PageNumber=${this.serverParams.page}&PageSize=${perPage}`,
          // headers: {
          //   // idCurrencyType: this.typeFilter.code,
          //   searchStatement: this.symbolFilter ? this.symbolFilter : {}
          // }
        })
        this.totalRecords = res.data.totalRecords
        this.rows = res.data.data
        this.isLoading = false

      } catch (error) {
        this.totalRecords = 0
        this.rows = null
        this.isLoading = false

      }

    }
  },
  async mounted() {
    this.$rtl.enableRTL()

    this.loadItems()
  },
  components: {
    VueGoodTable,
    datePicker: VuePersianDatetimePicker,
    Multiselect
  }
}
</script>

<style lang="scss" scoped>
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';

.multiselect__content-wrapper {
  .multiselect__content {
    padding: 0 0 70px 0;
  }
}
.logo-coin {
  width: 27px !important;
}
</style>
