<template>
  <section class="personal-info">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-lg-12 col-xl-8">
          <ValidationObserver ref="contentValidation">
            <!-- card 1 content -->
            <card>
              <div class="card-header">
                <div class="card-title d-flex align-items-center justify-content-between">
                  <h6>ویرایش بازار</h6>
                  <!-- status Confirmation -->
                  <div class="profile d-flex align-items-center justify-content-start my-3">
                    <p class="m-3">وضعیت</p>
                    <div class="switch">
                      <input type="checkbox" class="toggle" v-model="status" @change="changeStatus" />
                    </div>
                  </div>
                </div>
              </div>

              <!-- row one (explorer_transaction , minAmountOfBC , takerCommissionMultiplier , tradeMinUnit -->
              <div class="d-flex flex-column flex-sm-row align-items-end justify-content-between my-3">
                <!-- symbol -->
                <div class="date-modify w-100 w-lg-50 mx-2">
                  <ValidationProvider name="نماد" rules="required" v-slot="{ errors }">
                    <span> نماد</span>
                    <input type="text" v-model="symbol" disabled />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <!-- priority -->
                <div class="date-modify w-100 w-lg-50 mx-2">
                  <ValidationProvider name=" اولویت نمایش" rules="required|numeric" v-slot="{ errors }">
                    <span> اولویت نمایش</span>
                    <input type="text" v-model="priority" />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <!-- priority -->
                <div class="date-modify w-100 w-lg-50 mx-2">
                  <ValidationProvider name="عدد روند قیمت" rules="required|numeric" v-slot="{ errors }">
                    <span> عدد روند قیمت </span>
                    <input type="text" v-model="roundNo" />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <!-- row two (explorer_transaction , minAmountOfBC , takerCommissionMultiplier , tradeMinUnit -->
              <div class="d-flex flex-column flex-sm-row align-items-end justify-content-between my-3">
                <!-- minAmountOfBC -->
                <div class="date-modify w-100 w-lg-50 mx-2">
                  <ValidationProvider name="حداقل ارزش اردر بر اساس ارز مبنا" rules="required|double" v-slot="{ errors }">
                    <span> حداقل ارزش اردر بر اساس ارز مبنا</span>
                    <input type="text" v-model="minAmountOfBC" />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <!--  tradeMatchingRangeMultiplier -->
                <div class="date-created w-100 w-lg-50">
                  <ValidationProvider name=" ضریب بازه مچ اردر" rules="required|double" v-slot="{ errors }">
                    <span> ضریب بازه مچ اردر</span>
                    <input type="text" v-model="tradeMatchingRangeMultiplier" />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <!-- makerCommissionMultiplier -->
                <div class="date-modify w-100 w-lg-50 mx-2">
                  <ValidationProvider name="Maker" rules="required|double" v-slot="{ errors }">
                    <span> Maker</span>
                    <input type="text" v-model="makerCommissionMultiplier" />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <!-- takerCommissionMultiplier -->
                <div class="date-modify w-100 w-lg-50 mx-2">
                  <ValidationProvider name="Taker" rules="required|double" v-slot="{ errors }">
                    <span>Taker</span>
                    <input type="text" v-model="takerCommissionMultiplier" />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <!-- the page content  -->
              <div class="card-body">
                <div class="d-flex flex-column flex-sm-row align-items-end justify-content-between my-3">
                  <!-- heaterCount -->
                  <div class="date-modify w-100 w-lg-50 mx-2">
                    <ValidationProvider name="تعداد گرمکن" rules="required|numeric" v-slot="{ errors }">
                      <span> تعداد گرمکن</span>
                      <input type="text" v-model="heaterCount" />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <!-- heaterActivityRangeMultiplier -->
                  <div class="date-modify w-100 w-lg-50 mx-2">
                    <ValidationProvider name=" ضریب بازه فعالیت گرمکن" rules="required|double" v-slot="{ errors }">
                      <span> ضریب بازه فعالیت گرمکن</span>
                      <input type="text" v-model="heaterActivityRangeMultiplier" />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <!-- heaterAvgAmountOfCurrency -->
                  <div class="date-modify w-100 w-lg-50 mx-2">
                    <ValidationProvider name="میانگین حجم معاملات گرمکن" rules="required|double" v-slot="{ errors }">
                      <span> میانگین حجم معاملات گرمکن</span>
                      <input type="text" v-model="heaterAvgAmountOfCurrency" />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <!-- heaterMatchCreationDelayBySec -->
                  <div class="date-modify w-100 w-lg-50 mx-2">
                    <ValidationProvider name="تاخیر در ایجاد سفارش مناسب توسط گرمکن" rules="required|numeric" v-slot="{ errors }">
                      <span> تاخیر در ایجاد سفارش مناسب توسط گرمکن</span>
                      <input type="text" v-model="heaterMatchCreationDelayBySec" />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </card>
          </ValidationObserver>

          <!-- logo url -->
          <ValidationObserver ref="networksValidation" class="row" v-if="false">
            <!-- card 3 content (logo and sell state)-->
            <div class="col-12 col-md-6">
              <card>
                <!-- the page content  -->
                <div class="card-body">
                  <!-- sell_enabled -->
                  <div class="d-flex flex-column flex-lg-row align-items-center justify-content-start my-3" v-if="false">
                    <p class="px-3">وضعیت فروش</p>
                    <div class="switch">
                      <input type="checkbox" class="toggle" v-model="sell_enabled" />
                    </div>
                  </div>

                  <!-- logo -->
                  <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-3">
                    <div class="w-100 w-lg-50 px-3">
                      <ValidationProvider name="آدرس لوگو" rules="required" v-slot="{ errors }">
                        <span>آدرس لوگو</span>
                        <div class="mt-3 d-flex align-items-center justify-content-between">
                          <input type="text" v-model="logo" />
                          <img :src="logo" v-if="logo" class="logo-coin" alt="" />
                        </div>
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </card>
            </div>

            <!-- card 4 network -->
            <div class="col-12 col-md-6" v-if="false">
              <card>
                <!-- the page content  -->
                <div class="card-body">
                  <!-- networks -->
                  <div class="date-modify w-100 mx-2">
                    <div class="d-flex justify-content-between">
                      <span>شبکه</span>
                      <svg title="افزودن شبکه" xmlns="http://www.w3.org/2000/svg" width="20.67" height="20.67" viewBox="0 0 20.67 20.67" @click="addNetwork">
                        <g id="Group_2" data-name="Group 2" transform="translate(-884 -636)">
                          <circle id="Ellipse_1" data-name="Ellipse 1" cx="8.5" cy="8.5" r="8.5" transform="translate(886 638)" fill="#fff" />
                          <path
                            id="plus-circle"
                            d="M72.84,62.5A10.335,10.335,0,1,0,83.17,72.83,10.336,10.336,0,0,0,72.84,62.5Zm7.125,11.02a5.123,5.123,0,0,1-.111.716H74.238v5.617a5.482,5.482,0,0,1-.742.12c-.229.017-.468.026-.716.026-.213,0-.418-.009-.631-.026a4.918,4.918,0,0,1-.716-.12V74.238h-5.61a5.54,5.54,0,0,1-.12-.742c-.017-.229-.026-.468-.026-.716,0-.213.009-.418.026-.631a4.917,4.917,0,0,1,.12-.716h5.608v-5.61a5.046,5.046,0,0,1,.742-.111,6.383,6.383,0,0,1,.716-.033,5.554,5.554,0,0,1,.631.033,5.124,5.124,0,0,1,.716.111v5.608h5.617a5.046,5.046,0,0,1,.111.742,6.381,6.381,0,0,1,.033.716A4.6,4.6,0,0,1,79.964,73.52Z"
                            transform="translate(821.5 573.5)"
                            fill="#4effa1"
                          />
                        </g>
                      </svg>
                    </div>
                    <card>
                      <div class="row" v-for="(network, index) in networks" :key="index">
                        <div class="col-12 col-sm-6 col-md-4">
                          <ValidationProvider :name="'name' + '_' + index" rules="required" v-slot="{ errors }">
                            <base-input label="name" v-model="network.name"></base-input>
                            <span class="error">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                          <ValidationProvider :name="'fee' + '_' + index" rules="required" v-slot="{ errors }">
                            <base-input label="fee" v-model="network.fee"></base-input>
                            <span class="error">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                          <base-input label="address" v-model="network.address"></base-input>
                        </div>
                        <div class="col-12 col-sm-6 col-md-10">
                          <base-input label="explorer" v-model="network.explorer"></base-input>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2 text-center align-items-center justify-content-center d-flex">
                          <svg title="حذف شبکه" xmlns="http://www.w3.org/2000/svg" width="20.538" height="20.538" viewBox="0 0 20.538 20.538" v-if="networks.length != 1" @click="removeNetwork(index)">
                            <g id="Group_1" data-name="Group 1" transform="translate(-1037.731 -698.231)">
                              <rect id="Rectangle_1" data-name="Rectangle 1" width="16" height="3" transform="translate(1040 707)" fill="#fff" />
                              <path
                                id="minus-circle"
                                d="M72.774,62.5A10.269,10.269,0,1,0,83.038,72.764,10.27,10.27,0,0,0,72.774,62.5Zm7.079,10.95a5.091,5.091,0,0,1-.11.711H65.8a5.5,5.5,0,0,1-.12-.737c-.016-.228-.026-.465-.026-.711,0-.211.009-.423.026-.627a4.885,4.885,0,0,1,.12-.711h13.94a5.013,5.013,0,0,1,.11.737,6.341,6.341,0,0,1,.033.711A4.652,4.652,0,0,1,79.853,73.45Z"
                                transform="translate(975.231 635.731)"
                                fill="#ff5151"
                              />
                            </g>
                          </svg>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
              </card>
            </div>
          </ValidationObserver>
        </div>

        <!-- box listOfAcceptableSpotOrderTypes -->
        <div class="col-12 col-lg-12 col-xl-4">
          <ValidationObserver ref="listOfAcceptableSpotOrderTypesValidate">
            <!-- the page content  -->
            <card>
              <div class="card-header">
                <span>نوع اردرهای قابل پذیرش</span>
              </div>
              <div class="card-body">
                <!-- listOfAcceptableSpotOrderTypes -->
                <div class="row my-2" v-for="(listOfAcceptableSpotOrderType, index) in listOfAcceptableSpotOrderTypes" :key="index">
                  <!-- id -->
                  <input type="hidden" label="id" v-model="listOfAcceptableSpotOrderType.id" />
                  <!-- name -->
                  <div class="col-8">
                    <span>نام</span>
                    <input type="text" :disabled="true" label="name" v-model="listOfAcceptableSpotOrderType.name" />
                  </div>
                  <!-- acceptable -->
                  <div class="col-4">
                    <span>قابل پذیرش</span>
                    <div class="switch mt-4">
                      <input type="checkbox" class="toggle" v-model="listOfAcceptableSpotOrderType.acceptable" />
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </ValidationObserver>
        </div>

        <!-- box tag market -->
        <div class="col-12">
          <!-- the page content  -->
          <card>
            <div class="card-header">
              <span>نوع Tag ها</span>
            </div>
            <div class="card-body d-flex">
              <!-- tag market -->
              <div class="d-flex justify-content-start align-items-center my-2" v-for="(item, index) in marketTagType" :key="index">
                <!-- name -->
                <div class="d-flex justify-content-around align-items-center">
                  <span class="px-3">{{ item.value }}</span>
                  <input label="name" value="item.id" type="checkbox" :checked="item.title" v-model="item.title" />
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>

      <!-- submit -->

      <div class="col-12">
        <div class="submit d-flex justify-content-start align-items-center pt-3">
          <button class="btn-primary mx-1" @click="submit" v-if="!isLoading">ذخیره</button>
          <div v-else class="loader-wait-request mx-5"></div>

          <button class="btn-light mx-1" @click="$router.go(-1)">انصراف</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/**import mixin */
import Helper from '../../../settings/Helper'

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

/**import moment-jalali */
var moment = require('moment-jalaali')

// Multiselect
import Multiselect from 'vue-multiselect'

import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate'
import { numeric, required, double } from 'vee-validate/dist/rules'
import fa from 'vee-validate/dist/locale/fa'
extend('required', required)
extend('double', double)
extend('numeric', numeric)
localize('fa', fa)

export default {
  name: 'UserPersonalInfo',
  props: ['id'],
  mixins: [Helper],
  data() {
    return {

      heaterCount: null,
      heaterActivityRangeMultiplier: null,
      heaterAvgAmountOfCurrency: null,
      heaterMatchCreationDelayBySec: null,

      idCoin: null,
      explorer_transaction: null,
      minAmountOfBC: null,
      dateCreated: null,
      dateModify: null,
      tradeMatchingRangeMultiplier: null,
      max_sell: null,
      priority: null,
      min_buy: null,
      price: null,
      logo: null,
      name: null,
      status: null,
      makerCommissionMultiplier: null,
      symbol: null,
      networks: [{ fee: "", name: "", address: "", explorer: "" }],
      takerCommissionMultiplier: null,
      sell_enabled: true,
      tradeMinUnit: null,
      type: null,
      types: [
        { code: 'c', lable: 'کویین' },
        { code: 't', lable: 'توکن' }
      ],

      listOfAcceptableSpotOrderTypes: null,
      isLoading: false,
      marketTagType: null,
      roundNo: null
    }
  },
  methods: {
    /** enable or disable status market */
    async changeStatus() {
      try {

        let url = 'Organizer/Market/Disable'

        if (this.status)
          url = 'Organizer/Market/Activate'

        await this.$axios({
          url: url,
          method: 'get',
          headers: { mSymbol: this.$router.history.current.params.id }
        })

        this.$notify({
          text: 'عملیات با موفقیت انجام شد',
          type: 'success'
        })
      } catch (error) {
        console.log(error)
      }
    },

    // submit save
    async submit() {
      try {
        /**validate the form */
        if (! await this.$refs.contentValidation.validate()) return this.$notify({ text: 'لطفا خطاهای رخ داده را برطرف نمایید', type: 'error' })

        this.isLoading = true

        let listOfAcceptableSpotOrderTypes = this.listOfAcceptableSpotOrderTypes.map(function (item) {
          return {
            key: item.id,
            value: item.acceptable
          }
        })

        let marketTagType = this.marketTagType
        let lstTags = marketTagType.filter(function (item) {
          if (item.title)
            return item
        })
        lstTags = lstTags.map(function (item) {
          if (item.title)
            return item.id
        })
        let data = {
          mSymbol: this.symbol,
          priority: parseInt(this.priority),
          makerCommissionMultiplier: parseFloat(this.makerCommissionMultiplier),
          takerCommissionMultiplier: parseFloat(this.takerCommissionMultiplier),
          minAmountOfBC: parseFloat(this.minAmountOfBC),
          tradeMatchingRangeMultiplier: parseFloat(this.tradeMatchingRangeMultiplier),

          heaterCount: parseInt(this.heaterCount),
          heaterActivityRangeMultiplier: parseFloat(this.heaterActivityRangeMultiplier),

          heaterAvgAmountOfCurrency: parseFloat(this.heaterAvgAmountOfCurrency),
          heaterMatchCreationDelayBySec: parseInt(this.heaterMatchCreationDelayBySec),
          lstAcceptableSpotOrderTypes: listOfAcceptableSpotOrderTypes,
          lstTags: lstTags ? lstTags : "",
          roundNo: this.roundNo ? parseInt(this.roundNo) : 0
        }

        await this.$axios({
          url: "Organizer/Market/update",
          data: data
        })

        this.$notify({
          text: 'عملیات با موفقیت انجام شد',
          type: 'success'
        })

        this.isLoading = false
        this.$router.push({ path: '/dashboard/market', replace: true })
      } catch (error) {
        console.error(error)
      }
    },

    // load date for edit
    async loadData() {
      try {
        // get data for edit
        let res = await this.$axios({
          method: 'get',
          url: 'organizer/Market/Get',
          headers: { mSymbol: this.$router.history.current.params.id }
        })
        this.minAmountOfBC = res.data.data.minAmountOfBC
        this.tradeMatchingRangeMultiplier = res.data.data.tradeMatchingRangeMultiplier
        this.priority = res.data.data.priority
        this.makerCommissionMultiplier = res.data.data.makerCommissionMultiplier
        this.symbol = res.data.data.marketSymbol
        this.takerCommissionMultiplier = res.data.data.takerCommissionMultiplier
        this.tradeMinUnit = res.data.data.tradeMinUnit
        this.heaterCount = res.data.data.heaterCount
        this.heaterActivityRangeMultiplier = res.data.data.heaterActivityRangeMultiplier
        this.heaterAvgAmountOfCurrency = res.data.data.heaterAvgAmountOfCurrency
        this.heaterMatchCreationDelayBySec = res.data.data.heaterMatchCreationDelayBySec
        this.status = res.data.data.idState == 0 ? true : false
        this.listOfAcceptableSpotOrderTypes = res.data.data.listOfAcceptableSpotOrderTypes
        this.roundNo = res.data.data.roundNo

        let listOfTags = res.data.data.listOfIdTagValues ? res.data.data.listOfIdTagValues : []
        listOfTags = listOfTags.map(function (item) {
          return item.id
        })

        // get market tag type
        let req = await this.$axios({
          url: '/Organizer/Enums/GetMarketTagType',
          method: 'get',
          headers: {
            idLang: 1065
          }
        })
        let marketTagType = req.data.data


        this.marketTagType = marketTagType.map(function (item) {
          return {
            title: listOfTags.includes(item.id),
            value: item.value,
            id: item.id
          }
        });

      } catch (error) {
        // this.status=true
        // this.changeStatus()
        this.$router.push("/dashboard/market", true)
      }
    },

    /**
     * add new network
     */
    addNetwork() {
      /**check latest index */
      if (!this.networks[this.networks.length - 1].name || !this.networks[this.networks.length - 1].fee) return this.$notify({ text: 'مقادیر fee و name شبکه الزامی است', type: 'error' })

      /**add new one */
      this.networks.push({ fee: "", name: "", address: "", explorer: "" })
    },

    /**
     * remove network
     */
    removeNetwork(index) {
      this.networks.splice(index, 1)
    }
  },
  components: {
    datePicker: VuePersianDatetimePicker,
    Multiselect,
    ValidationProvider,
    ValidationObserver
  },
  async mounted() {
    this.$rtl.enableRTL()

    // load date for edit
    this.loadData()

  },
  computed: {
    minAgeDate: () => {
      return moment().subtract(18, 'year').format('jYYYY/jM/jD') // somthing like 1382/10/05
    },
    maxAgeDate: () => {
      return moment().subtract(90, 'year').format('jYYYY/jM/jD') // somthing like  1310/10/05
    }
  },

}
</script>

<style lang="scss" scoped>
.personal-info {
  svg {
    cursor: pointer;
  }
  .multiselect {
    border: none !important;
    border-bottom: 1px solid #ffffff !important;
    border-radius: 0;
    padding-bottom: 10px;
  }
  input[type='text'] {
    background-color: transparent !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    color: #ffffeb;
    &:disabled {
      color: #9b9b83;
    }
  }
  textarea {
    background-color: transparent !important;
    border: 1px solid #ffffeb;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    direction: ltr;
    color: #ffffeb67;
    padding-top: 10px;
    padding-bottom: 4px;
    padding-left: 10px;
    &:focus-visible {
      outline: none !important;
      color: #ffffeb;
    }
  }
  .flexible {
    border-bottom: 2px solid #ffffff !important;
  }
  .change {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #74839b;
    margin: 0 auto 0 0;
    cursor: pointer;
    &:hover {
      background-color: #445877;
      svg {
        fill: #74839b;
      }
    }
  }
  .switch {
    input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      &:focus {
        outline: 0;
      }
    }

    .toggle {
      height: 23px;
      width: 50px;
      border-radius: 30px;
      display: inline-block;
      position: relative;
      margin: 0;
      border: 2px solid #474755;
      background: linear-gradient(180deg, #2d2f39 0%, #1f2027 100%);
      transition: all 0.2s ease;
      &:after {
        content: '';
        position: absolute;
        top: -4px;
        left: -2px;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background: #5c6e8b;
        box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
        transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
      }
      &:checked {
        border-color: #4f8bec;
        &:after {
          transform: translatex(20px);
          left: 2px;
          background: #4982df;
        }
      }
    }
  }
  .submit {
    button {
      padding: 10px 35px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }
  }
}
.logo-coin {
  width: 70px !important;
}
</style>
