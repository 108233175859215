<template>
  <section class="personal-info">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <ValidationObserver ref="contentValidation">
            <!-- card 1 content -->
            <div class="col-12">
              <card>
                <div class="card-header">
                  <div class="card-title d-flex align-items-center justify-content-between">
                    <h6>ویرایش شبکه دیجیتال</h6>
                    <!-- depositWithdrawDisabled Confirmation -->
                    <div class="profile d-flex align-items-center justify-content-start my-3">
                      <p class="m-3">غیرفعال سازی مجوز واریز و برداشت</p>
                      <div class="switch">
                        <input type="checkbox" class="toggle" v-model="depositWithdrawDisabled" @change="changeStatus" />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- the page content  -->
                <div class="card-body">
                  <div class="row">
                    <!-- title -->
                    <div class="col-12 col-md-6 col-lg-3 mt-3">
                      <span> عنوان </span>
                      <input type="text" v-model="title" disabled />
                    </div>

                    <!-- minDelayToCheckTxBySec -->
                    <div class="col-12 col-md-6 col-lg-3 mt-3">
                      <span> حداقل تاخیر برای چک تراکنش بر حسب ثانیه,</span>
                      <input type="text" v-model="minDelayToCheckTxBySec" disabled />
                    </div>

                    <!-- minCountOfConfirmation -->
                    <div class="col-12 col-md-6 col-lg-3 mt-3">
                      <span> حداقل تعداد تایید</span>
                      <input type="text" v-model="minCountOfConfirmation" disabled />
                    </div>

                    <!--  chainId -->
                    <div class="col-12 col-md-6 col-lg-3 mt-3">
                      <span>آی دی زنجیره</span>
                      <input type="text" v-model="chainId" disabled />
                    </div>

                    <!-- title -->
                    <div class="col-12 col-md-6 col-lg-3 mt-3">
                      <span> نام EXPLORE</span>
                      <input type="text" v-model="titleExplore" disabled />
                    </div>

                    <!-- addressFormat -->
                    <div class="col-12 col-md-6 col-lg-3 mt-3">
                      <span>فرمت آدرس</span>
                      <input type="text" v-model="addressFormatExplore" disabled />
                    </div>

                    <!--  txFormat -->
                    <div class="col-12 col-md-6 col-lg-3 mt-3">
                      <span>فرمت تراکنش</span>
                      <input type="text" v-model="txFormatExplore" disabled />
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </ValidationObserver>

          <!-- submit -->
          <div class="col-12" v-if="false">
            <div class="submit">
              <button class="btn-primary" @click="changeStatus">ذخیره</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

/**import mixin */
import Helper from '../../../settings/Helper'

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

/**import moment-jalali */
var moment = require('moment-jalaali')

// Multiselect
import Multiselect from 'vue-multiselect'

import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate'
import { numeric, required } from 'vee-validate/dist/rules'
import fa from 'vee-validate/dist/locale/fa'
extend('required', required)
extend('numeric', numeric)
localize('fa', fa)

export default {
  name: 'UserPersonalInfo',
  props: ['id'],
  mixins: [Helper],
  data() {
    return {
      idCoin: null,
      chainId: null,
      title: 0,
      logoUrl: null,
      title: null,
      cSymbol: null,
      minDelayToCheckTxBySec: null,
      depositWithdrawDisabled: null,
      minCountOfConfirmation: null,
      explorer: [{ fee: "", name: "", address: "", explorer: "" }],

      titleExplore: null,
      addressFormatExplore: null,
      txFormatExplore: null,
      networkBlockChain: null
    }
  },
  methods: {
    // /**
    //      * @param object 
    //      */
    // showExplorer(explore) {
    //   let net = null;
    //   if (explore) {
    //     net += explore['title'] + ' - ' + explore['addressFormat'] + ' - ' + explore['txFormat']
    //   }
    //   return net
    // },
    /** enable or disable depositWithdrawDisabled market */
    async changeStatus() {
      try {

        await this.$axios({
          url: 'Organizer/Network/Update',
          method: 'post',
          headers: { idNetworkBlockChain: this.networkBlockChain, depWithDisabled: this.depositWithdrawDisabled }
        })

        this.$notify({
          text: 'عملیات با موفقیت انجام شد',
          type: 'success'
        })
        this.$router.go(-1)
      } catch (error) {
        console.log(error)
      }
    },

    // submit save
    async submit() {
      try {
        /**validate the form */
        if (! await this.$refs.contentValidation.validate()) return this.$notify({ text: 'لطفا خطاهای رخ داده را برطرف نمایید', type: 'error' })

        this.isLoading = true

        let data = {
          chainId: this.chainId,
          networkProtocol: this.title,
          logoUrl: this.logoUrl,
          cSymbol: this.cSymbol,
          minDelayToCheckTxBySec: this.minDelayToCheckTxBySec,
          minCountOfConfirmation: this.minCountOfConfirmation
        }

        await this.$axios({
          url: 'Organizer/Currency/Update',
          data: data
        })

        this.$notify({
          text: 'عملیات با موفقیت انجام شد',
          type: 'success'
        })

        this.isLoading = false
        this.$router.push({ path: '/dashboard/coin', replace: true })
      } catch (error) {
        console.error(error)
      }
    },

    // load date for edit
    async loadData() {
      try {
        // this.idCoin = 

        let res = await this.$axios({
          method: 'get',
          url: 'Organizer/Network/Get',
          headers: {
            IdNetworkProtocol: this.$router.history.current.params.id
          }
        })
        this.networkBlockChain = res.data.data.idNetworkBlockChain
        this.title = res.data.data.networkProtocol
        this.logoUrl = res.data.data.logoUrl
        this.cSymbol = res.data.data.symbol
        this.chainId = res.data.data.chainId
        this.minDelayToCheckTxBySec = res.data.data.minDelayToCheckTxBySec
        this.depositWithdrawDisabled = res.data.data.depositWithdrawDisabled
        // this.explorer = 
        this.minCountOfConfirmation = res.data.data.minCountOfConfirmation

        let explore = res.data.data.explorer
        if (explore) {
          this.titleExplore = explore.title
          this.addressFormatExplore = explore.addressFormat
          this.txFormatExplore = explore.txFormat
        }

      } catch (error) {
        console.error(error)
      }
    },


  },
  components: {
    datePicker: VuePersianDatetimePicker,
    Multiselect,
    ValidationProvider,
    ValidationObserver
  },
  async mounted() {
    this.$rtl.enableRTL()

    // load date for edit
    this.loadData()

    /**get user info */
  },
  computed: {
    minAgeDate: () => {
      return moment().subtract(18, 'year').format('jYYYY/jM/jD') // somthing like 1382/10/05
    },
    maxAgeDate: () => {
      return moment().subtract(90, 'year').format('jYYYY/jM/jD') // somthing like  1310/10/05
    }
  }
}
</script>

<style lang="scss" scoped>
.personal-info {
  svg {
    cursor: pointer;
  }
  .multiselect {
    border: none !important;
    border-bottom: 1px solid #ffffff !important;
    border-radius: 0;
    padding-bottom: 10px;
  }
  input[type='text'] {
    background-color: transparent !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    color: #ffffeb;
    &:disabled {
      color: #9b9b83;
    }
  }
  textarea {
    background-color: transparent !important;
    border: 1px solid #ffffeb;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    direction: ltr;
    color: #ffffeb67;
    padding-top: 10px;
    padding-bottom: 4px;
    padding-left: 10px;
    &:focus-visible {
      outline: none !important;
      color: #ffffeb;
    }
  }
  .flexible {
    border-bottom: 2px solid #ffffff !important;
  }
  .change {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #74839b;
    margin: 0 auto 0 0;
    cursor: pointer;
    &:hover {
      background-color: #445877;
      svg {
        fill: #74839b;
      }
    }
  }
  .switch {
    input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      &:focus {
        outline: 0;
      }
    }

    .toggle {
      height: 23px;
      width: 50px;
      border-radius: 30px;
      display: inline-block;
      position: relative;
      margin: 0;
      border: 2px solid #474755;
      background: linear-gradient(180deg, #2d2f39 0%, #1f2027 100%);
      transition: all 0.2s ease;
      &:after {
        content: '';
        position: absolute;
        top: -4px;
        left: -2px;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background: #5c6e8b;
        box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
        transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
      }
      &:checked {
        border-color: #4f8bec;
        &:after {
          transform: translatex(20px);
          left: 2px;
          background: #4982df;
        }
      }
    }
  }
  .submit {
    button {
      padding: 10px 35px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }
  }
}
.logoUrl-coin {
  width: 70px !important;
}
</style>
