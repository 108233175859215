<template>
  <section>
    <!-- header -->
    <div class="title-component d-flex align-items-center mb-3">
      <i :class="data.icon"></i>
      <h3 class="my-2">{{ data.title }}</h3>
    </div>
    <DxPieChart id="pie" :data-source="data.data" :title="data.options.title ? data.options.title : ''" :palette="data.options.color" @point-click="pointClickHandler($event)" @legend-click="legendClickHandler($event)">
      <DxSeries argument-field="lable" value-field="value">
        <DxLabel :visible="true">
          <DxConnector :visible="true" :width="1" />
        </DxLabel>
      </DxSeries>
      <DxSize :width="data.options.width ? data.options.width : 500" />
      <DxExport v-if="data.options.export" :enabled="true" />
    </DxPieChart>
  </section>
</template>

<script>

import DxPieChart, {
  DxSize,
  DxSeries,
  DxLabel,
  DxConnector,
  DxExport,
} from 'devextreme-vue/pie-chart';

export default {
  name: 'pieChart',
  props: ['data'],
  components: {
    DxPieChart,
    DxSize,
    DxSeries,
    DxLabel,
    DxConnector,
    DxExport,
  },
  methods: {
    pointClickHandler(e) {
      this.toggleVisibility(e.target);
    },
    legendClickHandler(e) {
      const arg = e.target;
      const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];

      this.toggleVisibility(item);
    },
    toggleVisibility(item) {
      item.isVisible() ? item.hide() : item.show();
    },
  },
};
</script>