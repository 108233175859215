<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header d-flex justify-content-between">
            <div class="card-title">
              <h6>آی پی مشترک در ثبت نام</h6>
              <base-button v-if="false" type="info" class="mx-3" size="sm" @click="addCoin()">افزودن</base-button>
            </div>
            <h6 class="filter-title" @click="filter = !filter" v-if="false">
              <span v-if="!filter"> + </span>
              <span v-if="filter"> - </span> فیلتر
            </h6>
          </div>
          <!-- filter content -->
          <card v-if="filter" class="filter">
            <form @submit.prevent="loadItems">
              <div class="row">
                <!-- fromFilter -->
                <div class="col-12 col-md-6 col-xl-4 date-holder">
                  <base-input class="fromDate" label="از تاریخ" type="text" placeholder="از تاریخ" v-model="fromFilter"> </base-input>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDate('from')" type>
                    <path
                      id="eraser"
                      d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                      transform="translate(-62.575 -109.75)"
                      fill="#fff"
                    />
                  </svg>
                  <date-picker v-model="fromFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".fromDate" view="year" auto-submit></date-picker>
                </div>

                <!-- toFilter -->
                <div class="col-12 col-md-6 col-xl-4 date-holder">
                  <base-input class="toDate" label="تا تاریخ" type="text" placeholder="تا تاریخ" v-model="toFilter"> </base-input>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDate('to')" type>
                    <path
                      id="eraser"
                      d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                      transform="translate(-62.575 -109.75)"
                      fill="#fff"
                    />
                  </svg>
                  <date-picker v-model="toFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".toDate" view="year" auto-submit></date-picker>
                </div>

                <div class="col-12 d-flex justify-content-between">
                  <div class="d-flex">
                    <base-button class="btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                    <base-button class="btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                  </div>
                  <a href="#" target="_blank"><base-button class="btn-filter" type="info" size="sm">دانلود فایل</base-button></a>
                </div>
              </div>
            </form>
          </card>

          <!-- the page content  -->
          <div class="card-body">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="columns"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- userName -->
                <a v-if="props.column.field == 'userName'" :href="'/#/dashboard/users/' + props.row.userId + '/personal-info'" target="_blank">
                  <span dir="ltr"> {{ props.row.userName }} </span>
                </a>

                <!-- created field  -->
                <span v-else-if="props.column.field == 'regTime'">
                  {{ jdate(props.row.regTime) }}
                </span>

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from "vue-good-table";

/**convert miladi to jalali */
var moment = require("moment-jalaali");

/**import mixin */
import Helper from "../../../settings/Helper";


/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

export default {
  name: "user-history",
  mixins: [Helper],
  data() {
    return {
      columns: [

        {
          label: "نام و نام خانوادگی",
          field: "nameFamily",
          sortable: false,
        },
        {
          label: "نام کاربری",
          field: "userName",
          sortable: false,
        }, {
          label: "زمان ثبت نام",
          field: "regTime",
          sortable: false,
        }, {
          label: "آی پی ثبت نام",
          field: "regIPInfo",
          sortable: false,
        },

      ],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: window.env.CountItemInPerPage,
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: "بعدی",
        prevLabel: "قبلی",
        firstLabel: "اولین",
        lastLabel: "آخرین",
        rowsPerPageLabel: "تعداد ردیف هر صفحه",
        ofLabel: "از",
        pageLabel: "صفحه", // for 'pages' mode
        allLabel: "همه",
        perPageDropdown: window.env.PerPageDropdown,
        dropdownAllowAll: false,
      },
      filter: false,
      fromFilter: null,
      toFilter: null,
    };
  },
  methods: {
    /** return Type Profile History 
     * @param object
     * @returns string
    */
    showTypeProfileHistory(histories) {
      let history = ''
      histories.map(function (item) {
        history += ', ' + item.value
      })
      return history.substring(1)
    },


    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage;
        let offset = this.serverParams.perPage * (this.serverParams.page - 1);
        this.isLoading = true;
        let res = await this.$axios({
          url:
            "Organizer/User/GetRegCommonInIP" + "?PageNumber=" + this.serverParams.page + "&PageSize=" + perPage,
          method: 'get',
          headers: {
            uIdCode: this.$router.history.current.params.id,
            // searchStatements: [
            //   {
            //     key: "srchDateFrom",
            //     value: this.fromFilter ? moment(this.fromFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
            //   },
            //   {
            //     key: "srchDateTo",
            //     value: this.toFilter ? moment(this.toFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
            //   },
            // ]
          }
        });

        this.totalRecords = res.data.totalRecords;
        this.rows = res.data.data;
        this.isLoading = false;
      } catch (error) {
        this.totalRecords = 0;
        this.rows = null;
        console.error(error);
      }
    },

    /**reset search and search filters */
    resetSearch() {
      this.fromFilter = this.toFilter = null

      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null
      this.loadItems()

    },
    /**clear from and to date filter */
    eraseDate(type) {
      type == 'from' ? this.fromFilter = null : this.toFilter = null
    },


  },
  async mounted() {
    this.$rtl.enableRTL();

    /**get orders */
    this.loadItems()
  },
  components: {
    VueGoodTable,
    datePicker: VuePersianDatetimePicker,

  },
}
</script>

<style>
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';
</style>
