<template>
  <div class="container-fluid spot-report">
    <div class="row">
      <div class="col-12">
        <card>
          <!-- title and filter -->
          <div class="card-header">
            <div class="card-title d-flex flex-column flex-sm-row justify-content-between">
              <div class="d-flex align-items-center">
                <h6>گزارش های اسپات</h6>
                <base-button v-if="false" type="info" class="mx-3" size="sm" @click="addCoin()">افزودن</base-button>
              </div>
              <h6 class="filter-title" @click="filter = !filter">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر
              </h6>
            </div>

            <!-- tab -->
            <div class="mt-4">
              <Tabs
                :theme="theme"
                v-model="assetLogFilter"
                @input="
                  setCurrentColums();
                  loadItems();
                "
              >
                <TabItem name="openOrders">
                  <template v-slot:name>سفارشات باز</template>
                </TabItem>
                <TabItem name="ordersHistory">
                  <template v-slot:name>سفارشات پرشده </template>
                </TabItem>
                <TabItem name="ordersHistory">
                  <template v-slot:name> معاملات</template>
                </TabItem>
              </Tabs>
            </div>
            <!-- filter content -->
            <card v-if="filter" class="filter">
              <form @submit.prevent="loadItems">
                <div class="row">
                  <!-- srchUser  -->
                  <base-input v-if="false" dir="ltr" class="col-12 col-md-6 col-xl-3" label=" نام کاربر" type="text" placeholder=" کاربر" v-model="srchUser"> </base-input>
                  <!-- fromFilter -->
                  <div class="col-12 col-md-6 col-lg-4 date-holder">
                    <base-input class="fromDate" label="از تاریخ" type="text" placeholder="از تاریخ" v-model="fromFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDate('from')" type>
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="fromFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".fromDate" view="year" auto-submit></date-picker>
                  </div>

                  <!-- toFilter -->
                  <div class="col-12 col-md-6 col-lg-4 date-holder">
                    <base-input class="toDate" label="تا تاریخ" type="text" placeholder="تا تاریخ" v-model="toFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDate('to')" type>
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="toFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".toDate" view="year" auto-submit></date-picker>
                  </div>

                  <!-- srchMSymbol  -->
                  <base-input dir="ltr" class="col-12 col-md-6 col-lg-4" label="نماد بازار" type="text" placeholder="نماد" v-model="srchMSymbol"> </base-input>

                  <!-- srchByAssetLogType -->
                  <div class="col-12 col-md-6 col-xl-3" v-if="false">
                    <span class="cart-lable-color">نوع گزارش</span>
                    <div class="mt-1">
                      <multiselect :showLabels="false" v-model="assetLogFilter" :options="assetLogsFilter" placeholder="نوع تغییر دارایی" track-by="code" label="title" :searchable="false" :allow-empty="false"></multiselect>
                    </div>
                  </div>

                  <!-- btn submit and reset-->
                  <div class="col-12 d-flex justify-content-between">
                    <div class="d-flex">
                      <base-button class="btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                      <base-button class="btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                    </div>
                    <a href="#" target="_blank"><base-button class="btn-filter" type="info" size="sm">دانلود فایل</base-button></a>
                  </div>
                </div>
              </form>
            </card>
          </div>

          <!-- the page content  -->
          <div class="card-body" :class="detectClass">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="currentColums"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- type field  -->
                <span v-if="props.column.field == 'logoUrl'">
                  <img :src="props.row.logoUrl" alt="copy" class="logo-coin" />
                </span>

                <!-- type field  -->
                <span v-else-if="props.column.field == 'detail'">
                  <base-button class="" type="info" size="sm" @click="showDetail(props.row)">جزئیات</base-button>
                </span>

                <!-- userName -->
                <a v-else-if="props.column.field == 'userName'" :href="'/#/dashboard/users/' + props.row.userId + '/personal-info'" target="_blank">
                  <span dir="ltr"> {{ props.row.userName }} </span></a
                >

                <!-- time field -->
                <span v-else-if="props.column.field == 'regTime'" class="d-flex flex-column align-items-center">
                  <b>{{ jdate(props.row.regTime, 'jYYYY/jMM/jDD') }}</b>
                  <b>{{ jdate(props.row.regTime, 'HH:mm:ss') }}</b>
                </span>

                <!-- newNumberFormat -->
                <span v-else-if="props.column.field == 'amountOfOrder'"> {{ props.row.amountOfOrder }}</span>
                <span v-else-if="props.column.field == 'amountOfFilled'"> {{ props.row.amountOfFilled }}</span>

                <span v-else-if="props.column.field == 'side'">
                  <span class="text-success" v-if="props.row.idSide == 1"> {{ props.row.side }}</span>
                  <span class="text-danger" v-else> {{ props.row.side }}</span>
                </span>

                <span v-else-if="props.column.field == 'amountOfHiddenProfitPerBc'" dir="ltr" class="text-left">
                  <div v-if="props.row.amountOfHiddenProfitPerBc != '0' || props.row.amountOfHiddenProfitPerUnit != '0'">
                    <span class="text-gold">{{ props.row.amountOfHiddenProfitPerBc }} </span><span> ( {{ props.row.amountOfHiddenProfitPerUnit }} )</span>
                  </div>
                  <div v-else>0</div>
                </span>

                <!-- wage -->
                <span v-else-if="props.column.field == 'Wage'" class="text-right">
                  <div v-if="props.row.commissionDiscountPercentage != 0">
                    <div class="d-flex align-items-start flex-column">
                      <small
                        >تخفیف <samp dir="ltr">{{ props.row.commissionDiscountPercentage }}</samp> درصد</small
                      >
                      <div>
                        <span class="" style="opacity: 0.7" v-if="props.row.commissionDeducedAfterDiscount != props.row.commissionDeducedBeforeDiscount"
                          ><del> {{ numberWithCommas(props.row.commissionDeducedBeforeDiscount) }} </del></span
                        >
                        <span class="font-bold mx-2"> {{ numberWithCommas(props.row.commissionDeducedAfterDiscount) }} </span>
                      </div>
                    </div>
                  </div>
                  <div v-else class="font-bold">{{ numberWithCommas(props.row.commissionDeducedAfterDiscount) }}</div>
                </span>

                <!-- direction ltr -->
                <div v-else-if="props.column.field == 'amountOfSpent'" class="text-left">
                  {{ props.row.amountOfSpent }}
                </div>

                <!-- direction ltr -->
                <div v-else-if="props.column.field == 'amountOfAchievedAfterCommission'" class="text-left">
                  {{ props.row.amountOfAchievedAfterCommission }}
                </div>

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>

    <!-- modal show all detail order -->
    <modal name="modalDetailOrder" :click-to-close="false">
      <!-- content -->
      <div v-if="detail" class="detail">
        <p class="text-center font-bold mb-2">جزئیات سفارش</p>
        <div class="item coin d-flex flex-row justify-content-center align-items-center">
          <div class="coin-img">
            <img :src="detail.cLogoUrl" alt="coin logo" />
          </div>
          <div class="coin-symbol mx-2">
            <span>
              <b>{{ detail.mSymbol }}</b>
            </span>
          </div>
          <div class="action-type" :class="{ buy: detail.idSide == 1 }">
            <span>
              <b>{{ detail.idSide == 1 ? 'خرید' : 'فروش' }}</b>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="item col-6 col-sm-4">
            <span>زمان : </span>
            <span>
              <b>{{ jdate(detail.regTime) }}</b>
            </span>
          </div>
          <div class="item col-6 col-sm-4">
            <span>نوع : </span>
            <span>
              <b>{{ detail.type }}</b>
            </span>
          </div>
          <div class="item col-6 col-sm-4">
            <span>مقدار سفارش : </span>
            <span>
              <b>{{ detail.amountOfOrder }}</b>
            </span>
          </div>
          <div class="item col-6 col-sm-4">
            <span>قیمت توقف : </span>
            <span>
              <b>{{ detail.amountOfStop }}</b>
            </span>
          </div>
          <div class="item col-6 col-sm-4">
            <span>قیمت واحد : </span>
            <span>
              <b>{{ detail.amountOfLimit }}</b>
            </span>
          </div>
          <div class="item col-6 col-sm-4">
            <span>قیمت بازار : </span>
            <span>
              <b>{{ detail.amountOfIxPrice }}</b>
            </span>
          </div>
          <div class="item col-6 col-sm-4">
            <span>مبلغ کل : </span>
            <span>
              <b>{{ detail.amountOfTotalBc }}</b>
            </span>
          </div>
          <div class="item col-6 col-sm-4">
            <span>مقدار پر شده : </span>
            <span>
              <b>{{ detail.amountOfFilled }}</b>
            </span>
          </div>
          <div class="item col-6 col-sm-4">
            <span>وضعیت : </span>
            <span>
              <b>{{ detail.state }}</b>
            </span>
          </div>
        </div>
        <div class="tradeDetails" v-if="detail.lstTradesAsJson.length">
          <p class="text-right font-bold my-2">جزئیات معامله ها</p>
          <div class="row my-2" v-for="(trades, index) in detail.lstTradesAsJson" :key="index">
            <div class="item col-6 col-sm-4">
              <span>زمان : </span>
              <b>{{ jdate(trades.tradeTime) }}</b>
            </div>
            <div class="item col-6 col-sm-4">
              <span> قیمت بازار : </span>
              <b>{{ trades.tradeDetails.indexPrice }} </b>
            </div>
            <div class="item col-6 col-sm-4">
              <span> کمیسیون : </span>
              <b>
                <div v-if="trades.tradeDetails.commissionDiscountPercentage != 0">
                  <div class="d-flex align-items-start flex-column">
                    <small
                      >تخفیف <samp dir="ltr">{{ trades.tradeDetails.commissionDiscountPercentage }}</samp> درصد</small
                    >
                    <div>
                      <small class="text-muted" style="opacity: 0.7" v-if="trades.tradeDetails.commissionDeducedAfterDiscount != trades.tradeDetails.commissionDeducedBeforeDiscount"
                        ><del> {{ numberWithCommas(trades.tradeDetails.commissionDeducedBeforeDiscount) }} </del></small
                      >
                      <span class="font-bold mx-2"> {{ numberWithCommas(trades.tradeDetails.commissionDeducedAfterDiscount) }} </span>
                    </div>
                  </div>
                </div>
                <div v-else class="font-bold">{{ numberWithCommas(trades.tradeDetails.commissionDeducedAfterDiscount) }}</div>
              </b>
            </div>
            <div class="item col-6 col-sm-4">
              <span> وضعیت : </span>
              <b>{{ trades.tradeDetails.orderCompleted == true ? 'پایان یافته' : 'در حال تکمیل' }} </b>
            </div>
            <div class="item col-6 col-sm-4">
              <span> مقدار : </span>
              <b>{{ trades.tradeDetails.amountOfSpent }} </b>
            </div>
            <div class="item col-6 col-sm-4">
              <span> مبلغ کل : </span>
              <b>{{ trades.tradeDetails.amountOfAchievedAfterCommission }} </b>
            </div>
          </div>
        </div>
      </div>
      <!-- close -->
      <div class="close cursor-pointer" @click="hideModal()">
        <svg xmlns="http://www.w3.org/2000/svg" width="22.268" height="22.268" viewBox="0 0 22.268 22.268">
          <g id="Group_4019" data-name="Group 4019" transform="translate(4422.232 1395.174)">
            <path id="Path_14730" data-name="Path 14730" d="M10.384,20.768A10.384,10.384,0,1,0,0,10.384,10.384,10.384,0,0,0,10.384,20.768Z" transform="translate(-4421.482 -1394.424)" fill="none" stroke="#bb283a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
            <path id="Path_15133" data-name="Path 15133" d="M6.936,5.153A1.437,1.437,0,0,0,4.9,7.186Zm4.743,8.81a1.437,1.437,0,1,0,2.033-2.033ZM4.9,7.186l6.776,6.777,2.033-2.033L6.936,5.153Z" transform="translate(-4420.406 -1393.598)" fill="#bb283a" />
            <path id="Path_15134" data-name="Path 15134" d="M13.712,7.186a1.437,1.437,0,0,0-2.033-2.033ZM4.9,11.93a1.437,1.437,0,0,0,2.033,2.033Zm6.776-6.777L4.9,11.93l2.033,2.033,6.776-6.777Z" transform="translate(-4420.406 -1393.598)" fill="#bb283a" />
          </g>
        </svg>
      </div>
    </modal>
  </div>
</template>

<script>

/**navigation tabs */
import { Tabs, TabItem } from "vue-material-tabs"


/**grid table */
import { VueGoodTable } from 'vue-good-table'

/**convert miladi to jalali */
var moment = require('moment-jalaali')

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

/**import mixin */
import Helper from '../../../settings/Helper'


// Multiselect
import Multiselect from 'vue-multiselect'

export default {
  name: 'Addresses',
  mixins: [Helper],
  data() {
    return {
      selectedNetwork: null,
      address: null,
      selectedCoin: null,
      coins: [],
      columnsOpen: [
        {
          label: 'بازار',
          field: 'mSymbol',
          sortable: false
        },
        {
          label: 'سمت',
          field: 'side',
          sortable: false
        },
        {
          label: 'نوع',
          field: 'type',
          sortable: false
        },
        {
          label: 'مقدار سفارش',
          field: 'amountOfOrder',
          sortable: false
        },
        {
          label: 'قیمت توقف',
          field: 'amountOfStop',
          sortable: false
        },
        {
          label: ' قیمت واحد',
          field: 'amountOfLimit',
          sortable: false
        },
        {
          label: ' قیمت بازار',
          field: 'amountOfIxPrice',
          sortable: false
        },

        {
          label: ' مبلغ کل',
          field: 'amountOfTotalBc',
          sortable: false
        },
        {
          label: 'مقدار پر شده',
          field: 'amountOfFilled',
          sortable: false
        }
        , {
          label: ' وضعیت ',
          field: 'state',
          sortable: false
        },
        {
          label: ' زمان ثبت',
          field: 'regTime',
          sortable: false
        },
        {
          label: 'جزئیات',
          field: 'detail',
          sortable: false
        },



      ],
      columnsTrade: [

        {
          label: 'بازار',
          field: 'mSymbol',
          sortable: false
        },
        {
          label: 'سمت',
          field: 'side',
          sortable: false
        },
        {
          label: 'نوع',
          field: 'type',
          sortable: false
        },

        {
          label: 'سود مخفی',
          field: 'amountOfHiddenProfitPerBc',
          sortable: false
        },
        {
          label: 'خرج شده ',
          field: 'amountOfSpent',
          sortable: false
        },
        {
          label: ' بدست آمده',
          field: 'amountOfAchievedAfterCommission',
          sortable: false
        }
        ,
        {
          label: ' زمان ثبت',
          field: 'regTime',
          sortable: false
        }

        ,
        {
          label: ' کارمزد ',
          field: 'Wage',
          sortable: false
        },
        // {
        //   label: 'جزئیات',
        //   field: 'detail',
        //   sortable: false
        // },



      ],
      columnsFilled: [

        {
          label: 'بازار',
          field: 'mSymbol',
          sortable: false
        },
        {
          label: 'سمت',
          field: 'side',
          sortable: false
        },
        {
          label: 'نوع',
          field: 'type',
          sortable: false
        },
        {
          label: 'مقدار سفارش',
          field: 'amountOfOrder',
          sortable: false
        },
        {
          label: 'قیمت توقف',
          field: 'amountOfStop',
          sortable: false
        },
        {
          label: ' قیمت واحد',
          field: 'amountOfLimit',
          sortable: false
        },
        {
          label: ' قیمت بازار',
          field: 'amountOfIxPrice',
          sortable: false
        },

        {
          label: ' مبلغ کل',
          field: 'amountOfTotalBc',
          sortable: false
        },
        {
          label: 'مقدار پر شده',
          field: 'amountOfFilled',
          sortable: false
        }
        ,
        {
          label: ' زمان ثبت',
          field: 'regTime',
          sortable: false
        },
        {
          label: 'جزئیات',
          field: 'detail',
          sortable: false
        },
      ],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: window.env.CountItemInPerPage,
        search: null
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: window.env.PerPageDropdown,
        dropdownAllowAll: false
      },
      // filter==true => show filter card
      filter: true,
      srchMSymbol: null,
      srchUser: null,
      fromFilter: null,
      toFilter: null,
      assetLogsFilter: [{ code: 'SpotPendingOrderList', title: 'سفارشات باز ' }, { code: 'SpotFilledOrderList', title: ' سفارشات پر شده ' }, { code: 'SpotTradeList', title: 'معاملات' }],
      assetLogFilter: 0,

      theme: {
        nav: "#1e1e2b",
        navItem: "#40435d",
        navActiveItem: "#d250da",
        slider: "#CE93D8",
        arrow: "#f3f3f3",
      },

      detail: null,
      currentColums: []


    }
  },
  methods: {
    setCurrentColums() {

      if (this.assetLogFilter == 0)
        this.currentColums = this.columnsOpen
      else if (this.assetLogFilter == 1)
        this.currentColums = this.columnsFilled
      else if (this.assetLogFilter == 2)
        this.currentColums = this.columnsTrade

      this.serverParams.page = 1
    },

    /**reset search and search filters */
    resetSearch() {
      this.srchMSymbol = this.fromFilter = this.toFilter = this.srchUser = null

      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null


      this.loadItems()
    },
    /**clear from and to date filter */
    eraseDate(type) {
      type == 'from' ? this.fromFilter = null : this.toFilter = null
    },


    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },


    // load items is what brings back the rows from server
    async loadItems() {
      try {

        if (this.isLoading) return

        let perPage = this.serverParams.perPage
        let offset = this.serverParams.perPage * (this.serverParams.page - 1)
        this.isLoading = true

        /**handle sorts */
        let sort = ''
        if (this.serverParams.sort[0].field) {
          /**check sort asc or desc */
          let sortType = this.serverParams.sort[0].type == 'asc' ? '' : '-'
          sort = this.serverParams.sort[0].field ? '&ordering=' + sortType + this.serverParams.sort[0].field : ''
        }

        let url = '';
        switch (this.assetLogFilter) {
          case 0:
            url = 'SpotPendingOrderList'
            break;
          case 1:
            url = 'SpotFilledOrderList'
            break;
          case 2:
            url = 'SpotTradeList'
            break;
        }
        let res = await this.$axios({
          method: 'post',
          url: `Organizer/User/${url}?PageNumber=${this.serverParams.page}&PageSize=${perPage}`,
          data: {
            userId: this.$router.history.current.params.id,
            searchStatements: [
              {
                key: "srchUser",
                value: this.srchUser ? this.srchUser : ''
              },
              {
                key: "srchMSymbol",
                value: this.srchMSymbol ? this.srchMSymbol : ''
              },
              {
                key: "srchDateFrom",
                value: this.fromFilter ? moment(this.fromFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
              },
              {
                key: "srchDateTo",
                value: this.toFilter ? moment(this.toFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
              },
            ]
          }
        })
        this.totalRecords = res.data.totalRecords
        this.rows = res.data.data
        this.isLoading = false

      } catch (error) {
        this.totalRecords = 0
        this.rows = null
        this.isLoading = false

      }
    },

    /** show all detail order */
    async showDetail(row) {
      try {
        let req = await this.$axios({
          url: '/Organizer/User/SpotGetOrder',
          method: 'get',
          headers: {
            uIdCode: row.userId,
            mSymbol: row.mSymbol,
            oIdCode: row.id
          }
        })

        this.detail = req.data.data
        this.showModal()

      } catch (error) {
        console.log(error)
      }
    },

    /**show modal modalDetailOrder  */
    async showModal() {
      this.$modal.show('modalDetailOrder')
    },

    /**hide modal modalDetailOrder*/
    async hideModal() {
      this.$modal.hide('modalDetailOrder')
    },
  },
  async mounted() {
    this.$rtl.enableRTL()

    /**get orders */
    this.loadItems()


  },
  beforeDestroy() {
    this.isLoading = true
  },
  components: {
    VueGoodTable,
    datePicker: VuePersianDatetimePicker,
    Multiselect,
    Tabs, TabItem
  },
  computed: {
    detectClass: {
      get() {
        if (this.assetLogFilter == 2) {
          return 'sort-title-tab2'
        }
        else if (this.assetLogFilter == 1) {
          return 'sort-title-tab1'
        }
        return 'sort-title-tab0'
      },
      set() {
        return
      }
    }
  }
}
</script>

<style lang="scss" >
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';
.spot-report {
  .multiselect__content-wrapper {
    .multiselect__content {
      padding: 0 0 70px 0;
    }
  }
  .logo-coin {
    width: 27px !important;
  }

  .close {
    position: absolute;
    left: 33px;
    top: 15px;
  }

  .text-gold {
    color: gold;
  }
}

.sort-title-tab2 {
  th:nth-child(6),
  th:nth-child(7),
  th:nth-child(5) {
    text-align: left !important;
  }

  th:nth-child(9) {
    text-align: right !important;
  }
}
.sort-title-tab1 {
  th:nth-child(6),
  th:nth-child(7),
  th:nth-child(8),
  th:nth-child(9),
  th:nth-child(10),
  th:nth-child(5) {
    text-align: left !important;
  }

  td:nth-child(6),
  td:nth-child(7),
  td:nth-child(8),
  td:nth-child(9),
  td:nth-child(10),
  td:nth-child(5) {
    text-align: left !important;
  }
}
.sort-title-tab0 {
  th:nth-child(6),
  th:nth-child(7),
  th:nth-child(8),
  th:nth-child(9),
  th:nth-child(10),
  th:nth-child(5) {
    text-align: left !important;
  }

  td:nth-child(6),
  td:nth-child(7),
  td:nth-child(8),
  td:nth-child(9),
  td:nth-child(10),
  td:nth-child(5) {
    text-align: left !important;
  }
}
</style>
