<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <!-- title and filter -->
          <div class="card-header">
            <div class="card-title d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <h6>گزارش فعالیت {{ isAllUsers ? 'کاربران' : 'کاربر' }}</h6>
                <base-button v-if="false" type="info" class="mx-3" size="sm" @click="addCoin()">افزودن</base-button>
              </div>
              <h6 class="filter-title" @click="filter = !filter">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر
              </h6>
            </div>
            <!-- filter content -->
            <card v-if="filter" class="filter">
              <form @submit.prevent="loadItems">
                <div class="row">
                  <!-- name farsi -->
                  <base-input dir="ltr" v-if="isAllUsers" class="col-12 col-lg-3" label="نام کاربر" type="text" placeholder="نام کاربر" v-model="userFilter"> </base-input>

                  <!-- fromFilter -->
                  <div class="col-12 col-md-6 col-xl-3 date-holder">
                    <base-input class="fromDate" label="از تاریخ" type="text" placeholder="از تاریخ" v-model="fromFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDate('from')" type>
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="fromFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".fromDate" view="year" auto-submit></date-picker>
                  </div>

                  <!-- toFilter -->
                  <div class="col-12 col-md-6 col-xl-3 date-holder">
                    <base-input class="toDate" label="تا تاریخ" type="text" placeholder="تا تاریخ" v-model="toFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDate('to')" type>
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="toFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".toDate" view="year" auto-submit></date-picker>
                  </div>
                  <!-- srchUserLogType -->
                  <div class="col-12 col-lg-3">
                    <span class="cart-lable-color">نوع فعالیت</span>
                    <div class="mt-1">
                      <multiselect
                        :showLabels="false"
                        v-model="userLogType"
                        :options="userLogsType"
                        placeholder="نوع فعالیت"
                        track-by="id"
                        label="value"
                        :searchable="false"
                        :allow-empty="false"
                      ></multiselect>
                    </div>
                  </div>

                  <div class="col-12 d-flex justify-content-between flex-wrap">
                    <div class="d-flex flex-wrap">
                      <base-button class="btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                      <base-button class="btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                    </div>
                    <a href="#" target="_blank"><base-button class="btn-filter" type="info" size="sm">دانلود فایل</base-button></a>
                  </div>
                </div>
              </form>
            </card>
          </div>
          <!-- the page content  -->
          <div>
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="columns"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- type field  -->
                <span v-if="props.column.field == 'logoUrl'">
                  <img :src="props.row.logoUrl" alt="copy" class="logo-coin" />
                </span>

                <!-- type field  -->
                <span v-else-if="props.column.field == 'type'" v-html="getTypeLabel(props.row.type)"> </span>

                <!-- type field  -->
                <span v-else-if="props.column.field == 'edit'">
                  <base-button class="" type="primary" size="sm" @click="edit(props.row.symbol)">ویرایش</base-button>
                </span>

                <!-- status field  -->
                <span v-else-if="props.column.field == 'state'" v-html="getStatusLabel(props.row.state)"> </span>

                <!-- userName -->
                <a v-else-if="props.column.field == 'userName'" :href="'/#/dashboard/users/' + props.row.userId + '/personal-info'" target="_blank">
                  <span dir="ltr"> {{ props.row.userName }} </span></a
                >

                <!-- logTime -->
                <span v-else-if="props.column.field == 'logTime'"> {{ jdate(props.row.logTime) }}</span>

                <!-- value -->
                <span v-else-if="props.column.field == 'value'"> {{ newNumberFormat(props.row.value) }}</span>

                <!-- balanceAfter -->
                <span v-else-if="props.column.field == 'balanceAfter'"> {{ newNumberFormat(props.row.balanceAfter) }}</span>

                <!-- TMN_value field  -->
                <span v-else-if="props.column.field == 'TMN'">
                  {{ showValue(props.row.lstEstimatedValues, 'TMN') }}
                </span>

                <!-- USDT field  -->
                <span v-else-if="props.column.field == 'USDT'">
                  {{ showValue(props.row.lstEstimatedValues, 'USDT') }}
                </span>

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from 'vue-good-table'

/**convert miladi to jalali */
var moment = require('moment-jalaali')

// Multiselect
import Multiselect from 'vue-multiselect'

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

/**import mixin */
import Helper from '../../../settings/Helper'

export default {
  name: 'UserLogComponent',
  mixins: [Helper],
  props: {
    columns: {
      type: Array,
      default: () => [
        {
          label: 'نام کاربر',
          field: 'userName',
          sortable: false
        },
        {
          label: 'فعالیت',
          field: 'typeName',
          sortable: false
        },
        {
          label: 'iPv4',
          field: 'iPv4',
          sortable: false
        },
        {
          label: 'iPv6',
          field: 'iPv6',
          sortable: false
        },
        {
          label: ' زمان ثبت',
          field: 'logTime',
          sortable: false
        },

      ]
    },
    isAllUsers: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      selectedNetwork: null,
      address: null,
      selectedCoin: null,
      coins: [],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: window.env.CountItemInPerPage,
        search: null
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: window.env.PerPageDropdown,
        dropdownAllowAll: false
      },
      // filter==true => show filter card
      filter: true,
      userFilter: null,
      fromFilter: null,
      toFilter: null,
      userLogType: null,

    }
  },
  methods: {

    /**reset search and search filters */
    resetSearch() {
      this.userFilter = this.toFilter = this.fromFilter = this.userLogType = null

      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null
      this.loadItems()
    },
    /**clear from and to date filter */
    eraseDate(type) {
      type == 'from' ? this.fromFilter = null : this.toFilter = null
    },


    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage
        let offset = this.serverParams.perPage * (this.serverParams.page - 1)
        this.isLoading = true

        /**handle sorts */
        let sort = ''
        if (this.serverParams.sort[0].field) {
          /**check sort asc or desc */
          let sortType = this.serverParams.sort[0].type == 'asc' ? '' : '-'
          sort = this.serverParams.sort[0].field ? '&ordering=' + sortType + this.serverParams.sort[0].field : ''
        }

        let url = `Organizer/Report/UserLog?PageNumber=${this.serverParams.page}&PageSize=${perPage}`
        let data = [
          {
            key: "srchUser",
            value: this.userFilter ? this.userFilter : ''
          },
          {
            key: "srchDateFrom",
            value: this.fromFilter ? moment(this.fromFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
          },
          {
            key: "srchDateTo",
            value: this.toFilter ? moment(this.toFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
          },
          {
            key: "srchUserLogType",
            value: this.userLogType ? this.userLogType.id.toString() : null
          }
        ]

        let res = await this.$axios({
          method: 'post',
          url: this.isAllUsers ? url : `Organizer/User/GetUserLog?PageNumber=${this.serverParams.page}&PageSize=${perPage}`,
          data: this.isAllUsers ? data : {
            userId: this.$router.history.current.params.id,
            searchStatements: [

              {
                key: "srchDateFrom",
                value: this.fromFilter ? moment(this.fromFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
              },
              {
                key: "srchDateTo",
                value: this.toFilter ? moment(this.toFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
              },
              {
                key: "srchUserLogType",
                value: this.userLogType ? this.userLogType.id.toString() : null
              }
            ]
          }
        })
        this.totalRecords = res.data.totalRecords
        this.rows = res.data.data
        this.isLoading = false

      } catch (error) {
        this.totalRecords = 0
        this.rows = null
        this.isLoading = false
        console.log(error)
      }
    }
  },
  async mounted() {
    this.$rtl.enableRTL()


    /** get getUserLogType */
    this.$store.dispatch('getUserLogType')

    /**get orders */
    this.loadItems()
  },
  components: {
    VueGoodTable,
    datePicker: VuePersianDatetimePicker,
    Multiselect
  },
  computed: {
    userLogsType: {
      get() {
        return this.$store.state.userLogType
      },
      set() {
        return
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';

.multiselect__content-wrapper {
  .multiselect__content {
    padding: 0 0 70px 0;
  }
}
.logo-coin {
  width: 27px !important;
}
</style>
