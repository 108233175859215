<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="card-title d-flex flex-column flex-sm-row justify-content-between">
              <div>
                <h6 class="d-inline">پاداش ها</h6>
              </div>

              <h6 class="filter-title" @click="filter = !filter">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر پاداش ها
              </h6>
            </div>

            <!-- filter content -->
            <card v-if="filter" class="filter">
              <form @submit.prevent="search">
                <!-- valueFilter -->
                <div class="row">
                  <!-- uid Filter -->
                  <base-input class="col-12 col-md-6 col-xl-4" label="کد معرف" type="text" placeholder="کد معرف" v-model="uidFilter"> </base-input>

                  <!-- username Filter -->
                  <base-input class="col-12 col-md-6 col-xl-4" label="همراه" type="text" placeholder="همراه" v-model="usernameFilter"> </base-input>

                  <!-- ReferId Filter -->
                  <base-input class="col-12 col-md-6 col-xl-4" label="شناسه کاربر معرفی شده" type="text" placeholder="شناسه کاربر معرفی شده" v-model="referIdFilter"> </base-input>

                  <!-- checkbox confirmed -->
                  <div class="col-4 d-flex">
                    <base-checkbox v-model="confirmedFilter">تایید شده</base-checkbox>
                  </div>

                  <!-- checkbox not confirmed -->
                  <div class="col-4 d-flex">
                    <base-checkbox v-model="notConfirmedFilter">تایید نشده</base-checkbox>
                  </div>

                  <!-- btn submit and reset-->
                  <div class="col-12">
                    <!-- btn  submit-->
                    <base-button class="mt-3 btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                    <!-- btn  reset-->
                    <base-button class="mt-3 mx-0 mx-sm-3 btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                  </div>
                </div>
              </form>
            </card>
          </div>

          <!-- the page content  -->
          <div class="card-body">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="columns"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- value field  -->
                <span v-if="props.column.field == 'value'">
                  <span dir="ltr" :class="props.row.value > 0 ? 'badge badge-success' : 'badge badge-danger'">
                    {{ numberWithCommas(props.row.value) }}
                  </span>
                </span>

                <!-- created field  -->
                <span v-else-if="props.column.field == 'created'">
                  {{ jdate(props.row.created) }}
                </span>

                <!-- confirmed field  -->
                <span v-else-if="props.column.field == 'confirmed'" v-html="getStatusLabel(props.row.confirmed)"></span>

                <!-- actions field  -->
                <span v-else-if="props.column.field == 'actions' && !props.row.confirmed">
                  <base-button type="primary" size="sm" @click="confirmLedger(props.row)">تایید کردن</base-button>
                </span>

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from "vue-good-table";


/**import mixin */
import Helper from "../../../settings/Helper";

export default {
  name: "user-history",
  mixins: [Helper],
  data() {
    return {
      /**filters states */
      uidFilter: null,
      confirmedFilter: true,
      notConfirmedFilter: true,
      usernameFilter : null ,
      referIdFilter : null ,

      filter: true,
      columns: [
        {
          label : "کد معرف", 
          field : "user.uid" , 
          sortable: false,
        },
        {
          label : "شناسه کاربر معرفی شده" , 
          field : "refer_id" ,
          sortable: false,
        },
        {
          label : "همراه", 
          field : "user.username" , 
          sortable: false,
        },
        {
          label: "توضیحات",
          field: "description",
          sortable: false,
        },
        {
          label: "شماره فاکتور",
          field: "refer_id",
          sortable: false,
        },
        {
          label: "وضعیت",
          field: "confirmed",
          sortable: false,
        },
        {
          label: "مقدار",
          field: "value",
          html: true,
          sortable: false,
        },
        {
          label: "تاریخ",
          field: "created",
          sortable: false,
        },
        {
          label: "عملیات",
          field: "actions",
          sortable: false,
        },
      ],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 10,
        search : null
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: "بعدی",
        prevLabel: "قبلی",
        firstLabel: "اولین",
        lastLabel: "آخرین",
        rowsPerPageLabel: "تعداد ردیف هر صفحه",
        ofLabel: "از",
        pageLabel: "صفحه", // for 'pages' mode
        allLabel: "همه",
        perPageDropdown: [10, 20, 30, 40, 50],
        dropdownAllowAll: false,
      },
    }
  },
  methods: {
    /**handle search filters */
    search() {
      let confirm , uidFilter, confirmedFilter, notConfirmedFilter, usernameFilter, referIdFilter
      uidFilter = confirm = confirmedFilter = notConfirmedFilter = usernameFilter  = referIdFilter = ''

      /**uid */
      if (this.uidFilter) {
        uidFilter = '&user__uid=' + this.uidFilter
      }

      /**username */
      if (this.usernameFilter) {
        usernameFilter = '&user__username=' + this.usernameFilter
      }

      /**ReferId */
      if (this.referIdFilter) {
        referIdFilter = '&refer_id=' + this.referIdFilter
      }

      /**check confirm status */
      confirm = ""
      this.confirmedFilter ? confirm = '&confirmed=true' : ""
      this.notConfirmedFilter ? confirm = '&confirmed=false' : ""
      this.confirmedFilter && this.notConfirmedFilter ? confirm = "" : ""

      this.serverParams.search = uidFilter + usernameFilter + referIdFilter +  confirm 
      this.loadItems()
    },

    /**reset search filters */
    resetSearch(){
      this.uidFilter          = null
      this.usernameFilter     = null
      this.referIdFilter      = null
      this.confirmedFilter    = true
      this.notConfirmedFilter = true
      this.search()
    },

    /**confirm ladger */
    async confirmLedger(row) {
      this.$confirm(
        {
          message: `از تایید این تراکنش مطمئن هستید؟`,
          button: {
            no: 'خیر',
            yes: 'بله'
          },
          /**
          * Callback Function
          * @param {Boolean} confirm
          */
          callback: async confirm => {
            if (confirm) {
              try {
                await this.$axios({ method: 'post', url: `accountings/deposits/verify/${row.id}` })
                this.$notify({ text: 'با موفقیت انجام شد', type: 'success' })
                this.loadItems()
              } catch (error) {
                conosle.log(error)
              }
            }
          }
        }
      )
    },
  

    /**
     * @param status
     * @return string the translated of status
     */
    getStatusLabel(status) {
      if (status) return '<span class="badge badge-success">تایید شده</span>'

      return '<span class="badge badge-danger">تایید نشده</span>'
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let search = this.serverParams.search ? `${this.serverParams.search}` : ""
        let perPage = this.serverParams.perPage;
        let offset = this.serverParams.perPage * (this.serverParams.page - 1);
        this.isLoading = true;
        let res = await this.$axios({
          method: "get",
          url: `ledger/?credit_type=R&limit=${perPage}&ordering=-created&offset=${offset}${search}`
        });

        this.totalRecords = res.data.count
        this.rows = res.data.results
        this.isLoading = false
      } catch (error) {
        console.error(error)
      }
    },
  },
  async mounted() {
    this.$rtl.enableRTL();

    /**get orders */
    try {
      this.isLoading = true;
      let res = await this.$axios({
        method: "get",
        url: `ledger/?limit=10&offset=0&credit_type=R&ordering=-created`,
      });
      this.totalRecords = res.data.count
      this.rows = res.data.results
      this.isLoading = false;
    } catch (error) {
      console.error(error);
    }
  },
  components: {
    VueGoodTable,
  },
}
</script>

<style>
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';
</style>
