<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <!-- title and filter -->
          <div class="card-header">
            <div class="card-title d-flex flex-column flex-sm-row justify-content-between">
              <div class="d-flex align-items-center">
                <h6>لیست اعلانات</h6>
                <base-button type="info" class="mx-3" size="sm" @click="addNotification()">افزودن</base-button>
              </div>
              <h6 class="filter-title" @click="filter = !filter" v-if="false">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر
              </h6>
            </div>
            <!-- filter content -->
            <card v-if="filter" class="filter">
              <form @submit.prevent="loadItems">
                <div class="row">
                  <!-- name farsi -->
                  <base-input v-if="false" class="col-12 col-lg-4" label="نام" type="text" placeholder="نام" v-model="nameFilter"> </base-input>

                  <!-- symbol -->
                  <base-input class="col-12 col-lg-4" label="symbol" type="text" placeholder="symbol" v-model="symbolFilter"> </base-input>

                  <!-- type -->
                  <div class="col-12 col-lg-4" v-if="false">
                    <span class="cart-lable-color">نوع</span>
                    <div class="mt-1">
                      <multiselect
                        :showLabels="false"
                        v-model="typeFilter"
                        :options="typeFilters"
                        placeholder="نوع کویین"
                        track-by="code"
                        label="lable"
                        :searchable="false"
                        :allow-empty="false"
                      ></multiselect>
                    </div>
                  </div>

                  <div class="col-12 col-lg-3">
                    <base-button class="btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                    <base-button class="btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                  </div>
                </div>
              </form>
            </card>
          </div>
          <!-- the page content  -->
          <div>
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="columns"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- type field  -->
                <span v-if="props.column.field == 'logoUrl'">
                  <img :src="props.row.logoUrl" alt="copy" class="logo-coin" />
                </span>

                <!-- type field  -->
                <span v-else-if="props.column.field == 'edit'">
                  <base-button class="" type="primary" size="sm" @click="edit(props.row.id)">ویرایش</base-button>
                </span>

                <!-- type field  -->
                <span v-else-if="props.column.field == 'userSet'">
                  <base-button class="" type="success" size="sm" @click="userSet(props.row.id)">اعمال</base-button>
                </span>

                <!-- status field  -->
                <span v-else-if="props.column.field == 'isDisabled'" v-html="getStatusLabel(props.row.isDisabled)"> </span>

                <!-- delete field  -->
                <span v-else-if="props.column.field == 'delete'">
                  <base-button class="" type="danger" size="sm" @click="showModal(props.row.id, props.row.title)">حذف</base-button>
                </span>

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>

    <!-- modal confirm delete-->
    <modal name="removeItem" :click-to-close="false">
      <div class="title d-flex justify-content-between align-items-center">
        <h6>حذف "{{ titleRemove }}" انجام شود ؟</h6>
        <div v-if="!isLoading" class="d-flex align-items-center justify-content-end">
          <base-button type="success" @click="remove()">تایید </base-button>
          <base-button type="danger" @click="hideModal()"> انصراف</base-button>
        </div>
        <!-- loader -->
        <div v-else class="">
          <div class="loader-wait-request mx-5"></div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from 'vue-good-table'

// Multiselect
import Multiselect from 'vue-multiselect'

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

/**import mixin */
import Helper from '../../../settings/Helper'

export default {
  name: 'Addresses',
  mixins: [Helper],
  data() {
    return {

      idRemove: null,
      titleRemove: null,


      selectedNetwork: null,
      address: null,
      selectedCoin: null,
      coins: [],
      columns: [

        {
          label: 'عنوان',
          field: 'title',
          sortable: false
        },
        {
          label: 'نوع قالب',
          field: 'themeType',
          sortable: false
        },
        {
          label: 'دلیل',
          field: 'reasonType',
          sortable: false
        },
        {
          label: 'وضعیت',
          field: 'isDisabled',
          sortable: false
        },
        {
          label: 'ویرایش',
          field: 'edit',
          sortable: false
        },
        {
          label: 'حذف',
          field: 'delete',
          sortable: false
        },

      ],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: window.env.CountItemInPerPage,
        search: null
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: window.env.PerPageDropdown,
        dropdownAllowAll: false
      },

      // add address
      newNetworkAdd: null,
      newNetworkAdds: [
        { code: 'usdt', lable: 'Usd' },
        { code: 'btc', lable: 'Bitcoin' },
        { code: 'ltc', lable: 'Litecoin' },
        { code: 'dash', lable: 'Dash' },
        { code: 'doge', lable: 'DogeCoin' }
      ],
      newAddress: null,

      // filter==true => show filter card
      filter: false,
      nameFilter: null,
      symbolFilter: null,
      typeFilter: { code: '21', lable: 'Crypto ' },
      typeFilters: [
        { code: '11', lable: 'NonCrypto ' },
        { code: '21', lable: 'Crypto ' }
      ]
    }
  },
  methods: {
    /**show modal remove item 
    * @param int and string of item 
    */
    async showModal(id, title) {
      try {
        this.idRemove = id
        this.titleRemove = title
        this.$modal.show('removeItem')
      } catch (error) {
        console.error(error)
      }
    },

    /**hide modal remove item and reload data*/
    async hideModal() {
      this.$modal.hide('removeItem')
      this.idRemove = null
      this.titleRemove = null
    },

    // remove notification\
    async remove() {
      try {
        if (this.isLoading) return
        await this.$axios({
          url: '/Organizer/Notification/Delete',
          method: 'delete',
          headers: {
            idNotification: this.idRemove
          }

        })

        this.loadItems();
        this.$notify({
          text: " عملیات با موفقیت انجام شد",
          type: "success"
        })

      } catch (error) {
        this.totalRecords = 0
        this.rows = null
        console.log(error)
      }
      this.isLoading = false
      this.hideModal()
    },

    /**
           * @param status
           * @return string the translated of status
           */
    getStatusLabel(status) {
      return !status ? '<span class="badge badge-success">فعال</span>' : '<span class="badge badge-danger"> غیر فعال</span>'
    },


    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },


    /**add reject-reason */
    async addNotification() {
      this.$router.push({ path: 'notification/add', replace: true })
    },

    // edit notification\
    async edit(id) {
      this.$router.push({ path: 'notification/' + id + '/edit', replace: true })
    },

    // user-set notification\
    async userSet(id) {
      this.$router.push({ path: 'notification/' + id + '/user-set', replace: true })
    },


    /** ----------- End modal add address ------------- */

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage
        let offset = this.serverParams.perPage * (this.serverParams.page - 1)
        this.isLoading = true

        /**handle sorts */
        let sort = ''
        if (this.serverParams.sort[0].field) {
          /**check sort asc or desc */
          let sortType = this.serverParams.sort[0].type == 'asc' ? '' : '-'
          sort = this.serverParams.sort[0].field ? '&ordering=' + sortType + this.serverParams.sort[0].field : ''
        }

        let res = await this.$axios({
          url: `/Organizer/Notification/ListGet?PageNumber=${this.serverParams.page}&PageSize=${perPage}`,
          data: [
            {
              "key": "string",
              "value": "string"
            }
          ]
        })
        this.totalRecords = res.data.totalRecords
        this.rows = res.data.data
        this.isLoading = false

      } catch (error) {
        this.totalRecords = 0
        this.rows = null
        this.isLoading = false
      }


    }
  },
  async mounted() {
    this.$rtl.enableRTL()

    /**get orders */
    this.loadItems()
  },
  components: {
    VueGoodTable,
    datePicker: VuePersianDatetimePicker,
    Multiselect
  }
}
</script>

<style lang="scss" scoped>
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';

.multiselect__content-wrapper {
  .multiselect__content {
    padding: 0 0 70px 0;
  }
}
.logo-coin {
  width: 27px !important;
}
</style>
