<template>
  <div class="container-fluid dashboard">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="card-title d-flex justify-content-between">
              <h6>داشبورد</h6>
            </div>
          </div>

          <!-- the page content  -->
          <div class="card-body dashboard-items p-0">
            <div class="container-fluid">
              <!-- dynamic content  -->
              <div class="row">
                <div v-for="(item, index) in rows" :key="index" :class="`col-md-${item.col}`" class="my-2 px-0">
                  <!-- slider is true -->
                  <div v-if="item.slider">
                    <sliderComponent :data="item"></sliderComponent>
                  </div>
                  <!-- slider is false -->
                  <div v-if="!item.slider" class="">
                    <component v-bind:is="item.children[0].type" v-bind="{ data: item.children[0] }"></component>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

// new component
import barChart from './BarChart.vue'
import pieChart from './PieChart.vue'
import areaChart from './AreaChart.vue'
import lineChart from './LineChart.vue'
import pinCard from './CardComponent.vue'
import dashTable from './TableComponent.vue'
import sliderComponent from './SliderComponent.vue'

import 'devextreme/dist/css/dx.dark.css';


export default {
  name: "Dashboard",
  data() {
    return {
      allCoinsNumber: null,
      allOrders: null,
      serverIp: '104.21.25.80',
      OrdersAwaitProcessing: null,
      usersInventory: '845,654,321,654',
      userAwaitChecking: null,
      paymentConfirmation: null,

      // new data
      isLoading: false,
      rows: null,

    }
  },
  methods: {
    // load data
    async loadData() {
      try {
        this.isLoading = true

        // let res = await axios({
        //   method: 'get',
        //   url: `https://api.demond.ir/api/v1/dashboard`
        //   // url: `http://localhost:8000/api/v1/dashboard`
        // });

        let res = await this.$axios({
          url: 'Organizer/Dashboard/AllGet',
          method: 'get'
        })

        this.rows = JSON.parse(String(res.data.data).replaceAll("\r\n", ""))
      } catch (error) {
        this.rows = null
        console.error(error);
      }
      this.isLoading = false
    }
  },
  async mounted() {
    this.$rtl.enableRTL()

    this.loadData()
  },
  components: {
    barChart,
    pieChart,
    areaChart,
    lineChart,
    pinCard,
    dashTable,
    sliderComponent,
  }
}
</script>

<style lang="scss">
@import '/icofont/icofont.min.css';
.dashboard {
  .copy {
    width: 14px !important;
    cursor: pointer;
  }

  .dashboard-items {
    .content {
      background-color: #1e1e2b;
      border-radius: 10px;
      padding: 25px !important;
    }
  }

  .chart-users {
    width: 100%;
  }

  .title-component {
    h3 {
      color: whitesmoke;
    }
  }

  .dxc-title text {
    fill: rgba(255, 255, 255, 0.815) !important;
  }
  // .dxc-legend * {
  //   font-size: 16px !important;
  // }
}
</style>
