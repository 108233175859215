<template>
  <section class="personal-info">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <ValidationObserver ref="contentValidation">
            <!-- card 1 content -->
            <div class="col-12">
              <card>
                <div class="card-header">
                  <div class="card-title d-flex align-items-center justify-content-between">
                    <h6 v-if="id">ویرایش دلایل رد مستندات</h6>
                    <h6 v-else>افزودن دلایل رد مستندات</h6>
                    <!-- isDisabled Confirmation -->
                    <div class="profile d-flex align-items-center justify-content-start my-3">
                      <p class="m-3">وضعیت</p>
                      <div class="switch">
                        <input type="checkbox" class="toggle" v-model="isDisabled" />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- the page content  -->
                <div class="card-body">
                  <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between my-3">
                    <!-- title -->
                    <div class="date-modify w-75 mx-2">
                      <ValidationProvider name="عنوان" rules="required" v-slot="{ errors }">
                        <span> عنوان</span>
                        <input type="text" v-model="title" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <!--  sort -->
                    <div class="date-created w-25">
                      <ValidationProvider name="اولویت نمایش" rules="required" v-slot="{ errors }">
                        <span>اولویت نمایش</span>
                        <input type="text" v-model="sort" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </ValidationObserver>

          <div class="col-12">
            <div class="submit d-flex justify-content-start align-items-center pt-3">
              <button class="btn-primary mx-1" @click="submit" v-if="!isLoading">ذخیره</button>
              <div v-else class="loader-wait-request mx-5"></div>

              <button class="btn-light mx-1" @click="$router.go(-1)">انصراف</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>


/**import mixin */
import Helper from '../../../settings/Helper'


import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate'
import { numeric, required, double } from 'vee-validate/dist/rules'
import fa from 'vee-validate/dist/locale/fa'
extend('required', required)
extend('numeric', numeric)
extend('double', double)

localize('fa', fa)

export default {
  name: 'UserPersonalInfo',
  mixins: [Helper],
  data() {
    return {
      id: null,
      sort: null,

      title: null,

      isDisabled: null,
      isLoading: false,

    }
  },
  methods: {

    // submit save
    async submit() {
      try {
        /**validate the form */
        if (! await this.$refs.contentValidation.validate()) return this.$notify({ text: 'لطفا خطاهای رخ داده را برطرف نمایید', type: 'error' })

        this.isLoading = true

        let data = {
          id: parseInt(this.id ? this.id : 0),
          sort: parseInt(this.sort),
          title: this.title,
          idLang: 1065,
          isDisabled: !this.isDisabled
        }

        await this.$axios({
          url: 'Organizer/RejectReason/AddOrUpdate',
          data: data
        })

        this.$notify({
          text: 'عملیات با موفقیت انجام شد',
          type: 'success'
        })

        this.$router.push({ path: '/dashboard/reject-reason', replace: true })
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    },

    // load date for edit
    async loadData() {
      try {
        // this.idCoin = 

        let res = await this.$axios({
          method: 'get',
          url: 'Organizer/RejectReason/GetToManage',
          headers: {
            idDocsRejectReason: this.id
          }
        })

        this.sort = res.data.data.sort
        this.isDisabled = !res.data.data.isDisabled
        this.title = res.data.data.title

      } catch (error) {
        console.error(error)
      }
    },

  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  async mounted() {
    this.$rtl.enableRTL()

    // load date for edit
    this.id = this.$router.history.current.params.id
    if (this.id)
      this.loadData()

    /**get user info */
  },
  computed: {
    minAgeDate: () => {
      return moment().subtract(18, 'year').format('jYYYY/jM/jD') // somthing like 1382/10/05
    },
    maxAgeDate: () => {
      return moment().subtract(90, 'year').format('jYYYY/jM/jD') // somthing like  1310/10/05
    }
  }
}
</script>

<style lang="scss" scoped>
.personal-info {
  svg {
    cursor: pointer;
  }
  .multiselect {
    border: none !important;
    border-bottom: 1px solid #ffffff !important;
    border-radius: 0;
    padding-bottom: 10px;
  }
  input[type='text'] {
    background-color: transparent !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    color: #ffffeb;
    &:disabled {
      color: #9b9b83;
    }
  }
  textarea {
    background-color: transparent !important;
    border: 1px solid #ffffeb;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    direction: ltr;
    color: #ffffeb67;
    padding-top: 10px;
    padding-bottom: 4px;
    padding-left: 10px;
    &:focus-visible {
      outline: none !important;
      color: #ffffeb;
    }
  }
  .flexible {
    border-bottom: 2px solid #ffffff !important;
  }
  .change {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #74839b;
    margin: 0 auto 0 0;
    cursor: pointer;
    &:hover {
      background-color: #445877;
      svg {
        fill: #74839b;
      }
    }
  }
  .switch {
    input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      &:focus {
        outline: 0;
      }
    }

    .toggle {
      height: 23px;
      width: 50px;
      border-radius: 30px;
      display: inline-block;
      position: relative;
      margin: 0;
      border: 2px solid #474755;
      background: linear-gradient(180deg, #2d2f39 0%, #1f2027 100%);
      transition: all 0.2s ease;
      &:after {
        content: '';
        position: absolute;
        top: -4px;
        left: -2px;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background: #5c6e8b;
        box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
        transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
      }
      &:checked {
        border-color: #4f8bec;
        &:after {
          transform: translatex(20px);
          left: 2px;
          background: #4982df;
        }
      }
    }
  }
  .submit {
    button {
      padding: 10px 35px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }
  }
}
.logoUrl-coin {
  width: 70px !important;
}
</style>
