<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="card-title d-flex justify-content-between">
              <h6>گزارش روزانه</h6>
              <h6 class="filter-title" @click="filter = !filter" v-if="false">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر معرف ها
              </h6>
            </div>
            <!-- filter content -->
            <card v-if="false" class="filter">
              <form @submit.prevent="search">
                <!-- introducedFilter -->
                <div class="row">
                  <base-input class="col-md-3" label="معرف" type="text" placeholder="معرف" v-model="introducedFilter"> </base-input>

                  <!-- userFilter -->
                  <base-input label="کاربر" type="text" placeholder="کاربر" class="col-md-3" v-model="userFilter"> </base-input>

                  <!-- userFilter -->
                  <base-input label="کمیسیون" type="text" placeholder="کمیسیون" class="col-md-3" v-model="commissionFilter"> </base-input>

                  <!-- dateFilter -->
                  <div class="col-md-3 date-holder">
                    <base-input class="dateFilter" label="تاریخ" type="text" placeholder="تاریخ" v-model="dateFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraserDateFilter">
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="dateFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".dateFilter" view="year" auto-submit></date-picker>
                  </div>

                  <!-- btn submit and reset-->
                  <div class="col-12">
                    <!-- btn  submit-->
                    <base-button class="mt-3 btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                    <!-- btn  reset-->
                    <base-button class="mt-3 mx-0 mx-sm-3 btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                  </div>
                </div>
              </form>
            </card>
          </div>
          <!-- the page content  -->
          <div class="card-body">
            <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :isLoading.sync="isLoading" :pagination-options="paginationOptions" :totalRows="totalRecords" :rows="rows" :columns="columns" :rtl="true" :line-numbers="true" styleClass="table tablesorter">
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- total_value field  -->
                <span v-if="props.column.field == 'total_value'">
                  {{ numberWithCommas(props.row.total_value) }}
                </span>

                <!-- date field  -->
                <span v-else-if="props.column.field == 'date'">
                  {{ jdate(props.row.date) }}
                </span>

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from 'vue-good-table'

/**import mixin */
import Helper from '../../../settings/Helper'

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

export default {
  name: 'Referrals',
  mixins: [Helper],
  data() {
    return {
      columns: [
        {
          label: 'کل پرداختی',
          field: 'total_value',
          sortable: true
        },
        {
          label: 'جمع دعودت شدگان',
          field: 'referrals',
          sortable: true
        },
        {
          label: 'تاریخ',
          field: 'date',
          sortable: true
        }
      ],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: 10,
        search: null
      },
      paginationOptions: {
        enabled: false,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: [10, 20, 30, 40, 50],
        dropdownAllowAll: false
      },

      // filter==true => show filter card
      filter: true,
      introducedFilter: null,
      userFilter: null,
      commissionFilter: null,
      dateFilter: null
    }
  },
  methods: {
    /**handle search filters */
    search() {
      let userFilter, introducedFilter, dateFilter, commissionFilter
      userFilter = introducedFilter = dateFilter = commissionFilter = ''

      /** userFilter  */
      if (this.userFilter) {
        userFilter = '&user_id=' + this.userFilter
      }

      /** introducedFilter  */
      if (this.introducedFilter) {
        introducedFilter = '&introduced_id=' + this.introducedFilter
      }

      /** commissionFilter  */
      if (this.commissionFilter) {
        commissionFilter = '&commission=' + this.commissionFilter
      }

      /** dateFilter  */
      if (this.dateFilter) {
        dateFilter = '&created=' + this.dateFilter
      }

      this.serverParams.search = userFilter + introducedFilter + dateFilter + commissionFilter

      this.loadItems()
    },

    /**reset search and search filters */
    resetSearch() {
      this.userFilter = this.introducedFilter = this.dateFilter = this.commission = null

      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null
      this.loadItems()
    },

    /** eraser input Date Filter */
    eraserDateFilter() {
      this.dateFilter = null
    },

  
    /**
     * @param status
     * @return string the translated of status
     */
    getStatusLabel(status) {
      switch (status) {
        case 'PENDING':
          return '<span class="badge badge-info">معلق</span>'
        case 'OPEN':
          return '<span class="badge badge-primary">باز</span>'
        case 'SUCCESS':
          return '<span class="badge badge-success">موفق</span>'
        default:
          return status
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage
        let offset = this.serverParams.perPage * (this.serverParams.page - 1)
        this.isLoading = true

        /**handle sorts */
        let sort = ''
        if (this.serverParams.sort[0].field) {
          /**check sort asc or desc */
          let sortType = this.serverParams.sort[0].type == 'asc' ? '' : '-'
          sort = this.serverParams.sort[0].field ? '&ordering=' + sortType + this.serverParams.sort[0].field : ''
        }

        let search = ''
        if (this.serverParams.search) {
          search = this.serverParams.search
        }

        let res = await this.$axios({
          method: 'get',
          url: 'referral/?limit=' + perPage + '&offset=' + offset + sort + search
        })
        this.totalRecords = res.data.count
        this.rows = res.data.results
        this.isLoading = false
      } catch (error) {
        console.error(error)
      }
    }
  },
  async mounted() {
    this.$rtl.enableRTL()

    /**get orders */
    try {
      this.isLoading = true
      let res = await this.$axios({
        method: 'get',
        url: 'referals'
      })
      this.totalRecords = res.data.count
      this.rows = res.data.data
      this.isLoading = false
    } catch (error) {
      console.error(error)
    }
  },
  components: {
    VueGoodTable,
    datePicker: VuePersianDatetimePicker
  }
}
</script>

<style>
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';
.network {
  text-transform: uppercase;
}
</style>
