<template>
  <div id="chart-demo" class="my-3">
    <!-- header -->
    <div class="title-component d-flex align-items-center mb-3">
      <i :class="data.icon"></i>
      <h3 class="my-2">{{ data.title }}</h3>
    </div>
    <DxChart id="chart" :data-source="data.data.data" :palette="data.options.color ? data.options.color : null" :title="data.options.title">
      <DxCommonSeriesSettings :type="type" argument-field="step" />
      <DxCommonAxisSettings>
        <DxGrid v-if="data.options.grid" :visible="true" />
      </DxCommonAxisSettings>
      <DxSeries v-for="(item, index) in data.data.param" :key="index" :value-field="item.key" :name="item.value" />
      <DxMargin :bottom="20" />
      <DxArgumentAxis :allow-decimals="false" :axis-division-factor="60">
        <DxLabel>
          <DxFormat type="decimal" />
        </DxLabel>
      </DxArgumentAxis>
      <DxLegend vertical-alignment="right" horizontal-alignment="right" />
      <DxExport v-if="data.options.export" :enabled="true" />
      <DxTooltip :enabled="true" />
    </DxChart>
    <div class="options" v-if="false">
      <div class="caption">Options</div>
      <div class="option">
        <span>Series Type</span>
        <DxSelectBox :data-source="types" v-model:value="type" />
      </div>
    </div>
  </div>
</template>
<script>

import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxCommonAxisSettings,
  DxGrid,
  DxExport,
  DxLegend,
  DxMargin,
  DxTooltip,
  DxLabel,
  DxFormat,
} from 'devextreme-vue/chart';
import DxSelectBox from 'devextreme-vue/select-box';


export default {
  name: 'lineChart',
  props: ['data'],
  components: {
    DxSelectBox,
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxCommonAxisSettings,
    DxGrid,
    DxExport,
    DxLegend,
    DxMargin,
    DxTooltip,
    DxLabel,
    DxFormat,
  },
  mounted() {
    console.log(this.data.data.data);
  },
  data() {
    return {
      types: ['spline', 'stackedspline', 'fullstackedspline'],
      type: 'spline',
    };
  },
};
</script>
<style>
.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-right: 20px;
}

.option {
  margin-top: 10px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option > span {
  margin-right: 14px;
}

.option > .dx-widget {
  display: inline-block;
  vertical-align: middle;
}
</style>