<template>
  <div class="container-fluid dashboard-slider">
    <!-- the page content  -->
    <div class="container-fluid">
      <!-- slider -->
      <div v-for="(contentSlider, indexSlider) in data.children" :key="indexSlider">
        <component v-bind:is="contentSlider.type" v-bind="{ data: contentSlider }" v-if="sliderActived == indexSlider"></component>
      </div>
      <!-- paginate -->
      <div class="d-flex justify-content-center">
        <span v-for="(contentSlider, indexActive) in data.children" :key="indexActive" @click="sliderActived = indexActive" class="slider-number" :class="{ 'slider-active': indexActive == sliderActived }">{{ indexActive + 1 }}</span>
      </div>
    </div>
  </div>
</template>

<script>

// new component
import barChart from './BarChart.vue'
import pieChart from './PieChart.vue'
import areaChart from './AreaChart.vue'
import lineChart from './LineChart.vue'
import pinCard from './CardComponent.vue'
import dashTable from './TableComponent.vue'

export default {
  name: "dashboardSlider",
  props: ['data'],
  data() {
    return {
      allCoinsNumber: null,
      allOrders: null,
      serverIp: '104.21.25.80',
      OrdersAwaitProcessing: null,
      usersInventory: '845,654,321,654',
      userAwaitChecking: null,
      paymentConfirmation: null,

      // new data
      isLoading: false,
      rows: null,
      sliderActived: 0

    }
  },
  components: {
    barChart,
    pieChart,
    areaChart,
    lineChart,
    pinCard,
    dashTable
  }
}
</script>

<style lang="scss">
.dashboard-slider {
  .slider-number {
    color: white;
    width: 35px;
    height: 35px;
    border-radius: 25%;
    background-color: #393b55;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .slider-active {
    background-color: orange !important;
    margin: 0 5px;
  }
}
</style>
