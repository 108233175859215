<template>
  <div class="col-12 dynamic-tabel my-3">
    <!-- header -->
    <div class="title-component d-flex align-items-center mb-3">
      <i :class="data.icon"></i>
      <h3 class="my-2">{{ data.title }}</h3>
    </div>
    <table :style="data.options.tableStyle">
      <thead>
        <tr :style="data.options.thStyle">
          <th v-for="(head, index) in data.data.header" :key="index" :style="head.style ? head.style : ''">
            <div v-html="head.html"></div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(body, index) in data.data.body" :key="index + 'tr'" :style="data.options.tdStyle">
          <td v-for="(item, indexTd) in body" :key="indexTd + 'td'" :style="item.style ? item.style : ''">
            <div v-html="item.html"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  props: ['data'],
};
</script>
<style scoped lang='scss'>
.dynamic-tabel {
  overflow-x: auto;
  table {
    width: 100%;
    border-collapse: collapse;
    tr,
    td,
    th {
      text-align: center;
    }
    thead {
      background-color: var(--header);
      tr {
        th {
          padding: 10px 20px;
          min-width: 80px;
          white-space: nowrap;
          font-size: 15px;
          color: whitesmoke;
          &:first-child {
            text-align: right;
          }
        }
      }
    }
    tbody {
      tr:not(:last-child) {
        border-bottom: 2px solid #84848432;
      }
      tr {
        cursor: pointer;
        &:hover {
          background-color: rgba(245, 245, 245, 0.13);
        }
        td {
          padding: 10px 20px;
          min-width: 80px;
          white-space: nowrap;
          color: rgba(240, 248, 255, 0.603);
        }
      }
      //**level name */
      img {
        width: 25px !important;
      }
      span {
        margin: 0 5px 0 0;
      }

      //** award */
      .award {
        padding: 0 0 0 15px;
        img {
          width: 80px !important;
          padding: 2%;
        }
      }
    }
  }
}
</style>