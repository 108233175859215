<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="card-title d-flex flex-column flex-sm-row justify-content-between">
              <h6>لیست پرداخت های دستی</h6>
              <h6 class="filter-title" @click="filter = !filter">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر پرداخت ها
              </h6>
            </div>
            <!-- filter content -->
            <card v-if="filter" class="filter">
              <form @submit.prevent="search">
                <!-- valueFilter -->
                <div class="row ">
                  <base-input class="col-12 col-lg-4" label="مقدار" type="text" placeholder="مقدار" v-model="valueFilter"> </base-input>

                  <!-- descFilter -->
                  <base-input label="توضیحات" type="text" placeholder="توضیحات" class="col-12 col-lg-4" v-model="descFilter"> </base-input>

                  <!-- refer_idFilter -->
                  <base-input class="col-12 col-lg-4" label="شماره فاکتور" type="text" placeholder="شماره فاکتور" v-model="invoiceIdFilter"> </base-input>

                  <!-- date -->
                  <div v-if="false" class="col-xl-2 col-md-4 date-holder">
                    <base-input class="dateFilter" label="تاریخ" type="text" placeholder="تاریخ" v-model="dateFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDateFilter">
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="dateFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".dateFilter" view="year" auto-submit></date-picker>
                  </div>

                  <!-- checkbox statusFilter -->
                  <div class="col-12 d-flex">
                    <base-checkbox v-model="statusFilter">وضعیت</base-checkbox>
                  </div>

                  <!-- btn submit and reset-->
                  <div class="col-12">
                    <!-- btn  submit-->
                    <base-button class="mt-3 btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                    <!-- btn  reset-->
                    <base-button class="mt-3 mx-0 mx-sm-3 btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                  </div>
                </div>
              </form>
            </card>
          </div>

          <!-- the page content  -->
          <div class="card-body">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="columns"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- user field  -->
                <span v-if="props.column.field == 'user'">
                  <a :href="'/dashboard/users/' + props.row.user.id">{{ props.row.user.username }}</a>
                </span>

                <!-- value field  -->
                <span v-else-if="props.column.field == 'value'">
                  <span dir="ltr" :class="props.row.value > 0 ? 'badge badge-success' : 'badge badge-danger'">
                    {{ numberWithCommas(props.row.value) }}
                  </span>
                </span>

                <!-- confirmed field  -->
                <span v-else-if="props.column.field == 'confirmed'">
                  <span :class="props.row.confirmed ? 'badge badge-success' : 'badge badge-danger'">{{ props.row.confirmed ? 'موفق' : 'ناموفق' }}</span>
                </span>

                <!-- created field  -->
                <span v-else-if="props.column.field == 'created'">
                  {{ jdate(props.row.created) }}
                </span>

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>

    <!-- modal confirmStatusLable  -->
    <modal name="confirmStatusLable">
      <div class="title text-center">
        <p>تائید وضعیت پرداخت شماره تراکنش {{ confirmStatusLable }}</p>
      </div>

      <div class="d-flex align-items-center justify-content-around mt-4">
        <base-button type="success" @click="submitConfirmStatusLable">تایید پرداخت</base-button>
        <base-button type="danger" @click="hideModal()"> انصراف</base-button>
      </div>
      <!-- confirmation -->
    </modal>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from 'vue-good-table'

/**import mixin */
import Helper from '../../../settings/Helper'

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

export default {
  name: 'Ledger',
  mixins: [Helper],
  data() {
    return {
      columns: [
        {
          label: 'مقدار',
          field: 'value',
          sortable: true
        },
        {
          label: 'توضیحات',
          field: 'description',
          sortable: false
        },
        {
          label: 'شماره فاکتور',
          field: 'refer_id',
          sortable: false
        },
        {
          label: 'وضعیت',
          field: 'confirmed',
          sortable: true,
          html: true
        },
        {
          label: 'تاریخ',
          field: 'created',
          sortable: true
        },
      ],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: 10,
        search: null
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: [10, 20, 30, 40, 50],
        dropdownAllowAll: false
      },

      confirmStatusLable: null,
      confirmStatus: null,

      // filter==true => show filter card
      filter: true,
      valueFilter: null,
      descFilter: null,
      invoiceIdFilter: null,
      statusFilter: true,
      dateFilter: null
    }
  },
  methods: {
    /** show modal confirm status */
    async showModal(invoiceId, data) {
      this.confirmStatusLable = invoiceId
      this.confirmStatus = data
      this.$modal.show('confirmStatusLable')
    },

    /**hide changeStatus modal */
    hideModal() {
      this.confirmStatusLable = this.confirmStatus = null
      this.$modal.hide('confirmStatusLable')
    },

    /** change status tarakonesh */
    async submitConfirmStatusLable() {
      try {
        this.isLoading = true
        let data = {
          status: true,
          amount: this.confirmStatus.amount,
          bill_id: this.confirmStatus.bill_id,
          invoice_id: this.confirmStatus.invoice_id
        }
        let res = await this.$axios({
          method: 'put',
          url: 'payments/' + this.confirmStatus.id + '/',
          data: data
        })

        this.$notify({
          text: ' وضعیت تراکنش ' + this.confirmStatusLable + ' تغییر کرد',
          type: 'success'
        })

        this.isLoading = false

        this.loadItems()
        this.hideModal()
      } catch (error) {
        console.error(error)
      }
    },

    /**reset search and search filters */
    resetSearch() {
      this.valueFilter = null
      this.descFilter = null
      this.invoiceIdFilter = null
      this.statusFilter = true
      this.dateFilter = null
      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null
      this.loadItems()
    },

    /**handle search filters */
    search() {
      let valueFilter, descFilter, invoiceIdFilter, statusFilter, dateFilter
      valueFilter = descFilter = invoiceIdFilter = statusFilter = dateFilter = ''

      /**valueFilter */
      if (this.valueFilter) {
        valueFilter = '&value=' + this.valueFilter
      }

      /**descFilter */
      if (this.descFilter) {
        descFilter = '&description=' + this.descFilter
      }

      /**invoiceIdFilter */
      if (this.invoiceIdFilter) {
        invoiceIdFilter = '&refer_id=' + this.invoiceIdFilter
      }

      /**dateFilter */
      if (this.dateFilter) {
        dateFilter = '&created=' + this.dateFilter
      }

      /**statusFilter */
      statusFilter = '&confirmed=' + this.statusFilter

      this.serverParams.search = valueFilter + descFilter + invoiceIdFilter + dateFilter + statusFilter
      this.loadItems()
    },

    // clear DateFilter
    eraseDateFilter() {
      this.dateFilter = null
    },



    /**
     * @param status
     * @return string the translated of status
     */
    getStatusLabel(status) {
      if (status) return '<span class="badge badge-success">موفق</span>'

      return '<span class="badge badge-danger">ناموفق</span>'
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage
        let offset = this.serverParams.perPage * (this.serverParams.page - 1)
        this.isLoading = true

        /**handle sorts */
        let sort = ''
        if (this.serverParams.sort[0].field) {
          /**check sort asc or desc */
          let sortType = this.serverParams.sort[0].type == 'asc' ? '' : '-'
          sort = this.serverParams.sort[0].field ? '&ordering=' + sortType + this.serverParams.sort[0].field : ''
        }

        /**handle search */
        let search = ''
        if (this.serverParams.search) {
          search = this.serverParams.search
        }

        let res = await this.$axios({
          method: 'get',
          url: 'ledger/?limit=' + perPage + '&offset=' + offset + '&ordering=-created' + '&manual=true' + sort + search
        })
        this.totalRecords = res.data.count
        this.rows = res.data.results
        this.isLoading = false
      } catch (error) {
        console.error(error)
      }
    }
  },
  async mounted() {
    this.$rtl.enableRTL()

    /**get orders */
    try {
      this.isLoading = true
      let res = await this.$axios({
        method: 'get',
        url: 'ledger/?limit=10&offset=0&ordering=-created&manual=true'
      })
      this.totalRecords = res.data.count
      this.rows = res.data.results
      this.isLoading = false
    } catch (error) {
      console.error(error)
    }
  },
  components: {
    VueGoodTable,
    datePicker: VuePersianDatetimePicker
  }
}
</script>

<style>
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';
</style>
