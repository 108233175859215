<template>
  <div class="container-fluid">
    <!-- the page content  -->
    <DigiCoin source="user"></DigiCoin>
  </div>
</template>

<script>

import DigiCoin from "../reports/DigitalCoinRequest.vue"

export default {
  name: 'UserDigitalCoinRequest',
  components: {
    DigiCoin
  }
}


</script>

<style lang="scss" scoped>
</style>
