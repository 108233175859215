<template>
  <div class="col-12 my-0 p-0 card-dashboard">
    <div class="card-new-style orders mx-1">
      <div class="card-items d-flex align-items-center justify-content-between">
        <!-- img, title -->
        <div class="d-flex align-items-center">
          <!-- card img -->
          <div class="card-img">
            <i :class="data.icon + ' icofont-2x text-light'"></i>
          </div>
          <!-- card title -->
          <p class="mx-2 mx-md-1 mb-0 card-title">{{ data.title }}</p>
        </div>

        <!-- text -->
        <div class="card-text">
          <p>{{ data.data }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['data'],

};
</script>

<style lang='scss' >
.card-dashboard {
  .card-new-style {
    background-color: #1e1e2b;
    border-radius: 7px;
    padding: 13px 10px;
  }
  .card-items {
    .card-img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background-color: #393b55;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .card-title {
      white-space: nowrap;
    }
    @media (min-width: 770px) and (max-width: 1300px) {
      flex-wrap: wrap;
      .card-img {
        width: 33px;
        height: 33px;
        i {
          transform: scale(0.8);
        }
      }
      .card-text {
        margin: auto;
      }
    }
  }
}
</style>