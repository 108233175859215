<template>
  <section class="login-section">
    <div class="login-content">
      <div class="login-header">
        <h3 class="m-0">بازیابی کلمه عبور</h3>
      </div>

      <!-- logo brand -->
      <div class="brand">
        <svg xmlns="http://www.w3.org/2000/svg" width="32.062" height="46.469" viewBox="0 0 32.062 46.469">
          <g id="Group_745" data-name="Group 745" transform="translate(-509.893 -437.461)">
            <path
              id="Path_1777"
              data-name="Path 1777"
              d="M864.739,457.912a7.585,7.585,0,0,0-4.6-3.15,12.585,12.585,0,0,0,4.833-3.046,8.326,8.326,0,0,0,2.321-4.518q.789-4.831-2.091-7.282t-9.362-2.455H842.8l-5.961,36.512h13.83c.1,0,.2,0,.3,0l.644-3.945c-.065,0-.128,0-.192,0h-8.68l2.146-13.144h8.78q7.834,0,6.76,6.607a7.085,7.085,0,0,1-2.917,4.8,7.748,7.748,0,0,1-.946.587l-.742,4.548a14.418,14.418,0,0,0,5.151-2.264,11.342,11.342,0,0,0,4.744-7.714A7.587,7.587,0,0,0,864.739,457.912Zm-5.493-6.453a10.123,10.123,0,0,1-5.8,1.577h-7.939l1.895-11.6h7.784q3.91,0,5.61,1.365t1.214,4.377A6.2,6.2,0,0,1,859.246,451.458Z"
              transform="translate(-326.942)"
              fill="#fff"
            />
            <path id="Path_1778" data-name="Path 1778" d="M867.026,503.487h18.913l-.644,3.945H861.1l5.96-36.509h5.283Z" transform="translate(-343.984 -23.502)" fill="#fff" />
          </g>
        </svg>
      </div>

      <!-- reset form -->
      <div>
        <ValidationObserver ref="newMobileAndEmail">
          <div class="login-main">
            <!-- step 1 -> get email or mobile -->
            <div v-if="step == 1">
              <div dir="ltr" class="my-3">
                <p-radio class="p-default p-curve" name="color" value="mobile" color="warning-o" v-model="typeLogin">بازیابی با موبایل</p-radio>
                <p-radio class="p-default p-curve" name="color" value="email" color="warning-o" v-model="typeLogin">بازیابی با ایمیل</p-radio>
                <!-- <p-radio class="p-default p-curve" value="email" name="color" color="warning-o" v-model="typeLogin">1</p-radio> -->
                <!-- <p-radio class="p-default p-curve" value="mobile" name="color" color="warning-o" v-model="typeLogin">2</p-radio> -->
              </div>
              <ValidationProvider rules="required|mobile" name="شماره همراه" v-slot="{ errors }" v-if="typeLogin == 'mobile'">
                <div class="d-flex justify-content-center country-code">
                  <!-- <vue-country-code @onSelect="getCode" :onlyCountries="['ir']" :disabledFetchingCountry="true"> </vue-country-code> -->
                  <div id="countryCodeNumber"></div>
                  <input type="text" @keypress="isNumber($event)" @keyup.enter="sendVerify()" v-model="phoneNumber" placeholder="شماره همراه " />
                </div>
                <div>
                  <span class="error">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <!-- email  -->
              <ValidationProvider rules="required|email" name="ایمیل" v-slot="{ errors }" v-else>
                <div class="d-flex justify-content-center country-code">
                  <!-- <vue-country-code @onSelect="getCode" :onlyCountries="['ir']" :disabledFetchingCountry="true"> </vue-country-code> -->
                  <div id="countryCodeNumber"></div>
                  <input type="text" v-model="email" @keyup.enter="sendVerify()" placeholder=" ایمیل خود را وارد کنید" />
                </div>
                <div>
                  <span class="error">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <div class="login-footer">
                <button class="btn-warning" @click="sendVerify">مرحله بعد</button>
              </div>
            </div>

            <!-- verify form -->
            <div v-if="step == 2">
              <!-- pin code input  -->
              <div class="d-flex justify-content-center pincode" dir="ltr">
                <PincodeInput :length="6" v-model="pinCode" />
              </div>
              <div class="login-footer">
                <button class="btn-warning" @click="checkIdentification">مرحله بعد</button>
              </div>

              <!-- resend code -->
              <!-- disabled countdown  -->
              <span class="resend-disabled d-flex align-items-center justify-content-start my-3" v-if="!resend">
                <svg xmlns="http://www.w3.org/2000/svg" class="mx-1" width="19.513" height="11.869" viewBox="0 0 19.513 11.869">
                  <path
                    id="retweet"
                    d="M67.278,242.807v-4.575a.294.294,0,0,1,.294-.294h1.409c.163,0,.207-.1.1-.221l-3.073-3.5a.251.251,0,0,0-.388,0l-3.075,3.5c-.107.123-.062.221.1.221h1.409a.294.294,0,0,1,.294.294v5.659a1.977,1.977,0,0,0,1.978,1.978h8.589c.163,0,.205-.1.1-.216l-2.136-2.337a.757.757,0,0,0-.493-.216H67.573A.294.294,0,0,1,67.278,242.807Zm12.859-1.168v-5.661A1.977,1.977,0,0,0,78.159,234H69.571c-.163,0-.205.1-.1.216l2.136,2.337a.757.757,0,0,0,.493.216h4.812a.294.294,0,0,1,.294.294v4.575a.294.294,0,0,1-.294.294H75.506c-.163,0-.207.1-.1.221l3.075,3.5a.251.251,0,0,0,.388,0l3.075-3.5c.107-.123.062-.221-.1-.221H80.434A.3.3,0,0,1,80.137,241.639Z"
                    transform="translate(-62.489 -234)"
                    fill="#707070"
                  />
                </svg>
                ارسال مجدد کد تا
                <VueCountdown dir="ltr" @end="onCountdownEnd" :time="timer" :interval="100" v-slot="{ minutes, seconds }" class="mx-1"> {{ minutes }} : {{ seconds }} </VueCountdown>
                ثانیه دیگر
              </span>
              <!-- resend verify code btn  -->
              <span class="resend d-flex align-items-center justify-content-start my-3" @click="resendVerify" v-if="resend">
                <svg xmlns="http://www.w3.org/2000/svg" class="mx-1" width="19.513" height="11.869" viewBox="0 0 19.513 11.869">
                  <path
                    id="retweet"
                    d="M67.278,242.807v-4.575a.294.294,0,0,1,.294-.294h1.409c.163,0,.207-.1.1-.221l-3.073-3.5a.251.251,0,0,0-.388,0l-3.075,3.5c-.107.123-.062.221.1.221h1.409a.294.294,0,0,1,.294.294v5.659a1.977,1.977,0,0,0,1.978,1.978h8.589c.163,0,.205-.1.1-.216l-2.136-2.337a.757.757,0,0,0-.493-.216H67.573A.294.294,0,0,1,67.278,242.807Zm12.859-1.168v-5.661A1.977,1.977,0,0,0,78.159,234H69.571c-.163,0-.205.1-.1.216l2.136,2.337a.757.757,0,0,0,.493.216h4.812a.294.294,0,0,1,.294.294v4.575a.294.294,0,0,1-.294.294H75.506c-.163,0-.207.1-.1.221l3.075,3.5a.251.251,0,0,0,.388,0l3.075-3.5c.107-.123.062-.221-.1-.221H80.434A.3.3,0,0,1,80.137,241.639Z"
                    transform="translate(-62.489 -234)"
                    fill="#707070"
                  />
                </svg>
                ارسال مجدد
              </span>
            </div>

            <!-- step 3 -> get new password -->
            <div v-if="step == 3" class="password-reset-input">
              <!--New password -->
              <ValidationProvider rules="required|confirmed:confirmation" name="کلمه عبور جدید" v-slot="{ errors }">
                <div class="mt-4">
                  <input type="hidden" v-model="password" />
                  <PasswordStrength v-model="password" ref="passwordRef"></PasswordStrength>
                  <span class="error">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <!-- repeat password  -->
              <ValidationProvider dir="ltr" name="تکرار کلمه عبور" v-slot="{ errors }" vid="confirmation">
                <div class="mt-2">
                  <input type="password" v-model="repeatPassword" placeholder="تکرار کلمه عبور" @keyup.enter="changePassword()" />
                  <span class="error">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <div class="login-footer">
                <button class="btn-warning" @click="changePassword">ورود</button>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </section>
</template>

<script>

var mobileReg = /(0|\+98)?([ ]|-|[()]){0,2}9[0|1|2|3|4|9]([ ]|-|[()]){0,2}(?:[0-9]([ ]|-|[()]){0,2}){8}/gi,
  junkReg = /[^\d]/gi,
  persinNum = [/۰/gi, /۱/gi, /۲/gi, /۳/gi, /۴/gi, /۵/gi, /۶/gi, /۷/gi, /۸/gi, /۹/gi],
  num2en = function (str) {
    for (var i = 0;i < 10;i++) {
      str = str.replace(persinNum[i], i)
    }
    return str
  },
  getMobiles = function (str) {
    var mobiles = num2en(str + '').match(mobileReg) || []
    mobiles.forEach(function (value, index, arr) {
      arr[index] = value.replace(junkReg, '')
      arr[index][0] === '0' || (arr[index] = '0' + arr[index])
    })
    return mobiles
  }


/**import validations */
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import fa from 'vee-validate/dist/locale/fa'
extend('required', required)
extend('email', email)
extend('mobile', {
  validate: (value) => {
    return getMobiles(value).length
  },
  message: 'شماره همراه وارد شده صحیح نمی باشد'
})
localize('fa', fa)

/**import auth methods */
import { setToken, setRefreshToken, getToken } from "../../settings/AuthApi"

/**password strenght component */
import PasswordStrength from "../../components/PasswordStrength"

// import Base64 for encode package
import { Base64 } from 'js-base64';

/**the resend countdown */
import VueCountDown from "@chenfengyuan/vue-countdown";

/**the pincode input */
import PincodeInput from "vue-pincode-input";

/** Pretty Checkbox */
import PrettyRadio from 'pretty-checkbox-vue/radio';

export default {
  name: "resetPassword",
  data() {
    return {
      phoneNumber: null,
      password: null,
      repeatPassword: null,
      step: 1,
      pinCode: '',
      resend: false,
      typeLogin: 'email',
      email: null,
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    VueCountdown: VueCountDown,
    PincodeInput,
    'p-radio': PrettyRadio,
    PasswordStrength
  },
  methods: {
    /** submit change password */
    async changePassword() {
      try {
        /**check password strenght */
        if (!this.$refs.passwordRef.valid_password) {
          this.$refs.passwordRef.focus = true
          this.$notify({ text: 'لطفا گذرواژه قوی تری انتخاب نمایید', type: 'warn', })
          return
        }
        if (! await this.$refs.newMobileAndEmail.validate()) return

        this.$axios({
          method: "get",
          url: 'auth/PassReset',
          headers: {
            loginPassword: Base64.encode(this.password),
            Authorization: `Bearer ${getToken()}`,
            receivedCodeForIdentification: this.pinCode
          }
        })

        this.$notify({
          text: " عملیات با موفقیت انجام شد",
          type: "success"
        })

        this.$router.push("dashboard", true)

      } catch (error) {
        console.log(error)
      }
    },

    /**send verify code to user*/
    async sendVerify() {
      if (! await this.$refs.newMobileAndEmail.validate()) return

      try {
        // const reCaptchaToken = await this.$recaptcha('passReset')

        let url = 'auth/PassResetRequestViaEmail'
        let data = {
          idLang: 1065,
          emailAddress: this.email,
          // reCaptchaToken: reCaptchaToken,

        }
        if (this.typeLogin == 'mobile') {
          url = 'auth/PassResetRequestViaMobile'
          data = {
            idLang: 1065,
            mobilePrefix: '+98',
            mobileNumber: this.phoneNumber.substring(1),
            // reCaptchaToken: reCaptchaToken,
          }
        }

        // password Reset Request via mobile and email
        await this.$axios({
          url: url,
          data: data,
        })

        this.step = 2;
      } catch (error) {
        console.log(error)
        return
      }

    },
    /**resend verify code */
    async resendVerify() {
      try {
        this.pinCode = null;
        // const reCaptchaToken = await this.$recaptcha('resend')


        let url = 'auth/ResendIdentificationViaEmail'
        let data = {
          idLang: 1065,
          emailAddress: this.email,
          // reCaptchaToken: reCaptchaToken,

        }
        if (this.typeLogin == 'mobile') {
          url = 'auth/ResendIdentificationViaMobile'
          data = {
            idLang: 1065,
            mobilePrefix: "+98",
            mobileNumber: this.phoneNumber.substring(1),
            // reCaptchaToken: reCaptchaToken,
          }
        }

        // request for resend code
        await this.$axios({
          url: url,
          data: data,
        })
      } catch (error) {
        return;
      }
      this.resend = false;

    },
    /**Check pincode and next step
     */
    async checkIdentification() {
      /**validate token inserted or not */
      // if (!this.pincodeFilled) return this.$notify({ text: "وارد کردن پین کد الزامی است", type: "error" })

      try {

        let url = 'auth/CheckIdentificationViaEmail'
        let data = {
          idLang: 1065,
          emailAddress: this.email,
          receivedCode: this.pinCode
        }
        if (this.typeLogin == 'mobile') {
          url = 'auth/CheckIdentificationViaMobile'
          data = {
            idLang: 1065,
            mobilePrefix: '+98',
            mobileNumber: this.phoneNumber.substring(1),
            receivedCode: this.pinCode
          }
        }

        // request for Check Identification 
        let RefreshTokenCode = await this.$axios({
          url: url,
          data: data,
        })

        /**request for get jwtToken and refreshTokenCode */
        let TokenCode = await this.$axios({
          url: 'auth/TokenGet',
          headers: {
            refreshTokenCode: RefreshTokenCode.data.data,
          },
          method: 'get'
        })

        setToken(TokenCode.data.data.jwtToken)
        setRefreshToken(TokenCode.data.data.refreshTokenCode)


        this.step = 3
      } catch (error) {
        console.log(error)
        return;
      }
      this.sendingRequest = false

    },
    // get just number
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    /**count down end event */
    onCountdownEnd() {
      this.resend = true;
    },
  },
  computed: {
    timer() {
      return parseInt(window.env.VUE_APP_TIMER)
    }
  },
  watch: {
    pinCode() {
      this.pinCode.length == 6 ? this.checkIdentification() : ''
    },
  }
}
</script>

<style lang="scss">
@import '~pretty-checkbox/src/pretty-checkbox.scss';

.login-section {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-content {
    box-shadow: 0 0px 10px #2a2a3a;
    background-color: #1e1e2f;
    border-radius: 15px;
    padding: 30px;
    .login-header {
      padding: 15px 150px;
      background: #ffb115;
      border-radius: 10px;
      transform: translateY(-80%);
      margin-bottom: -15px;
      white-space: nowrap;
      text-align: center;
    }
    .brand {
      margin: 0 0 30px 0;
      text-align: center;
      position: relative;
      &::after,
      &::before {
        content: '';
        width: 35%;
        height: 1px;
        background: rgba(255, 255, 255, 0.171);
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &::before {
        left: 25%;
      }
      &::after {
        right: -9%;
      }
    }
    .login-main {
      .label {
        p {
          color: #6b7795;
        }
      }
      input {
        color: #6b7795;
        background: transparent;
        border: 0.5px solid #6b77955b;
        box-shadow: 0 0px 10px #2a2a3a;
        font-family: 'iranyekan';
      }
      .password {
        margin: 40px 0 0 0;
      }
    }
    .login-footer {
      margin: 40px 0 0 0;
      .btn-warning {
        width: 250px;
        height: 45px;
        margin: auto;
        background: #ffb115;
        border: 1px solid #ffb115;
        font-family: 'iranyekan';
        color: #fff;
        transition: all 0.3s linear;
        outline: none;
        &:hover {
          color: #b47800;
          background: #ffcc66 !important;
          border: 1px solid #b47800;
          transition: all 0.3s linear;
        }
        &:active,
        &:focus {
          background: #ffb115 !important;
          border: 1px solid #ffb115;
        }
      }
    }
  }
  @media (max-width: 1400px) {
    .password,
    .login-footer {
      margin: 20px 0 0 0 !important;
    }
  }
  @media (max-width: 767.9px) {
    .login-content {
      width: 85%;
      padding: 30px 10px !important;

      .login-header {
        padding: 15px 50px !important;
        width: 100%;
      }
      .login-footer {
        .btn-warning {
          width: 95% !important;
        }
      }
    }
  }
  // disabled countdown (resent code)
  span.resend-disabled,
  .resend {
    opacity: 0.7;
    font-weight: bold;
    color: #6a6a6a;
  }
  .pincode {
    margin-bottom: 40px;
    .vue-pincode-input-wrapper {
      .vue-pincode-input {
        padding: 0 !important;
        height: 55px !important;
        max-width: 51px !important;
        margin: 0 9px !important;
        background: #f2f2f2 !important;
        @media (max-width: 550px) {
          height: 34px !important;
          max-width: 29px !important;
          margin: 0 3px !important;
        }
      }
    }
  }
  .resend {
    cursor: pointer;
  }

  .password-reset-input input::placeholder{
    text-align: right;
  }
}
</style>