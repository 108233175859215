<template>
  <div class="container-fluid deposit-claim">
    <div class="row">
      <div class="col-12">
        <card>
          <!-- title and filter -->
          <div class="card-header">
            <div class="card-title d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <h6>ادعای واریز</h6>
                <base-button type="info" class="mx-3" size="sm" @click="showModal('new')">افزودن</base-button>
              </div>
              <h6 class="filter-title" @click="filter = !filter">
                <span v-if="!filter"> + </span>
                <span v-if="filter"> - </span> فیلتر
              </h6>
            </div>
            <!-- filter content -->
            <card v-if="filter" class="filter">
              <form @submit.prevent="loadItems">
                <div class="row">
                  <!-- srchUser  -->
                  <base-input v-if="source != 'user'" dir="ltr" class="col-12 col-md-6 col-xl-3" label=" نام کاربر" type="text" placeholder=" کاربر" v-model="srchUser"> </base-input>
                  <!-- factor -->
                  <base-input dir="ltr" class="col-12 col-md-6 col-xl-3" label=" شماره فاکتور " type="text" placeholder="فاکتور" v-model="srchFactor"> </base-input>

                  <!-- fromFilter -->
                  <div class="col-12 col-md-6 col-xl-3 date-holder">
                    <base-input class="fromDate" label="از تاریخ" type="text" placeholder="از تاریخ" v-model="fromFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDate('from')" type>
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="fromFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".fromDate" view="year" auto-submit></date-picker>
                  </div>

                  <!-- toFilter -->
                  <div class="col-12 col-md-6 col-xl-3 date-holder">
                    <base-input class="toDate" label="تا تاریخ" type="text" placeholder="تا تاریخ" v-model="toFilter"> </base-input>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.214" height="13.568" viewBox="0 0 15.214 13.568" @click="eraseDate('to')" type>
                      <path
                        id="eraser"
                        d="M77.194,116.124a2.04,2.04,0,0,0-.049-2.927q-1.4-1.4-2.793-2.794a2.075,2.075,0,0,0-3.02,0q-4.053,4.051-8.1,8.1a2.08,2.08,0,0,0-.007,3.022c.511.518,1.022,1.038,1.549,1.539a.738.738,0,0,0,.447.184c.76.019,1.522.009,2.281.009.744,0,1.489-.007,2.233.005a.744.744,0,0,0,.584-.238c1.255-1.266,2.52-2.525,3.779-3.786C75.132,118.2,76.177,117.176,77.194,116.124ZM72,120.384l-1,1c-.358.358-.713.722-1.08,1.073a.482.482,0,0,1-.292.129q-2.123.013-4.246,0a.434.434,0,0,1-.273-.115c-.487-.475-.97-.951-1.442-1.44a1.405,1.405,0,0,1,.012-2.041c.965-.974,2.716-2.718,3.469-3.464a.505.505,0,0,1,.715,0l4.14,4.14A.5.5,0,0,1,72,120.384Zm-.064,2.488h5.85v.445h-5.85Z"
                        transform="translate(-62.575 -109.75)"
                        fill="#fff"
                      />
                    </svg>
                    <date-picker v-model="toFilter" format="jYYYY/jMM/jDD" display-format="jYYYY/jMM/jDD" custom-input=".toDate" view="year" auto-submit></date-picker>
                  </div>

                  <!-- srchCSymbol  -->
                  <base-input v-if="false" dir="ltr" class="col-12 col-md-6 col-xl-3" label="نماد بازار" type="text" placeholder="نماد" v-model="srchCSymbol"> </base-input>

                  <!-- srchByAssetLogType -->
                  <div class="col-12 col-md-6 col-xl-4" v-if="false">
                    <span class="cart-lable-color">نوع گزارش</span>
                    <div class="mt-1">
                      <multiselect
                        :showLabels="false"
                        v-model="assetLogFilter"
                        :options="assetLogsFilter"
                        placeholder="نوع تغییر دارایی"
                        track-by="code"
                        label="title"
                        :searchable="false"
                        :allow-empty="false"
                      ></multiselect>
                    </div>
                  </div>

                  <div class="col-12 d-flex justify-content-between flex-wrap">
                    <div class="d-flex flex-wrap">
                      <base-button class="btn-filter" type="primary" size="sm" native-type="Submit">جستجو</base-button>
                      <base-button class="btn-filter" type="success" size="sm" @click="resetSearch">ریست</base-button>
                    </div>
                    <a href="#" target="_blank"><base-button class="btn-filter" type="info" size="sm">دانلود فایل</base-button></a>
                  </div>
                </div>
              </form>
            </card>
          </div>
          <!-- the page content  -->
          <div :class="detectClass">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="source == 'user' ? columnsUser : columns"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- userName -->
                <a v-if="props.column.field == 'userName'" :href="'/#/dashboard/users/' + props.row.userId + '/personal-info'" target="_blank">
                  <span dir="ltr"> {{ props.row.userName }} </span></a
                >

                <!-- type field  -->
                <span v-else-if="props.column.field == 'operation'">
                  <base-button class="" type="primary" size="sm" @click="showModal(props.row)">تائید / رد</base-button>
                </span>

                <!-- coin datail -->
                <div class="coin-detail d-flex align-items-center" v-else-if="props.column.field == 'coinInNetwork'">
                  <div class="d-flex align-items-center" v-if="props.row.cLogoUrl">
                    <!-- coin icon -->
                    <div class="coin-icon">
                      <img :src="props.row.cLogoUrl" alt="coin" />
                    </div>

                    <div class="d-flex flex-column align-items-start">
                      <!-- coin symbol -->
                      <div class="coin-symbol d-flex align-items-center mt-1">
                        <span class="font-bold">{{ props.row.cSymbol }}</span>
                      </div>
                      <!-- network name -->
                      <div class="coin-name">
                        <span>{{ props.row.network.length > 10 ? props.row.network.substring(0, 10) + ' ...' : props.row.network }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-center w-100" v-else>{{ props.row.network.length > 10 ? props.row.network.substring(0, 10) + ' ...' : props.row.network }}</div>
                </div>

                <!-- cptTxHash -->
                <span v-else-if="props.column.field == 'cptTxHash'">
                  <a v-if="props.row.cptTxHash" :href="props.row.cptTxHashExplorerUrl" target="_blank" dir="ltr"> {{ props.row.cptTxHash.substring(0, 5) + ' ...' }} </a>
                  <span v-else>خالی</span>
                </span>

                <!-- limmit field and copy-->
                <span style="cursor: pointer" dir="ltr" @click="copy(props.row.cptAddress)" v-else-if="props.column.field == 'cptAddress'">
                  {{ props.row.cptAddress.substring(0, 5) + ' ...' }}
                </span>

                <!-- limit text and copy value after click -->
                <span v-else-if="props.column.field == 'bankRefNo'" @click="copy(props.row.bankRefNo)" :title="props.row.bankRefNo" style="cursor: pointer">
                  {{ props.row.bankRefNo ? '...' + props.row.bankRefNo.substring(0, 5) : 'خالی' }}</span
                >

                <span v-else-if="props.column.field == 'bankIban'" @click="copy(props.row.bankIban)" :title="props.row.bankIban" style="cursor: pointer">
                  {{ props.row.bankCard ? '...' + props.row.bankCard.substring(0, 5) : 'خالی' }}</span
                >

                <span v-else-if="props.column.field == 'bankCard'" @click="copy(props.row.bankCard)" :title="props.row.bankCard" style="cursor: pointer">
                  {{ props.row.bankCard ? '...' + props.row.bankCard.substring(0, 5) : 'خالی' }}</span
                >

                <span v-else-if="props.column.field == 'bankTrackId'" @click="copy(props.row.bankTrackId)" :title="props.row.bankTrackId" style="cursor: pointer">
                  {{ props.row.bankTrackId ? '...' + props.row.bankTrackId.substring(0, 5) : 'خالی' }}</span
                >
                <span v-else-if="props.column.field == 'rejectReason'" @click="copy(props.row.rejectReason)" :title="props.row.rejectReason" style="cursor: pointer">
                  {{ props.row.rejectReason ? '...' + props.row.rejectReason.substring(0, 5) : 'خالی' }}</span
                >

                <!-- time field -->
                <span v-else-if="props.column.field == 'regTime'" class="d-flex flex-column align-items-center">
                  <b>{{ jdate(props.row.regTime, 'jYYYY/jMM/jDD') }}</b>
                  <b>{{ jdate(props.row.regTime, 'HH:mm:ss') }}</b>
                </span>
                <span v-else-if="props.column.field == 'txTime'" class="d-flex flex-column align-items-center">
                  <b>{{ jdate(props.row.txTime, 'jYYYY/jMM/jDD') }}</b>
                  <b>{{ jdate(props.row.txTime, 'HH:mm:ss') }}</b>
                </span>

                <!-- stateColumnsDeposit field and copy-->
                <span v-else-if="props.column.field == 'id'" style="font-family: iranyekan !important">
                  {{ props.row.id }}
                </span>

                <!-- doneTime -->
                <span v-else-if="props.column.field == 'doneTime'" class="d-flex flex-column align-items-center">
                  <span v-if="props.row.doneTime">
                    <b>{{ jdate(props.row.doneTime, 'jYYYY/jMM/jDD') }}</b>
                    <b>{{ jdate(props.row.doneTime, 'HH:mm:ss') }}</b>
                  </span>
                  <span v-if="!props.row.doneTime"> اجرا نشده </span>
                </span>

                <span v-else-if="props.column.field == 'to'" class="d-flex flex-column align-items-center">
                  <b>{{ jdate(props.row.to, 'jYYYY/jMM/jDD') }}</b>
                  <b>{{ jdate(props.row.to, 'HH:mm:ss') }}</b>
                </span>

                <span v-else-if="props.column.field == 'from'" class="d-flex flex-column align-items-center">
                  <b>{{ jdate(props.row.from, 'jYYYY/jMM/jDD') }}</b>
                  <b>{{ jdate(props.row.from, 'HH:mm:ss') }}</b>
                </span>

                <span v-else-if="props.column.field == 'bankPaidAt'">
                  <div class="d-flex flex-column align-items-center" v-if="props.row.bankPaidAt">
                    <b>{{ jdate(props.row.bankPaidAt, 'jYYYY/jMM/jDD') }}</b>
                    <b>{{ jdate(props.row.bankPaidAt, 'HH:mm:ss') }}</b>
                  </div>
                  <span v-else> پرداخت نشده</span>
                </span>

                <!-- btn field  -->
                <span v-else-if="props.column.field == 'detail'">
                  <base-button class="" type="info" size="sm" @click="detail(props.row)">جزئیات</base-button>
                </span>

                <!-- state  -->
                <span v-else-if="props.column.field == 'claimedState'">
                  <span v-html="getStatusLabel(props.row.idState, props.row.claimedState)"></span>
                  <!-- reject Reason -->
                  <div class="position-absolute reject-reason">
                    <span v-if="props.row.rejectReason">
                      {{ props.row.rejectReason }}
                    </span>
                  </div>
                </span>

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>
      </div>
    </div>

    <!-- modal Add Address -->
    <modal name="modalConfirmWithdraw">
      <!-- reject and confirm claim -->
      <div v-if="currentRow != 'new'">
        <div class="title text-center mt-3">
          <span v-if="currentRow"
            >فاکتور شماره {{ currentRow.factor }}
            <span class="px-3">
              کاربر <span dir="ltr">{{ currentRow.userName }}</span>
            </span>
          </span>
        </div>
        <!-- content -->
        <div class="d-flex align-items-end justify-content-around mb-4">
          <div class="w-75">
            <span class="cart-lable-color">دلیل رد</span>
            <div class="mt-1">
              <multiselect :showLabels="false" v-model="rejectReason" :options="rejectReasons" placeholder="انتخاب دلیل رد  " track-by="id" label="title" :allow-empty="false"></multiselect>
            </div>
          </div>
        </div>

        <!-- btn -->
        <div class="d-flex align-items-center">
          <span v-if="!isLoading">
            <base-button type="primary" @click="submitOperation('confirm')"> تائید</base-button>
            <base-button type="danger" @click="submitOperation('reject')" class="mx-3"> رد</base-button>
          </span>
          <!-- loader -->
          <span v-else class="loader-wait-request mx-5"></span>

          <base-button type="info" @click="hideModal()" class="mx-3"> بستن</base-button>
        </div>
      </div>
      <!-- add new claim -->
      <div v-if="currentRow == 'new'">
        <div class="title text-center mt-3">
          <span> افزودن ادعای واریز </span>
        </div>
        <!-- content -->
        <div class="mb-4">
          <div class="col-12 my-2">
            <span class="cart-lable-color"> شبکه</span>
            <div class="mt-1">
              <multiselect
                :showLabels="false"
                v-model="cptNetwork"
                :options="optionCptNetwork"
                placeholder="انتخاب  شبکه"
                track-by="idNetworkProtocol"
                label="networkProtocol"
                :allow-empty="false"
              ></multiselect>
            </div>
          </div>
          <base-input dir="ltr" class="col-12" label=" کدتراکنش" type="text" placeholder="کدتراکنش" v-model="cptTxHash"> </base-input>
        </div>

        <!-- btn -->
        <div class="">
          <base-button type="primary" @click="submitNewClaim()">
            <span v-if="!isLoading"> ثبت </span>
            <div v-else class="loader-wait-request"></div>
          </base-button>

          <base-button type="info" @click="hideModal()" class="mx-3"> بستن</base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from 'vue-good-table'

/**convert miladi to jalali */
var moment = require('moment-jalaali')

/**talkhabi datepicker */
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

/**import mixin */
import Helper from '../../../settings/Helper'


// Multiselect
import Multiselect from 'vue-multiselect'

export default {
  name: 'Deposit-claim',
  mixins: [Helper],
  props: ['source'],
  data() {
    return {
      selectedNetwork: null,
      address: null,
      selectedCoin: null,
      coins: [],
      columns: [
        {
          label: 'نام کاربر',
          field: 'userName',
          sortable: false
        },
        // {
        //   label: 'نوع',
        //   field: 'claimedType',
        //   sortable: false
        // },
        {
          label: 'وضعیت',
          field: 'claimedState',
          sortable: false
        },
        {
          label: 'ارز در شبکه',
          field: 'coinInNetwork',
          sortable: false
        },

        {
          label: 'شماره فاکتور',
          field: 'factor',
          sortable: false
        },

        {
          label: 'فاکتور از',
          field: 'from',
          sortable: false
        },
        {
          label: 'فاکتور تا',
          field: 'to',
          sortable: false
        },
        {
          label: 'زمان ثبت ادعا',
          field: 'regTime',
          sortable: false
        },
        {
          label: 'آدرس',
          field: 'cptAddress',
          sortable: false
        },
        {
          label: 'کد تراکنش',
          field: 'cptTxHash',
          sortable: false
        },
        {
          label: ' مقدار',
          field: 'amount',
          sortable: false
        },

        {
          label: 'عملیات',
          field: 'operation',
          sortable: false
        },
      ],
      columnsUser: [
        {
          label: 'نوع',
          field: 'claimedType',
          sortable: false
        },
        {
          label: 'وضعیت',
          field: 'claimedState',
          sortable: false
        },
        {
          label: 'ارز در شبکه',
          field: 'coinInNetwork',
          sortable: false
        },

        {
          label: 'شماره فاکتور',
          field: 'factor',
          sortable: false
        },

        {
          label: 'فاکتور از',
          field: 'from',
          sortable: false
        },
        {
          label: 'فاکتور تا',
          field: 'to',
          sortable: false
        },

        {
          label: 'زمان ثبت ادعا',
          field: 'regTime',
          sortable: false
        },
        {
          label: 'آدرس',
          field: 'cptAddress',
          sortable: false
        },
        {
          label: 'کد تراکنش',
          field: 'cptTxHash',
          sortable: false
        },
        {
          label: ' مقدار',
          field: 'amount',
          sortable: false
        },

        {
          label: 'عملیات',
          field: 'operation',
          sortable: false
        },
      ],

      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: window.env.CountItemInPerPage,
        search: null
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: window.env.PerPageDropdown,
        dropdownAllowAll: false
      },
      // filter==true => show filter card
      filter: true,
      srchCSymbol: null,
      srchUser: null,
      srchFactor: null,
      fromFilter: null,
      toFilter: null,
      assetLogsFilter: [{ code: 'SpotPendingOrderList', title: 'سفارشات باز ' }, { code: 'SpotFilledOrderList', title: ' سفارشات پر شده ' }, { code: 'SpotTradeList', title: 'معاملات' }],
      assetLogFilter: { code: 'SpotPendingOrderList', title: 'سفارشات باز ' },

      currentRow: null,
      rejectReasons: [],
      rejectReason: null,

      cptTxHash: null,
      optionCptNetwork: [],
      cptNetwork: null,

    }
  },
  methods: {
    /** show state with set color 
  * @param int idState->detect state id
  * @param string State->text of state
 */
    getStatusLabel(idState, state) {

      let setState = '';
      switch (idState) {
        case 11:
        case 13:
          setState = `<span class='text-dark badge badge-warning'>${state}</span>`;
          break;
        case 21:
          setState = `<span class='text-dark badge badge-success'>${state}</span>`;
          break;
        case 31:
          setState = `<span class='text-dark badge badge-danger'>${state}</span>`;
          break;
        default:
          setState = state;
      }

      return setState;
    },

    async copy(value) {
      if (!value) return
      await navigator.clipboard.writeText(value);
      this.$notify({
        text: `${value} کپی شد`,
        type: "success"
      })
    },
    /**reset search and search filters */
    resetSearch() {
      this.srchCSymbol = this.fromFilter = this.toFilter = this.srchUser = this.srchFactor = null

      this.serverParams.sort.field = null
      this.serverParams.sort.type = null
      this.serverParams.search = null


      this.loadItems()
    },
    /**clear from and to date filter */
    eraseDate(type) {
      type == 'from' ? this.fromFilter = null : this.toFilter = null
    },


    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },


    // load items is what brings back the rows from server
    async loadItems() {
      try {
        let perPage = this.serverParams.perPage
        this.isLoading = true

        let url = 'Organizer/Report/DepositClaimedList'
        let data = [
          {
            key: "srchUser",
            value: this.srchUser ? this.srchUser : null
          },
          {
            key: "srchDateFrom",
            value: this.fromFilter ? moment(this.fromFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
          },
          {
            key: "srchDateTo",
            value: this.toFilter ? moment(this.toFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
          },
          {
            key: "srchFactor",
            value: this.srchFactor ? this.srchFactor : null
          },
        ]
        /** detect parant[from report page or user page] */
        if (this.source == 'user') {
          url = 'Organizer/User/DepositClaimedList'
          data = {
            userId: this.$router.history.current.params.id,
            searchStatements: [
              {
                key: "srchFactor",
                value: this.srchFactor ? this.srchFactor : null
              },
              {
                key: "srchDateFrom",
                value: this.fromFilter ? moment(this.fromFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
              },
              {
                key: "srchDateTo",
                value: this.toFilter ? moment(this.toFilter, 'jYYYY/jM/jD').format('YYYY-MM-DD') : null
              },
            ]
          }
        }

        let res = await this.$axios({
          method: 'post',
          url: `${url}?PageNumber=${this.serverParams.page}&PageSize=${perPage}`,
          data: data
        })
        this.totalRecords = res.data.totalRecords
        this.rows = res.data.data
        this.isLoading = false

      } catch (error) {
        this.totalRecords = 0
        this.rows = null
        this.isLoading = false
      }
    },

    /** submit for new claim
     */
    async submitNewClaim() {
      try {
        if (this.isLoading) return
        if (!this.cptTxHash || !this.cptNetwork)
          return this.$notify({ text: 'لطفا شبکه و کد تراکنش را وارد نمائید', type: 'warn' })

        this.isLoading = true
        await this.$axios({
          url: `Organizer/Report/DepositClaimedAdd`,
          data: {
            IdNetworkProtocol: this.cptNetwork.IdNetworkProtocol,
            cptTxHash: this.cptTxHash
          }
        })
        this.$notify({ text: 'عملیات با موفقیت انجام شد', type: 'success' })
        this.hideModal()

        this.loadItems()
      } catch (error) {
        console.log(error)
      }
      this.isLoading = false

    },

    /** submit for confirm withdraw or reject
    * @param string enum[confirm,reject]
    */
    async submitOperation(type) {
      try {
        if (this.isLoading) return

        if (type == 'reject' && !this.rejectReason)
          return this.$notify({ text: 'وارد نمودن دلیل رد الزامی می باشد', type: 'warn' })
        this.isLoading = true

        await this.$axios({
          method: 'get',
          url: `Organizer/User/${type == 'reject' ? 'DepositClaimedReject' : 'DepositClaimedAccept'}`,
          headers: {
            uIdCode: this.currentRow.userId,
            ID: this.currentRow.id,
            idRejectReason: this.rejectReason ? this.rejectReason.id : 0
          }
        })
        this.$notify({ text: 'عملیات با موفقیت انجام شد', type: 'success' })
        this.hideModal()

        this.loadItems()
      } catch (error) {
        console.log(error)
      }

      this.isLoading = false

    },
    /**show inquiry modal */
    async showModal(row) {
      this.currentRow = row;
      this.$modal.show('modalConfirmWithdraw')
    },

    /**show inquiry modal */
    hideModal() {
      this.cptTxHash = this.cptNetwork = null
      this.currentRow = null
      this.$modal.hide('modalConfirmWithdraw')
    },

    // get Reject Reasons
    async getRejectReasons() {
      try {
        let req = await this.$axios({
          url: "Organizer/RejectReason/ListGetToChoose",
          method: 'get'
        })


        this.rejectReasons = req.data.data
      } catch (error) {
        console.log(error)
      }
    },

    // get network - use in modal new claim
    async getNetwork() {
      try {
        let req = await this.$axios({
          url: "Organizer/Network/ListGet",
          method: 'get'
        })


        this.optionCptNetwork = req.data.data
      } catch (error) {
        console.log(error)
      }
    },
  },
  async mounted() {
    this.$rtl.enableRTL()

    /**get orders */
    this.loadItems()

    this.getRejectReasons()

    // use in add claim
    this.getNetwork()


  },
  components: {
    VueGoodTable,
    datePicker: VuePersianDatetimePicker,
    Multiselect
  },
  computed: {
    detectClass: {
      get() {
        if (this.source != 'user')
          return 'claim-report'
        else
          return 'claim-user'
      },
      set() {
        return
      }
    }
  }
}
</script>

<style lang="scss" >
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';
.deposit-claim{
  .table{
    > thead > tr > th,
    > tbody > tr > th,
    > tfoot > tr > th,
    > thead > tr > td,
    > tbody > tr > td,
    > tfoot > tr > td{
        padding: 12px 7px 25px !important;
    }
  }
  .reject-reason{
    bottom: 4px;
  }
}
.multiselect__content-wrapper {
  .multiselect__content {
    padding: 0 0 70px 0;
  }
}
.logo-coin {
  width: 27px !important;
}

.claim-report {
  th:nth-child(11) {
    white-space: pre !important;
  }
}
.claim-user {
  th:nth-child(10) {
    white-space: pre !important;
  }
}
</style>
