<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <!-- title and filter -->
          <div class="card-header">
            <!-- tab -->
            <div class="mt-4">
              <Tabs :theme="theme" v-model="typeFactor">
                <TabItem name="DepositClaim">
                  <template v-slot:name> ادعای واریز</template>
                </TabItem>
                <TabItem name="Deposit">
                  <template v-slot:name> واریزی ارزی</template>
                </TabItem>
                <TabItem name="DepositTmn">
                  <template v-slot:name> واریزی تومانی</template>
                </TabItem>
                <TabItem name="Withdraw">
                  <template v-slot:name> برداشت ارزی</template>
                </TabItem>
                <TabItem name="WithdrawTmn">
                  <template v-slot:name> برداشت تومانی</template>
                </TabItem>
              </Tabs>
            </div>
          </div>
          <!-- the page content  -->
          <div class="card-body">
            <DepositClaim v-if="typeFactor == 0" source="user"></DepositClaim>
            <Deposit v-if="typeFactor == 1" source="user"></Deposit>
            <DepositTmn v-if="typeFactor == 2" source="user"></DepositTmn>
            <WithdrawTmn v-if="typeFactor == 4" source="user"></WithdrawTmn>
            <Withdraw v-if="typeFactor == 3" source="user"></Withdraw>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>

import DepositTmn from "../factors/DepositTmn.vue"
import Deposit from "../factors/Deposit.vue"
import WithdrawTmn from "../factors/WithdrawTmn.vue"
import Withdraw from "../factors/Withdraw.vue"
import DepositClaim from "../factors/DepositClaim.vue"
/**navigation tabs */
import { Tabs, TabItem } from "vue-material-tabs"


export default {
  name: 'factor-user',
  data() {
    return {
      typeFactor: 0,

      theme: {
        nav: "#1e1e2b",
        navItem: "#40435d",
        navActiveItem: "#d250da",
        slider: "#CE93D8",
        arrow: "#f3f3f3",
      },
    }
  },
  components: {
    DepositTmn,
    Deposit,
    WithdrawTmn,
    Withdraw,
    DepositClaim,
    Tabs, TabItem
  },
  beforeDestroy() {
    this.isLoading = true
  },

}

</script>

<style lang="scss" scoped>
</style>
