<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="card-header">
            <div class="card-title d-flex align-items-center">
              <h6>لیست کارت ها</h6>
              <base-button type="info" class="mx-3" size="sm" @click="showModalAddCard()">افزودن</base-button>
            </div>
          </div>

          <!-- the page content  -->
          <div class="card-body">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoading"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="columns"
              :rtl="true"
              :line-numbers="true"
              styleClass="table tablesorter"
            >
              <!-- customize fields  -->
              <template slot="table-row" slot-scope="props">
                <!-- IBAN field  -->
                <span v-if="props.column.field == 'IBAN'">
                  {{ props.row.IBAN }}
                  <img :src="require('../../../assets/img/copy.svg')" alt="copy" class="copy" @click="copyCode(props.row.IBAN)" />
                </span>

                <!-- card_number field  -->
                <span v-else-if="props.column.field == 'card_number'">
                  {{ props.row.card_number }}
                  <img :src="require('../../../assets/img/copy.svg')" alt="copy" class="copy" @click="copyCode(props.row.card_number)" />
                </span>

                <!-- actions field  -->
                <span v-else-if="props.column.field == 'actions'">
                  <base-button size="sm" type="primary" @click="showModal(props.row.id)">بررسی</base-button>
                </span>

                <!-- type field  -->
                <span v-else-if="props.column.field == 'state'" v-html="getStatusLabel(props.row.idState)"></span>

                <!-- delete field  -->
                <span v-else-if="props.column.field == 'delete'">
                  <base-button class="" type="danger" size="sm" @click="showModalRemova(props.row.idBankAccount, props.row.bankCardNo)">حذف</base-button>
                </span>

                <!-- show in default  -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </card>

        <!-- modal -->
        <modal name="inquiry">
          <div class="title text-center">
            <p>اطلاعات ثبت شده توسط کاربر</p>
          </div>
          <div class="d-flex align-items-center justify-content-between my-3 mx-5">
            <div class="user-name">
              <span>نام</span>
              <p>{{ username }}</p>
            </div>
            <div v-if="false" class="national-number">
              <span>کد ملی</span>
              <p>{{ nationalNumber }}</p>
            </div>
          </div>
          <hr class="mx-5 bg-white my-4" />
          <div class="text-center">
            <p>تاییدیه</p>
          </div>
          <div class="d-flex align-items-center justify-content-between mt-3 mx-5">
            <div class="cart-number">
              <span>شماره کارت</span>
              <p>{{ cartNumber }}</p>
            </div>
            <div class="cart-owner">
              <span>نام</span>
              <p>{{ cartOwner }}</p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-3 mx-5">
            <div class="account-number">
              <span>شماره حساب</span>
              <p>{{ accountNumber }}</p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-around">
            <base-button type="success" @click="submitInquiry">تایید اطلاعات</base-button>
            <base-button type="danger" @click="hideModal()"> انصراف</base-button>
          </div>
          <!-- confirmation -->
        </modal>

        <!-- modal Add Card -->
        <modal name="modalAddCard">
          <div class="title text-center">
            <p>افزودن کارت جدید</p>
          </div>
          <div class="user-name">
            <form @submit.prevent="submitAddCard">
              <base-input v-model="bankName" class="w-100 my-4" label="نام بانک" type="text" placeholder="نام بانک"> </base-input>
              <base-input v-model="numberCard" class="w-100 my-4" label="شماره کارت" type="text" placeholder="شماره کارت" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"> </base-input>
              <base-input v-model="shabaCard" class="w-100 my-4" label="شماره شبا" type="text" placeholder="شماره شبا"> </base-input>
            </form>
          </div>

          <div class="d-flex align-items-center justify-content-around">
            <base-button type="success" @click="submitAddCard">تایید اطلاعات</base-button>
            <base-button type="danger" @click="hideModalAddCard()"> انصراف</base-button>
          </div>
          <!-- confirmation -->
        </modal>

        <!-- modal confirm delete-->
        <modal name="removeItem" :click-to-close="false">
          <div class="title d-flex justify-content-between align-items-center">
            <h6>حذف شماره کارت "{{ titleRemove }}" انجام شود ؟</h6>
            <div v-if="!isLoading" class="d-flex align-items-center justify-content-end">
              <base-button type="success" @click="remove()">تایید </base-button>
              <base-button type="danger" @click="hideModalRemova()"> انصراف</base-button>
            </div>
            <!-- loader -->
            <div v-else class="">
              <div class="loader-wait-request mx-5"></div>
            </div>
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
/**grid table */
import { VueGoodTable } from 'vue-good-table'

/**import mixin */
import Helper from '../../../settings/Helper'

export default {
  name: 'UserCards',
  mixins: [Helper],
  data() {
    return {
      columns: [
        {
          label: 'IBAN',
          field: 'iban',
          sortable: false
        },
        {
          label: 'بانک',
          field: 'bankName',
          sortable: false
        },
        {
          label: ' کارت بانکی',
          field: 'bankCardNo',
          sortable: false,
          html: true
        },
        {
          label: 'وضعیت',
          field: 'state',
          sortable: false
        },
        {
          label: 'حذف',
          field: 'delete',
          sortable: false
        }

      ],
      rows: [],
      totalRecords: null,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: window.env.CountItemInPerPage,
      },
      paginationOptions: {
        enabled: true,
        jumpFirstOrLast: true,
        nextLabel: 'بعدی',
        prevLabel: 'قبلی',
        firstLabel: 'اولین',
        lastLabel: 'آخرین',
        rowsPerPageLabel: 'تعداد ردیف هر صفحه',
        ofLabel: 'از',
        pageLabel: 'صفحه', // for 'pages' mode
        allLabel: 'همه',
        perPageDropdown: window.env.PerPageDropdown,
        dropdownAllowAll: false
      },
      // modal datas
      username: 'محمد هرازه',
      nationalNumber: '06455455865',
      accountNumber: '45215465',
      cartNumber: '60370150154856',
      cartOwner: 'سپه',
      cartId: null,

      /** for add new card */
      shabaCard: null,
      numberCard: null,
      bankName: null,

      /** remove vard */
      titleRemove: null,
      idRemove: null
    }

  },
  methods: {
    /**show modal remove item 
        * @param int and string of item 
        */
    async showModalRemova(id, title) {
      try {
        this.idRemove = id
        this.titleRemove = title
        this.$modal.show('removeItem')
      } catch (error) {
        console.error(error)
      }
    },

    /**hide modal remove item and reload data*/
    async hideModalRemova() {
      this.$modal.hide('removeItem')
      this.idRemove = null
      this.titleRemove = null
    },

    // remove notification\
    async remove() {
      try {
        if (this.isLoading) return
        await this.$axios({
          url: 'Organizer/User/DeleteBankAccount',
          method: 'delete',
          headers: {
            idBankAccount: this.idRemove
          }

        })

        this.$notify({
          text: " عملیات با موفقیت انجام شد",
          type: "success"
        })

      } catch (error) {
        console.log(error)
      }
      this.loadItems();
      this.isLoading = false
      this.hideModalRemova()
    },

    /**show add card modal */
    async showModalAddCard() {
      this.$modal.show('modalAddCard')
    },

    /**show add card modal */
    hideModalAddCard() {
      this.$modal.hide('modalAddCard')
    },

    // submit add
    async submitAddCard() {
      try {
        if (!this.numberCard || !this.shabaCard || !this.bankName) return this.$notify({ text: 'تکمیل تمام فیلدها الزامی می باشد', type: 'error' })


        this.isLoading = true

        await this.$axios({
          method: 'get',
          url: 'Organizer/User/AddBankAccount',
          headers: {
            iban: this.shabaCard,
            bankCardNo: this.numberCard,
            bankName: this.bankName,
            uIdCode: this.$router.history.current.params.id
          }
        })

        this.$notify({
          text: 'شماره کارت جدید با موفقیت ثبت شد',
          type: 'success'
        })


      } catch (error) {
        console.error(error)
      }

      this.isLoading = false

      this.loadItems()
      this.hideModalAddCard()

    },



    /**submit inquiry */
    async submitInquiry() {
      try {
        let res = await this.$axios({
          method: 'post',
          url: '/card/verify/' + this.cartId
        })

        this.$notify({ text: 'اطلاعات تائید شد', type: 'success' })
        this.hideModal()
        this.loadItems()
      } catch (error) {
        return console.log(error)
      }
    },

    /**show inquiry modal */
    async showModal(id) {
      try {
        this.isLoading = true
        let res = await this.$axios({
          method: 'get',
          url: '/card/information/' + id
        })
        this.isLoading = false

        this.cartId = id
        this.username = res.data.profile.first_name + ' ' + res.data.profile.last_name
        this.accountNumber = res.data.data.IBAN
        this.cartNumber = res.data.data.card
        this.cartOwner = res.data.data.bankName

        this.$modal.show('inquiry')
      } catch (error) {
        console.error(error)
      }
    },

    /**show inquiry modal */
    hideModal() {
      this.cartId = this.username = this.accountNumber = this.cartNumber = this.cartOwner = null

      this.$modal.hide('inquiry')
    },

    async copyCode(s) {
      await this.copyToClipboard(s)
      this.$notify({
        text: 'کپی انجام شد',
        type: 'success',
        group: 'copynotif'
      })
    },

    /**handle unssl server */
    async copyToClipboard(textToCopy) {
      // navigator clipboard api needs a secure context (https)
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return await navigator.clipboard.writeText(textToCopy)
      } else {
        // text area method
        let textArea = document.createElement('textarea')
        textArea.value = textToCopy
        // make the textarea out of viewport
        textArea.style.position = 'fixed'
        textArea.style.left = '-999999px'
        textArea.style.top = '-999999px'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand('copy') ? res() : rej()
          textArea.remove()
        })
      }
    },


    /**
     * @param status
     * @return string the translated of status
     */
    getStatusLabel(status) {
      if (status == 0) {
        return '<span class="badge badge-success">فعال</span>'
      } else {
        return '<span class="badge badge-danger">غیرفعال</span>'
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field
          }
        ]
      })
      this.loadItems()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    // load items is what brings back the rows from server

    async loadItems() {
      this.$rtl.enableRTL()

      /**get orders */
      try {
        this.isLoading = true
        let res = await this.$axios({
          method: 'get',
          url: 'Organizer/User/GetBankAccount',
          headers: {
            uIdCode: this.$router.history.current.params.id
          }
        })
        this.totalRecords = res.data.totalRecords
        this.rows = res.data.data
        this.isLoading = false
      } catch (error) {
        console.error(error)
      }
    }
  },
  async mounted() {
    this.loadItems()
  },
  components: {
    VueGoodTable
  }
}
</script>

<style lang="scss">
/**vue table */
@import '~vue-good-table/dist/vue-good-table.css';

.copy {
  width: 14px !important;
  cursor: pointer;
}
</style>
